import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Project from "../Shared/Project.js"
import styles from "./Index.scss"
import InfiniteScroller from "../../Shared/InfiniteScroller.js"

const propTypes = {
  projects: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

const Index = (props) => {
  const [projects, setProjects] = useState(props.projects)

  let projectList = []
  projects.forEach((project, index) => {
    projectList.push(
      <div key={index}>
        <Project project={project} />
      </div>
    )
  })

  return (
    <div>
      <div className={styles.projects_container}>
        <h2>{props.title}</h2>
        {projectList}

        <InfiniteScroller
          items={projects}
          setState={setProjects}
          itemsKey="projects"
        />
      </div>
    </div>
  )
}

Index.propTypes = propTypes

export default Index
