import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./New.scss"
import RailsForm from "./../../../Shared/RailsForm.js"
import TextField from "./../../FormTags/TextField.js"
import Submit from "./../../FormTags/Submit.js"

const propTypes = {
  withdrawableAmount: PropTypes.number.isRequired
}

const New = (props) => {
  const [withdrawalAmount, setWithdrawalAmount] = useState()

  const formElements = (
    <>

    </>
  )

  return (
    <div>
      <h1>引き出し</h1>
      <p>
        現在の引き出し可能額は
        <span className={styles.withdrawableAmount}>
          {props.withdrawableAmount.toLocaleString()}円
        </span>
        です
      </p>

      <RailsForm
        action={`/influencer/withdrawal_requests/confirmation`}
        elements={formElements}
      >
        <div className={styles.withdrawalAmountFieldContainer}>
          <TextField
            label="引き出し額"
            id="withdrawal_request_withdrawable_amount"
            name="withdrawal_request[withdrawal_amount]"
            type="number"
            value={withdrawalAmount}
            setState={setWithdrawalAmount}
            placeholder="5000"
            required={true}
            className={styles.withdrawalAmountField}
          />

          <p className={styles.currencyUnit}>円</p>
        </div>

        <Submit
          className={styles.largeRoundnessButton}
          text={"確認する"}
          buttonType="circle"
        />
      </RailsForm>
    </div>
  )
}

New.propTypes = propTypes

export default New
