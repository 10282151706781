import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Select from "../../../../FormTags/Select.js"
import CheckboxBool from "../../../../FormTags/CheckboxBool.js"
import TextField from "../../../../FormTags/TextField.js"
import TextArea from "../../../../FormTags/TextArea.js"
import ZipCode from "../../../../FormTags/ZipCode.js"

const propTypes = {
  prefectures: PropTypes.array.isRequired,
  setIsVisitInput: PropTypes.func.isRequired,
}

const VisitInput = (props) => {
  const project = props.project
  const project_type_visit = project && project.project_type_visit
  const address = project_type_visit && project_type_visit.address
  const [transportationFullPayment, setTransportationFullPayment] =
    useState(project_type_visit ? project_type_visit.transportation_full_payment : false)
  const [transportationExpenses, setTransportationExpenses] =
    useState(project_type_visit && project_type_visit.transportation_expenses)
  const [restraintTime, setRestraintTime] =
    useState(project_type_visit && project_type_visit.restraint_time)
  const [addressInput, setAddressInput] = useState(true)
  const [postalCode, setPostalCode] = useState(address && address.postal_code)
  const [prefectureId, setPrefectureId] = useState(address && address.prefecture_id)
  const [city, setCity] = useState(address && address.city)
  const [block, setBlock] = useState(address && address.block)
  const [building, setBuilding] = useState(address && address.building)
  const [belongings, setBelongings] = useState(project_type_visit && project_type_visit.belongings)
  const [howToAccept, setHowToAccept] = useState(project_type_visit && project_type_visit.how_to_accept)
  const [accompany, setAccompany] = useState(project_type_visit && project_type_visit.accompany)

  const checkValues = (
    isExist(restraintTime) &&
    (
      !addressInput ||
      (
        addressInput &&
        isExist(postalCode) &&
        isExist(prefectureId) &&
        isExist(city) &&
        isExist(block) &&
        isExist(belongings) &&
        isExist(howToAccept)
      )
    )
  )

  useEffect(() => {
    props.setIsVisitInput(checkValues)
  })

  let prefectureOptions = []
  props.prefectures.forEach((prefecture) => {
    prefectureOptions.push({
      value: prefecture.id,
      name: prefecture.name,
    })
  })

  return (
    <div>
      <CheckboxBool
        label="交通費を全額負担する"
        id="project_type_visit_transportation_full_payment"
        name="project_type_visit[transportation_full_payment]"
        value={transportationFullPayment}
        setState={setTransportationFullPayment}
        onChange={props.onChange}
      />

      {!transportationFullPayment && (
        <>
          <TextField
            type="number"
            label="負担する交通費"
            id="project_type_visit_transportation_expenses"
            name="project_type_visit[transportation_expenses]"
            value={transportationExpenses}
            setState={setTransportationExpenses}
            required={true}
            onChange={props.onChange}
          />
        </>
      )}

      <Select
        label="拘束時間"
        id="project_type_visit_restraint_time"
        name="project_type_visit[restraint_time]"
        options={[
          { value: null, name: "拘束時間を選択してください" },
          { value: 1, name: "1時間" },
          { value: 2, name: "2時間" },
          { value: 3, name: "3時間" }
        ]}
        value={restraintTime}
        setState={setRestraintTime}
        required={true}
        onChange={props.onChange}
      />

      <CheckboxBool
        label="会場の住所を入力する"
        id="project_type_visit_adress_input"
        name="project_type_visit_adress_input"
        value={addressInput}
        setState={setAddressInput}
      />

      { addressInput &&
        <>
          <ZipCode
            label="郵便番号"
            id="address_postal_code"
            name="address[postal_code]"
            value={postalCode}
            setState={setPostalCode}
            setPrefectureId={setPrefectureId}
            setCity={setCity}
            required={true}
          />

          <Select
            label="都道府県"
            id="address_prefecture_id"
            name="address[prefecture_id]"
            options={prefectureOptions}
            value={prefectureId}
            setState={setPrefectureId}
            required={true}
          />

          <TextField
            label="市区町村"
            id="address_city"
            name="address[city]"
            value={city}
            setState={setCity}
            required={true}
          />

          <TextField
            label="番地"
            id="address_block"
            name="address[block]"
            value={block}
            setState={setBlock}
            required={true}
          />

          <TextField
            label="マンション名・部屋番号等"
            id="address_building"
            name="address[building]"
            value={building}
            setState={setBuilding}
          />
        </>
      }
      <TextArea
        label="持ち物"
        id="project_type_visit_belongings"
        name="project_type_visit[belongings]"
        value={belongings}
        setState={setBelongings}
        required={true}
        onChange={props.onChange}
      />
      <TextArea
        label="受付方法"
        id="project_type_visit_how_to_accept"
        name="project_type_visit[how_to_accept]"
        value={howToAccept}
        setState={setHowToAccept}
        required={true}
        onChange={props.onChange}
      />

      <CheckboxBool
        label="お子様の同伴可否"
        id="project_type_visit_accompany"
        name="project_type_visit[accompany]"
        value={accompany}
        setState={setAccompany}
        onChange={props.onChange}
      />
    </div>
  )
}

VisitInput.propTypes = propTypes

export default VisitInput
