import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./MessageTable.scss"
import LeftImg from "./left.png"
import Submit from "../../FormTags/Submit.js"
import LazyLoad from "../../../Shared/LazyLoad.js"
import RailsForm from "../../../Shared/RailsForm.js"

const propTypes = {
  messageRoom: PropTypes.object.isRequired,
}

const MessageTable = (props) => {
  const messageRoom = props.messageRoom
  const messageList = props.messageRoom.messages
  const influencerMessagesPath = '/influencer/messages'

  const scrollMessageBottom = (behavior) => {
    let target = document.getElementById('message')
    let bottom = target.scrollHeight - target.clientHeight
    target.scroll({
      top: bottom,
      behavior: behavior
    })
  };

  useEffect(() =>
    scrollMessageBottom("auto")
    , [])

  const formElements = (
    <>
      <textarea name="message_content" />
      <input type="hidden" name="message_room_id" value={messageRoom.id} />
      <Submit text="送信" className={styles.message_submit} />
    </>
  )

  let recent_date = new Date(0)
  let current_date = new Date()
  let messages = []
  messageList.forEach((message, index) => {
    let message_date = new Date(message.created_at)

    if (recent_date.getFullYear() !== message_date.getFullYear() || recent_date.getMonth() !== message_date.getMonth() || recent_date.getDate() !== message_date.getDate()) {
      if (current_date.getFullYear() === message_date.getFullYear()) {
        messages.push(
          <div key={`1-${index}`} className={styles.message_date}>
            {`${message_date.getMonth() + 1}月${message_date.getDate()}日`}
          </div>
        )
      } else {
        messages.push(
          <div key={`1-${index}`} className={styles.message_date}>
            {`${message_date.getFullYear()}年${message_date.getMonth() + 1}月${message_date.getDate()}日`}
          </div>
        )
      }
    }
    let messageType
    if (message.sender_type === 'influencer' || message.sender_type === 'maro_for_sponsor') {
      messageType = 'sender'
    } else {
      messageType = 'receiver'
    }

    messages.push(
      <div key={`2-${index}`} className={`${styles.message_content} ${styles[`message_${messageType}`]}`}>
        <div className={styles.message_content_icon}>
          {message.sender_type === 'influencer'
            ? <LazyLoad src={String(messageRoom.influencer.profile_pic)}/>
            : message.sender_type === 'sponsor'
              ? <LazyLoad src={String(messageRoom.sponsor.image_url)}/>
              : <img />}
        </div>
        {
          message.message_type == "message" &&
          <div className={styles.message_content_text}>
            {message.content}
          </div>
        }
        {
          message.message_type == "project" &&
          message.project &&
          <div className={`${styles.message_content_info} ${styles.message_content_project}`}>
            <LazyLoad src={message.project.image_url}/>
            <div className={styles.message_content_project_detail}>
              <p className={styles.message_content_project_title}>{message.project.title}</p>
              <p className={styles.message_content_project_list_price}>商品の無料提供（¥{message.project.product.list_price.toLocaleString()}相当）</p>

              <a href={`/influencer/projects/${message.project.id}`} className={styles.message_content_project_button}>
                プロジェクトの詳細を見る
              </a>
            </div>
          </div>
        }
        {
          message.message_type == "message" &&

          <div className={styles.message_content_info}>
            {messageType === 'sender' && message.read_message && <div className="read_message">既読</div>}

            <div className="message_content_time">
              {`${message_date.getHours()}:${('0' + message_date.getMinutes()).slice(-2)}`}
            </div>
          </div>
        }
      </div>
    )
    recent_date = message_date
  })

  return (
    <div className={styles.message_table}>
      <div className={styles.message_header}>
        <div>
          <a href={influencerMessagesPath}>
            <img src={LeftImg} />
          </a>
          <span>
            {messageRoom.sponsor.name}
          </span>
        </div>
      </div>
      <div className={styles.message_contents} id="message">
        {messages}
      </div>
      <div className={styles.message_form}>
        <RailsForm
          action={influencerMessagesPath}
          elements={formElements}
          remote={true}
        />
      </div>
    </div>
  )
}



MessageTable.propTypes = propTypes


export default MessageTable
