import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import ProductInput from "./Step02/ProductInput.js"
import VisitInput from "./Step02/VisitInput.js"
import BuyInput from "./Step02/BuyInput.js"
import AppInput from "./Step02/AppInput.js"
import ImageFile from "../../../FormTags/ImageFile.js"
import CheckboxBool from "../../../FormTags/CheckboxBool.js"
import TextField from "../../../FormTags/TextField.js"
import TextArea from "../../../FormTags/TextArea.js"
import styles from "./Step02.scss"

const propTypes = {
  stepBackBtn: PropTypes.object,
  projectTypeId: PropTypes.number,
  operationSystems: PropTypes.array.isRequired,
  prefectures: PropTypes.array.isRequired,
  setProductName: PropTypes.func.isRequired,
  nextBtn: PropTypes.object.isRequired,
  typeUseId: PropTypes.number.isRequired,
  typeVisitId: PropTypes.number.isRequired,
  typeBuyId: PropTypes.number.isRequired,
  typeAppUseId: PropTypes.number.isRequired,
  steps: PropTypes.array
}

const Step02 = (props) => {
  const project = props.project
  const product = project && project.product
  const product_operation_systems = product && product.product_operation_systems || []

  const [isApplication, setIsApplication] = useState(!!product_operation_systems.length)
  const [isProductInput, setIsProductInput] = useState(false)
  const [isVisitInput, setIsVisitInput] = useState(false)
  const [isBuyInput, setIsBuyInput] = useState(false)
  const [isAppInput, setAppInput] = useState(false)
  const [title, setTitle] = useState(project && project.title)
  const [image, setImage] = useState(project && project.image)
  const [appointmentInfluencerStatue, setAppointmentInfluencerStatue] = useState(project && project.appointment_influencer_statue)
  const [message, setMessage] = useState(project && project.message)

  const [messageImage, setMessageImage] = useState(project && project.message_image)

  const checkValues = (
    isExist(title) &&
    isExist(image) &&
    isExist(appointmentInfluencerStatue) &&
    isProductInput
  )

  return (
    <div>
      <div className={styles.header}>
        {props.stepBackBtn}
        {props.saveBtn}
      </div>

      { props.steps.length == 1 ? <h2>プロジェクトの魅力を伝えよう</h2> : <h2>{props.steps.indexOf(props.step) + 1}/{props.steps.length} プロジェクトの魅力を伝えよう</h2> }

      <div className={styles.container}>
        <TextField
          label="プロジェクトのタイトル"
          id="project_title"
          name="project[title]"
          value={title}
          setState={setTitle}
          onChange={props.onChange}
          required={true}
        />
        <ImageFile
          label="プロジェクトのトップ画像"
          id="project_image"
          name="project[image]"
          value={image}
          setState={setImage}
          onChange={props.onChange}
          required={project ? false : true}
        />
      </div>

      <div className={styles.container}>
        <h3>{props.projectTypeId === props.typeVisitId ? "サービス情報" : "商品情報"}</h3>
        <ProductInput
          setProductName={props.setProductName}
          setIsProductInput={setIsProductInput}
          product={product}
          onChange={props.onChange}
          projectTypeId={props.projectTypeId}
          typeVisitId={props.typeVisitId}
        />
      </div>

      {props.projectTypeId == props.typeVisitId && (
        <div className={styles.container}>
          <VisitInput
            prefectures={props.prefectures}
            setIsVisitInput={setIsVisitInput}
            project={project}
          />
        </div>
      )}

      {props.projectTypeId == props.typeBuyId && (
        <div className={styles.container}>
          <BuyInput setIsBuyInput={setIsBuyInput} project={project} />
          <CheckboxBool
            label="WEB/アプリ"
            value={isApplication}
            setState={setIsApplication}
            name="isApplication"
            onChange={props.onChange}
          />
          {isApplication && (
            <>
              <AppInput
                operationSystems={props.operationSystems}
                setAppInput={setAppInput}
                project={project}
                onChange={props.onChange}
              />
            </>
          )}
        </div>
      )}

      {props.projectTypeId == props.typeAppUseId && (
        <div className={styles.container}>
          <AppInput
            operationSystems={props.operationSystems}
            setAppInput={setAppInput}
            project={project}
          />
        </div>
      )}

      <div className={styles.container}>
        <TextArea
          label="起用したいインフルエンサー像"
          id="project_appointment_influencer_statue"
          name="project[appointment_influencer_statue]"
          value={appointmentInfluencerStatue}
          setState={setAppointmentInfluencerStatue}
          required={true}
          onChange={props.onChange}
        />
      </div>

      <div className={styles.container}>
        <ImageFile
          label="企業からのメッセージ"
          id="project_message_image"
          name="project[message_image]"
          value={messageImage}
          setState={setMessageImage}
          onChange={props.onChange}
        />
        <TextArea
          label="メッセージ"
          id="project_message"
          name="project[message]"
          value={message}
          setState={setMessage}
          required={false}
          onChange={props.onChange}
        />
      </div>

      {checkValues && (
        <>
          {
            props.projectTypeId === props.typeUseId &&
            props.nextBtn
          }
          {
            props.projectTypeId === props.typeVisitId &&
            isVisitInput && (
              props.nextBtn
            )
          }
          {
            props.projectTypeId === props.typeBuyId &&
            isBuyInput &&
            !isApplication && (
              props.nextBtn
            )
          }
          {
            props.projectTypeId === props.typeBuyId &&
            isBuyInput &&
            isApplication &&
            isAppInput && (
              props.nextBtn
            )
          }
          {
            props.projectTypeId === props.typeAppUseId &&
            isAppInput && (
              props.nextBtn
            )
          }
        </>
      )}
    </div>
  )
}

Step02.propTypes = propTypes

export default Step02
