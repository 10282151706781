import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import LazyLoad from "../../Shared/LazyLoad.js"
import styles from "./Project.scss"

const propTypes = {
  project: PropTypes.object.isRequired,
  previewMode: PropTypes.bool,
  detail: PropTypes.detail,
  smallStyle: PropTypes.bool,
  sponsorLink: PropTypes.bool
}

const defaultProps = {
  smallStyle: false,
  sponsorLink: false
}

const Project = (props) => {
  const influencerProjectPath = `/influencer/projects`
  const influencerSponsorsPath = `/influencer/sponsors/${props.project.sponsor_id}`

  const ensureInfluencerMode = (e) => {
    if(props.previewMode) {
      e.preventDefault()
      return false
    } else {
      return true
    }
  }

  return (
    <div className={`${styles.container} ${props.smallStyle ? styles.small : ""}`}>
      <a href={`${influencerProjectPath}/${props.project.id}`} onClick={ensureInfluencerMode}>
        <div className={styles.project_image_container}>
          <LazyLoad className={props.detail ? styles.project_image_detail : styles.project_image} src={String(props.project.image)} />
        </div>
        <div className={styles.title}>
          {props.project.title}
        </div>
      </a>
      {props.project.product && (
        <a
          href={props.project.product.url}
          className={styles.link}
          target="_blank"
          rel="noopener"
          onClick={ensureInfluencerMode}
        >サイトを見る ＞</a>
      )}
      {props.project.sponsor && !props.sponsorLink && (
        <div className={styles.sponsor_container}>
          <LazyLoad src={String(props.project.sponsor.image)} />
          <div className={styles.sponsor_name}>
            {props.project.sponsor.name}
          </div>
        </div>
      )}

      {props.project.sponsor && props.sponsorLink && (
        <a href={influencerSponsorsPath} onClick={ensureInfluencerMode}>
          <div className={styles.sponsor_container}>
            <LazyLoad src={String(props.project.sponsor.image)} />
            <div className={styles.sponsor_name}>
              {props.project.sponsor.name}
            </div>
          </div>
        </a>
      )}
    </div>
  )
}

Project.propTypes = propTypes
Project.defaultProps = defaultProps

export default Project
