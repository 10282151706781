import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./Index.scss"

const Index = () => {
  return (
    <div>
      <h1>レポート</h1>

      <div className={styles.selectbox_container}>
        <div className={styles.selectbox}>プロジェクト名</div>
        <div className={styles.selectbox}>20xx/xx/xx-20xxx/xx/xx</div>
      </div>

      <div className={styles.container_title}>主要KPI</div>
      <div className={styles.kpi_container}>
        <div className={styles.kpi_item}>
          <div>合計訪問回数</div>
          <div className={styles.kpi_result}>99</div>
        </div>

        <div className={styles.kpi_item}>
          <div>合計購入回数</div>
          <div className={styles.kpi_result}>75</div>
        </div>

        <div className={styles.kpi_item}>
          <div>CVR</div>
          <div className={styles.kpi_result}>33%</div>
        </div>

        <div className={styles.kpi_item}>
          <div>CPC</div>
          <div className={styles.kpi_result}>¥223</div>
        </div>

        <div className={styles.kpi_item}>
          <div>合計報酬額</div>
          <div className={styles.kpi_result}>¥19,800</div>
        </div>
      </div>

      <div className={styles.chart_container}>
        <div className={styles.chart_text}>
          折れ線グラフ（回数 × 日付）
        </div>
        <div className={styles.chart_text}>
          インフルエンサー、投稿数、クリック発生数の増減を重ねて相関を確認する
        </div>
      </div>

      <div className={styles.container_title}>インフルエンサーごとの成果レポート</div>
      <table>
        <tbody>
          <tr>
            <th>インフルエンサー</th>
            <th>クリック数</th>
            <th>購入数</th>
            <th>CVR</th>
            <th>CPC</th>
            <th>CPA</th>
            <th>合計報酬額</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Index
