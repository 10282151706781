import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'

import Request from "./Request.js"
import Unshipped from "./Unshipped.js"
import PostReport from "./PostReport.js"
import Evaluation from "./Evaluation.js"
import Management from "./Management.js"
import styles from "./Panel.scss"

const Panel = (props) => {
  const TYPE_USE_ID = 1
  const [pendingCount, setPendingCount] = useState(props.pending_count)
  const [approvedCount, setApprovedCount] = useState(props.approved_count)
  const [rejectedCount, setRejectedCount] = useState(props.rejected_count)
  const [unshipmentCount, setUnshipmentCount]  = useState(props.unshipment_count)
  const [shippedCount, setShippedCount] = useState(props.shipped_count)
  const [unreportedCount, setUnreportedCount] = useState(props.unreported_count)
  const [waitingEvaluationCount, setWaitingEvaluationCount] = useState(props.waiting_evaluation_count)
  const [unconfirmCvCount, setUnconfirmCvCount] = useState(props.unconfirm_cv_count)
  const [confirmedCvCount, setConfirmedCvCount] = useState(props.confirmed_cv_count)

  const updatePanelCount = () => {
    fetch(`/sponsor/projects/${props.project.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      console.log(jsonData)
      setPendingCount(jsonData.pending_count)
      setApprovedCount(jsonData.approved_count)
      setRejectedCount(jsonData.rejected_count)
      setUnshipmentCount(jsonData.unshipment_count)
      setShippedCount(jsonData.shipped_count)
      setUnreportedCount(jsonData.unreported_count)
      setWaitingEvaluationCount(jsonData.waiting_evaluation_count)
      setUnconfirmCvCount(jsonData.unconfirm_cv_count)
      setConfirmedCvCount(jsonData.confirmed_cv_count)
    }).catch((error) => {
      console.log(error)
    })
  }

  const PanelTitle = ({ title, count }) => {
    return (
      <div className={styles.title_name}>
        {title}
        { count && count > 0 ?
          <span className={styles.title_count}>{count}</span> :
          <></>
        }
      </div>
    )
  }

  const panels = ([
    {
      title: <PanelTitle title="応募" count={pendingCount} />,
      content: (
        <Request
          project={props.project}
          entries={props.entries}
          pendingCount={pendingCount}
          approvedCount={approvedCount}
          rejectedCount={rejectedCount}
          updatePanelCount={updatePanelCount}
        />
      )
    }
  ])

  if (props.project.project_type_id == TYPE_USE_ID) {
    panels.push(
      {
        title: <PanelTitle title="未発送" count={unshipmentCount} />,
        content: (
          <Unshipped unshipmentCount={unshipmentCount} shippedCount={shippedCount} updatePanelCount={updatePanelCount} project={props.project}/>
        )
      }
    )
  }

  panels.push(
    {
      title: <PanelTitle title="未投稿報告" count={unreportedCount} />,
      content: (
        <PostReport updatePanelCount={updatePanelCount} project={props.project} typeVisitId={props.typeVisitId} />
      )
    }
  )

  panels.push(
    {
      title: <PanelTitle title="評価" count={waitingEvaluationCount} />,
      content: (
        <Evaluation updatePanelCount={updatePanelCount} sponsor={props.sponsor} />
      )
    }
  )
  if (props.project.project_achievement_reward) {
    panels.push(
      {
        title: <PanelTitle title="成果管理" count={unconfirmCvCount} />,
        content: (
          <Management unconfirmCvCount={unconfirmCvCount} confirmedCvCount={confirmedCvCount} updatePanelCount={updatePanelCount} />
        )
      }
    )
  }
  return panels
}

export default Panel
