import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./ArrowLink.scss"

const propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

const defaultProps = {
  onClick: null,
}

const ArrowLink = (props) => {
  return (
    <a href={props.url} onClick={props.onClick}>
      <div className={styles.arrow_link}>
        {props.text}
      </div>
    </a>
  )
}

ArrowLink.propTypes = propTypes
ArrowLink.defaultProps = defaultProps

export default ArrowLink