import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from "prop-types"
import Step01 from "./MulchStepForm/Step01.js"
import Step02 from "./MulchStepForm/Step02.js"
import Step03 from "./MulchStepForm/Step03.js"
import Step04 from "./MulchStepForm/Step04.js"
import Step05 from "./MulchStepForm/Step05.js"
import Step06 from "./MulchStepForm/Step06.js"
import ArrowBack from "./MulchStepForm/ArrowBack.js"
import NextBtn from "./MulchStepForm/NextBtn.js"
import Submit from "../../FormTags/Submit.js"
import ArrowLink from "../../Shared/ArrowLink.js"
import RailsForm from "../../../Shared/RailsForm.js"
import styles from "./MulchStepForm.scss"
import SaveTimer from "./MulchStepForm/SaveTimer.js"

const propTypes = {
  project: PropTypes.object,
  projectTypes: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  socialNetworks: PropTypes.array.isRequired,
  operationSystems: PropTypes.array.isRequired,
  projectAdTypes: PropTypes.array.isRequired,
  sociaNetworkPostTypes: PropTypes.array.isRequired,
  prefectures: PropTypes.array.isRequired,
  typeUseId: PropTypes.number.isRequired,
  typeVisitId: PropTypes.number.isRequired,
  typeBuyId: PropTypes.number.isRequired,
  typeAppUseId: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired
}

const MulchStepForm = (props) => {
  const project = props.project
  const sponsorProjectsPath = `/sponsor/projects`
  const hashtags = project?.hashtags
  const [formPath, setFormPath] = useState(props.formPath)
  const [formMethod, setFormMethod] = useState(props.formMethod)
  const [step, setStep] = useState(props.steps[0])
  const [projectTypeId, setProjectTypeId] = useState(project && project.project_type_id)
  const [productName, setProductName] = useState("")
  const [isInstagram, setIsInstagram] = useState(true)
  const [isTwitter, setIsTwitter] = useState(false)
  const [isYoutube, setIsYoutube] = useState(false)
  const [instagramCount, setInstagramCount] = useState(1)
  const [twitterCount, setTwitterCount] = useState(1)
  const [youtubeCount, setYoutubeCount] = useState(1)
  const [approveAll, setApproveAll] = useState(project?.approve_all)
  //const [sending, setSending] = useState(false)
  const [projectId, setProjectId] = useState(project?.id)
  const [categoryIds, setCategoryIds] = useState(project?.categories?.map((c) => c.id) || [])
  const [socialNetworkIds, setSocialNetworkIds] = useState(project?.social_network_ids || [1])
  const [currentOnSuccess, setCurrentOnSuccess] = useState()
  const [saveCountMessage, setSaveCountMessage] = useState()
  const [saveTimer, setSaveTimer] = useState(null)

  const socialNetworkCountList = {
    "instagram": instagramCount,
    "twitter": twitterCount,
    "youtube": youtubeCount
  }
  const socialNetworkSetCountList = [
    setInstagramCount,
    setTwitterCount,
    setYoutubeCount
  ]

  const steps = props.steps

  const nextBtn = (formStep) => {
    if(formStep == steps[steps.length - 1]) {
      return <Submit text={ props.project?.published_at ? (props.completeText || "完了") : "完了" } disableWith={"送信中..."} onClick={ () => postForm({ redirect: true }) } />
    } else {
      return <NextBtn
        text="次へ"
        onClick={() => (
          scrollToTop(),
          setStep(step + 1)
        )}
      />
    }
  }

  const completeButton = () => {
    return <Submit
      text={"公開する"}
      disableWith={"送信中..."}
      onClick={() => { postForm({ publish: true }) }}
    />
  }

  // useEffect(() => {
  //   debugger
  // }, [project])

  const pageBackBtn = (
    <ArrowLink
      url={sponsorProjectsPath}
      text="プロジェクト一覧へ戻る"
    />
  )
  const stepBackBtn = (() => {
    if(steps[0] === step) {
    } else {
      return <ArrowBack
        text="前のページへ戻る"
        onClick={() => (setStep(step - 1))}
      />
    }
  })()

  const styleStatus = (index) => {
    return step === index ? "" : styles.hide
  }

  const scrollToTop = () => {
    const top = 0
    window.scrollTo({
      top,
      behavior: "smooth"
    })
  }

  const form = useRef();

  const onChange = (e) => {
    if(project?.published_at) {
      return true
    }

    postForm()

    return true
  }

  let sending = false

  const postForm = (options) => {
    if(sending) {
      console.log('not send because sending is true')
      return
    }

    sending = true

    const form = document.querySelector('#multch-step-form') ;
    const formData = new FormData(form);

    // 送信用データを設定
    const formOptions = {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    // ここで明示的に消してあげる
    delete formOptions.headers['Content-Type'];

    console.log(projectId)
    console.log(formPath)
    setFormMethod("patch")

    if(formMethod === "patch" && `/sponsor/projects/${projectId}` === '/sponsor/projects/null') {
      console.log('not send because formPath is /sponsor/projects/null')
      return
    }

    console.log(`POST to ${projectId ? `/sponsor/projects/${projectId}` : formPath}`)

    let path = null
    if(projectId) {
      if(options?.publish) {
        path = `/sponsor/projects/${projectId}?publish=true`
      } else if(options?.redirect) {
        path = `/sponsor/projects/${projectId}?redirect=true`
      } else {
        path = `/sponsor/projects/${projectId}`
      }
    } else {
      path = formPath
    }
    // 設定したデータをPOST
    fetch(path, formOptions)
    .then((response) => {
      return response.json()
    })
    .then((jsonData) => {
      sending = false
      console.log(jsonData)
      const newProjectId = jsonData.project.id
      setProjectId(newProjectId)
      setFormPath(`/sponsor/projects/${projectId}`)
      console.log(newProjectId)
      console.log(`/sponsor/projects/${projectId}`)
      if(jsonData.redirect) {
        location.href = "/sponsor/projects"
      }
      setSaveTimer(<SaveTimer originalKey={Math.random()} />)
    }).catch((e) => {
      sending = false
      console.log(e);
    });
  }

  const SaveButton = () => {
    if(project?.published_at) {
      return true
    }

    return <div className={styles.save_button}>
      {saveTimer}
      <a onClick={() => { postForm({ redirect: true }) }}>保存して閉じる</a>
    </div>
  }

  return (
    <div className={styles.container}>
      <RailsForm
        action={formPath}
        multipart={true}
        remote={true}
        method={formMethod}
        formRef={form}
        onSuccess={() => {}}
        formId="multch-step-form"
      >
        {/* memo */}
        {/* ステップを入れ替えるようなissueが出た場合は、 */}
        {/* 各ステップのファイルとフォルダ名をStep0Xからやってることの名前に変更する */}
        { steps.includes(1) &&
          <div className={styleStatus(1)}>
            <Step01
              pageBackBtn={pageBackBtn}
              projectTypes={props.projectTypes}
              categories={props.categories}
              socialNetworks={props.socialNetworks}
              projectTypeId={projectTypeId}
              parentCategoryId={props.parent_category_id}
              setProjectTypeId={setProjectTypeId}
              categoryIds={categoryIds}
              setCategoryIds={setCategoryIds}
              setIsInstagram={setIsInstagram}
              socialNetworkIds={socialNetworkIds}
              setSocialNetworkIds={setSocialNetworkIds}
              setIsTwitter={setIsTwitter}
              setIsYoutube={setIsYoutube}
              approveAll={approveAll}
              setApproveAll={setApproveAll}
              nextBtn={nextBtn(1)}
              saveBtn={<SaveButton />}
              project={project}
              step={1}
              steps={steps}
              onChange={onChange}
            />
          </div>
        }

        { steps.includes(2) &&
          <div className={styleStatus(2)}>
            <Step02
              stepBackBtn={stepBackBtn}
              projectTypeId={projectTypeId}
              operationSystems={props.operationSystems}
              prefectures={props.prefectures}
              setProductName={setProductName}
              typeUseId={props.typeUseId}
              typeVisitId={props.typeVisitId}
              typeBuyId={props.typeBuyId}
              typeAppUseId={props.typeAppUseId}
              nextBtn={nextBtn(2)}
              project={project}
              step={2}
              steps={steps}
              onChange={onChange}
              saveBtn={<SaveButton />}
            />
          </div>
        }

        { steps.includes(3) &&
          <div className={styleStatus(3)}>
            <Step03
              stepBackBtn={stepBackBtn}
              isSnsList={[isInstagram, isTwitter, isYoutube]}
              socialNetworks={props.socialNetworks}
              sociaNetworkPostTypes={props.sociaNetworkPostTypes}
              socialNetworkSetCountList={socialNetworkSetCountList}
              accountTagNames={project?.account_tag_names}
              project={project}
              projectId={projectId}
              nextBtn={nextBtn(3)}
              step={3}
              steps={steps}
              onChange={onChange}
              saveBtn={<SaveButton />}
            />
          </div>
        }

        { steps.includes(4) &&
          <div className={styleStatus(4)}>
            <Step04
              stepBackBtn={stepBackBtn}
              projectAdTypes={props.projectAdTypes}
              projectAdTypeId={project?.project_ad_type_id}
              hashtags={hashtags?.map((h) => h.name)}
              productName={productName}
              nextBtn={nextBtn(4)}
              step={4}
              steps={steps}
              onChange={onChange}
              saveBtn={<SaveButton />}
            />
          </div>
        }

        { steps.includes(5) &&
          <div className={styleStatus(5)}>
            <Step05
              approveAll={approveAll}
              stepBackBtn={stepBackBtn}
              nextBtn={nextBtn(5)}
              step={5}
              steps={steps}
              onChange={onChange}
              project={project}
              saveBtn={<SaveButton />}
              projectTypeId={projectTypeId}
              typeVisitId={props.typeVisitId}
            />
          </div>
        }

        {
          steps.includes(6) &&
          <div className={styleStatus(6)}>
            <Step06
              stepBackBtn={stepBackBtn}
              isInstagram={isInstagram}
              isTwitter={isTwitter}
              isYoutube={isYoutube}
              socialNetworkCountList={socialNetworkCountList}
              projectTypeId={projectTypeId}
              nextBtn={completeButton()}
              step={6}
              steps={steps}
              onChange={onChange}
              project={project}
              saveBtn={<SaveButton />}
            />
          </div>
        }
      </RailsForm>
    </div>
  )
}

MulchStepForm.propTypes = propTypes

export default MulchStepForm
