import "core-js/stable"
import "regenerator-runtime/runtime"
import React, {useState} from 'react'
import PropTypes from "prop-types"
import styles from "./StepPage.scss";
import QuestionImage from "./StepPage/question_button.svg";
import InstagramIconImage from "./StepPage/instagram.svg";
import InstagramWhiteIconImage from "./StepPage/instagram_white.svg";
import CautionImage from "./StepPage/caution.svg";
import Step1_Img from "./StepPage/ig_basic_display_api_sign_in.png"
import Step2_Img from "./StepPage/ig_basic_display_api_allow.png"
import Step3_Img from "./StepPage/ig_basic_display_api_complete.png"

const propTypes = {
  basicDisplayAuthUrl: PropTypes.string.isRequired,
}

const BasicDisplayStep = (props) => {
  return (
    <div className={styles.step_container}>
      <div className={styles.step_header}>
        <h1>Instagram連携</h1>
      </div>
      <div className={`${styles.step_top} ${styles.step_basic_display}`}>
        <div className={styles.step_bar_container} />

        <div className={styles.question_container}>
          <QuestionImage onClick={props.onClickQuestion} />
        </div>

        <div className={styles.step_main}>
          <div className={styles.step_title}>
            <InstagramIconImage className={styles.instagram_icon_image}/>
            <h1>Instagramと連携して<br />仮登録をしましょう</h1>
          </div>

          <div className={styles.button_container}>
            <a
              href={props.basicDisplayAuthUrl}
              className={`${styles.sns_link_btn_item} ${styles.instagram}`}
            >
              <InstagramWhiteIconImage className={styles.snsIconImg} />
              Instagram連携
            </a>
          </div>

          <div className={styles.checkpoint_container}>
            <h2 className={styles.checkpoint_title}><CautionImage className={styles.caution_image}/><span>確認事項</span></h2>
            <p className={styles.checkpoint}>
              ※ インフルエンサーとして活動されているアカウントの連携をお願い致します<br /><br />
              ※ ご連携によりアカウントの凍結やリーチ数の減少といった事象が発生することはございません<br /><br />
              ※ 当サービスおよび株式会社Maroがご連携頂いたInstagramアカウントにログインすることはできませんのでご安心ください<br /><br />
              ※ Instagram連携により仮登録が完了いたします。弊社による審査通過後に本登録可能となります
            </p>
          </div>
        </div>
      </div>

      <div className={styles.step_what}>
        <h2 className={styles.step_what_title}>利用開始までの流れ</h2>
        <p className={styles.step_what_content}>
          1. 仮登録（Instagram連携）<br />
          ↓<br />
          2. 審査<br />
          ↓<br />
          3. 本登録<br />
          ↓<br />
          4. サービス利用開始<br /><br /><br />

          ※ 審査は主に下記等の基準を元に総合的に判断し、結果を通知させて頂きます。<br />
          ・フォロワー数（1000名以上〜）<br />
          ・フォロワーを購入していないか
        </p>
      </div>
      <div className={styles.step_detail}>
        <h2>Instagram連携の方法</h2>

        <div className={styles.step_detail_body}>
          <div className={styles.block}>
            <p>1. Instagramとの連携ページに遷移後、ログイン情報を入力します。（ログイン済みの方は次のステップ2にお進みください）</p>
            <p className={styles.caption}>※必ずインフルエンサーアカウントでログインをおこなってください。</p>
            <img className={styles.step_image} src={Step1_Img} />
          </div>

          <div className={styles.block}>
            <p>2. 連携を許可します。</p>
            <p className={styles.caption}>
              ※このページに表示されているアカウントとは別のアカウントに切り替えをご希望の場合は、
              「Don’t Allow」を選択し、ログイン中のアカウントをログアウトした後、
              再度MaroからInstagram連携をおこなってください。
            </p>
            <img className={styles.step_image} src={Step2_Img} />
          </div>

          <div className={styles.block}>
            <p>3. 仮登録完了です。</p>
            <img className={styles.step_image} src={Step3_Img} />
          </div>
        </div>
      </div>
    </div>
  )
}

BasicDisplayStep.propTypes = propTypes

export default BasicDisplayStep
