import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Radio from "../../../FormTags/Radio.js"
import TextField from "../../../FormTags/TextField.js"
import styles from "./Step06.scss"

const propTypes = {
  stepBackBtn: PropTypes.object,
  isInstagram: PropTypes.bool.isRequired,
  isTwitter: PropTypes.bool.isRequired,
  isYoutube: PropTypes.bool.isRequired,
  socialNetworkCountList: PropTypes.object.isRequired,
  nextBtn: PropTypes.object.isRequired,
}

const Step06 = (props) => {
  const project = props.project
  const savedAchievementReward = project?.project_achievement_reward?.amount
  const savedInstagramReward = project?.project_social_networks?.find((p) => p.social_network_id === 1)?.project_social_network_reward?.amount
  const [achievementReward, setAchievementReward] = useState(savedAchievementReward || 0)
  const [instagramReward, setInstagramReward] = useState(savedInstagramReward || 0)
  const [isInstagramReward, setIsInstagramReward] = useState(savedInstagramReward ? true : false)
  const [twitterReward, setTwitterReward] = useState(0)
  const [isTwitterReward, setIsTwitterReward] = useState(false)
  const [youtubeReward, setYoutubeReward] = useState(0)
  const [isYoutubeReward, setIsYoutubeReward] = useState(false)
  const [rewardPlan, setRewardPlan] = useState(project && !savedAchievementReward && !savedInstagramReward ? "product_only" : "product_and_reward")
  const [rewardType, setRewardType] = useState((!project || savedAchievementReward && savedInstagramReward) ? "achievement_and_fixed" : (savedInstagramReward  ? "fixed" : "achievement"))

  const checkValuesAchievement = (
    rewardType === "achievement" &&
    isGreaterThanToZero(achievementReward)
  )

  const checkValuesFixed = (
    rewardType === "fixed" &&
    props.isInstagram === isInstagramReward &&
    props.isTwitter === isTwitterReward &&
    props.isYoutube === isYoutubeReward
  )

  const checkValuesAchievementAndFixed = (
    rewardType === "achievement_and_fixed" &&
    props.isInstagram === isInstagramReward &&
    props.isTwitter === isTwitterReward &&
    props.isYoutube === isYoutubeReward
  )

  const instagramAmount = (
    props.isInstagram ? Number(instagramReward) * Number(props.socialNetworkCountList["instagram"]) : 0
  )
  const twitterAmount = (
    props.isTwitter ? Number(twitterReward) * Number(props.socialNetworkCountList["twitter"]) : 0
  )
  const youtubeAmount = (
    props.isYoutube ? Number(youtubeReward) * Number(props.socialNetworkCountList["youtube"]) : 0
  )

  return (
    <div>
      <div></div>

      <div className={styles.header}>
        {props.stepBackBtn}
        {props.saveBtn}
      </div>

      <h2>6/6 報酬を決めましょう</h2>

      {
        rewardPlan != "reward_only" &&
        <Radio
          label="報酬"
          id="mulch_step_form_reward_type"
          name="reward_plan"
          elements={
            [
              {
                label: "商品提供と金銭報酬",
                value: "product_and_reward",
              },
              {
                label: `${props.projectTypeId == 2 ? "サービス" : "商品"}提供のみ`,
                value: "product_only",
              }
            ]
          }
          value={rewardPlan}
          setState={setRewardPlan}
          onChange={props.onChange}
        />
      }
      { rewardPlan != "product_only" &&
        <Radio
          label="報酬タイプを選択"
          id="mulch_step_form_reward_type"
          name="reward_type"
          elements={
            [
              {
                label: "成果報酬",
                value: "achievement",
              },
              {
                label: "固定報酬",
                value: "fixed",
              },
              {
                label: "成果報酬と固定報酬",
                value: "achievement_and_fixed",
              }
            ]
          }
          value={rewardType}
          setState={setRewardType}
          onChange={props.onChange}
        />
      }
      {rewardPlan != "product_only" && (rewardType === "achievement" || rewardType === "achievement_and_fixed") && (
        <>
          <label></label>
          <div>
            <TextField
              type="number"
              label="1件売上発生あたりの報酬"
              id="project_achievement_reward_amount"
              name="project_achievement_reward[amount]"
              className={styles.input_text}
              value={achievementReward}
              setState={setAchievementReward}
              required={true}
              onChange={props.onChange}
            />
          </div>
          <div className={styles.result_price}>
            総報酬額 ¥{achievementReward}円/1件あたり
          </div>
        </>
      )}

      {rewardPlan != "product_only" && (rewardType === "fixed" || rewardType === "achievement_and_fixed") && (
        <>
          {props.isInstagram && (
            <TextField
              type="number"
              label={`Instagramの1件投稿あたりの報酬`}
              id={`project_social_network_reward_amount_instagram`}
              name={`project_social_networks[][project_social_network_reward][amount]`}
              className={styles.input_text}
              value={instagramReward}
              onChange={(e) => (
                setInstagramReward(e.target.value),
                setIsInstagramReward(isExist(e.target.value)),
                props.onChange()
              )}
              required={true}
            />
          )}
          {props.isTwitter && (
            <TextField
              type="number"
              label={`Twitterの1件投稿あたりの報酬`}
              id={`project_social_network_reward_amount_twitter`}
              name={`project_social_networks[][project_social_network_reward][amount]`}
              className={styles.input_text}
              value={twitterReward}
              onChange={(e) => (
                setTwitterReward(e.target.value),
                setIsTwitterReward(isExist(e.target.value)),
                props.onChange()
              )}
              required={true}
            />
          )}
          {props.isYoutube && (
            <TextField
              type="number"
              label={`YouTubeの1件投稿あたりの報酬`}
              id={`project_social_network_reward_amount_youtube`}
              name={`project_social_networks[][project_social_network_reward][][amount]`}
              className={styles.input_text}
              value={youtubeReward}
              onChange={(e) => (
                setYoutubeReward(e.target.value),
                setIsYoutubeReward(isExist(e.target.value)),
                props.onChange()
              )}
              required={true}
            />
          )}
          <div className={styles.result_price}>
            総報酬額 ¥{instagramAmount + twitterAmount + youtubeAmount}円/1人あたり
          </div>
        </>
      )}

      {(rewardPlan == "product_only" || checkValuesFixed || checkValuesAchievement || checkValuesAchievementAndFixed) && (
         props.nextBtn
      )}
    </div>
  )
}

Step06.propTypes = propTypes

export default Step06
