import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import ErrorMessages from "../../Shared/RailsForm/ErrorMessages.js"
import styles from "./AccountForm.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
  staff: PropTypes.object.isRequired
}

const AccountForm = (props) => {
  const [name, setName] = useState(props.staff.name)
  const [email, setEmail] = useState(props.staff.email)
  const [tel, setTel] = useState(props.staff.tel)

  return (
    <>
      {props.errors.length !== 0 && (
        <ErrorMessages messages={props.errors} />
      )}

      <TextField
        label="担当者名"
        id="staff_name"
        name="staff[name]"
        placeholder="担当者名を入力"
        value={name}
        setState={setName}
        required={true}
      />
      <TextField
        type="email"
        label="メールアドレス"
        id="staff_email"
        name="staff[email]"
        placeholder="メールアドレスを入力"
        value={email}
        setState={setEmail}
        required={true}
        disabled={props.invited}
      />
      <TextField
        label="電話番号"
        id="staff_tel"
        name="staff[tel]"
        placeholder="電話番号を入力"
        value={tel}
        setState={setTel}
        required={true}
      />
      <TextField
        type="password"
        label="パスワード"
        id="staff_password"
        name="staff[password]"
        placeholder="パスワードを入力"
        required={true}
      />
      <TextField
        type="password"
        label="パスワード確認"
        id="staff_password_confirmation"
        name="staff[password_confirmation]"
        placeholder="入力したパスワードを確認"
        required={true}
      />
      {
        props.staff.invitation_token &&
        <input type='hidden' name="staff[invitation_token]" value={props.staff.invitation_token} />
      }
      <button
        type="submit"
        className={styles.form_submit}
      >アカウントを作成する</button>
    </>
  )
}

AccountForm.propTypes = propTypes

export default AccountForm
