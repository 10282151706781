import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import ErrorMessages from "../../Shared/RailsForm/ErrorMessages.js"
import styles from "./New.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
}

const defaultProps = {
  errors: [],
}

const New = (props) => {
  const [email, setEmail] = useState("")
  const staffSignInPath = `/staff/sign_in`
  const staffSignUpPath = `/staff/sign_up`
  const newStaffForgetPasswordPath = `/staff/password/new`

  const elements = (
    <>
      <h2 className={styles.form_title}>ログイン</h2>
      {props.errors.length !== 0 && (
        <ErrorMessages messages={props.errors} />
      )}

      <TextField
        label="メールアドレス"
        id="staff_email"
        name="staff[email]"
        value={email}
        setState={setEmail}
      />
      <TextField
        type="password"
        label="パスワード"
        id="staff_password"
        name="staff[password]"
      />
      <div className={styles.form_link}>
        <a href={staffSignUpPath}>
          アカウントの作成はこちら
        </a>
      </div>
      <div className={styles.form_link}>
        <a href={newStaffForgetPasswordPath}>
          パスワードを忘れた方はこちら
        </a>
      </div>
      <button
        type="submit"
        className={styles.form_submit}
      >ログイン</button>
    </>
  )
  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <RailsForm
          action={staffSignInPath}
          elements={elements}
        />
      </div>
    </div>
  )
}

New.propTypes = propTypes
New.defaultProps = defaultProps

export default New
