import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Modal from "../Shared/Modal.js"
import EditIconImg from "./Edit/edit.png"
import CautionIconImg from "./Edit/caution.png"
import Form from "./Edit/Form.js"
import styles from "./Edit.scss"

const propTypes = {
  influencer: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  prefectures: PropTypes.array.isRequired
}

const Edit = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [modalFullScreenStatus, setModalFullScreenStatus] = useState(false);
  const [visibleAttributes, setVisibleAttributes] = useState([]);

  const desiredIncentivePerPostOptions = (
    props.influencer.desired_incentive_per_post > 0 ?
    `${props.influencer.desired_incentive_per_post.toLocaleString()}円以上` : "気にしない"
  )

  const EditableAttribute = ({ label, value, attributes, fullScreen }) => {
    const onClick = () => {
      setVisibleAttributes(attributes)
      setModalStatus(true)
      setModalFullScreenStatus(fullScreen)
    }
    return (
      <div className={styles.influencerAttribute}>
        <div className={styles.labelContainer}>
          <label>{label}</label>
          <div onClick={onClick}>
            <img src={EditIconImg} className={styles.editIcon} />
          </div>
        </div>
        <p className={styles.attributeValue} onClick={onClick}>{value || "　"}</p>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <EditableAttribute
        label={"生年月日"}
        value={props.influencer.birthdate.replace(/-/g, "/")}
        attributes={["birth_year", "birth_month", "birth_day"]}
      />
      <EditableAttribute
        label={"都道府県"}
        value={props.influencer.prefecture_name}
        attributes={["prefecture_id"]}
      />
      <EditableAttribute
        label={"投稿カテゴリ"}
        value={props.influencer.category_names.join("、")}
        attributes={["category_ids"]}
        fullScreen={true}
      />
      <EditableAttribute
        label={"職業（任意）"}
        value={props.influencer.occupation?.name}
        attributes={["occupation_id"]}
      />
      <EditableAttribute
        label={"希望報酬"}
        value={desiredIncentivePerPostOptions}
        attributes={["desired_incentive_per_post"]}
      />
      <div className={styles.desiredIncentivePerPostAnnotation}>
        <img src={CautionIconImg} className={styles.cautionImage} />
        <p className={styles.annotationText}>
          1プロジェクトあたりの希望報酬額を選択してください。
        </p>
      </div>

      <Modal
        status={modalStatus}
        setState={setModalStatus}
        fullScreen={modalFullScreenStatus}
      >
        <Form
          influencer={props.influencer}
          categories={props.categories}
          prefectures={props.prefectures}
          visibleAttributes={visibleAttributes}
          occupations={props.occupations}
        />
      </Modal>
    </div>
  )
}

Edit.propTypes = propTypes

export default Edit
