import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import ErrorMessages from "../../Shared/RailsForm/ErrorMessages.js"
import styles from "./Edit.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
}

const defaultProps = {
  errors: [],
}

const Edit = (props) => {
  const staffPath = `/staff`

  const elements = (
    <>
      {props.errors.length !== 0 && (
        <ErrorMessages messages={props.errors} />
      )}
      
      <TextField
        type="password"
        label="現在のパスワード"
        id="staff_current_password"
        name="staff[current_password]"
      />

      <TextField
        type="password"
        label="新しいパスワード"
        id="staff_password"
        name="staff[password]"
      />

      <TextField
        type="password"
        label="パスワード確認"
        id="staff_password_confirmation"
        name="staff[password_confirmation]"
      />

      <button
        type="submit"
        className={styles.form_submit}
      >保存する</button>
    </>
  )
  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <RailsForm
          action={staffPath}
          elements={elements}
          patch={true}
        />
      </div>
    </div>
  )
}

Edit.propTypes = propTypes
Edit.defaultProps = defaultProps

export default Edit
