import "core-js/stable"
import "regenerator-runtime/runtime"
import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react'
import styles from "./Switch.scss"

const propTypes = {
  elements: PropTypes.array.isRequired
}

const Switch = (props) => {
  const [buttonList, setButtonList] = useState([])

  useEffect(() => {
    const newButtonList =
      props.elements.map((element, i) => {
        return ({
          text: element.text,
          selected: buttonList.length ? buttonList[i].selected : element.selected,
          onClick: element.onClick
        })
      })

    setButtonList(newButtonList)
  }, [props.elements])

  const onClick = (element) => {
    const newButtonList = props.elements.map((_element) => {
      if(_element.text === element.text) {
        _element.selected = true
      } else {
        _element.selected = false
      }

      return _element
    })
    setButtonList(newButtonList)

    element.onClick()
  }

  return (
    <div className={styles.container}>
      {
        buttonList.map((element, index) => {
          return (
            <div
              key={index}
              className={`${styles.item} ${element.selected ? styles.selected : ""}`}
              onClick={() => onClick(element)}
              >
              {element.text}
            </div>
          )
        })
      }
    </div>
  )
}

Switch.propTypes = propTypes

export default Switch
