import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./StepPage.scss"
import CheckIconImage from "./StepPage/check_icon.svg"
import ArrowImage from "./StepPage/chevron-down.svg";

const propTypes = {
}

const PassingCompleteStep = (props) => {
  return (
    <div className={`${styles.step_container} ${styles.complete_step}`}>
      <div className={styles.step_header}>
        <h1>審査完了のお知らせ</h1>
      </div>

      <div className={styles.complete_step_main}>
        <CheckIconImage className={styles.checkicon}/>
        <h1 className={styles.passing_complete_title}>審査が完了しました</h1>
        <p className={styles.passing_complete_message}>
          <span>
          本登録の手続きをおこなって頂くことで
          <br />
          Maroがご利用可能となります。
          <br /><br />
         【 本登録のステップ 】
          <br />
          1. Facebook連携（1分）※任意 <br />
          2. プロフィール登録（10秒）</span>
        </p>

        <a onClick={props.onClickYes} className={styles.button}>
          本登録を開始する
          <ArrowImage className={styles.arrow} />
        </a>
      </div>
    </div>
  )
}

PassingCompleteStep.propTypes = propTypes

export default PassingCompleteStep
