import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import Select from "../Select.js"

const SelectDesiredIncentivePerPost = (props) => {
  const desiredIncentivePerPostOptions = [
    { value: 0, name: "気にしない" },
    { value: 1000, name: "1,000円以上" },
    { value: 5000, name: "5,000円以上" },
    { value: 10000, name: "10,000円以上" },
    { value: 50000, name: "50,000円以上" }
  ]

  return (
    <Select
      label={props.label}
      id={props.id}
      name={props.name}
      options={desiredIncentivePerPostOptions}
      value={props.value}
      className={props.className}
      onClick={props.onClick}
      onChange={props.onChange}
      setState={props.setState}
      required={props.required}
    />
  )
}

export default SelectDesiredIncentivePerPost
