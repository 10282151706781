import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useRef, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./SearchBox.scss"
import SearchIconImg from "./searchIcon.svg"

const propTypes = {
  onInput: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  sticky: PropTypes.bool.isRequired
}

const SearchBox = (props) => {
  const searchBoxRef = useRef(null)
  const boxTop = useRef(0)

  useEffect(() => {
    if (!props.sticky) {
      return;
    }

    const handleScroll = () => {
      if (searchBoxRef.current && boxTop.current) {
        if (window.pageYOffset > boxTop.current) {
          searchBoxRef.current.classList.add(styles.sticky)
        } else {
          searchBoxRef.current.classList.remove(styles.sticky)
        }
      }
    };
    if (searchBoxRef.current) boxTop.current = searchBoxRef.current.offsetTop;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [searchBoxRef, props.sticky])

  const handleSearchClick = () => {
    props.onSearchClick()
  }

  return (
    <div className={styles.input_search} ref={searchBoxRef}>
      <div onClick={handleSearchClick} className={styles.button}>
        絞り込み  ▼
      </div>
    </div>
  )
}

SearchBox.propTypes = propTypes

export default SearchBox
