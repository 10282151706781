import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import LazyLoad from "../../Shared/LazyLoad.js"
import styles from "./ImageFile.scss"

const propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  multiple: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  required: PropTypes.bool,
}

const defaultProps = {
  className: "",
  value: null,
  multiple: false,
  onClick: null,
  onChange: null,
  setState: null,
  required: false,
}

const ImageFile = (props) => {
  const [imageList, setImageList] = useState(
    props.value ? <LazyLoad src={props.value} /> : ""
  )

  useEffect(() => {
    const fileInput = document.getElementById(props.id)
    fileInput.addEventListener('change', (e) => { onFileChange(e) })
  })

  const fileListCreator = (files) => {
    let list = []
    files.forEach((file, index) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        list.push(<img key={index} src={e.target.result} />)
      }
    })

    setTimeout(() => {
      setImageList(list)
    }, 250);
  }

  const onFileChange = (e) => {
    setImageList("")
    const files = e.target.files
    if (files.length === 0) { return }

    fileListCreator(files)
  }

  return (
    <div className={props.className}>
      <label>{props.label}</label>
      <div className={styles.image_file}>
        <input
          type="file"
          id={props.id}
          name={props.name}
          accept="image/gif, image/jpeg, image/pjpeg, image/jpg, image/png, image/bmp"
          multiple={props.multiple ? "multiple" : ""}
          onClick={props.onClick}
          onChange={props.onChange}
          onInput={(e) => props.setState ? props.setState(e.target.value) : null}
          required={props.required}
        />
        <div>
          {/* ここでdocument.getElementByIdするのはよくない気がする。。。 */}
          <span onClick={() => document.getElementById(props.id).click()}>
            画像を選択
          </span>
        </div>
        <div>
          {imageList}
        </div>
      </div>
    </div>
  )
}

ImageFile.propTypes = propTypes
ImageFile.defaultProps = defaultProps

export default ImageFile