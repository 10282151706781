import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./Show.scss"
import EyeCatchLogo from "./eyecatch_logo.png"
import { Link, animateScroll as scroll } from "react-scroll";

const Show = () => {
  return (
    <>
      <div id="TOP"></div>
      <header className={styles.header}>
        <Link
          to="ABOUT_US"
          spy={true}
          smooth={true}
          offset={-50}
          duration={600}
          className={styles.layouts_module_header_internal_link}>
          About Us
        </Link>
        <Link
          to="SERVICES"
          spy={true}
          smooth={true}
          offset={-50}
          duration={600}
          className={styles.layouts_module_header_internal_link}>
          Services
        </Link>
        <Link
          to="COMPANY"
          spy={true}
          smooth={true}
          offset={-50}
          duration={600}
          className={styles.layouts_module_header_internal_link}>
          Company
        </Link>
        <Link
          to="RECRUIT"
          spy={true}
          smooth={true}
          offset={-50}
          duration={600}
          className={styles.layouts_module_header_internal_link}>
          Recruit
        </Link>

        <a href="mailto:info&#64;maro.mu" className={styles.layouts_module_header_contact_link}>お問い合わせ</a>
      </header>
      <main className={styles.main}>
        <div className={styles.layouts_module_main_content_container}>
          <div className={styles.home_index_eyecatch_contaner}>
            <img src={EyeCatchLogo} />
          </div>

          <div className={styles.home_index_content_container}>
            <h2 id="ABOUT_US">About Us</h2>
            <p>
              私たちの使命は、”好きなことで生きる”インフルエンサーがあふれる世界を実現することです。
            </p>
            <p>
              使命の実現に向け、インフルエンサーの収益化をサポートするサービスを開発・提供しています。
            </p>


            <h2 id="SERVICES">Services</h2>
            <p>インフルエンサーと美容・アパレルブランドをマッチングするプラットフォーム「Maro」</p>

            <div className={styles.home_index_external_link}>
              <a href="/" target="_blank">＝＞ ブランド様はこちら</a>
              <a href="/for-influencers/" target="_blank">＝＞ インフルエンサー様はこちら</a>
            </div>


            <h2 id="COMPANY">Company</h2>
            <div className={styles.home_index_company_item}>
              <div className={styles.home_index_company_item_title}>会社名</div>
              <div className={styles.home_index_company_item_content}>株式会社Maro</div>
            </div>
            <div className={styles.home_index_company_item}>
              <div className={styles.home_index_company_item_title}>代表取締役</div>
              <div className={styles.home_index_company_item_content}>森川恵美</div>
            </div>
            <div className={styles.home_index_company_item}>
              <div className={styles.home_index_company_item_title}>所在地</div>
              <div className={styles.home_index_company_item_content}>東京都港区赤坂一丁目12番32号</div>
            </div>
            <div className={styles.home_index_company_item}>
              <div className={styles.home_index_company_item_title}>資本金</div>
              <div className={styles.home_index_company_item_content}>80,268千円（資本準備金を含む）</div>
            </div>
            <div className={styles.home_index_company_item}>
              <div className={styles.home_index_company_item_title}>連絡先</div>
              <div className={styles.home_index_company_item_content}>info@maro.mu</div>
            </div>
            <div className={styles.home_index_company_item}>
              <div className={styles.home_index_company_item_title}>事業内容</div>
              <div className={styles.home_index_company_item_content}>インフルエンサーおよび広告主の支援に向けたサービスの開発・提供</div>
            </div>
            <a href="mailto:info&#64;maro.mu" className={styles.home_index_contact_link}>お問い合わせ</a>

            <h2 id="RECRUIT">Recruit</h2>
            <p>インフルエンサー業界の健全な発展および弊社の事業成長を共に実現する仲間を募集しています。</p>


            <a href="https://www.wantedly.com/companies/company_8873424/projects" target="_blank" className={styles.home_index_external_link}>＝＞ Wantedly</a>

          </div>
        </div>
      </main>
      <footer className={styles.footer}>
        <div className={styles.layouts_module_footer_company_name}>
          © 2020 Maro All Rights Reserved.
        </div>
      </footer>
    </>
    )
  }

export default Show
