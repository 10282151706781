import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./Error404.scss"
import LogoImg from "./logo.png"

const Error404 = () => {
  return (
    <div>
      <header className={styles.header}>
        <a href={"/"}>
          <img src={LogoImg} />
        </a>
      </header>
      <div className={styles.error_container}>
        <h1 className={styles.error_title}>お探しのページは<br className={styles.sp_only} />見つかりませんでした</h1>
        <p className={styles.error_message}>入力したアドレスが間違っているか、<br className={styles.sp_only} />ページが移動した可能性があります。</p>
        <a className={styles.back_link} href={"/"}>トップに戻る</a>
      </div>
    </div>
  )
}

export default Error404
