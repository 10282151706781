import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect, useState } from 'react'
import Rating from 'react-rating'
import Modal from "../../Shared/Modal.js"
import LazyLoad from "../../../Shared/LazyLoad.js"
import RoundBtn from "../../Shared/Buttons/Round.js"
import SwitchBtn from "../../Shared/Buttons/Switch.js"
import TextArea from "../../FormTags/TextArea.js"
import TextField from "../../FormTags/TextField.js"
import Button from "../../Shared/Buttons/Round.js"
import Slick from "../../../Shared/Slick.js"
import Layout from "./Shared/Layout.js"
import styles from "./Evaluation.scss"
import fetchEntry from "./fetchEntry.js"
import SVGIconEmpty from "./Evaluation/emptyStar.svg"
import SVGIconFull from "./Evaluation/fullStar.svg"
import LinkButton from "./Evaluation/link_button.png"
import Message from "./Shared/Message.js"
import InfluencerDetailModal from "../../Shared/InfluencerDetailModal.js"
import QuestionMark from "../../../Shared/QuestionMark.js"

const Evaluation = (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`
  const [modalStatus, setModalStatus] = useState(false);
  const [entryId, setEntryId] = useState();
  const [evaluationComment, setEvaluationComment] = useState('');
  const [evaluationRate, setEvaluationRate] = useState(5);
  const [loadingImageStatus, setLoadingImageStatus] = useState(false);
  const currentStatus = "reported"
  const [entries, setEntries] = useState({ reported: [] })
  const [influencerId, setInfluencerId] = useState(null)
  const [influencerDetailModalStatus, setInfluencerDetailModalStatus] = useState(false)

  useEffect(() => {
    fetchEntry({
      status: "reported",
      setEntries: setEntries,
      loadStart: () => setLoadingImageStatus(true),
      loadEnd: () => setLoadingImageStatus(false)
    })
  }, [])

  const influencerList = entries[currentStatus].length !== 0 ? [
    <tr className={`${styles.table_header} ${styles.entry}`}>
      <th>インフルエンサー</th>
      <th>投稿画像</th>
      <th>投稿</th>
      <th>表示回数</th>
      <th>いいね</th>
      <th>コメント</th>
      <th>保存数</th>
      <th>ストーリーズクリック <QuestionMark message="フォロワーが1万未満のインフルエンサーはストーリーズにリンクを張ることができないため、クリック数は表示されません" /></th>
      { !props.sponsor.cv_hidden && <th>CV</th> }
      <th>評価</th>
      <th>メッセージ</th>
    </tr>
  ] : []

  entries[currentStatus].forEach((entry, key) => {
    const imageList = []

    if(entry.post_report_image) {
      imageList.push(
        <div><LazyLoad className={styles.instagram_post_image} src={String(entry.post_report_image)} /></div>
      )
    }

    if(entry.post_report_images) {
      entry.post_report_images.forEach((img) => {
        imageList.push(
          <div><LazyLoad className={styles.instagram_post_image} src={String(img)} /></div>
        )
      })
    }

    entry.instagram_post_images.map((image, i_key) => {
      imageList.push(
        <div key={i_key+1}>
          <LazyLoad className={styles.instagram_post_image} src={String(image)} />
        </div>
      )
    })

    influencerList.push(
      <tr key={key} className={styles.entry}>
        <td>
          <div className={styles.influencer_name_column}>
            <div
              className={styles.influencer_name}
              onClick={(e) => (
                setInfluencerId(entry.influencer.id),
                setInfluencerDetailModalStatus(true)
              )}

            >
              <div className={styles.influencer_icon}>
                <LazyLoad src={String(entry.influencer.profile_pic)}/>
              </div>
              <p>
                {entry.influencer.name}
              </p>
            </div>
          </div>
        </td>
        <td className={styles.image_column}>
          <Slick images={imageList} className={styles.image_slick} arrows={true}/>
        </td>
        <td className={styles.url_column}>
          { entry.post_report.post_report_posts.map((post) => {
              return <a href={post.url} target="_blank"><img className={styles.url_link_button} src={LinkButton} /></a>
            })
          }
        </td>
        {
          entry.influencer["fb_connected?"] ?
          <>
            <td className={styles.count_column}>
              <p className={styles.entry_data_count}>
                <span className={styles.entry_data_number}>
                  {
                    entry.post_report.post_report_posts.reduce((sum, post_report_post) => {
                      return post_report_post.instagram_post?.impressions || 0
                    }, 0)
                  }
                </span>
                <span className={styles.entry_data_unit}>回</span>
              </p>
            </td>
            <td className={styles.count_column}>
              <p className={styles.entry_data_count}>
                <span className={styles.entry_data_number}>
                  {
                    entry.post_report.post_report_posts.reduce((sum, post_report_post) => {
                      return post_report_post.instagram_post?.like_count || 0
                    }, 0)
                  }
                </span>
                <span className={styles.entry_data_unit}>回</span>
              </p>
            </td>
            <td className={styles.count_column}>
              <p className={styles.entry_data_count}>
                <span className={styles.entry_data_number}>
                  {
                    entry.post_report.post_report_posts.reduce((sum, post_report_post) => {
                      return post_report_post.instagram_post?.comments_count || 0
                    }, 0)
                  }
                </span>
                <span className={styles.entry_data_unit}>回</span>
              </p>
            </td>
            <td className={styles.count_column}>
              <p className={styles.entry_data_count}>
                <span className={styles.entry_data_number}>
                  {
                    entry.post_report.post_report_posts.reduce((sum, post_report_post) => {
                      return post_report_post.instagram_post?.saved_count || 0
                    }, 0)
                  }
                </span>
                <span className={styles.entry_data_unit}>回</span>
              </p>
            </td>
          </> :
          <td colspan="4">未計測</td>
        }
        <td className={styles.count_column}>
          <div className={styles.entry_data_container}>
            <p className={styles.entry_data_count}>
              <span className={styles.entry_data_number}>
                {entry.influencer.followed_by_count > 10000 ? entry.affiliate_click_count : "-"}
              </span>
              <span className={styles.entry_data_unit}>回</span>
            </p>
          </div>
        </td>
        {
          !props.sponsor.cv_hidden &&
          <td className={styles.count_column}>
            <div className={styles.entry_data_container}>
              <p className={styles.entry_data_count}>
                <span className={styles.entry_data_number}>
                  {entry.affiliate_cv_count || 0}
                </span>
                <span className={styles.entry_data_unit}>回</span>
              </p>
            </div>
          </td>
        }
        <td>
          {!entry.evaluation &&
            (<RoundBtn
              text="評価"
              color="main"
              type={null}
              onClick={
                () => {
                   setModalStatus(true)
                   setEntryId(entry.id)
                   setEvaluationRate(5)
                   setEvaluationComment('')
                }
              }
            />)
          }
          {entry.evaluation &&
             (<p className={styles.evaluation}>評価: {entry.evaluation.rate}<br />{entry.evaluation.comment}</p>)
          }
        </td>
        <td><Message influencerId={entry.influencer.id} /></td>
      </tr>
    )
  })

  const onSubmit = () => {
    fetch(`${currentPageUrl}/entries/${entryId}/evaluations`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: JSON.stringify({
        "evaluation": {
          comment: evaluationComment,
          rate: evaluationRate
        }
      }),
    }).then(() => {
      props.updatePanelCount()
      fetchEntry({
        status: "reported",
        setEntries: setEntries,
        loadStart: () => setLoadingImageStatus(true),
        loadEnd: () => setLoadingImageStatus(false)
      })
    }).catch((error) => {
      alert(error)
    })
    setModalStatus(false)

    return false;
  }

  return (
    <>
      <Layout
        topLeftButtons={[]}
        topRightButtons={[]}
        table={influencerList}
        loadingImageStatus={loadingImageStatus}
      />
      <Modal
        status={modalStatus}
        setState={setModalStatus}
        className={styles.modal}
      >
        <div>
          <div className={styles.form_title}>インフルエンサーの評価</div>

          <TextField
            label=""
            id="evaluation_rate"
            name="evaluation[rate]"
            value={evaluationRate}
            setState={setEvaluationRate}
            required={true}
            className={styles.evaluation_rate_field}
          />

          <div className={styles.rate_container} >
            <Rating
              quiet
              emptySymbol={<SVGIconEmpty className={styles.rating_symbol} />}
              fullSymbol={<SVGIconFull className={styles.rating_symbol} />}
              initialRating={evaluationRate}
              onClick={ (rate) => {
                setEvaluationRate(rate)
                return rate
              }}
            />
          </div>

          <TextArea
            label="コメント"
            id="evaluation_comment"
            name="evaluation[comment]"
            value={evaluationComment}
            setState={setEvaluationComment}
            placeholder=""
            required={true}
          />
          <div className={styles.button_container}>
            <Button type="" text="送信する" onClick={onSubmit}/>
          </div>
        </div>
      </Modal>
      <InfluencerDetailModal
        influencerId={influencerId}
        influencerDetailModalStatus={influencerDetailModalStatus}
        setInfluencerDetailModalStatus={setInfluencerDetailModalStatus}
      />

    </>
  )
}

export default Evaluation
