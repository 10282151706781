import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./EmailLabel.scss"

const propTypes = {
  email: PropTypes.string.isRequired
}

const EmailLabel = (props) => {
  const EmailDesc = () => {
    return (
        <div className={styles.mail_desc}>
            <label>メールアドレス</label>
            <span>{props.email}</span>
        </div>
    )
  }

  return EmailDesc()
}

EmailLabel.propTypes = propTypes

export default EmailLabel
