import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./StepPage.scss"
import QuestionImage from "./StepPage/question_button.svg"
import InstagramIconImage from "./StepPage/instagram.svg"
import StepNumber from "./StepPage/1.svg"
import CautionImage from "./StepPage/caution.svg"
import Step1_1_Img from "./StepPage/step1_1.png"
import Step1_2_Img from "./StepPage/step1_2.png"
import Step1_3_Img from "./StepPage/step1_3.png"
import Step1_4_Img from "./StepPage/step1_4.png"
import Step1_5_Img from "./StepPage/step1_5.png"
import Step1_6_Img from "./StepPage/step1_6.png"
import ArrowImage from "./StepPage/chevron-down.svg"
import BackButtonImage from "./StepPage/back_button.svg"
import { Link, animateScroll as scroll } from "react-scroll";

const propTypes = {
}

const FirstStep = (props) => {
  return (
    <div className={styles.step_container}>
      <div className={styles.step_header}>
        <a onClick={props.onClickBack} className={styles.back_button}>
          <BackButtonImage />
        </a>
        <h1>Facebook連携</h1>
      </div>
      <div className={`${styles.step_top} ${styles.step_1}`}>
        <div className={styles.step_bar_container}>
          <div className={styles.step_bar_4}>
          </div>

          <div className={`${styles.step_bar_4} ${styles.active}`}>
          </div>

          <div className={styles.step_bar_4}>
          </div>

          <div className={styles.step_bar_4}>
          </div>
        </div>

        <div className={styles.question_container}>
          <QuestionImage onClick={props.onClickQuestion} />
        </div>

        <div className={styles.step_main}>
          <div className={styles.step_title}>
            <StepNumber className={styles.step_number} />
            <InstagramIconImage className={styles.instagram_icon_image}/>
            <h1>Instagramはビジネス/クリエイター<br />アカウントになっていますか？</h1>
          </div>

          <div className={styles.button_container}>
            <Link
              to="detail"
              spy={true}
              smooth={true}
              offset={-50}
              duration={600}
              className={styles.no_button}>
              いいえ/わからない
            </Link>
            <a onClick={props.onClickYes} className={styles.yes_button}>
              <span>はい</span>
              <ArrowImage className={styles.arrow} />
            </a>
          </div>

          <div className={styles.checkpoint_container}>
            <h2 className={styles.checkpoint_title}><CautionImage className={styles.caution_image}/><span>確認事項</span></h2>
            <p className={styles.checkpoint}>
              ※ビジネス/クリエイターアカウントではない場合、Maroとの連携をすることができません。<br /><br />
              ※ビジネス/クリエイターアカウントへの切り替えにあたって、新規にアカウントを作る必要はありません。切り替えは無料・審査無しで完了します。<br /><br />
              ※ ビジネス/クリエイターアカウントへの切り替えがが周囲に通知されることはありません。<br /><br />
              ※ 非公開アカウントではビジネス/クリエイターアカウントへの切り替えすることができません。
            </p>
          </div>
        </div>
      </div>

      <div className={styles.step_what}>
        <h2 id="detail" className={styles.step_what_title}>ビジネス/クリエイターアカウントとは</h2>
        <p className={styles.step_what_content}>
          ビジネス/クリエイターアカウントとは、Instagram上のアカウント種別のひとつです。<br /><br />
          アカウントをビジネス/クリエイターアカウントに切り替えることでInstagramが提供している下記のような各種機能が利用できるようになります。<br /><br />
          ・ビジネスプロフィールの表示<br />
          ・投稿やアカウントに対する詳細のインサイト情報（リーチ数やフォロワーの増減・男女比等）の表示<br />
          ・DM送信時のテンプレート登録<br />
          ・投稿予約<br />
          等
        </p>
      </div>

      <div className={styles.step_detail}>
        <h2>ビジネス/クリエイターアカウントに切り替える方法</h2>

        <div className={styles.step_detail_body}>
          <div className={styles.block}>
            <p>1. お仕事を引き受けるアカウントを開き、プロフィールの右上にある「メニュー」を選択</p>
            <p className={styles.caption}>※ 必ずPR投稿を行う際に利用するアカウントをお選び下さい。</p>
            <img className={styles.step_image} src={Step1_1_Img} />
          </div>

          <div className={styles.block}>
            <p>2. 「設定」を選択</p>
            <img className={styles.step_image} src={Step1_2_Img} />
          </div>

          <div className={styles.block}>
            <p>3. 「アカウント」を選択</p>
            <img className={styles.step_image} src={Step1_3_Img} />
          </div>

          <div className={styles.block}>
            <p>4. 「プロアカウントに切り替える」を選択</p>
            <img className={styles.step_image} src={Step1_4_Img} />
          </div>

          <div className={styles.block}>
            <p>5. 「ビジネス/クリエイター」のどちらかを選択</p>
            <p className={styles.caption}>※ 多くのインフルエンサー様はクリエイターに当てはまるかと思われます。※ 職業（カテゴリー）を選択される際に迷われた場合は「個人ブログ」がおすすめです。</p>
            <img className={styles.step_image} src={Step1_5_Img} />
          </div>

          <div className={styles.block}>
            <p><center>以下の画面が表示された場合<br />ビジネスアカウントの設定完了です</center></p>
            <img className={styles.step_image} src={Step1_6_Img} />
          </div>
        </div>
      </div>
    </div>
  )
}

FirstStep.propTypes = propTypes

export default FirstStep
