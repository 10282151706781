import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import MessageTable from "./Shared/MessageTable.js";

const propTypes = {
  messageRoom: PropTypes.object.isRequired,
}

const Show = (props) => {
  return (
    <MessageTable
      messageRoom={props.messageRoom}
    />
  )
}

Show.propTypes = propTypes

export default Show
