import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Modal.scss"

const propTypes = {
  status: PropTypes.bool.isRequired,
  content: PropTypes.object,
  setState: PropTypes.func.isRequired,
  position: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
}

const defaultProps = {
  status: false,
  position: "center",
}

const Modal = (props) => {
  const display = props.status ? "" : styles.hide
  const position = (
    props.position === "center" ? styles.center :
      props.position === "topLeft" ? styles.top_left :
        props.position === "topRight" ? styles.top_right : ""
  )

  return (
    <>
      <div className={`${styles.container} ${display} ${position} ${props.className}`}>
        {props.children || props.content}
      </div>
      <div
        className={`${styles.background} ${display}`}
        onClick={() => props.setState(false)}
      />
    </>
  )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
