// ファイル名がserver_renderingのスネークケースなのは自動生成された時からなので気にしない

// By default, this pack is loaded for server-side rendering.
// It must expose react_ujs as `ReactRailsUJS` and prepare a require context.

// DOMContentLoadedじゃないとReactが初回アクセス時に表示されない
window.addEventListener("DOMContentLoaded", function name() {
  let componentRequireContext = require.context("components", true)
  let ReactRailsUJS = require("react_ujs")
  ReactRailsUJS.useContext(componentRequireContext)
})

document.addEventListener('turbolinks:render', function () {
  window.ReactRailsUJS.unmountComponents()
})

document.addEventListener('turbolinks:before-render', function () {
  window.ReactRailsUJS.unmountComponents()
})

document.addEventListener('turbolinks:before-cache', function () {
  window.ReactRailsUJS.unmountComponents()
})