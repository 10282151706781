import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./Edit.scss"
import Submit from "../FormTags/Submit.js"
import RailsForm from "../../Shared/RailsForm.js"
import Form from "./Shared/Form.js"
import Moment from 'moment'

const propTypes = {
  errors: PropTypes.array.isRequired,
}

const defaultProps = {
  errors: [],
}

const Edit = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const influencerUpdatePath = `/agency/influencers/${props.influencer.id}`
  const [errorMessage, setErrorMessage] = useState([])

  return (
    <>
      <h2>インフルエンサーの編集</h2>
      <p>インフルエンサーの詳細情報を設定してください。</p>
      {props.errors.length !== 0 && (
        <ErrorMessages messages={props.errors} />
      )}
      {
        errorMessage.length > 0 &&
        <p className={styles.error_message}>
          {errorMessage}
        </p>
      }
      <div>
        <RailsForm
          action={influencerUpdatePath}
          method="patch"
          remote={true}
          setCustomErrorMessage={setErrorMessage}
        >
          <Form influencer={props.influencer} agency_influencer={props.agency_influencer} edit={true} />
          <Submit text="保存する" />
        </RailsForm>

        <div>
          <h3>還元率変更履歴</h3>
          <table className={styles.reward_distribution_table}>
            <tbody>
              <tr>
                <th>インフルエンサー</th>
                <th>報酬還元率</th>
              </tr>
              {
                props.reward_distributions.map((reward_distribution) => {
                  return (
                    <tr>
                      <td>
                        {Moment(reward_distribution.start_at).format("YYYY/MM/DD")}
                        〜
                        {reward_distribution.end_at && Moment(reward_distribution.end_at).format("YYYY/MM/DD")}
                      </td>
                      <td>{reward_distribution.rate}％</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

Edit.propTypes = propTypes
Edit.defaultProps = defaultProps

export default Edit
