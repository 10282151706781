import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from "prop-types"
import Modal from "../../../../../Shared/Modal.js"
import TextArea from "../../../../../FormTags/TextArea.js"
import Select from "../../../../../FormTags/Select.js"
import styles from "./Input.scss"

const propTypes = {
  no: PropTypes.number.isRequired,
  socialNetwork: PropTypes.object.isRequired,
  sociaNetworkPostTypes: PropTypes.array.isRequired,
  setIsSocialNetworks: PropTypes.func.isRequired,
}

const Input = (props) => {
  const [modalStatus, setModalStatus] = useState(false)
  const [socialNetworkPostTypeText, setSocialNetworkPostTypeText] =
    useState(props.projectSocialNetworkPost?.project_social_network_post_types?.map((project_social_network_post_type)=> project_social_network_post_type.social_network_post_type.name)?.join() || "未選択")
  const [socialNetworkPostTypeIds, setSocialNetworkPostTypeIds] =
    useState(props.projectSocialNetworkPost?.project_social_network_post_types?.map((project_social_network_post_type)=> project_social_network_post_type.social_network_post_type.id) || [])
  const [imageDescription, setImageDescription] = useState(props.projectSocialNetworkPost?.image_description)
  const [textDescription, setTextDescription] = useState(props.projectSocialNetworkPost?.text_description)
  const [daysToPost, setDaysToPost] = useState(0)

  const checkValues = (
    isGreaterThanToZero(socialNetworkPostTypeIds.length) &&
    isExist(imageDescription) &&
    isExist(textDescription) &&
    isExist(daysToPost)
  )

  useEffect(() => {
    props.setIsSocialNetworks(checkValues)
  })

  const isFirstRender = useRef(false)

  useEffect(() => {
    isFirstRender.current = true
  }, [])

  useEffect(() => {
    if(isFirstRender.current) {
      isFirstRender.current = false
    } else {
      props.onChange()
    }
  }, [socialNetworkPostTypeIds, socialNetworkPostTypeText, imageDescription, textDescription, daysToPost])


  let snsPostTypeList = []
  props.sociaNetworkPostTypes.forEach((sociaNetworkPostType, index) => {
    if (sociaNetworkPostType.social_network_id !== props.socialNetwork.id) { return }

    snsPostTypeList.push(
      <div key={index} className={styles.radio_item}>
        <label>
          <input
            type="checkbox"
            id={`project_social_network_post_check_${sociaNetworkPostType.name}_${props.no}`}
            name={`project_social_network_post[][check_${props.socialNetwork.id}]`}
            value={sociaNetworkPostType.id}
            onClick={(e) => {
              let array = []

              if(e.target.checked) {
                array = socialNetworkPostTypeIds.concat([sociaNetworkPostType.id])
              } else {
                array =
                  socialNetworkPostTypeIds.filter((v) => {
                    return v != sociaNetworkPostType.id
                  })
              }

              setSocialNetworkPostTypeIds(array)

              setSocialNetworkPostTypeText(props.sociaNetworkPostTypes.filter((elm) => {
                return array.includes(elm.id)
              }).map((elm) => {
                return elm.name
              }).join("＆"))
            }}
          />
          <div className={`${styles.radio_title} ${socialNetworkPostTypeIds.includes(sociaNetworkPostType.id) ? styles.active : ""}`}>
            {sociaNetworkPostType.name}
          </div>
          <div className={`${styles.radio_image} ${props.socialNetwork.id == 1 ? styles.instagram : ""}`}>
            {props.socialNetwork.id == 1 &&
              <img src={require(`../../../../../Shared/Icons/post-type-${sociaNetworkPostType.id}.png`)}/>
            }
          </div>
        </label>
      </div>
    )
  })

  const modalContent = (
    <div className={styles.radio_modal_container}>
      <h2>投稿の種類を選んでください</h2>
      <div className={styles.radio_description_container}>
        <p className={styles.radio_description}>
          <span className={styles.radio_description_title}>フィード投稿</span>：主にタイムラインに表示される投稿のことをフィード投稿を呼びます。発見タブに投稿が表示されることもあります。動画の再生時間は3〜60秒です。
        </p>
        <p className={styles.radio_description}>
          <span className={styles.radio_description_title}>ストーリー</span>：タイムライン上部にあるアイコンに表示される投稿のことをストーリーと呼びます。ストーリーの再生時間は１投稿あたり15秒で、投稿してから24時間の間のみ表示され、時間が経過すると表示されなくなります。
        </p>
      </div>

      <div className={styles.radio_container}>
        {snsPostTypeList}
      </div>

      <div>
        <div
          className={styles.modal_close_btn}
          onClick={() => setModalStatus(false)}
        >完了する</div>
      </div>
    </div>
  )

  const HiddenPostTypes = []
  socialNetworkPostTypeIds.forEach((v, i) =>{
    HiddenPostTypes.push(
      <input
        type="hidden"
        id={`project_social_network_post_social_network_post_type_id_${props.socialNetwork.name}_${props.no}`}
        name={`project_social_networks[][project_social_network_post][][social_network_post_type_ids][]`}
        value={v}
        key={i}
      />
    )
  })

  return (
    <div>
      <input
        type="hidden"
        id={`project_social_network_post_project_social_network_id_${props.socialNetwork.id}_${props.no}`}
        name="project_social_networks[][project_social_network_post][][social_network_id]"
        value={props.socialNetwork.id}
      />

      {props.no === 0 && (
        <input
          type="hidden"
          id={`project_social_network_post_days_to_post_${props.socialNetwork.id}_${props.no}`}
          name="project_social_networks[][project_social_network_post][][days_to_post]"
          value={0}
        />
      )}
      {props.no !== 0 && (
        <Select
          label="希望の投稿時期"
          id={`project_social_network_post_days_to_post_${props.socialNetwork.id}_${props.no}`}
          name="project_social_networks[][project_social_network_post][][days_to_post]"
          options={[
            //{ value: null, name: "希望の投稿時期を選択" },
            { value: 1, name: "前回の投稿から1日後" },
            { value: 2, name: "前回の投稿から2日後" },
            { value: 3, name: "前回の投稿から3日後" },
            { value: 4, name: "前回の投稿から4日後" },
            { value: 5, name: "前回の投稿から5日後" },
            { value: 6, name: "前回の投稿から6日後" },
            { value: 7, name: "前回の投稿から7日後" }
          ]}
          value={daysToPost}
          setState={setDaysToPost}
          required={true}
        />
      )}

      <label>投稿の種類を選択</label>
      <div className={styles.modal_btn_and_value}>
        <div
          className={styles.modal_open_btn}
          onClick={() => setModalStatus(true)}
        >選択する</div>
        {HiddenPostTypes}
        <div className={styles.radio_selected_radio_name}>
          ・{socialNetworkPostTypeText}
        </div>
      </div>

      <Modal
        status={modalStatus}
        content={modalContent}
        setState={setModalStatus}
      />

      <TextArea
        label="投稿する画像/動画の要望"
        id={`project_social_network_post_image_description_${props.socialNetwork.name}_${props.no}`}
        name={`project_social_networks[][project_social_network_post][][image_description]`}
        value={imageDescription}
        setState={setImageDescription}
        required={true}
      />

      <TextArea
        label="投稿するテキストの要望"
        id={`project_social_network_post_text_description_${props.socialNetwork.name}_${props.no}`}
        name={`project_social_networks[][project_social_network_post][][text_description]`}
        value={textDescription}
        setState={setTextDescription}
        required={true}
      />
    </div>
  )
}

Input.propTypes = propTypes

export default Input
