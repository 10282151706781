import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Project from "../Shared/Project.js"
import RailsForm from "../../Shared/RailsForm.js"
import ZipCode from "../FormTags/ZipCode.js"
import Select from "../FormTags/Select.js"
import TextField from "../FormTags/TextField.js"
import TextArea from "../FormTags/TextArea.js"
import CheckboxBool from "../FormTags/CheckboxBool.js"
import Submit from "../FormTags/Submit.js"
import styles from "./New.scss"

const propTypes = {
  project: PropTypes.object.isRequired,
  prefectures: PropTypes.array.isRequired,
  typeUseId: PropTypes.number.isRequired,
  typeVisitId: PropTypes.number.isRequired
}

const New = (props) => {
  const delivery_information = props.delivery_information
  const address = delivery_information?.address
  const [postalCode, setPostalCode] = useState(address?.postal_code)
  const [prefectureId, setPrefectureId] = useState(address?.prefecture_id)
  const [city, setCity] = useState(address?.city)
  const [block, setBlock] = useState(address?.block)
  const [building, setBuilding] = useState(address?.building)
  const [tel, setTel] = useState(delivery_information?.tel)
  const [firstName, setFirstName] = useState(delivery_information?.first_name)
  const [lastName, setLastName] = useState(delivery_information?.last_name)
  const [firstNameKana, setFirstNameKana] = useState(delivery_information?.first_name_kana)
  const [lastNameKana, setLastNameKana] = useState(delivery_information?.last_name_kana)
  const [influencerMessage, setInfluencerMessage] = useState()
  const [isAgree, setIsAgree] = useState(false)
  const [productOptionVariationIds, setProductOptionVariationIds] = useState({})
  const influencerProjectEntriesPath = `/influencer/projects/${props.project.id}/entries`
  const [showAgreeValidationMessage, setShowAgreeValidationMessage] = useState()

  let prefectureOptions = []
  props.prefectures.forEach((prefecture) => {
    prefectureOptions.push({
      value: prefecture.id,
      name: prefecture.name,
    })
  })

  const onClickSubmit = (e) => {
    if(isAgree) {
      return true
    } else {
      e.preventDefault()
      setShowAgreeValidationMessage(true)
      return false
    }
  }

  const formElements = (
    <>
      {props.project.project_type_id === props.typeUseId && (
        <>
          { props.project.product.options.length > 0 &&
            <div className={styles.form_title}>
              商品オプションを選択
            </div>
          }
          {
            props.project.product.options.map((product_option, index) => {
              const options =
                product_option.product_option_variations.map((variation) => {
                  return {
                    name: variation.name,
                    value: variation.id
                  }
                })

              const [productOptionVariationId, setProductOptionVariationId] = useState(options[0].value);

              return(
                <Select
                  id={`entry_product_option_variation_id_${index}`}
                  key={index}
                  name="entry[product_option_variation_ids][]"
                  label={`${product_option.name}`}
                  options={options}
                  value={productOptionVariationId}
                  setState={setProductOptionVariationId}
                />
              )
            })
          }
          <div className={styles.form_title}>
            お届け先情報を入力
          </div>
          <ZipCode
            label="郵便番号"
            id="address_postal_code"
            name="address[postal_code]"
            value={postalCode}
            setState={setPostalCode}
            setPrefectureId={setPrefectureId}
            setCity={setCity}
            required={true}
          />
          <Select
            label="都道府県"
            id="address_prefecture_id"
            name="address[prefecture_id]"
            options={prefectureOptions}
            value={prefectureId}
            setState={setPrefectureId}
            required={true}
          />
          <TextField
            label="市区町村"
            id="address_city"
            name="address[city]"
            value={city}
            setState={setCity}
            required={true}
          />
          <TextField
            label="番地"
            id="address_block"
            name="address[block]"
            value={block}
            setState={setBlock}
            required={true}
          />
          <TextField
            label="マンション名・部屋番号等"
            id="address_building"
            name="address[building]"
            value={building}
            setState={setBuilding}
          />
          <TextField
            label="電話番号"
            id="delivery_information_tel"
            name="delivery_information[tel]"
            value={tel}
            setState={setTel}
            required={true}
          />
          <div className={styles.name_container}>
            <TextField
              label="姓"
              id="delivery_information_first_name"
              name="delivery_information[first_name]"
              className={styles.name_item}
              value={firstName}
              setState={setFirstName}
              required={true}
            />
            <TextField
              label="名"
              id="delivery_information_last_name"
              name="delivery_information[last_name]"
              className={styles.name_item}
              value={lastName}
              setState={setLastName}
              required={true}
            />
          </div>

          <div className={styles.name_container}>
            <TextField
              label="せい"
              id="delivery_information_first_name"
              name="delivery_information[last_name_kana]"
              className={styles.name_item}
              value={lastNameKana}
              setState={setLastNameKana}
              required={true}
            />
            <TextField
              label="めい"
              id="delivery_information_last_name"
              name="delivery_information[first_name_kana]"
              className={styles.name_item}
              value={firstNameKana}
              setState={setFirstNameKana}
              required={true}
            />
          </div>
        </>
      )}

      <TextArea
        label="スポンサーに伝えたいこと（意気込み・その他連絡事項）"
        id="entry_influencer_message"
        name="entry[influencer_message]"
        placeholder="丁寧に魅力を伝えることを心がけます。よろしくお願いいたします！"
        value={influencerMessage}
        setState={setInfluencerMessage}
        required={true}
      />

      <div className={styles.attention}>
        <h2 className={styles.attention_title}>プロジェクト参加時の注意事項</h2>
        <p className={styles.attention_message}>
          ・当ページ内に記載されている条件（投稿方法等）を全て厳守してください。
          <br /><br />
          ・投稿の削除及びアーカイブはお控えください。
          <br /><br />
          {
            props.project.project_type_id !== props.typeVisitId &&
            <>
              ・商品全体を写した画像の投稿をお願い致します。
              <br /><br />
            </>
          }
          ・上記を遵守いただけない場合、本キャンペーンの報酬受取および今後のプロジェクトへのご応募が制限される可能性がございます。
          <br /><br />
          ・本案件の募集元であるブランド様が投稿を二次利用する可能性がございます。
        </p>
      </div>
      <CheckboxBool
        label="上記の内容に同意します"
        name="agreement[is_agree]"
        value={isAgree}
        setState={setIsAgree}
        required={true}
        className={styles.agree}
      />

      { showAgreeValidationMessage && !isAgree && <p className={styles.agree_validation_message}>プロジェクト参加時の注意事項に同意してください</p> }

      {
        props.project["type_buy?"] &&
        <p>こちらは自己購入案件です。ご自身で商品を購入をしていただく必要があります。（クーポンが発行されるため、購入に費用は一切かかりません）</p>
      }

      <Submit text="応募する" onClick={onClickSubmit} />
    </>
  )

  return (
    <div>
      <h2>このプロジェクトに応募する</h2>
      <div className={styles.eyecatch_container}>
        <Project project={props.project} />
      </div>

      {
        props.project["type_use?"] &&
        <p>商品発送のために必要な情報をご入力ください</p>
      }

      <div className={styles.form_container}>
        <RailsForm
          action={influencerProjectEntriesPath}
          elements={formElements}
          remote={true}
        />
      </div>
    </div>
  )
}

New.propTypes = propTypes

export default New
