import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Modal from "../../../Shared/Modal.js"
import styles from "./Message.scss"
import RailsForm from "../../../../Shared/RailsForm.js"
import Select from "../../../FormTags/Select.js"
import TextArea from "../../../FormTags/TextArea.js"
import Submit from "../../../FormTags/Submit.js"
import Envelope from "./envelope.svg"

const propTypes = {
  influencerId: PropTypes.number.isRequired,
}

const Message = (props) => {
  const [message, setMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if(modalStatus) {
      fetchMessageTemplates()
    }
  }, [modalStatus])

  const fetchMessageTemplates = () => {
    const sponsorMessageTemplatesPath = `${window.location.origin}/sponsor/message_template`

    fetch(sponsorMessageTemplatesPath , {
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
    }).catch((error) => {
      console.log(error)
    })
  }

  const onClick = () => {
    if(message) {
      setSending(true)

      return true
    } else {
      return false
    }
  }

  return (
    <>
      <a onClick={() => {
          setModalStatus(true)
          setSending(false)
        }}
        className={styles.button}
      >
        <Envelope />
      </a>
      <Modal
        status={modalStatus}
        setState={setModalStatus}
        position="center"
      >
        <RailsForm
          action={"/sponsor/messages"}
          post={true}
          remote={true}
          onSuccess={(e) => {
            setMessage('')
            setSending(false)
            setModalStatus(false)
          }}
          idPrefix={props.influencerId}
        >
          <h2 className={styles.title}>メッセージ送信</h2>
          <input type='hidden' name='influencer_id' value={props.influencerId} />
          <TextArea
            label="インフルエンサーへのメッセージ"
            id={`message_content_${props.influencerId}`}
            name="message_content"
            value={message}
            setState={setMessage}
            required={true}
          />
          <p className={styles.annotation}>※ 自動でメッセージが送信されます。この処理は戻すことができません。</p>

          <Submit onClick={onClick} sending={sending} text={"メッセージを送信"} position={"center"} buttonStyle={"circle"}/>
        </RailsForm>
      </Modal>
    </>
  )
}

Message.propTypes = propTypes

export default Message
