import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from "prop-types"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import styles from "./HashTag.scss"
import TextField from "./TextField.js"

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  placeholder: PropTypes.string,
  tagPosition: PropTypes.string,
  prefix: PropTypes.string
}

const defaultProps = {
  value: [],
  onChange: () => {},
  setState: null,
  placeholder: "",
  tagPosition: "inside",
  prefix: "#"
}

// このコンポーネントはもっと拡張できる
// 詳しくはreact-tag-inputのドキュメントを読めばわかる
const HashTag = (props) => {
  if(props.tagPosition === "inside") {
    const [tags, setTags] = useState(props.value)

    useEffect(() => {
      props.onChange()
    }, [tags.length])

    const inputs =
      tags.map((tag, i) => {
        return (
          <input
            key={i}
            type="hidden"
            id={`${props.id}${i}`}
            name={props.name}
            defaultValue={tag}
          />
        )
      })

    return (
      <div>
        <div className={styles.react_tag_input}>
          <ReactTagInput
            placeholder={props.placeholder}
            tags={tags}
            onChange={(newTags) => (
              setTags(newTags),
              props.setState ? props.setState(newTags) : null
            )}
          />
        </div>
        {inputs}
      </div>
    )
  } else if(props.tagPosition === "outside") {
    const [tags, setTags] = useState(props.value)
    const [currentText, setCurrentText] = useState()
    const [visibleTags, setVisibleTags] = useState([])
    const [inputs, setInputs] = useState([])
    const isFirstRender = useRef(false)

    const updateTags = (newTags) => {
      const newVisibleTags =
        newTags.map((tag, i) => {
          return (
            <p
              key={i}
              className={styles.outside_tag}
            >
              <span className={styles.tag_name}>{props.prefix}{tag}</span>
              <span
                className={styles.delete}
                onClick={() => {
                  console.log(`remove ${tag}`)

                  setTags(newTags.filter((t) => t != tag))
                  updateTags(newTags.filter((t) => t != tag))
                }}
              >x</span>
            </p>
          )
        })

      setInputs(
        newTags.map((tag, i) => {
          console.log(`input: ${tag}`)
          return (
            <input
              key={i}
              type="hidden"
              id={`${props.id}${i}`}
              name={props.name}
              defaultValue={tag}
              value={tag}
            />
          )
        })
      )

      props.setState(newTags)

      setVisibleTags(newVisibleTags)
    }

    useEffect(() => {
      isFirstRender.current = true
    }, [])

    useEffect(() => {
      updateTags(tags)
    }, [tags.length])

    useEffect(() => {
      if(isFirstRender.current && tags.length === 0) {
        isFirstRender.current = false
      } else {
        isFirstRender.current = false

        updateTags(tags)
        props.onChange()
      }
    }, [inputs.length])

    const uniq = (array) => {
      return [...new Set(array)];
    }
    return (
      <div>
        <div>
          <TextField
            placeholder={props.placeholder}
            onKeyPress={(e) => {
              if(props.prefix === "#" && (e.key === "#" || e.key === "＃")) {
                e.preventDefault()
                return false
              } else if(props.prefix === "@" && (e.key === "@" || e.key === "＠")) {
                e.preventDefault()
                return false
              } else if (e.key === " " || e.key === "　" || e.key == "Enter") {
                console.log({ currentText: currentText })

                if(currentText) {
                  let newTags = tags
                  newTags = newTags.concat(currentText.trim().split(/( |　)/).filter((t) => !["", " ", "　"].includes(t)))
                  setTags(uniq(newTags))
                  setCurrentText("")
                }

                e.preventDefault()
                return false
              }
            }}
            value={currentText}
            setState={setCurrentText}
          />
        </div>
        <div className={styles.tag_container}>
          {visibleTags}
        </div>
        {inputs}
      </div>
    )
  }
}

HashTag.propTypes = propTypes
HashTag.defaultProps = defaultProps

export default HashTag
