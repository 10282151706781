import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import TextField from "../../../../../../FormTags/TextField.js"
import HashTag from "../../../../../../FormTags/HashTag.js"
import styles from "./Input.scss"

const propTypes = {
  count: PropTypes.number.isRequired,
  setIsProductOptions: PropTypes.func.isRequired,
}

const Input = (props) => {
  const product_option = props.product_option
  const product_option_variations =
    product_option && product_option.product_option_variations || []
  const [productOptionName, setProductOptionName] = useState(product_option && product_option.name)
  const [names, setNames] = useState(product_option_variations.map(v => v.name))

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <TextField
          label={`オプション${props.count}`}
          id={`product_option_name_${props.count}`}
          name="product_options[][name]"
          value={productOptionName}
          onChange={(e) => (
            props.onChange(e),
            setProductOptionName(e.target.value),
            props.setIsProductOptions(isExist(e.target.value))
          )}
          required={true}
        />
      </div>

      <div className={styles.item}>
        {/* ↓高さ調整のためのダミーラベル */}
        <label>　</label>
        <HashTag
          id={`product_option_variation_name_${props.count}`}
          name="product_options[][product_option_variations][][name]"
          value={names}
          onChange={(e) => {
            props.onChange(e)
            setNames
          }}
        />
      </div>
    </div>
  )
}

Input.propTypes = propTypes

export default Input
