import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import ProductOptions from "./ProductInput/ProductOptions.js"
import ImageFile from "../../../../FormTags/ImageFile.js"
import TextField from "../../../../FormTags/TextField.js"
import TextArea from "../../../../FormTags/TextArea.js"
import styles from "./ProductInput.scss"

const propTypes = {
  setProductName: PropTypes.func.isRequired,
  setIsProductInput: PropTypes.func.isRequired,
}

const ProductInput = (props) => {
  const product = props.product
  const projectTypeId = props.projectTypeId
  const productServiceTitle = props.projectTypeId === props.typeVisitId ? "サービス" : "商品"
  const [isProductOptions, setIsProductOptions] = useState(true)
  const [name, setName] = useState(product && product.name)
  const [description, setDescription] = useState(product && product.description)
  const [image, setImage] = useState(product && product.images)
  const [url, setUrl] = useState(product && product.url)
  const [listPrice, setListPrice] = useState(product && product.list_price)
  const productOptions = product ? product.product_options || [] : []

  const checkValues = (
    isExist(name) &&
    isExist(description) &&
    isExist(image) &&
    isExist(url) &&
    isProductOptions
  )

  useEffect(() => {
    props.setIsProductInput(checkValues)
  })

  return (
    <div>
      <TextField
        label={`${productServiceTitle}名`}
        id="product_name"
        name="product[name]"
        value={name}
        onChange={(e) => {
          props.onChange(e)
          props.setProductName(e.target.value)
        }}
        setState={setName}
        required={true}
      />

      <TextArea
        label={`${productServiceTitle}説明`}
        id="product_description"
        name="product[description]"
        value={description}
        setState={setDescription}
        onChange={props.onChange}
        required={true}
      />

      <ImageFile
        label={`${productServiceTitle}画像`}
        id="product_images"
        name="product[images][]"
        value={image}
        setState={setImage}
        multiple={true}
        onChange={props.onChange}
        required={product ? false : true}
      />

      <TextField
        label="URL"
        id="product_url"
        name="product[url]"
        value={url}
        setState={setUrl}
        onChange={props.onChange}
        required={true}
      />

      <div className={styles.price_container}>
        <TextField
          type="number"
          label="単価"
          id="product_list_price"
          name="product[list_price]"
          className={styles.price_container_item}
          value={listPrice}
          setState={setListPrice}
          onChange={props.onChange}
          required={true}
        />
      </div>

      <ProductOptions
        isProductOptions={isProductOptions}
        setIsProductOptions={setIsProductOptions}
        productOptions={productOptions}
        onChange={props.onChange}
        projectTypeId={props.projectTypeId}
        typeVisitId={props.typeVisitId}
      />
    </div>
  )
}

ProductInput.propTypes = propTypes

export default ProductInput
