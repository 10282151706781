import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import Logo from "./Footer/logo.png"
import styles from "./Footer.scss"

const Footer = () => {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={Logo} />
          <nav>
            <ul>
              <li><a href="https://maro-support.zendesk.com/hc/ja/requests/new" target="_blank">お問い合わせ</a></li>
              <li><a href="https://maro-support.zendesk.com/hc/ja" target="_blank">よくある質問</a></li>
              <li><a href="/corporate" target="_blank">会社概要</a></li>
              <li><a href="https://blog.maro.mu/">ブログ</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  )
}

export default Footer
