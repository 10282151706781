import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import ErrorMessages from "../../Shared/RailsForm/ErrorMessages.js"
import AccountForm from "../Shared/AccountForm.js"
import styles from "./Edit.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
  staff: PropTypes.object.isRequired
}

const defaultProps = {
  errors: [],
}

const Edit = (props) => {
  const staffInvitationUpdatePath = `/staff/invitation`

  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <RailsForm
          action={staffInvitationUpdatePath}
          patch={true}
        >
          <h2 className={styles.form_title}>アカウント作成</h2>

          <AccountForm errors={props.errors} invited={true} staff={props.staff} />
        </RailsForm>
      </div>
    </div>
  )
}

Edit.propTypes = propTypes
Edit.defaultProps = defaultProps

export default Edit
