import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useRef } from 'react'
import PropTypes from "prop-types"
import styles from "./Submit.scss"

const propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.string,
  onClick: PropTypes.func,
  confirm: PropTypes.bool,
  buttonType: PropTypes.string,
  buttonColor: PropTypes.string,
  type: PropTypes.string,
  setPopup: PropTypes.func,
  validationForm: PropTypes.bool,
  email: PropTypes.any,
  password: PropTypes.any,
  setUserId: PropTypes.func,
}

const defaultProps = {
  className: "",
  position: "right",
  onClick: null,
  confirm: false,
  buttonType: "square",
  buttonColor: null
}

const Submit = (props) => {
  let status = true
  const influencerCheckSignIn = `/influencer/check_sign_in`
  const buttonElement = useRef(null)

  const position = (
    props.position === "right" ? styles.right :
      props.position === "left" ? styles.left : ""
  )

  const handleSubmit = (e) => {
    // 多重送信防止
    if (!status) { return e.preventDefault() }

    status = false
    setTimeout(() => { status = true }, 3000)

    // 確認オプションが有効な場合は確認する
    if (!props.confirm) { return }

    if (window.confirm('本当に実行しますか？')) {
      return true
    }
    else {
      window.alert('キャンセルしました')
      e.preventDefault()
      return false
    }
  }

  const buttonType = {
    circle: styles.circle
  }[props.buttonType]

  const buttonColor = {
    brown: styles.button_color_brown
  }[props.buttonColor]

  const onClickButton = () => {
    buttonElement.current.click()
  }

  const handPopup = () => {
    props.setPopup('popupAuthent')
  }

  const handSessions = () => {
    const data = new FormData()
    data.append("email", props.email)
    data.append("password", props.password)
    fetch(influencerCheckSignIn, {
      method: "POST",
      credentials: 'include',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: data
    }).then(function (serverPromise) {
      serverPromise.json()
        .then(function (data) {
          if (data?.user) {
            if(data.agree_policy == null ){
              props.setPopup('popupSessions')
              props.setUserId(data.id)
            }else{
              document.getElementById("influencer_check_sessions_form").submit()
            }
          } else {
            document.getElementById("influencer_check_sessions_form").submit()
          }
        })
        .catch(function (e) {
          document.getElementById("influencer_check_sessions_form").submit()
        });
    })
      .catch(function (e) {
        document.getElementById("influencer_check_sessions_form").submit()
      });
  }

  return (
    <div className={props.className}>
      {props.text &&
        <div className={`${styles.submit} ${position} ${buttonType}`}>
          <div onClick={props.onClick} className={buttonColor}>
            {props.text}
            {props.validationForm ? props.type == 'check_form' ? <span onClick={() => handPopup()} />
              : props.type == 'check_sessions' ? <span onClick={() => handSessions()} /> :
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                />
              :
              <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
              />
            }
          </div>
        </div>
      }
      {
        props.children &&
        <>
          <div onClick={onClickButton}>{props.children}</div>
          <button
            type="submit"
            onClick={(e) => handleSubmit(e)}
            className={styles.hidden_button}
            ref={buttonElement}
          />
        </>
      }
    </div>
  )
}

Submit.propTypes = propTypes
Submit.defaultProps = defaultProps

export default Submit
