import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Input from "./ProductOptions/Input.js"
import CheckboxBool from "../../../../../FormTags/CheckboxBool.js"
import styles from "./ProductOptions.scss"

const propTypes = {
  isProductOptions: PropTypes.bool.isRequired,
  setIsProductOptions: PropTypes.func.isRequired,
  projectTypeId: PropTypes.number.isRequired,
  typeVisitId: PropTypes.number.isRequired
}

const ProductOptions = (props) => {
  const productOptions = props.productOptions
  const [isDisplay, setIsDisplay] = useState(!!productOptions.length)
  const [optionForms, setOptionForms] = useState(
    productOptions.map((option, i) => {
      return <Input
        key={i}
        count={i}
        setIsProductOptions={props.setIsProductOptions}
        product_option={option}
        onChange={props.onChange}
      />
    })
  )

  const inputComponent = (
    <Input
      key={optionForms.length}
      count={optionForms.length + 1}
      setIsProductOptions={props.setIsProductOptions}
      onChange={props.onChange}
    />
  )

  const addOption = () => {
    const newArray = optionForms.concat(inputComponent)
    setOptionForms(newArray)
  }

  const addFirstOption = () => {
    if (optionForms.length !== 0) { return }

    addOption()
  }

  const removeOption = () => {
    console.log(optionForms);
    let newArray = []
    optionForms.forEach((optionForm, index) => {
      if (optionForms.length - 1 !== index) {
        newArray.push(optionForm)
      }
    })
    setOptionForms(newArray)
  }

  return (
    <div>
      <label>オプション設定</label>

      <CheckboxBool
        label={`この${props.projectTypeId === props.typeVisitId ? "サービスにはオプションがあります" : "商品にはサイズ/色のオプションがあります"}`}
        onChange={() => {
          const checked = !isDisplay
          setIsDisplay(checked)
          if(checked) {
            props.setIsProductOptions(!!optionForms.length)
          } else {
            props.setIsProductOptions(true)
          }
          addFirstOption()
          props.onChange(e)
        }}
        value={!!optionForms.length}
      />

      {isDisplay && (
        <>
          {optionForms}

          <div className={styles.container}>
            {optionForms.length < 3 && props.isProductOptions && (
              <div
                onClick={() => (
                  props.setIsProductOptions(false),
                  addOption()
                )}
                className={styles.btn}
              >別のオプションを追加する</div>
            )}

            {optionForms.length > 1 && (
              <div
                onClick={() => (
                  props.setIsProductOptions(true),
                  removeOption()
                )}
                className={styles.btn}
              >最後のオプションを削除する</div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

ProductOptions.propTypes = propTypes

export default ProductOptions
