import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./InstagramImages.scss"
import LazyLoad from "../../../Shared/LazyLoad.js"

const propTypes = {
  src: PropTypes.object.isRequired
}

const InstagramImages = (props) => {
  const ImageElement = () => {
    if (props.src.instagram_accounts && props.src.instagram_accounts[0] && props.src.instagram_accounts[0].instagram_posts) {
      return (
        <div className={styles.post}>
          <div className={styles.img_big}>
            <LazyLoad src={String(props.src.instagram_accounts[0].instagram_posts[0]?.image)}/>
          </div>
          <div className={styles.img_small}>
            <LazyLoad src={String(props.src.instagram_accounts[0].instagram_posts[1]?.image)}/>
          </div>
          <div className={styles.img_small}>
            <LazyLoad src={String(props.src.instagram_accounts[0].instagram_posts[2]?.image)}/>
          </div>
          <div className={styles.img_small}>
            <LazyLoad src={String(props.src.instagram_accounts[0].instagram_posts[3]?.image)}/>
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.post}>
          <div className={styles.img_big}>
            <LazyLoad/>
          </div>
          <div className={styles.img_small}>
            <LazyLoad/>
          </div>
          <div className={styles.img_small}>
            <LazyLoad/>
          </div>
          <div className={styles.img_small}>
            <LazyLoad/>
          </div>
        </div>
      )
    }
  }

  return ImageElement()
}

InstagramImages.propTypes = propTypes

export default InstagramImages
