import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect } from 'react'
import styles from "./Index.scss"
import LogoImg from "./Index/logo.png"
import LoginImg from "./Index/login.png"
import FvImg from "./Index/fv_image.png"
import FvImgSp from "./Index/fv_image_sp.png"
import FtImg01 from "./Index/ft01.png"
import FtImg02 from "./Index/ft02.png"
import FtImg03 from "./Index/ft03.png"
import FtImg04 from "./Index/ft04.png"
import NoImg01 from "./Index/no1.png"
import NoImg02 from "./Index/no2.png"
import NoImg03 from "./Index/no3.png"
import NoImg04 from "./Index/no4.png"
import NoImg05 from "./Index/no5.png"
import InfluencerExample0_Icon from "./Index/InfluencerExample/0_icon.png"
import InfluencerExample0_0 from "./Index/InfluencerExample/0_0.png"
import InfluencerExample0_1 from "./Index/InfluencerExample/0_1.png"
import InfluencerExample1_Icon from "./Index/InfluencerExample/1_icon.png"
import InfluencerExample1_0 from "./Index/InfluencerExample/1_0.png"
import InfluencerExample1_1 from "./Index/InfluencerExample/1_1.png"
import InfluencerExample2_Icon from "./Index/InfluencerExample/2_icon.png"
import InfluencerExample2_0 from "./Index/InfluencerExample/2_0.png"
import InfluencerExample2_1 from "./Index/InfluencerExample/2_1.png"
import InfluencerExample3_Icon from "./Index/InfluencerExample/3_icon.png"
import InfluencerExample3_0 from "./Index/InfluencerExample/3_0.png"
import InfluencerExample3_1 from "./Index/InfluencerExample/3_1.png"
import InfluencerExample4_Icon from "./Index/InfluencerExample/4_icon.png"
import InfluencerExample4_0 from "./Index/InfluencerExample/4_0.png"
import InfluencerExample4_1 from "./Index/InfluencerExample/4_1.png"
import AfloatImg from "./Index/afloat.png"
import ArtistarImg from "./Index/artistar.png"
import LutyImg from "./Index/luty.png"
import MauloaImg from "./Index/mauloa.png"
import SecretmuseImg from "./Index/secretmuse.png"
import TogenkyoImg from "./Index/togenkyo.png"
import ViluxueuxImg from "./Index/viluxueux.png"
import BrandLogosImg from "./Index/brand_logos.png"
import AbsoluteImg from "./Index/absolute_image.png"
import { IntercomProvider, useIntercom } from 'react-use-intercom';

const INTERCOM_APP_ID = 'axv39iqc';

const Content = (props) => {
  const handleFaqClick = (evt) => {
    evt.target.classList.toggle('active')
  }

  const { boot, shutdown, hide, show, update } = useIntercom();

  useEffect(() => {
    boot({
      languageOverride: 'ja'
    })
  }, [])

  const signUpPath =
    props.agency_influencer_registration_link ?
      `/influencer/sign_up?agency_influencer_registration_link_code=${props.agency_influencer_registration_link.code}` :
      `/influencer/sign_up`

  const influencerExampleList = [
    {
      iconImg: InfluencerExample0_Icon,
      name: "H様",
      flw: "7,000",
      img0: InfluencerExample0_0,
      img1: InfluencerExample0_1
    },
    {
      iconImg: InfluencerExample1_Icon,
      name: "K様",
      flw: "5,300",
      img0: InfluencerExample1_0,
      img1: InfluencerExample1_1
    },
    {
      iconImg: InfluencerExample2_Icon,
      name: "R様",
      flw: "4,000",
      img0: InfluencerExample2_0,
      img1: InfluencerExample2_1
    },
    {
      iconImg: InfluencerExample3_Icon,
      name: "S様",
      flw: "19,000",
      img0: InfluencerExample3_0,
      img1: InfluencerExample3_1
    },
    {
      iconImg: InfluencerExample4_Icon,
      name: "U様",
      flw: "4,500",
      img0: InfluencerExample4_0,
      img1: InfluencerExample4_1
    },
  ]

  return (
    <div className={styles.influencer_lp_container}>
      <header className={styles.common_header}>
        <img src={LogoImg} alt="MARO" className={styles.logo} />
        <nav>
          <ul>
            <li className={styles.login}>
              <a href="/influencer/sign_in" target="_blank">ログイン<img src={LoginImg} alt="" /></a>
            </li>
            <li>
              <a href={signUpPath} className={`${styles.co_button} ${styles.account}`} target="_blank">アカウント登録</a>
            </li>
          </ul>
        </nav>
      </header>

      <main className={styles.influencer_lp02}>
        <section className={styles.first_view}>
          <h1 className={styles.left}>
            <span className={styles.up}>＼ 1500名以上が利用中 ／</span>
            <span className={styles.middle}>インフルエンサーとブランドの<br />マッチングプラットフォーム</span>
            <span className={styles.down}>素敵な美容・ファッション系ブランドの<br />PRやアンバサダー案件などが掲載されています。</span>
            <p className={styles.sp}><img src={FvImgSp} alt="" /></p>
            <a className={styles.co_button} href={signUpPath} target="_blank">アカウント登録（無料）</a>
          </h1>
          <p className={styles.right}><img src={FvImg} alt="" /></p>
        </section>
        <div className={styles.co_bg_light_blue}>
          <section className={styles.feature}>
            <h2>
              <span className={styles.up}>FEATURES</span>
              <span className={styles.down}>サービス特徴</span>
            </h2>
            <ul className={styles.flex}>
              <li className={styles.flex_box}>
                <img src={FtImg01} className={styles.image} alt="" />
                <h3>美容・ファッションの<br />お仕事が充実</h3>
                <p className={styles.sentence}>Maroには美容・ファッション系ブランドのPRやアンバサダー案件が豊富に掲載されています。</p>
              </li>
              <li className={styles.flex_box}>
                <img src={FtImg02} className={styles.image} alt="" />
                <h3>製品の魅力を<br />知ることができる</h3>
                <p className={styles.sentence}>案件紹介ページには商品に込められた想いが綴られており、あなたの心に響く商品を見つけることができます。</p>
              </li>
              <li className={styles.flex_box}>
                <img src={FtImg03} className={styles.image} alt="" />
                <h3>ブランドから<br />スカウトが届く</h3>
                <p className={styles.sentence}>ブランドからお仕事への参加依頼が届きます。プロフィール情報を充実させることで多くのスカウトを受け取ることができます。</p>
              </li>
              <li className={styles.flex_box}>
                <img src={FtImg04} className={styles.image} alt="" />
                <h3>スタッフによる<br />LINEサポート</h3>
                <p className={styles.sentence}>ご質問はLINEへ。ご不明点の解消・投稿方法に関するアドバイスなどを通じて、弊社スタッフが丁寧に対応させていただきます。</p>
              </li>
            </ul>
          </section>
        </div>
        <div className={styles.co_bg_brown}>
          <section className={styles.flow}>
            <h2>
              <span className={styles.up}>HOW TO USE</span>
              <span className={styles.down}>ご利用の流れ</span>
            </h2>
            <ul className={styles.flex}>
              <li className={styles.flex_box}>
                <h3><img src={NoImg01} className={`${styles.image_numbering} ${styles.no1}`} alt="1" />登録</h3>
                <p className={styles.sentence}>アカウント作成は最短1分で完了。<br />Maroの全機能を無料でご利用いただけます。<br />※ フォロワー数1000名以上の方からご登録頂けます</p>
              </li>
              <li className={styles.flex_box}>
                <h3><img src={NoImg02} className={`${styles.image_numbering} ${styles.no2}`} alt="2" />参加</h3>
                <p className={styles.sentence}>気に入ったお仕事に応募して参加します。ブランドからスカウトが届く場合もございます。</p>
              </li>
              <li className={styles.flex_box}>
                <h3><img src={NoImg03} className={`${styles.image_numbering} ${styles.no3}`} alt="3" />投稿</h3>
                <p className={styles.sentence}>ブランドから商品が発送されます。受け取り後、実際に利用してみた感想をSNSに投稿しましょう。</p>
              </li>
              <li className={styles.flex_box}>
                <h3><img src={NoImg04} className={`${styles.image_numbering} ${styles.no4}`} alt="4" />報告</h3>
                <p className={styles.sentence}>ブランドに「投稿完了報告」を送信。</p>
              </li>
              <li className={styles.flex_box}>
                <h3><img src={NoImg05} className={`${styles.image_numbering} ${styles.no5}`} alt="5" />報酬受取</h3>
                <p className={styles.sentence}>完了したお仕事に対する金銭報酬がMaroにストックされ、「振込申請」により引き出すことができます。</p>
                <p className={styles.attention}>※金銭報酬は発生せず商品提供のみのお仕事もございます</p>
              </li>
            </ul>
          </section>
        </div>
        <div className={styles.co_bg_light_blue}>
          <section className={styles.influencer}>
            <h2>
              <span className={styles.up}>INFLUENCERS</span>
              <span className={styles.down}>登録中のインフルエンサー様の例</span>
            </h2>
            <ul className={styles.flex}>
              {
                influencerExampleList.map((influencerExample) => {
                  return <li className={styles.flex_box}>
                    <div className={styles.up}>
                      <p className={styles.left}><img src={influencerExample.iconImg} alt="" /></p>
                      <p className={styles.right}>
                        <span className={styles.name}>{influencerExample.name}</span>
                        <span className={styles.number}>{influencerExample.flw}flw</span>
                      </p>
                    </div>
                    <div className={styles.image_container}>
                      <p className={styles.down}><img src={influencerExample.img0} alt="" /></p>
                      <p className={styles.down}><img src={influencerExample.img1} alt="" /></p>
                    </div>
                  </li>
                })
              }
            </ul>
            <p className={styles.co_alphabet}>※<span className={styles.sentence}>アルファベット順</span></p>
          </section>
        </div>
        <section className={styles.brand}>
          <h2>
            <span className={styles.up}>BRANDS</span>
            <span className={styles.down}>導入ブランド様の例</span>
          </h2>
          <ul className={`${styles.flex} ${styles.pc}`}>
            <li className={styles.flex_box}><img src={AfloatImg} className={styles.afloat} alt="afloat" /></li>
            <li className={styles.flex_box}><img src={ArtistarImg} className={styles.artistar} alt="artistar" /></li>
            <li className={styles.flex_box}><img src={LutyImg} className={styles.luty} alt="luty" /></li>
            <li className={styles.flex_box}><img src={MauloaImg} className={styles.mauloa} alt="mauloa" /></li>
            <li className={styles.flex_box}><img src={SecretmuseImg} className={styles.secretmuse} alt="secretmuse" /></li>
            <li className={styles.flex_box}><img src={TogenkyoImg} className={styles.togenkyo} alt="togenkyo" /></li>
            <li className={styles.flex_box}><img src={ViluxueuxImg} className={styles.viluxueux} alt="viluxueux" /></li>
          </ul>
          <p className={styles.sp}><img src={BrandLogosImg} alt="" /></p>
          <p className={styles.co_alphabet}>※<span className={styles.sentence}>アルファベット順</span></p>
        </section>
        <div className={styles.co_bg_light_brown}>
          <section className={styles.faq}>
            <h2>
              <span className={styles.up}>Q&amp;A</span>
              <span className={styles.down}>よくあるご質問</span>
            </h2>
            <ul>
              <li className={styles.faq_box}>
                <p className={styles.q} onClick={handleFaqClick}>利用料金は発生しますか？</p>
                <p className={styles.a}>A. いいえ、無料でご利用いただけます。</p>
              </li>
              <li className={styles.faq_box}>
                <p className={styles.q} onClick={handleFaqClick}>男性でも登録できますか？</p>
                <p className={styles.a}>A. はい、もちろんご利用いただけます。</p>
              </li>
              <li className={styles.faq_box}>
                <p className={styles.q} onClick={handleFaqClick}>スカウトは誰からどのように届きますか？</p>
                <p className={styles.a}>A. ブランドからMaro上にてチャット形式のメッセージが届きます。</p>
              </li>
              <li className={styles.faq_box}>
                <p className={styles.q} onClick={handleFaqClick}>報酬はどのように受け取ることができますか？</p>
                <p className={styles.a}>A. Maro上での「振込申請」により受け取ることができます。「振込申請」とは、ブランドから受け取った金銭報酬を受け取るために必要な手続きです。</p>
              </li>
            </ul>
          </section>
        </div>
        <section className={styles.start_job}>
          <p className={styles.absolute_image}>
            <img src={AbsoluteImg} alt="" />
          </p>
          <h2>
            <span className={styles.up}>ご登録はこちらから</span>
            <span className={styles.down}>私たちはインフルエンサーの活動を応援します。</span>
          </h2>
          <a href={signUpPath} className={styles.co_button} target="_blank">アカウント登録（無料）</a>
        </section>
        { props.agency_influencer_registration_link &&
          <section className={styles.agency_attention_container}>
            <div className={styles.agency_attention}>
              <h2 className={styles.agency_attention_title}>注意事項（登録前にご確認ください）</h2>
              <p className={styles.agency_attention_description}>
                ※ こちらはARTISTAR株式会社（以下「ARTISTAR」）が所属インフルエンサーを募集することを目的としたアカウント登録ページです<br />
                ※ ARTISTARは、所属インフルエンサーの管理およびPR案件の受注増加を目的に、株式会社Maroが提供する「Maro」を利用しています<br />
                ※ インフルエンサーがこちらのフォームを経由して「Maro」に登録した場合、自動的にARTISTAR所属となり、今後Maroに掲載されている案件を受注することにより獲得する金銭報酬の一部（最大20％）がARTISTARに配分されます。ARTISTAR所属となるメリットとしては、ARTISTARと取引のある美容系企業からのお仕事依頼が多く届く可能性が高い点が挙げられます<br />
                ※ ただし、 こちら（ <a href="/for-influencers">https://maro.mu/for-influencers</a> ）のフォームから登録いただけた場合、ARTISTAR所属ではなく一般のインフルエンサーとして登録されます。この場合、金銭報酬を全額受け取ることが可能です<br />
              </p>
            </div>
          </section>
        }
      </main>

      <footer className={styles.common_footer}>
        <div className={styles.footer_inner}>
          <img src={LogoImg} alt="MARO" className={styles.logo} />
          <nav>
            <ul>
              <li><a href="/corporate" target="_blank">会社概要</a></li>
              <li><a href="https://blog.maro.mu/" target="_blank">ブログ</a></li>
              <li><a href="mailto:info2@maro.mu" target="_blank">お問い合わせ</a></li>
              <li><a href="/TOSforInfluencer.pdf" target="_blank">利用規約</a></li>
            </ul>
          </nav>
        </div>
        <div className={styles.copyright}>
          <p>
            Copyright © 2020 Maro inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  )
}

const Index = (props) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Content {...props} />
    </IntercomProvider>
  )
}
export default Index
