import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Sponsor from "./Edit/Sponsor.js"
import Member from "./Edit/Member.js"
import MessageTemplate from "./Edit/MessageTemplate.js"
import Tab from "../Shared/Tab.js"
import styles from "./Edit.scss"

const propTypes = {
  sponsor: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  messageTemplate: PropTypes.object.isRequired,
}

const Edit = (props) => {
  const panels = ([
    {
      title: "企業情報",
      content: (
        <Sponsor sponsor={props.sponsor} />
      )
    },
    {
      title: "メンバー",
      content: (
        <Member
          sponsor={props.sponsor}
          members={props.members}
        />
      )
    },
    {
      title: "テンプレート",
      content: (
        <MessageTemplate messageTemplate={props.messageTemplate}/>
      )
    }
  ])

  return (
    <div className={styles.container}>
      <h1>会社設定</h1>
      <Tab panels={panels} />
    </div>
  )
}

Edit.propTypes = propTypes

export default Edit
