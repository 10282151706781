import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Select from "../../../FormTags/Select.js"
import Date from "../../../FormTags/Date.js"
import DateRange from "../../../FormTags/DateRange.js"
import styles from "./Step05.scss"
import QuestionPng from "./question.png"
import ReactTooltip from 'react-tooltip'
import Moment from 'moment'

const propTypes = {
  stepBackBtn: PropTypes.object,
  nextBtn: PropTypes.object.isRequired,
}

const Step05 = (props) => {
  const project = props.project
  const [recruitmentStartAt, setRecruitmentStartAt] = useState(project?.recruitment_start_at ? Moment(project.recruitment_start_at) : null)
  const [recruitmentEndAt, setRecruitmentEndAt] = useState(project?.recruitment_end_at ? Moment(project.recruitment_end_at) : null)
  const [daysToSelectionResult, setDaysToSelectionResult] = useState(project?.days_to_selection_result || 7)
  const [daysToShip, setDaysToShip] = useState(project?.days_to_ship || 7)
  const [daysToPost, setDaysToPost] = useState(project?.days_to_post || (props.projectTypeId === props.typeVisitId ? 3 : 17))
  const [daysToReport, setDaysToReport] = useState(project?.days_to_report || 2)
  const [displayRecruitmentCalendar, setDisplayRecruitmentCalendar] = useState(false);
  const [focusedInput, setFocusedInput] = useState("startDate")

  const checkValues = (
    isExist(recruitmentStartAt) &&
    isExist(recruitmentEndAt)
  )

  const onClickRecruitmentTerm = () => { setDisplayRecruitmentCalendar(true) }

  const dayOptions =
    [
      { value: 2, name: "2日以内" },
      { value: 3, name: "3日以内" },
      { value: 4, name: "4日以内" },
      { value: 5, name: "5日以内" },
      { value: 6, name: "6日以内" },
      { value: 7, name: "7日以内" },
      { value: 8, name: "8日以内" },
      { value: 9, name: "9日以内" },
      { value: 10, name: "10日以内" },
      { value: 11, name: "11日以内" },
      { value: 12, name: "12日以内" },
      { value: 13, name: "13日以内" },
      { value: 14, name: "14日以内" }
    ]

  const longDayOptions =
    [
      { value: 17, name: "17日以内" },
      { value: 18, name: "18日以内" },
      { value: 19, name: "19日以内" },
      { value: 20, name: "20日以内" },
    ]

  const visitDayOptions =
    [
      { value: 3, name: "3日以内" },
      { value: 4, name: "4日以内" },
      { value: 5, name: "5日以内" },
      { value: 6, name: "6日以内" },
      { value: 7, name: "7日以内" },
      { value: 8, name: "8日以内" },
      { value: 9, name: "9日以内" },
      { value: 10, name: "10日以内" },
      { value: 11, name: "11日以内" },
      { value: 12, name: "12日以内" },
      { value: 13, name: "13日以内" },
      { value: 14, name: "14日以内" },
      { value: 15, name: "15日以内" },
      { value: 16, name: "16日以内" },
      { value: 17, name: "17日以内" },
    ]

  return (
    <div>
      <div className={styles.header}>
        {props.stepBackBtn}
        {props.saveBtn}
      </div>

      <h2>5/6 プロジェクトのスケジュールについて</h2>

      <DateRange
        label="プロジェクトの掲載期間"
        startDate={recruitmentStartAt}
        endDate={recruitmentEndAt}
        setStartDate={setRecruitmentStartAt}
        setEndDate={setRecruitmentEndAt}
        startDateName={"project[recruitment_start_at]"}
        endDateName={"project[recruitment_end_at]"}
        onChange={props.onChange}
      />

      {
        (props.approveAll == "false" || props.approveAll == false) &&
        <Select
          label="応募受け取り後、何日以内に選考結果を通知しますか？"
          id="project_days_to_selection_result"
          name="project[days_to_selection_result]"
          options={dayOptions}
          value={daysToSelectionResult}
          setState={setDaysToSelectionResult}
          required={true}
          onChange={props.onChange}
        />
      }

      <Select
        label={props.projectTypeId === props.typeVisitId ? "応募承認後、何日以内に利用してもらいますか？" : "応募承認後、何日以内に商品を発送しますか？"}
        id="project_days_to_ship"
        name="project[days_to_ship]"
        options={dayOptions}
        value={daysToShip}
        setState={setDaysToShip}
        required={true}
        onChange={props.onChange}
      />

      <Select
        label={
          <label>
            {
              props.projectTypeId === props.typeVisitId ?
              "サービス利用後、何日以内に投稿してもらいますか？" :
              "商品発送完了後、何日以内に投稿してもらいますか？"
            }
            <span data-tip={"インフルエンサーが商品を受け取るまで時間を要する場合がございます。受け取りから撮影等の投稿準備を考慮した余裕のある期間設定をお願いいたします。"}>
              <img src={QuestionPng} className={styles.question_mark} />
              <ReactTooltip effect="solid" place="right" backgroundColor="white" textColor="#4F4F4F" />
            </span>
          </label>
        }
        id="project_days_to_post"
        name="project[days_to_post]"
        options={props.projectTypeId === props.typeVisitId ? visitDayOptions : longDayOptions}
        value={daysToPost}
        setState={setDaysToPost}
        required={true}
        onChange={props.onChange}
      />

      <Select
        label="投稿後、何日以内に投稿完了の報告をしてもらいますか？"
        id="project_days_to_report"
        name="project[days_to_report]"
        options={dayOptions}
        value={daysToReport}
        setState={setDaysToReport}
        required={true}
        onChange={props.onChange}
      />

      {checkValues && (
        props.nextBtn
      )}
    </div>
  )
}

Step05.propTypes = propTypes

export default Step05
