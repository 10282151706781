import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Select from "../Select.js"

const propTypes = {
  prefectures: PropTypes.array.isRequired,
}

const SelectPrefecture = (props) => {
  let prefectures = []
  props.prefectures.forEach((prefecture) => {
    prefectures.push({
      value: prefecture.id,
      name: prefecture.name
    })
  })

  return (
    <Select
      label={props.label}
      id={props.id}
      name={props.name}
      options={prefectures}
      value={props.value}
      className={props.className}
      onClick={props.onClick}
      onChange={props.onChange}
      setState={props.setState}
      required={props.required}
    />
  )
}

SelectPrefecture.propTypes = propTypes

export default SelectPrefecture