import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./Index.scss"
import RewardSummary from "../Shared/RewardSummary.js"
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment'
import 'moment/locale/ja'; // 日本語ローカライズ
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './datepicker.css';

const propTypes = {
  reward_summary: PropTypes.object.isRequired
}

const Index = (props) => {
  const defaultStartDate = moment(props.start_date)
  const defaultEndDate = moment(props.end_date)
  const [startDate, setStartDate] = useState(moment(props.start_date))
  const [endDate, setEndDate] = useState(moment(props.end_date))
  const [focusedInput, setFocusedInput] = useState("startDate")
  const [displayCalendar, setDisplayCalendar] = useState(false)
  moment.locale('ja');

  useEffect(() => {
    if(!displayCalendar && startDate && endDate && !(startDate.isSame(defaultStartDate) && endDate.isSame(defaultEndDate))) {
      location.href =
        `/agency/reports?start_date=${startDate.format("YYYY/MM/DD")}&end_date=${endDate.format("YYYY/MM/DD")}`
    }
  }, [displayCalendar])

  const onClickTerm = () => { setDisplayCalendar(true) }

  return (
    <div className={styles.container}>
      <h1>売上レポート</h1>
      <p className={styles.termInputLabel} onClick={onClickTerm}>{startDate?.format("YYYY/MM/DD")}〜{endDate?.format("YYYY/MM/DD")}</p>
      <div className={`${styles.calendar_container} agency-reports-range-datepicker`} >
        { displayCalendar &&
          <DayPickerRangeController
            startDate={startDate}
            startDateId="startDateId"
            endDate={endDate}
            endDateId="endDateId"
            focusedInput={focusedInput}
            onFocusChange={(focused) => {
              setFocusedInput(!focused ? 'startDate' : focused);
            }}
            onDatesChange={(selectedDates) => {
              if (focusedInput === 'startDate') {
                setStartDate(selectedDates.startDate);
              } else {
                setEndDate(selectedDates.endDate);
                setFocusedInput('startDate');
                if(endDate >= startDate) {
                  setDisplayCalendar(false)
                }
              }
            }}
            numberOfMonths={2}
            onOutsideClick={() => endDate && endDate >= startDate && setDisplayCalendar(false)}
            hideKeyboardShortcutsPanel={true}
          />
        }
      </div>
      <h3>売上実績</h3>
      <RewardSummary reward_summary={props.reward_summary} />

      <div className={styles.csv_download_link_container}>
        <a className={styles.csv_download_link} href={`/agency/reports.csv?start_date=${startDate.format("YYYY/MM/DD")}&end_date=${endDate.format("YYYY/MM/DD")}`} >CSVで出力</a>
      </div>

      <div>
        <table className={styles.influencer_table}>
          <tbody>
            <tr>
              <th>インフルエンサー</th>
              <th>プロジェクト参加数</th>
              <th>クリック</th>
              <th>コンバージョン</th>
              <th>未計上売上</th>
              <th>確定売上</th>
            </tr>

            {
              props.influencer_reports.map((row, i) => {
                return (
                  <tr key={i}>
                    <td className={styles.influencer_name_column}>
                      {row.influencer_name}
                    </td>
                    <td>
                      {row.entry_count.toLocaleString()}件
                    </td>
                    <td>
                      {row.click_count.toLocaleString()}件
                    </td>
                    <td>
                      {row.cv_count.toLocaleString()}件
                    </td>
                    <td>
                      {row.pending_amount.toLocaleString()}
                    </td>
                    <td>
                      {row.approved_amount.toLocaleString()}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

Index.propTypes = propTypes
export default Index
