import "core-js/stable"
import "regenerator-runtime/runtime"
import PropTypes from "prop-types"
import React, { useState } from 'react'
import styles from "./Switch.scss"

const propTypes = {
  elements: PropTypes.array.isRequired
}

const Switch = (props) => {
  const [buttonList, setButtonList] = useState(props.elements)
  const onClick = (element) => {
    const newButtonList = buttonList.map((_element) => {
      if(_element === element) {
        _element.selected = true
      } else {
        _element.selected = false
      }
      return _element
    })
    setButtonList(newButtonList)

    element.onClick()
  }

  return (
    <div className={styles.container}>
      {
        buttonList.map((element, index) => {
          return (
            <div
              key={index}
              className={`${styles.item} ${element.selected ? styles.selected : ""}`}
              onClick={() => onClick(element)}
              >
              {element.text}
            </div>
          )
        })
      }
    </div>
  )
}

Switch.propTypes = propTypes

export default Switch
