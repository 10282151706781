require("turbolinks").start()
Turbolinks.setProgressBarDelay(0)

const callAnalytics = (pushUrl) => {
  try {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "UA-XXXXX", {
      page_path: pushUrl,
      page_title: document.title
    })
    console.log(`page_title(${document.title})`)
    console.log(`page_path(${pushUrl})`)
  } catch (e) {
    console.log(e)
  }
}
window.callAnalytics = callAnalytics

const turbolinksGA = () => {
  try {
    const path = window.location.pathname
    const params = window.location.search
    const pushUrl = path + params
    callAnalytics(pushUrl)
  } catch (e) {
    console.log(e)
  }
}
document.addEventListener("turbolinks:load", turbolinksGA)

const turbolinksActiveCheck = () => {
  if (Turbolinks.supported) {
    console.log("turbolinks is active")
  } else {
    alert("このブラウザはHistoryAPIに対応していないため、一部の機能が正常に動作しない可能性がございます。")
  }
}
document.addEventListener("turbolinks:load", turbolinksActiveCheck)

const turbolinksPushState = () => {
  history.pushState({
    turbolinks: true,
    url: location.href
  }, '', location.href)
}
