import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./ReadMore.scss"

const propTypes = {
  word: PropTypes.string.isRequired,
  truncate: PropTypes.number.isRequired,
  forceShownWord: PropTypes.string
}

const ReadMore = (props) => {
  const truncatedWord = props.word.slice(0, props.truncate)
  const hiddenWord = props.word.slice(props.truncate + 1, props.word.length)
  const checkOpen = () => {
    return props.word.length <= props.truncate ||
    !!(props.forceShownWord && hiddenWord.match(new RegExp(props.forceShownWord))) ||
    !!(props.forceShownWord && !truncatedWord.match(new RegExp(props.forceShownWord)) && props.word.match(new RegExp(props.forceShownWord)))
  }
  const [open, setOpen] = useState(checkOpen())

  useEffect(() => {
    setOpen(checkOpen())
  }, [props.forceShownWord])

  const onClick = () => {
    setOpen(true)
  }

  return <p>{open ? <span>{props.word}</span> : <span>{truncatedWord}<br /><a className={styles.more_button} onClick={onClick}>もっと見る</a></span>}</p>
}

ReadMore.propTypes = propTypes

export default ReadMore
