import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Message.scss"

const propTypes = {
  flash: PropTypes.array,
}

const defaultProps = {
  flash: [],
}

const Message = (props) => {
  return (
    <>
      {props.flash.length > 0 && (
        <>
          {props.flash[0][0] === "notice" && (
            <div className={styles.notice}>
              {props.flash[0][1]}
            </div>
          )}
          {props.flash[0][0] === "alert" && (
            <div className={styles.alert}>
              {props.flash[0][1]}
            </div>
          )}
          {props.flash[0][0] === "error" && (
            <div className={styles.alert}>
              {props.flash[0][1]}
            </div>
          )}
        </>
      )}
    </>
  )
}

Message.propTypes = propTypes
Message.defaultProps = defaultProps

export default Message