import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./StepPage.scss"
import QuestionImage from "./StepPage/question_button.svg"
import InstagramFacebookIconImage from "./StepPage/instagram_facebook.svg"
import StepNumber from "./StepPage/2.svg"
import CautionImage from "./StepPage/caution.svg"
import Step3_1_Img from "./StepPage/step3_1.png"
import Step3_2_Img from "./StepPage/step3_2.png"
import Step3_3_Img from "./StepPage/step3_3.png"
import Step3_4_1_Img from "./StepPage/step3_4_1.png"
import Step3_4_2_Img from "./StepPage/step3_4_2.png"
import Step3_5_Img from "./StepPage/step3_5.png"
import Step3_6_Img from "./StepPage/step3_6.png"
import Step3_7_Img from "./StepPage/step3_7.png"
import Step3_8_Img from "./StepPage/step3_8.png"
import ArrowImage from "./StepPage/chevron-down.svg"
import BackButtonImage from "./StepPage/back_button.svg"
import { Link, animateScroll as scroll } from "react-scroll";

const propTypes = {
}

const SecondStep = (props) => {
  return (
    <div className={styles.step_container}>
      <div className={styles.step_header}>
        <a onClick={props.onClickBack} className={styles.back_button}>
          <BackButtonImage />
        </a>
        <h1>Facebook連携</h1>
      </div>
      <div className={`${styles.step_top} ${styles.step_2}`}>
        <div className={styles.step_bar_container}>
          <div className={styles.step_bar_4}>
          </div>

          <div className={styles.step_bar_4}>
          </div>

          <div className={`${styles.step_bar_4} ${styles.active}`}>
          </div>

          <div className={styles.step_bar_4}>
          </div>
        </div>

        <div className={styles.question_container}>
          <QuestionImage onClick={props.onClickQuestion} />
        </div>

        <div className={styles.step_main}>
          <div className={styles.step_title}>
            <StepNumber className={styles.step_number} />
            <InstagramFacebookIconImage  className={styles.instagram_facebook_icon_image }/>
            <h1>InstagramはFacebookページと<br />連携していますか？</h1>
          </div>

          <div className={styles.button_container}>
            <Link
              to="detail"
              spy={true}
              smooth={true}
              offset={-50}
              duration={600}
              className={styles.no_button}>
              いいえ/わからない
            </Link>

            <a onClick={props.onClickYes} className={styles.yes_button}>
              <span>はい</span>
              <ArrowImage className={styles.arrow} />
            </a>
          </div>

          <div className={styles.checkpoint_container}>
            <h2 className={styles.checkpoint_title}><CautionImage className={styles.caution_image}/><span>確認事項</span></h2>
            <p className={styles.checkpoint}>
              ※InstagramアカウントがFacebookと連携されていない場合、Maroとの連携をすることができません。<br /><br />
              ※ インフルエンサー様の許可なくInstagramの情報・投稿がFacebookに公開されることはありません。また、Facebookの情報・投稿がInstagramに公開されることもありません。<br /><br />
              ※ インフルエンサー様の許可なくMaroからInstagaram/Facebookに投稿されることはありません。
            </p>
          </div>
        </div>
      </div>

      <div className={styles.step_what}>
        <h2 id='detail' className={styles.step_what_title}>Facebookページとは</h2>
        <p className={styles.step_what_content}>
          Facebookページとは、Facebook内に個人アカウントとは別に開設できるページです。主にブランド・企業・タレント（クリエイター）向けのFacebookアカウントを指す正式名称です。<br /><br />
※ ページ名は匿名でもご登録いただけます。<br />
※ ページを非公開に設定することは可能です。
        </p>
      </div>

      <div className={styles.step_detail}>
        <h2>Facebookページを連携する方法</h2>

        <p className={styles.step_detail_caption}>※ まだFacebookアカウントをお持ちでない方は、<a className={styles.decoration_link} href="https://facebook.com" target="_blank">Facebookアカウントを作成（1分で完了）</a>する必要があります。</p>

        <div className={styles.step_detail_body}>
          <div className={styles.block}>
            <p>1. 「プロフィールを編集」を選択</p>
            <img className={styles.step_image} src={Step3_1_Img} />
          </div>

          <div className={styles.block}>
            <p>2. 「リンクまたは作成」を選択</p>
            <img className={styles.step_image} src={Step3_2_Img} />
          </div>

          <div className={styles.block}>
            <p>3. 「Facebookページを作成」を選択（すでにFacebookページをお持ちの方は「6」にお進み下さい。）</p>
            <img className={styles.step_image} src={Step3_3_Img} />
          </div>

          <div className={styles.block}>
            <p>4. 以下の画面で「次へ>設定を完了します」を選択</p>
            <img className={styles.step_image} src={Step3_4_1_Img} />
            <img className={styles.step_image} src={Step3_4_2_Img} />
          </div>

          <div className={styles.block}>
            <p>5. もう一度「リンクまたは作成」を選択</p>
            <img className={styles.step_image} src={Step3_5_Img} />
          </div>

          <div className={styles.block}>
            <p>6. 「既存ページをリンク」を選択</p>
            <img className={styles.step_image} src={Step3_6_Img} />
          </div>

          <div className={styles.block}>
            <p>7. 表示されているページの中から、連携したいページを選び、完了を選択</p>
            <img className={styles.step_image} src={Step3_7_Img} />
          </div>

          <div className={styles.block}>
            <p>8. プロフィールを確認し、ページに選択したページ名が反映されていたら成功です</p>
            <img className={styles.step_image} src={Step3_8_Img} />
          </div>
        </div>
      </div>
    </div>
  )
}

SecondStep.propTypes = propTypes

export default SecondStep
