import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./RichSelect.scss"

const propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  required: PropTypes.bool,
  richDesign: PropTypes.bool
}

const defaultProps = {
  className: "",
  value: "",
  onClick: null,
  onChange: null,
  setState: null,
  required: false,
  richDesign: false
}

const Select = (props) => {
  let list = []
  const [options, setOptions] = useState(props.options)
  const [showAll, setShowAll] = useState(false)
  const [selectedValue, setSelectedValue] = useState(props.value)

  useEffect(() => {
    const newOptions = options.sort((a, b) => {
      return a.value === props.value ? -1 : 1
    })
    setOptions(newOptions)
  }, [props.value])

  const onRichOptionClick = (value) => {
    if(showAll && selectedValue != value) {
      const newOptions = options.sort((a, b) => {
        return a.value === value ? -1 : 1
      })
      setOptions(newOptions)
    }
    setSelectedValue(value)
    setShowAll(!showAll)
    props.setState(value)
  }

  options.forEach((option, index) => {
    list.push(
      <option
        key={index}
        value={option.value}
      >{option.name}</option>
    )
  })

  return (
    <div className={props.className}>
      <label className={styles.label}>{props.label}</label>
      <div className={styles.select}>
        {
          options.map((option, i) => {
            return (
              <div className={i === 0 || showAll ? styles.select_input : `${styles.select_input} ${styles.hide}`} key={i} onClick={() => onRichOptionClick(option.value)}>
                {option.name}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select
