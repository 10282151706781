import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import Submit from "../FormTags/Submit.js"
import styles from "./New.scss"
import FacebookIconImg from "./facebook_icon.svg"
import Popup from "../../Application/PopupRules/Popup.js"

const propTypes = {
  authGraphApiPath: PropTypes.string.isRequired,
}

const New = (props) => {
  const influencerSignUpPath = "/influencer/sign_up"
  const influencerSessionsPath = "/influencer/sign_in"
  const influencerForgetPasswordPath = `/influencer/password/new`
  const [popup, setPopup] = useState('default')
  const [userId, setUserId] = useState(null)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const validationForm = () => {
    if (email && password) {
      return true
    } else {
      return false
    }
  }

  return (
    <div>
      <div className={styles.sns_link_btn_container}>
        <h2>ログイン</h2>
        <RailsForm
          action={influencerSessionsPath}
          formId="influencer_check_sessions_form"
        >
          <TextField
            label="メールアドレス"
            id="influencer_email"
            name="influencer[email]"
            type="email"
            value={email}
            setState={setEmail}
            required={true}
          />
          <TextField
            label="パスワード"
            id="influencer_password"
            name="influencer[password]"
            type="password"
            value={password}
            setState={setPassword}
            required={true}
          />
          <div className={styles.form_link}>
            <a href={influencerForgetPasswordPath}>
              パスワードを忘れた
            </a>
          </div>
          <Submit className={styles.submit_button} text={"ログイン"} type={"check_sessions"} setPopup={setPopup} validationForm={validationForm()} email={email} password={password} setUserId={setUserId} />
        </RailsForm>
        <Popup method={popup} setPopup={setPopup} userId={userId}/>
        <a
          href={props.authGraphApiPath}
          className={styles.sns_link_btn_item}
        >
          <FacebookIconImg className={styles.snsIconImg} />
          Facebookでログイン
        </a>

        <a className={styles.sign_up_link} href={influencerSignUpPath}>アカウントの新規作成はこちら</a>
      </div>
    </div>
  )
}

New.propTypes = propTypes

export default New
