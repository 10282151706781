import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./New.scss"
import ImportCsvModal from "./New/ImportCsvModal.js"
import Submit from "../FormTags/Submit.js"
import RailsForm from "../../Shared/RailsForm.js"
import Form from "./Shared/Form.js"

const propTypes = {
  errors: PropTypes.array.isRequired,
}

const defaultProps = {
  errors: [],
}

const New = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const influencerRegistrationPath = `/agency/influencers`
  const [errorMessage, setErrorMessage] = useState([])

  return (
    <>
      <h2>インフルエンサーの登録</h2>
      <p>インフルエンサーの情報を登録してください。</p>
      <div className={styles.csv_upload_link_container}>
        <a  className={styles.csv_upload_link} onClick={() => setModalStatus(true)}>CSVで一括登録</a>
      </div>

      {
        errorMessage.length > 0 &&
        <p className={styles.error_message}>
          {errorMessage}
        </p>
      }
      <div>
        <RailsForm
          action={influencerRegistrationPath}
          setCustomErrorMessage={setErrorMessage}
          remote={true}
        >
          <Form new={true} />
          <Submit text="登録する" />
        </RailsForm>
      </div>

      <ImportCsvModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
    </>
  )
}

New.propTypes = propTypes
New.defaultProps = defaultProps

export default New
