import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import LazyLoad from "../../Shared/LazyLoad.js"
import styles from "./Index.scss"
import Moment from "moment"

const propTypes = {
  entries: PropTypes.array.isRequired,
}

const Index = (props) => {
  const influencerProjectPath = `/influencer/projects`
  const influencerRecommendUrl = "/influencer/projects?order=recommend"
  const Status = (entry) => {
    const newInfluencerProjectPostReportPath = `${influencerProjectPath}/${entry.project.id}/post_report/new`
    let status = {title: "", message: null, labelClassName: ""}

    if(entry.status === "pending"){
      status.title = "承認待ち"
      status.message = <p className={styles.waitingMessage}>企業からの承認待ちです。</p>
      status.labelClassName = styles.waitingLabel
    }else if(entry.status === "rejected"){
      status.title = "承認されませんでした"
      status.labelClassName = styles.completedLabel
      status.message = <p className={styles.waitingMessage}>選考の結果、応募が承認されませんでした。またのご応募を心よりお待ちしております。</p>
    }else if(entry.status === "approve" && entry.project["type_use?"]){
      status.title = "発送待ち"
      status.labelClassName =  styles.waitingLabel
    }else if(entry.status === "approve" || entry.status === "shipped"){
      status.title = "投稿完了報告待ち"
      status.message = <p className={styles.actionRequiredMessage}>投稿後、<a className={styles.messageLink} href={newInfluencerProjectPostReportPath}>投稿完了報告</a>を行ってください</p>
      status.labelClassName = styles.actionRequiredLabel
    }else if(entry.status === "reported"){
      status.title = "プロジェクト完了"
      status.labelClassName = styles.completedLabel
    }
    return status
  }

  console.log(props);
  
  return (
    <div>
      <h1>プロジェクト</h1>
      {props.entries.length == 0 &&
        <div className={styles.projectEmpty}>
          <p className={styles.projectEmptyMessage}>
            参加中のプロジェクトはありません。
            <br />お気に入りのプロジェクトを見つけて参加しましょう。
          </p>
          <a href={influencerRecommendUrl} className={styles.projectEmptyRecommend}>プロジェクトを探す</a>
        </div>
      }
      {props.entries.map((entry, key) => {
        const status = Status(entry)
        return (
          <div key={key} className={styles.projectCard}>
            <a href={`${influencerProjectPath}/${entry.project.id}`} className={styles.projectImageContainer}>
              <LazyLoad src={String(entry.project.image)} />
            </a>
            <div className={styles.projectDetailContainer}>
              <div className={styles.status}>
                <p className={status.labelClassName}>{status.title}</p>
                <p className={styles.statusChanged}>{entry.days_status_changed}日前</p>
              </div>
              <a href={`${influencerProjectPath}/${entry.project.id}`} className={styles.projectTitle}>
                {entry.project.title}
              </a>
              <div className={styles.messageContainer}>
                {status.message}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}


Index.propTypes = propTypes

export default Index
