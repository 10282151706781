import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./StepPage.scss"
import CheckIconImage from "./StepPage/check_icon.svg"
import ArrowImage from "./StepPage/chevron-down.svg"

const PreCompleteStep = () => {
  return (
    <div className={`${styles.step_container} ${styles.complete_step}`}>
      <div className={styles.step_header}>
        <h1>仮登録完了のお知らせ</h1>
      </div>

      <div className={styles.complete_step_main}>
        <CheckIconImage className={styles.checkicon}/>
        <h1>仮登録が完了しました。<br /><br /><span>運営による登録審査の後、1営業日以内にメールにて審査結果を通知させていただきます。</span></h1>

        <p>※ 審査が承認された場合、本登録URLが記載されたメールが届きます。URLから本登録手続きを頂くことでMaroがご利用可能となります。</p>
      </div>
    </div>
  )
}

export default PreCompleteStep
