import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import { BarChart, Bar, XAxis, YAxis, LabelList, PieChart, Pie, Cell } from "recharts";
import LazyLoad from "../../../Shared/LazyLoad.js"
import styles from "./InstagramTab.scss"

const propTypes = {
  influencer: PropTypes.object,
}

const InstagramTab = (props) => {
  const influencer = props.influencer;
  const instagramAccount = influencer.instagram_accounts[0]
  const manualInputInstagramAccount = influencer.manual_input_instagram_account
  console.log(instagramAccount)
  console.log(manualInputInstagramAccount)

  const AudienceData = () => {
    if((!instagramAccount || instagramAccount.audience_ages.length === 0 && instagramAccount.audience_genders.length === 0) && !manualInputInstagramAccount) {
      return <></>
    }

    const AudienceAgesChart = () => {
      const ageData = instagramAccount.audience_ages.map((v) => {
        return {
          name: `${v.age_min}〜${v.age_max || ""}`,
          percent: v.percent
        }
      })
      const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;

        return (
          <g>
            <text x={250} y={y + 10} fill="#737373" textAnchor="middle" dominantBaseline="middle" font-weight="bold" font-size="16px" >
              {value}<tspan font-size="10px" font-size-adjust="0.58">%</tspan>
            </text>
          </g>
        );
      };

      return (
        <BarChart
          width={280}
          height={ageData.length * 33}
          data={ageData}
          layout="vertical"
          margin={{top: 5, right: 120, left: 20, bottom: 5}}
          className={styles.audience_chart}
        >
          <XAxis type="number" hide={true} />
          <YAxis axisLine={false} tickLine={false} type="category" dataKey="name" interval={0} />
          <Bar dataKey="percent" fill="#ac7979" isAnimationActive={false} >
            <LabelList dataKey="percent" position="right" content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      )
    }

    // if(instagramAccount && instagramAccount.audience_ages.length > 0 && instagramAccount.audience_genders.length > 0) {
    const AudienceGendersChart = () => {
      const genderData = ((instagramAccount?.audience_genders?.length && instagramAccount.audience_genders) || manualInputInstagramAccount.audience_genders).map((v) => {
        return {
          gender: v.gender,
          name: v.gender === "F" ? "女性" : "男性",
          percent: v.percent,
        }
      })

      const getPercent = (gender) => {
        const genderData = ((instagramAccount?.audience_genders?.length && instagramAccount.audience_genders) || manualInputInstagramAccount.audience_genders).find((v) => {
          return v.gender === gender
        })

        if(genderData){
          return genderData.percent
        } else {
          return 0
        }
      }

      const COLORS = { "F": "#AC7979", "M": "#ccc" }

      return (
        <div className={`${styles.gender_piechart_container} ${styles.audience_chart}`}>
          <PieChart
            width={100}
            height={100}
          >
            <Pie data={genderData} dataKey="percent" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#ac7979" labelLine={false} startAngle={-270} isAnimationActive={false}>
              {
                genderData.map((entry, index) => <Cell fill={COLORS[entry.gender]}/>)
              }
            </Pie>
          </PieChart>
          <div>
            <div className={styles.gender_piechart_label}>
              <div className={styles.gender_piechart_legend} style={{'background-color': COLORS["M"]}}></div>
              <p className={styles.gender_piechart_label_name}>男性&emsp;</p>
              <p className={styles.gender_piechart_label_num}>
                {getPercent("M")}<span className={styles.gender_piechart_label_num_unit}>%</span>
              </p>
            </div>
            <div className={styles.gender_piechart_label}>
              <div className={styles.gender_piechart_legend} style={{'background-color': COLORS["F"]}}></div>
              <p className={styles.gender_piechart_label_name}>女性&emsp;</p>
              <p className={styles.gender_piechart_label_num}>
                {getPercent("F")}<span className={styles.gender_piechart_label_num_unit}>%</span>
              </p>
            </div>
            <div className={styles.gender_piechart_label}>
              <div className={styles.gender_piechart_legend} style={{'background-color': '#000'}}></div>
              <p className={styles.gender_piechart_label_name}>その他</p>
              <p className={styles.gender_piechart_label_num}>
                {100 - parseInt(getPercent("F") + getPercent("M"))}<span className={styles.gender_piechart_label_num_unit}>%</span>
              </p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={styles.audience_chart_list}>
        {
          (instagramAccount && instagramAccount.audience_ages.length > 0) &&
          <div className={styles.audience_chart_container}>
            <p className={styles.audience_chart_title}>年齢</p>
            <AudienceAgesChart className={styles.audience_chart}/>
          </div>
        }

        { ((instagramAccount && instagramAccount.audience_genders.length > 0) || manualInputInstagramAccount) &&
          <div className={styles.audience_chart_container}>
            <p className={styles.audience_chart_title}>性別</p>
            <AudienceGendersChart className={styles.audience_chart}/>
          </div>
        }
      </div>
    )
  }

  const InsightData = () => {
    return(
      <div className={styles.insight_data_block_container}>
        <div className={styles.insight_data_block}>
          <p className={styles.insight_data_title}>
            リーチ数
          </p>
          <p className={styles.insight_data_num}>
            { instagramAccount?.average_reach ?
              <><span>{instagramAccount.average_reach.toLocaleString()}</span><span className={styles.insight_data_num_unit}>アカウント</span></> :
              <span>未計測</span>
             }
          </p>
        </div>
        <div className={styles.insight_data_block}>
          <p className={styles.insight_data_title}>
            エンゲージ
          </p>
          <p className={styles.insight_data_num}>
            { (instagramAccount?.average_engagement_rate || manualInputInstagramAccount?.average_engagement_rate) ?
              <><span>{instagramAccount?.average_engagement_rate || manualInputInstagramAccount?.average_engagement_rate}</span><span className={styles.insight_data_num_unit}>%</span></> :
              <span>未計測</span>
            }
          </p>
        </div>
        <div className={styles.insight_data_block}>
          <p className={styles.insight_data_title}>
            平均いいね数
          </p>
          <p className={styles.insight_data_num}>
            {instagramAccount?.average_like_count ?
              <><span>{instagramAccount.average_like_count.toLocaleString()}</span><span className={styles.insight_data_num_unit}>いいね</span></> :
              <span>未計測</span>
            }
          </p>
        </div>
        <div className={styles.insight_data_block}>
          <p className={styles.insight_data_title}>
            コメント数
          </p>
          <p className={styles.insight_data_num}>
            { instagramAccount?.average_comments_count ?
              <><span>{instagramAccount.average_comments_count.toLocaleString()}</span><span className={styles.insight_data_num_unit}>コメント</span></> :
              <span>未計測</span>
            }
          </p>
        </div>
        <div className={styles.insight_data_block}>
          <p className={styles.insight_data_title}>
            投稿頻度
          </p>
          <p className={styles.insight_data_num}>
            { instagramAccount?.average_post_span_per_week ?
              <><span>{instagramAccount.average_post_span_per_week}</span><span className={styles.insight_data_num_unit}>回/週</span></> :
              <span>未計測</span>
            }
          </p>
        </div>
      </div>
    )
  }

  const InstagramPosts = () => {
    if(instagramAccount && instagramAccount.instagram_posts && instagramAccount.instagram_posts.length) {
      return (
        <div>
          <p>投稿</p>
          <div className={styles.post_list_container}>
            {instagramAccount.instagram_posts.map((post, key) => {
              return (
                <div className={styles.post_image_container} key={key}>
                  <LazyLoad className={styles.post_image} src={String(post.image)} />
                </div>
              )
            })}
          </div>
        </div>
      )
    } else if(manualInputInstagramAccount?.post_image_path) {
      return <div>
          <p>投稿</p>
          <div className={styles.post_list_container}>
            <LazyLoad src={String(manualInputInstagramAccount.post_image_path)} />
          </div>
        </div>
    } else {
      return <div>
        <p>投稿</p>
        <div className={styles.post_list_container}>
          <p>未計測</p>
        </div>
      </div>
    }
  }

  return (
    <div>
      <AudienceData />
      <InsightData />
      <InstagramPosts />
    </div>
  )
}


InstagramTab.propTypes = propTypes

export default InstagramTab
