import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Moment from "moment"
import LazyLoad from "../../Shared/LazyLoad.js"
import RoundBrn from "../Shared/Buttons/Round.js"
import SwitchBtn from "../Shared/Buttons/Switch.js"
import InfiniteScroller from "../../Shared/InfiniteScroller.js"
import styles from "./Index.scss"
import loadingImage from "../Shared/Icons/loading.gif"

const propTypes = {
  projects: PropTypes.array.isRequired,
}

const Index = (props) => {
  const newSponsorProjectPath = `/sponsor/projects/new`
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`

  const [apiBaseUrl, setApiBaseUrl] = useState(currentPageUrl)
  const [projects, setProjects] = useState({ all: props.projects, unreleased: [], ongoing: [], finished: [] })
  const [loadingImageStatus, setLoadingImageStatus] = useState(false)
  const [currentStatus, setCurrentStatus] = useState("all")

  const createApiEndpoint = (status) => {
    switch (status) {
      case "all":
        return currentPageUrl
      case "unreleased":
        return `${currentPageUrl}/unreleased`
      case "ongoing":
        return `${currentPageUrl}/ongoings`
      case "finished":
        return `${currentPageUrl}/finished`
      default:
        console.log("The specified type does not exist")
        return null
    }
  }

  const fetchProject = (status) => {
    setCurrentStatus(status)
    setApiBaseUrl( () => {
      const newApiEndpoint = createApiEndpoint(status)

      callAnalytics(String(newApiEndpoint).replace(currentPageOrigin, ""))
      fetch(newApiEndpoint, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
        },
      }).then((response) => response.json()).then((jsonData) => {
        setProjects((newProjects) => {
          newProjects[status] = jsonData.items

          return newProjects
        })
      }).catch((error) => {
        console.log(error)
      })
      return newApiEndpoint
    })
  }

  let projectList = [
    <tr key={0}>
      <th>タイトル</th>
      <th>ステータス</th>
      <th>期間</th>
      <th>未承認</th>
      <th>未発送</th>
      <th>期間超過</th>
      <th>未評価</th>
    </tr>
  ]

  projects[currentStatus].forEach((project, index) => {
    const entryCount = (status) => {
      return project.entries.filter((entry) => {
        return entry.status === 'pending' ? entry.status == status && entry["has_instagram_data?"] : entry.status === status
      }).length
    }

    const sponsorProjectPath = `/sponsor/projects/${project.id}`
    projectList.push(
      <tr key={index + 1}>
        <td>
          <div className={styles.title_container}>
            <div><LazyLoad src={String(project.image)} /></div>
            <div>
              <div className={styles.type_and_manage}>
                <span className={styles.project_type}>
                  {project.project_type?.name}
                </span>
                { project.published_at &&
                  <a
                    href={sponsorProjectPath}
                    className={styles.manage_btn}
                  >管理する</a>
                }
              </div>
              <div className={styles.project_title}>
                {project.title}
              </div>
            </div>
          </div>
        </td>
        <td className={styles.status_column}>
          <span>{{ "ongoing": "公開中", "finished": "終了済み", "unreleased": "非公開", "draft": "下書き" }[project.status] }</span>
          { project.status == "draft" && <><br /><a className={styles.edit_button} href={`/sponsor/projects/${project.id}/edit`}>編集する</a></> }
        </td>
        <td>
          {project.recruitment_start_at && project.recruitment_end_at ? `${Moment(project.recruitment_start_at).format("YYYY年MM月DD日")}〜${Moment(project.recruitment_end_at).format("YYYY年MM月DD日")}` : ""}
        </td>
        <td>
          {project.approve_all ? "-" : entryCount("pending")}
        </td>
        <td>
          {project["type_use?"] ? entryCount("approve") : "-"}
        </td>
        <td>
          {project.entries.filter((entry)=>{ return entry["report_limit_expired?"] }).length}
        </td>
        <td>
          {entryCount("reported")}
        </td>
      </tr>
    )
  })

  return (
    <div>
      <div className={styles.project_title_container}>
        <div><h1>プロジェクト管理</h1></div>
        <div className={styles.create_project_btn_container}>
          <a href={newSponsorProjectPath}>
            <RoundBrn
              text="プロジェクトを作成する"
              type="plus"
            />
          </a>
        </div>
      </div>

      <div className={styles.navigation_container}>
        <div>
          <SwitchBtn
            elements={[
              { text: "すべて", onClick: () => fetchProject("all"), selected: true },
              { text: "非公開", onClick: () => fetchProject("unreleased") },
              { text: "公開中", onClick: () => fetchProject("ongoing") },
              { text: "終了済み", onClick: () => fetchProject("finished") }
            ]}
          />
        </div>
      </div>

      {
        projects.length === 0 ? <p className={styles.zero_projects_message}>表示するプロジェクトがありません。</p> :
        <>
          <table>
            <tbody>
              {projectList}
            </tbody>
          </table>
          { loadingImageStatus && <p className={styles.loading_image_container}><img src={loadingImage} className={styles.loading_image} /></p>}
          <InfiniteScroller
            items={projects}
            setState={setProjects}
            baseUrl={apiBaseUrl}
            loadStart={ () => setLoadingImageStatus(true) }
            loadEnd={ () => setLoadingImageStatus(false) }
          />
        </>
      }
    </div>
  )
}

Index.propTypes = propTypes

export default Index
