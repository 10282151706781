import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./Index.scss"
import LazyLoad from "../../Shared/LazyLoad.js"
import { IntercomProvider, useIntercom } from 'react-use-intercom';

const INTERCOM_APP_ID = 'axv39iqc';

const propTypes = {
  influencer: PropTypes.object.isRequired,
}

const Content = (props) => {
  const influencer = props.influencer || {}
  const { boot, shutdown, hide, show, update } = useIntercom();

  useEffect(() => {
    boot({
      name: influencer.name,
      email: influencer.email,
      hideDefaultLauncher: true,
      languageOverride: 'ja'
    })
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
           <LazyLoad src={influencer.profile_pic} className={styles.icon}/>
        </div>
        <div className={styles.profile}>
          <p className={styles.name}>{influencer.instagram_user_name}</p>
          <a className={styles.profile_link} href="/influencer/accounts/edit">プロフィールの編集</a>
        </div>
      </div>
      <div className={styles.menu_container}>
        <div className={styles.menu_list}>
          <h2 className={styles.menu_list_title}>
            アカウント
          </h2>
          <a href="/influencer/email/edit" className={styles.menu_list_item}>
            メールアドレスの変更
          </a>
          <a  href="/influencer/password/edit" className={styles.menu_list_item}>
            パスワードの変更
          </a>
        </div>
        <div className={styles.menu_list}>
          <h2 className={styles.menu_list_title}>
            サポート
          </h2>
          <div className={styles.menu_list_item} onClick={() => { show() }}>
            お問い合わせ
          </div>
        </div>
      </div>

      <a className={styles.sign_out_button} href="/influencer/sign_out" data-method="delete" rel="nofollow">ログアウト</a>
    </div>
  )
}

const Index = (props) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Content influencer={props.influencer}/>
    </IntercomProvider>
  )
}

Index.propTypes = propTypes

export default Index
