import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Square.scss"

const propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
}

const defaultProps = {
  onClick: null,
}

const Square = (props) => {
  return (
    <div
      className={`${styles.container} ${props.className}`}
      onClick={props.onClick}
    >{props.text}</div>
  )
}

Square.propTypes = propTypes
Square.defaultProps = defaultProps

export default Square
