import PropTypes from "prop-types"

const propTypes = {
  status: PropTypes.string.isRequired,
  setEntries: PropTypes.func.isRequired,
  loadStart: PropTypes.func,
  loadEnd: PropTypes.func
}

const defaultProps = {
  loadStart: () => {},
  loadEnd: () => {}
}

const FetchEntry = async (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`
  let currentPageNum = 1
  let loadMore = true
  const createApiEndpoint = (status) => {
    switch (status) {
      case "all":
        return `${currentPageUrl}/entries`
      case "unreported":
        return `${currentPageUrl}/entries/unreported`
      case "reported":
        return `${currentPageUrl}/entries/reported`
      case "pendings":
        return `${currentPageUrl}/entries/pendings`
      case "approves":
        return `${currentPageUrl}/entries/approves`
      case "rejects":
        return `${currentPageUrl}/entries/rejects`
      case "unshipment":
        return `${currentPageUrl}/entries/unshipments`
      case "shipment":
        return `${currentPageUrl}/entries/shipments`
      default:
        console.log("The specified type does not exist")
        return null
    }
  }

  const setEntries = (_entries) => {
    props.setEntries((newEntries) => {
      newEntries[props.status] = _entries

      return newEntries
    })
  }

  const newApiEndpoint = createApiEndpoint(props.status)
  setEntries([])

  // このの関数が非同期で複数回呼ばれたときに、複数のステータスが混ざってしまうため、
  // この関数自体がentriesを持つ
  let entries = []

  const updateCurrentPage = async () => {
    currentPageNum = currentPageNum + 1
    return true
  }

  const updateLoadMore = async () => {
    loadMore = false
    return true
  }

  while(loadMore) {
    props.loadStart()
    let page = `${newApiEndpoint}?page=${currentPageNum}`
    console.log(page)
    callAnalytics(String(newApiEndpoint).replace(currentPageOrigin, ""))

    await fetch(page, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then(async (response) => {
      const jsonData = await response.json()
      return jsonData
    }).then(async (jsonData) => {
      const items = jsonData.items
      entries = entries.concat(items)
      setEntries(entries)
      props.loadEnd()
      if(items.length === 0) {
        const result = await updateLoadMore()
        return result
      } else {
        const result = await updateCurrentPage()
        return result
      }
    }).catch((error) => {
      console.log(error)
    })
  }
  return newApiEndpoint
}
FetchEntry.propTypes = propTypes
FetchEntry.defaultProps = defaultProps

export default FetchEntry
