import "core-js/stable"
import "regenerator-runtime/runtime"
import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types"
import RoundBtn from "../../../Shared/Buttons/Round.js"
import Select from "../../../FormTags/Select"
import TextField from "../../../FormTags/TextField"
import Modal from "../../../Shared/Modal"
import EntryTable from "./index/EntryTable"

import styles from "./Index.scss"

const propTypes = {
  projects: PropTypes.array.isRequired
}

const Index = (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`

  const [projects, setProjects] = useState(props.projects)
  const [shipmentCarriers, setShipmentCarriers] = useState([])
  const [shipmentCarrier, setShipmentCarrier] = useState(1)
  const [trackingCode, setTrackingCode] = useState("")
  const [checkedEntries, setCheckEntries] = useState({})
  const [modalStatus, setModalStatus] = useState(false)

  useEffect(() => {
    fetchShipmentCarriers()
  }, [])

  const selectShipmentCarriers = () => {
    const shipmentCarriersOptions = []
    shipmentCarriers.forEach(carrier => {
      var opt = { value: carrier.id, name: carrier.name }
      shipmentCarriersOptions.push(opt)
    })
    return shipmentCarriersOptions
  }

  const filteredEntries = () => {
    let result = []
    Object.keys(checkedEntries).map((key) => {
      if (checkedEntries[key] === true) {
        result.push(key)
      }
    })
    console.log(result)
    return result
  }

  const confirmEntriesShipment = () => {
    setModalStatus(true)
  }

  const postShipmentEntries = () => {
    fetch(currentPageUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: JSON.stringify({
        "entries": filteredEntries(),
        "shipment": {
          "shipment_carrier_id": shipmentCarrier,
          "tracking_code": trackingCode
        }
      }),
    }).then(() => {
      fetchEntry()
    }).catch((error) => {
      console.log(error)
    })
    setCheckEntries({})
    setModalStatus(false)
  }

  const fetchShipmentCarriers = () => {
    const apiEndPoint = `${currentPageOrigin}/sponsor/shipment_carriers`
    callAnalytics(String(apiEndPoint).replace(currentPageOrigin, ""))
    fetch(apiEndPoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      setShipmentCarriers(jsonData.items)
    }).catch((error) => {
      console.log(error)
    })
  }

  const fetchEntry = () => {
    callAnalytics(String(currentPageUrl).replace(currentPageOrigin, ""))
    fetch(currentPageUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      setProjects(jsonData.items)
    }).catch((error) => {
      console.log(error)
    })
  }


  const ProjectList = []

  projects.forEach((project) => {
    ProjectList.push(
      <>
        <h4>
          {project.project.title}
        </h4>
        <EntryTable
          entries={project.entries}
          checkedEntries={checkedEntries}
          setCheckEntries={setCheckEntries}
        />
      </>
    )
  })

  return (
    <div>
      <div className={styles.title_container}>
        <h1>未発送</h1>
        <div className={styles.button_container}>
          <RoundBtn
            text="発送連絡する"
            type="check"
            color="main"
            onClick={() => confirmEntriesShipment()}
          />
        </div>
      </div>
      <div>
        {ProjectList}
      </div>
      <Modal
        status={modalStatus}
        setState={setModalStatus}
      >
        <div className={`${styles.modal_btn_container}`}>
          <Select
            id="shipment_carrier"
            name="shipment_carrier"
            label="配送業者を選択"
            options={selectShipmentCarriers()}
            value={shipmentCarrier}
            setState={setShipmentCarrier}
          />
          <TextField
            id="tracking_code"
            name="tracking_code"
            type="text"
            label="追跡番号を入力"
            value={trackingCode}
            setState={setTrackingCode}
          />
          <div className={styles.modal_btn_area}>
            <RoundBtn
              text="発送連絡する"
              type="check"
              color="main"
              onClick={() => postShipmentEntries()}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

Index.propTypes = propTypes

export default Index
