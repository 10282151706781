import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Checkbox.scss"

const propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  required: PropTypes.bool,
}

const defaultProps = {
  id: "",
  name: "",
  className: "",
  value: false,
  onClick: null,
  onChange: null,
  setState: null,
  required: false,
}

const CheckboxBool = (props) => {
  // 初期値の表示は調整中(まだ正常に動作するかの検証ができてない)
  return (
    <div className={`${styles.checkbox_bool} ${props.className}`}>
      <label>
        <input
          type="checkbox"
          onClick={props.onClick}
          onChange={props.onChange}
          onInput={() => props.setState ? props.setState(!props.value) : null}
          defaultChecked={props.value}
        />
        <span />
        <div>{props.label}</div>
      </label>
      <input
        type="hidden"
        id={props.id}
        name={props.name}
        value={props.value}
        required={props.required}
      />
    </div>
  )
}

CheckboxBool.propTypes = propTypes
CheckboxBool.defaultProps = defaultProps

export default CheckboxBool
