import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./Header.scss"

const propTypes = {
  agencyName: PropTypes.string.isRequired,
}

const Header = (props) => {
  const [display, setDisplay] = useState(false)
  const sponsorRootPath = `/sponsor`
  const staffSiginoutPath = `/staff/sign_out`
  const contaienrActive = styles.items_container_active
  const itemActive = styles.items_active
  const backgroundActive = styles.background_active

  return (
    <header>
      <div className={styles.outer_container}>
        <div className={styles.container}>
          <div
            className={`${styles.items_container} ${display ? contaienrActive : ""}`}
            onClick={() => setDisplay(!display)}
          >
            {props.agencyName}
            <span></span>

            <div className={`${styles.items} ${display ? itemActive : ""}`}>
              { props.hasSponsor &&
                <a href={sponsorRootPath}>
                  <div>スポンサー画面に切り替え</div>
                </a>
              }
              <a href={staffSiginoutPath} data-method="delete">
                <div>ログアウト</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.background} ${display ? backgroundActive : ""}`}
        onClick={() => setDisplay(!display)}
      />
    </header>
  )
}

Header.propTypes = propTypes

export default Header
