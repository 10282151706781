import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Index.scss"
import RoundBtn from "../Shared/Buttons/Round.js"
import LazyLoad from "../../Shared/LazyLoad.js"
import TextField from "../FormTags/TextField.js"
import Submit from "../FormTags/Submit.js"
import RailsForm from "../../Shared/RailsForm.js"

const propTypes = {
  influencers: PropTypes.array.isRequired
}

const Index = (props) => {
  const influencerNewPath = `/agency/influencers/new`

  return (
    <div className={styles.container}>
      <h1>所属インフルエンサー管理</h1>
      { props.influencers.length === 0 &&
        <div className={styles.blank_container}>
          <h2>インフルエンサーを登録してください</h2>
          <a href={influencerNewPath}>
            <RoundBtn
              text="インフルエンサーを登録"
              type="check"
            />
          </a>
        </div>
      }

      { props.influencers.length > 0 &&
        <div>
          <div className={styles.registration_container}>
            <a href={influencerNewPath} className={styles.influencer_new_button}>
              <RoundBtn
                text="インフルエンサーを登録"
                type="check"
              />
            </a>
          </div>

          <table className={styles.influencer_table}>
            <tbody>
              <tr>
                <th>インフルエンサー</th>
                <th>報酬還元率</th>
                <th>マイページ</th>
                <th>編集</th>
              </tr>

              {
                props.influencers.map((influencer, i) => {
                  return (
                    <tr key={i}>
                      <td className={styles.influencer_name_column}>
                        <div className={styles.influencer_name}>
                          <div className={styles.influencer_icon}>
                            <LazyLoad src={influencer.profile_pic}/>
                          </div>
                          <p>
                            {influencer.instagram_user_name || influencer.email}
                          </p>
                        </div>
                      </td>
                      <td>
                        {influencer.current_reward_distribution_rate}%
                      </td>
                      <td>
                        { influencer.invited_by_id && !influencer.invitation_accepted_at &&
                          <RailsForm
                            action={"/agency/invitation_notifications"}
                            method="post"
                          >
                            <input type='hidden' name='influencer_id' value={influencer.id} />
                            <input type='submit' className={styles.url_notification_button} value="✓ URL通知" />
                          </RailsForm>
                        }
                      </td>
                      <td>
                        <a href={`/agency/influencers/${influencer.id}/edit`}>編集する</a>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}

Index.propTypes = propTypes
export default Index
