import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Header.scss"
import HeaderLogo from "../../Shared/HeaderLogo.js"

const propTypes = {
  root_path: PropTypes.string.isRequired,
  previous_path: PropTypes.string,
  header_title: PropTypes.string
}

const defaultProps = {
  previous_path: "",
  header_title: null
}

const Header = (props) => {
  return (
    <header className={styles.container}>
      { props.previous_path &&
        <a href={props.previous_path} className={styles.back}>
          <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.10237 8.03362L8.96537 0.192364C9.22299 -0.0643949 9.64008 -0.0639633 9.89727 0.193692C10.1543 0.451314 10.1536 0.868642 9.89595 1.1256L2.50098 8.50015L9.89621 15.8747C10.1538 16.1317 10.1545 16.5487 9.89754 16.8064C9.76861 16.9355 9.59971 17.0001 9.4308 17.0001C9.26233 17.0001 9.09409 16.936 8.9654 16.8077L1.10237 8.96665C0.978287 8.8432 0.90866 8.6752 0.90866 8.50015C0.90866 8.3251 0.978486 8.1573 1.10237 8.03362Z" fill="black"/>
          </svg>
        </a>
      }
      
      {
        !props.header_title &&
        <a href={props.root_path}>
          <HeaderLogo />
        </a>
      }
      {
        props.header_title &&
        <span className={styles.header_title}>{props.header_title}</span>
      }

    </header>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
