import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Select from "../../../FormTags/Select.js"
import Radio from "../../../FormTags/Radio.js"
import Checkbox from "../../../FormTags/Checkbox.js"
import styles from "./Step01.scss"
import Categories from "../../../FormTags/Categories.js"
import Modal from "../../../Shared/Modal.js"
import RoundBtn from "../../../Shared/Buttons/Round.js"

const propTypes = {
  pageBackBtn: PropTypes.object.isRequired,
  projectTypes: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  socialNetworks: PropTypes.array.isRequired,
  projectTypeId: PropTypes.number,
  setProjectTypeId: PropTypes.func.isRequired,
  setIsInstagram: PropTypes.func.isRequired,
  setIsTwitter: PropTypes.func.isRequired,
  setIsYoutube: PropTypes.func.isRequired,
  nextBtn: PropTypes.object.isRequired,
}

const Step01 = (props) => {
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [projectTypeId, setProjectTypeId] = useState(props.projectTypeId);
  const address = props.project && props.project.project_type_visit && props.project.project_type_visit? props.project.project_type_visit.address : false
  const product_operation_systems = props.project && props.project.product && props.project.product.product_operation_systems || []

  const checkValues = (
    isExist(props.projectTypeId) &&
    isExist(props.categoryIds[0]) &&
    isExist(props.approveAll) &&
    isGreaterThanToZero(props.socialNetworkIds.length)
  )

  let projectTypeList = []
  props.projectTypes.forEach((projectType, index) => {
    projectTypeList.push(
      <label key={index} data-seq={index + 1}
            className={`${styles.project_type_item} ${props.projectTypeId == projectType.id ? styles.active : ""}`}>
        <input
          type="radio"
          name="project[project_type_id]"
          value={projectType.id}
          onChange={(e) => {
            if(isExist(address.city) || !!product_operation_systems.length){
              setProjectTypeId(Number(e.target.value))
              setDeleteModalStatus(true)
            }else{
              props.setProjectTypeId(Number(e.target.value))
              props.onChange(e)
            }
          }}
          required
        />
        {projectType.name}
        <p>{projectType.description}</p>
      </label>
    )
  })

  let approveTypes = [
    {
      label: "応募者全員を承認",
      value: true,
    },
    {
      label: "応募者から選定して承認",
      value: false,
    }
  ]

  const changeSnsArray = (value) => {
    let array = props.socialNetworkIds
    props.socialNetworkIds.includes(value) ? array.pop(value) : array.push(value)
    props.setSocialNetworkIds(array)
  }

  const snsFuncList = [props.setIsInstagram, props.setIsTwitter, props.setIsYoutube]
  let socialNetworkList = []
  props.socialNetworks.forEach((socialNetwork, index) => {
    socialNetworkList.push(
      {
        label: socialNetwork.name,
        id: `project_social_network_social_network_id_${socialNetwork.name}`,
        value: socialNetwork.id,
        onChange: (e) => {
          props.onChange(e)
          snsFuncList[index](e.target.checked)
        },
        onClick: (e) => changeSnsArray(e.target.value)
      }
    )
  })

  return (
    <div>
      <div className={styles.header}>
        {props.pageBackBtn}
        {props.saveBtn}
      </div>
      <h2>1/6 プロジェクトタイプを選択</h2>

      <label>プロジェクトの種類</label>
      <Modal
        status={deleteModalStatus}
        setState={setDeleteModalStatus}
      >
        <div>
          <div>
            プロジェクトの種類を変更することで、下記の入力情報がリセットされます。<br />
            よろしければ「変更」を選択してください。<br /><br />
            ・住所<br />
            ・対応OS<br />
          </div>
          <div className={styles.modal_btn_area}>
            <RoundBtn
              text="戻る"
              type="check"
              color="gray-border"
              onClick={() => {
                setDeleteModalStatus(false)
                setProjectTypeId(props.projectTypeId)
                // 2回連続で同じプロジェクトタイプが反応しない
              }}
            />
            <RoundBtn
              text="変更"
              type="check"
              color="main-border"
              onClick={(e) => {
                setDeleteModalStatus(false)
                props.setProjectTypeId(projectTypeId)
                props.onChange(e)
              }}
            />
          </div>
        </div>
      </Modal>

      <div className={styles.project_type_container}>
        {projectTypeList}
      </div>

      <Categories
        categories={props.categories}
        categoryIds={props.categoryIds}
        setCategoryIds={props.setCategoryIds}
        onChange={props.onChange}
        parentCategoryId={props.parentCategoryId}
      />

      <Radio
        label="プロジェクトに応募したインフルエンサーの承認方法"
        id="project_project_type_id"
        name="project[approve_all]"
        elements={approveTypes}
        value={props.approveAll}
        setState={props.setApproveAll}
        onChange={props.onChange}
        required={true}
      />
      <p className={styles.approve_description}>
        「応募者全員を承認」を選択された場合、応募者全員を自動で承認します。 <br />
        「応募者から選定して承認」を選択された場合、応募者の一部を選定の上、手動で承認していただきます。
      </p>

      <Checkbox
        label="募集するインフルエンサーのSNS"
        name="project_social_networks[][social_network_id]"
        elements={socialNetworkList}
        direction="column"
        onChange={props.onChange}
        value={props.socialNetworkIds}
      />

      {checkValues && (
        props.nextBtn
      )}
    </div>
  )
}

Step01.propTypes = propTypes

export default Step01
