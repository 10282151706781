import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import MulchStepForm from "./Shared/MulchStepForm.js"

const propTypes = {
  projectTypes: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  socialNetworks: PropTypes.array.isRequired,
  operationSystems: PropTypes.array.isRequired,
  projectAdTypes: PropTypes.array.isRequired,
  sociaNetworkPostTypes: PropTypes.array.isRequired,
  prefectures: PropTypes.array.isRequired,
  typeUseId: PropTypes.number.isRequired,
  typeVisitId: PropTypes.number.isRequired,
  typeBuyId: PropTypes.number.isRequired,
  typeAppUseId: PropTypes.number.isRequired,
}

const New = (props) => {
  return (
    <div>
      <MulchStepForm
        projectTypes={props.projectTypes}
        categories={props.categories}
        socialNetworks={props.socialNetworks}
        operationSystems={props.operationSystems}
        projectAdTypes={props.projectAdTypes}
        sociaNetworkPostTypes={props.sociaNetworkPostTypes}
        prefectures={props.prefectures}
        typeUseId={props.typeUseId}
        typeVisitId={props.typeVisitId}
        typeBuyId={props.typeBuyId}
        typeAppUseId={props.typeAppUseId}
        steps={[1, 2, 3, 4, 5, 6]}
        formPath={`/sponsor/projects`}
        formMethod={`post`}
      />
    </div>
  )
}

New.propTypes = propTypes

export default New
