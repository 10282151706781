import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Modal from "./Modal.js"
import Tab from "./Tab.js"
import LazyLoad from "../../Shared/LazyLoad.js"
import styles from "./InfluencerDetailModal.scss"
import InstagramIcon from "./InfluencerDetailModal/instagram_icon.png"
import InstagramTab from "./InfluencerDetailModal/InstagramTab.js"
import loadingImage from "./Icons/loading.gif"

const propTypes = {
  influencerId: PropTypes.number,
  influencerDetailModalStatus: PropTypes.bool.isRequired,
  setInfluencerDetailModalStatus: PropTypes.func.isRequired,
}

const InfluencerDetail = (props) => {
  const [influencer, setInfluencer] = useState();

  const requestInfluencer = (id) => {
    if(id && props.influencerDetailModalStatus) {
      setInfluencer(null)
      fetch(`/sponsor/influencers/${id}`, {
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
        },
      }).then((response) => response.json()).then((jsonData) => {
        setInfluencer(jsonData.influencer)
        console.log(jsonData.influencer)
      }).catch(
        (error) => console.log(error)
      )
    }
  }

  useEffect(()=>{
    setInfluencer(null)
    requestInfluencer(props.influencerId)
  }, [props.influencerDetailModalStatus, props.influencerId])

  return (
    <Modal
      status={props.influencerDetailModalStatus}
      setState={props.setInfluencerDetailModalStatus}
      position="topRight"
      className={styles.influencer_detail_modal}
    >
      { influencer &&
        <>
          <div className={styles.profile_container}>
            <div className={styles.profile_image_container}>
              <div className={styles.influencer_icon}>
                <LazyLoad src={String(influencer.profile_pic)}/>
              </div>
            </div>
            <div className={styles.profile_detail_container}>
              <div className={styles.profile_detail}>
                <div>
                  <p className={styles.influencer_name}>
                    No. {influencer.id} ({influencer.age})
                    {
                      influencer.social_network_accounts.map((v) => v.social_network_name ).includes("Instagram") ?
                        <img src={InstagramIcon} className={styles.sns_icon} /> :
                        <></>
                    }
                  </p>
                  <p><span className={styles.influencer_prefecture_label}>都道府県:</span> {influencer.prefecture_name}</p>
                  <p><span className={styles.influencer_prefecture_label}>希望単価:</span> {influencer.desired_incentive_per_post}円〜</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.audience_data}>
            <p className={styles.audience_data_title}>オーディエンスデータ</p>

            { (influencer.instagram_accounts[0] || influencer.manual_input_instagram_account) &&
              <Tab
                panels={
                   [{
                    title: <div className={styles.sns_tab_title}>
                      <a href={`https://www.instagram.com/${influencer.instagram_user_name}/`} target="_blank">
                        <img src={InstagramIcon} className={styles.sns_icon} />
                      </a>
                      <p>Instagram</p>
                    </div>,
                    content: (
                      <InstagramTab influencer={influencer}/>
                    )
                  }]
                }
                leftSide={true}
              />
            }
          </div>
        </>
      }
      { !influencer && <div className={styles.loading_image_container} ><img src={loadingImage} className={styles.loading_image} /></div>}
    </Modal>
  )
}

InfluencerDetail.propTypes = propTypes

export default InfluencerDetail
