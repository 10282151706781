import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import RoundBtn from "../Shared/Buttons/Round.js"
import InfiniteScroller from "../../Shared/InfiniteScroller.js"
import CheckboxBool from "../FormTags/CheckboxBool"
import Select from "../FormTags/RichSelect"
import InfluencerDetailModal from "./../Shared/InfluencerDetailModal.js"
import ScoutModal from "./Index/ScoutModal.js"
import ReadMore from "./Index/ReadMore.js"
import styles from "./Index.scss"
import InstagramIcon from "../Shared/Icons/monochrome_instagram_icon.png"
import TwitterIcon from "../Shared/Icons/monochrome_twitter_icon.png"
import LazyLoad from "../../Shared/LazyLoad.js"
import InstagramImages from "./Index/InstagramImages.js"
import SearchBox from "./Index/SearchBox";
import SearchPanel from "./Index/SearchPanel";
import loadingImage from "../Shared/Icons/loading.gif"

const propTypes = {
  categories: PropTypes.array.isRequired,
  influencers: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  current_sponsor_id: PropTypes.number.isRequired
}

// あとでページリセットの処理を追加する必要がある
const Index = (props) => {
  const [influencerId, setInfluencerId] = useState(null)
  const [influencers, setInfluencers] = useState(props.influencers)
  const [searchModalStatus, setSearchModalStatus] = useState(false)
  const [scoutModalStatus, setScoutModalStatus] = useState(false)
  const [influencerDetailModalStatus, setInfluencerDetailModalStatus] = useState(false)
  const [selectedInfluencerIds, setSelectedInfluencerIds] = useState([])
  const [baseUrl, setBaseUrl] = useState(location.href)
  const [sort, setSort] = useState()
  const [searchFormData, setSearchFormData] = useState({})
  const [showSearch, setShowSearch] = useState(false)
  const [scoutButtonStatus, setScoutButtonStatus] = useState(true)
  const [loadingImageStatus, setLoadingImageStatus] = useState(false)
  const [showZeroInfluencersMessage, setShowZeroInfluencersMessage] = useState(false)

  useEffect(() => {
    if(sort) {
      setSearchFormData(state => ({ ...state, "search[sorts]": sort }));
      searchInfluencer(sort)
    }
  }, [sort])

  const [pageOffset, setPageOffset] = useState(0);

  useEffect(() => {
    if (showSearch) {
      return;
    }

    window.scrollTo(0, pageOffset)

  }, [showSearch])

  const updateSelectedInfluencerIds = (e, influencerId) => {
    if(e.target.checked) {
      setSelectedInfluencerIds(selectedInfluencerIds.concat(influencerId))
    } else {
      const newSelectedInfluencerIds = selectedInfluencerIds.filter(id => id !== influencerId);
      setSelectedInfluencerIds(newSelectedInfluencerIds)
    }
  }

  const influencerCategories = (influencerCategories) => {
    let categories = []
    influencerCategories.forEach((category, index) => {
      if(category.parent && category.parent.mainly_displayed){
        if(categories[category.parent.id]){
          categories[category.parent.id].push(category.name)
        }else{
          categories[category.parent.id] = [category.name]
          categories[category.parent.id].parent_name = category.parent.name
        }
      }else if(category.mainly_displayed){
        if(!categories[category.id]){
          categories[category.id] = []
          categories[category.id].parent_name = category.name
        }
      }
    })
    categories = categories.filter(v => v)
    let categoryList = []
    categories.forEach((cate, index) =>{
      categoryList.push(<div key={index}>
        <div className={styles.influencer_category_parent}>{cate.parent_name}</div>
        <div className={styles.influencer_category}>{cate.join("・")}</div>
      </div>)
    })
    return categoryList
  }

  const searchInfluencer = (sort) => {
    const buildParams = (data) => {
      const params = new URLSearchParams();

      Object.entries(data).map((value, key) => {
        if(value[1]) {
          if (Array.isArray(value[1])) {
            value[1].map((item) => {
              params.append(value[0], item)
            })
          } else {
            params.append(value[0], value[1]);
          }
        }
      });

      return params;
    };

    // useEffectで設定している値は非同期なのでここではまだ反映されていないため
    if(sort) {
      searchFormData["search[sorts]"] = sort
    }

    const url = `${window.location.origin}/sponsor/search?${buildParams(searchFormData)}`

    url.toString()
    setBaseUrl(url)
    setInfluencers([])
    setShowSearch(false)
    setScoutButtonStatus(true)
    console.log(url);
    setShowZeroInfluencersMessage(false)
    setLoadingImageStatus(true)

    fetch(url, {
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      }
    }).then((response) => response.json()).then((jsonData) => {
      setLoadingImageStatus(false)
      setInfluencers(jsonData.items)
      if(jsonData.items.length === 0) {
        setShowZeroInfluencersMessage(true)
      }
    }).catch(
      (error) => console.log(error)
    )
  }

  const onSearchClick = () => {
    if(!showSearch) {
      setPageOffset(window.pageYOffset);
    }
    setShowSearch(state => !state)
    setScoutButtonStatus(state => !state)
  }

  let influencerList = []
  influencers.forEach((influencer, index) => {
    influencerList.push(
      <tr key={index}>
        <td>
          <div className={styles.influencer_name_column}>
            <CheckboxBool
              id={influencer.id}
              name="entries"
              className={styles.influencer_checkbox}
              checked={selectedInfluencerIds.includes(influencer.id)}
              onChange={(e)=>{ updateSelectedInfluencerIds(e, influencer.id) }}
            />
            <div
              className={styles.influencer_name}
            >
              <div className={styles.influencer_icon}>
                <LazyLoad src={String(influencer.profile_pic)}/>
              </div>
              <a
                className={styles.influencer_name_link}
                onClick={(e) => (
                  setInfluencerId(influencer.id),
                  setInfluencerDetailModalStatus(true)
                )}
              >
                No. {influencer.id}
              </a>
              <img src={InstagramIcon} className={styles.sns_icon} />
            </div>
          </div>
        </td>
        <td className={styles.category_column}>{influencerCategories(influencer.categories)}</td>
        <td className={styles.count_column}>
          {influencer.followed_by_count ? influencer.followed_by_count.toLocaleString() : "-"}
        </td>
        <td className={styles.count_column}>
          {influencer.average_engagement_rate ?
            <p>{influencer.average_engagement_rate}<span className={styles.count_column_unit}>%</span></p> :
            "-"
          }
        </td>
        <td key="9999"><InstagramImages src={influencer}/></td>
      </tr>
    )
  })

  return (
    <div className={styles.container}>
      <div className={styles.title_container}>
        <h1>スカウト</h1> {
          scoutButtonStatus &&
          <div className={styles.button_container}>
            {selectedInfluencerIds.length === 0 && 
            <RoundBtn
              text="インフルエンサーを選択してください"
              type="check"
              color="gray-fill"
              onClick={() => setScoutModalStatus(false)}
            />}
            {selectedInfluencerIds.length !== 0 && 
            <RoundBtn
              text="スカウトする"
              type="check"
              color="main"
              onClick={() => setScoutModalStatus(true)}
            />}
          </div>
        }
      </div>
      <div>
        <SearchBox onSearchClick={onSearchClick} sticky={!showSearch} />
        <div className={showSearch ? '' : styles.hidden}>
          <SearchPanel
            categories={props.categories}
            projects={props.projects}
            setSearchFormData={setSearchFormData}
            searchInfluencer={searchInfluencer}
            current_sponsor_id={props.current_sponsor_id}
          />
        </div>
      </div>

      {!showSearch &&
      <div>
        <div className={styles.sort_container}>
          <Select
            label=""
            id="search[sorts]"
            name="search[sorts]"
            options={[
              { value: "followed_by_count desc", name: "フォロワー数が多い順" },
              { value: "average_engagement_rate desc", name: "エンゲージメント率が高い順" }
            ]}
            value={sort}
            setState={setSort}
            required={true}
            className={styles.sort}
          />
        </div>
        {
          showZeroInfluencersMessage ?
            <p className={styles.zero_influencers_message}>条件にあうインフルエンサーが見つかりません。<br />条件を変更して再度検索してください。</p> :
            <table className={styles.influencer_table}>
              <tbody>
              <tr>
                <th>インフルエンサー</th>
                <th>カテゴリー</th>
                <th>フォロワー</th>
                <th>eng率</th>
                <th>投稿</th>
              </tr>
              {influencerList}
              </tbody>
            </table>
        }
      </div>
      }

      { loadingImageStatus && <p className={styles.loading_image_container}><img src={loadingImage} className={styles.loading_image} /></p>}
      <InfluencerDetailModal
        influencerId={influencerId}
        influencerDetailModalStatus={influencerDetailModalStatus}
        setInfluencerDetailModalStatus={setInfluencerDetailModalStatus}
      />
      <ScoutModal
        selectedInfluencerIds={selectedInfluencerIds}
        setSelectedInfluencerIds={setSelectedInfluencerIds}
        scoutModalStatus={scoutModalStatus}
        setScoutModalStatus={setScoutModalStatus}
      />
      {influencers.length > 0 && !showSearch &&
        <InfiniteScroller
          items={influencers}
          setState={setInfluencers}
          baseUrl={baseUrl}
          threshold={8000}
          loadStart={ () =>  setLoadingImageStatus(true) }
          loadEnd={ () => setLoadingImageStatus(false) }
        />
      }
    </div>
  )
}

Index.propTypes = propTypes
export default Index
