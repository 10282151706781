import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Date.scss"

const propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  required: PropTypes.bool,
}

const defaultProps = {
  className: "",
  value: "",
  min: "",
  max: "",
  onClick: null,
  onChange: null,
  setState: null,
  required: false,
}

const Date = (props) => {
  return (
    <div className={props.className}>
      <label>{props.label}</label>
      <input
        className={styles.date}
        type="date"
        id={props.id}
        name={props.name}
        value={
          props.value !== "" ?
            props.value.substr(0, 10) : props.value
        }
        min={props.min}
        max={props.max}
        onClick={props.onClick}
        onChange={props.onChange}
        onInput={(e) => props.setState ? props.setState(e.target.value) : null}
        required={props.required}
      />
    </div>
  )
}

Date.propTypes = propTypes
Date.defaultProps = defaultProps

export default Date