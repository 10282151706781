import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Project from "../Shared/Project.js"
import LazyLoad from "../../Shared/LazyLoad.js"
import InfiniteScroller from "../../Shared/InfiniteScroller.js"
import styles from "./Show.scss"

const propTypes = {
  sponsor: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
}

const Show = (props) => {
  const [projects, setProjects] = useState(props.projects)

  let projectList = []
  projects.forEach((project, index) => {
    projectList.push(
      <div key={index} className={styles.project_item}>
        <Project project={project} />
      </div>
    )
  })

  return (
    <div className={styles.container}>
      <h1>会社情報</h1>
      <div className={styles.eyecatch_container}>
        <LazyLoad src={String(props.sponsor.header_image)} />
        <div className={styles.sponsor_name_container}>
          <div><LazyLoad src={String(props.sponsor.image)} /></div>
          <div>{props.sponsor.name}</div>
        </div>
        <a
          href={props.sponsor.homepage_address}
          className={styles.site_link}
          target="_blank"
          rel="noopener"
        >サイトを見る ＞</a>
      </div>

      <h2>私たちについて</h2>
      <p>{props.sponsor.introduction}</p>

      <div className={styles.project_container}>
        <h2>募集中のプロジェクト</h2>
        {projectList}
      </div>

      <InfiniteScroller
        items={projects}
        setState={setProjects}
      />
    </div>
  )
}

Show.propTypes = propTypes

export default Show
