import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./Submit.scss"

const propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  position: PropTypes.string,
  onClick: PropTypes.func,
  confirm: PropTypes.bool,
}

const defaultProps = {
  className: "",
  position: "right",
  onClick: null,
  confirm: false,
}

const Submit = (props) => {
  let status = true

  const position = (
    props.position === "right" ? styles.right :
      props.position === "left" ? styles.let : ""
  )

  const handleSubmit = (e) => {
    // 多重送信防止
    if (!status) { return e.preventDefault() }

    status = false
    setTimeout(() => { status = true }, 3000)

    // 確認オプションが有効な場合は確認する
    if (!props.confirm) { return }

    if (window.confirm('本当に実行しますか？')) {
      return true
    }
    else {
      window.alert('キャンセルしました')
      e.preventDefault()
      return false
    }
  }

  return (
    <div className={props.className}>
      <div className={`${styles.submit} ${position}`}>
        <span onClick={props.onClick}>
          {props.text}
          <button
            type="submit"
            onClick={(e) => handleSubmit(e)}
          />
        </span>
      </div>
    </div>
  )
}

Submit.propTypes = propTypes
Submit.defaultProps = defaultProps

export default Submit