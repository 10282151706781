import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import LazyLoad from "../../../../../Shared/LazyLoad.js"
import CheckboxBool from "../../../../FormTags/CheckboxBool"

import styles from "./../Index.scss"

const propTypes = {
  entries: PropTypes.array.isRequired,
  checkedEntries: PropTypes.object.isRequired,
  setCheckEntries: PropTypes.func.isRequired,
}

const EntryTable = (props) => {
  const selectEntry = (e) => {
    props.setCheckEntries({
      ...props.checkedEntries,
      [e.target.id]: e.target.checked
    })
  }

  const entryList = [
    <tr key="head">
      <th>インフルエンサー</th>
      <th>発送商品</th>
      <th>発送先住所</th>
    </tr>
  ]

  props.entries.forEach((entry) => {
    entryList.push(
      <tr key={entry.id} className="unshipped">
        <td>
          <div className={styles.influencer_name_column}>
            <div className={styles.influencer_checkbox}>
              <CheckboxBool
                id={entry.id}
                name="entries"
                className={styles.influencer_checkbox}
                value={false}
                onChange={selectEntry}
              />
            </div>

            <div className={styles.influencer_name}>
              <div className={styles.influencer_icon}>
                <LazyLoad src={String(entry.influencer.profile_pic)}/>
              </div>
              <p>
                {entry.influencer.name}
              </p>
            </div>
          </div>
        </td>
        <td>
          <div>
            {entry.project.product.name}
            {entry.product_option_variations.length ? <br /> : <></>}
            {entry.product_option_variations.map((v)=> `${v.option_name}: ${v.name}`).join("、 ")}
          </div>
        </td>
        <td>
          { entry.delivery_information &&
          <>
            <div className={styles.entry_address_postal_code}>
              〒{entry.delivery_information.address.postal_code}
            </div>
            <div>
              <span>{entry.delivery_information.address.prefecture.name}</span>
              <span>{entry.delivery_information.address.city}</span>
              <span>{entry.delivery_information.address.block}</span>
              <span>{entry.delivery_information.address.building}</span>
            </div>
            <div>
              <span>{entry.delivery_information.first_name} {entry.delivery_information.last_name}</span>
            </div>
            <div className={styles.entry_tel}>
              {entry.delivery_information.tel}
            </div>
          </>
          }
          { !entry.delivery_information &&
          <p>発送先情報がありません。メッセージで確認してください</p>
          }
        </td>
      </tr>
    )
  })

  return (
    <table className={styles.entry_table}>
      <tbody>
      {entryList}
      </tbody>
    </table>
  )
}

EntryTable.propTypes = propTypes

export default EntryTable
