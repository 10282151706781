import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RoundBtn from "../../Shared/Buttons/Round.js"
import Modal from "../../Shared/Modal.js"
import TextField from "../../FormTags/TextField.js"
import Submit from "../../FormTags/Submit.js"
import RailsForm from "../../../Shared/RailsForm.js"
import styles from "./Member.scss"

const propTypes = {
  sponsor: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
}

const Member = (props) => {
  const [createModalStatus, setCreateModalStatus] = useState(false)
  const [email, setEmail] = useState()
  const sponsorSponsorStaffPath = `/sponsor/sponsor_staff`
  const editSponsorAccountPath = `/sponsor/account/edit`

  const formElements = (
    <>
      <TextField
        label="メールアドレス"
        id="sponsor_staff_email"
        name="sponsor_staff[email]"
        value={email}
        setState={setEmail}
        required={true}
      />
      <Submit text="招待する" />
    </>
  )

  const createModalContent = (
    <>
      <h2>{props.sponsor.name}にメンバーを招待する</h2>
      <RailsForm
        action={sponsorSponsorStaffPath}
        elements={formElements}
        remote={true}
      />
    </>
  )

  let memberList = []
  props.members.forEach((member, index) => {
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const deleteFormElement = (
      <>
        <h2>{props.sponsor.name}からメンバーを削除する</h2>
        <p>{member.name}({member.email})を本当に削除しますか？</p>
        <input
          type="hidden"
          id={`sponsor_staff_staff_id_${index}`}
          name="sponsor_staff[staff_id]"
          value={member.id}
          required
        />
        <Submit text="削除する" />
      </>
    )
    const deleteModalContent = (
      <RailsForm
        action={sponsorSponsorStaffPath}
        elements={deleteFormElement}
        delete={true}
        remote={true}
      />
    )
    memberList.push(
      <tr key={index}>
        <td>{member.name}</td>
        <td>{member.email}</td>
        <td className={styles.delete_container}>
          {index === 0 && (
            <a href={editSponsorAccountPath}>
              <RoundBtn
                text="アカウント設定"
                type="check"
                color="gray-border"
              />
            </a>
          )}
          {index !== 0 && (
            <>
              <RoundBtn
                text="削除"
                type="check"
                color="gray-border"
                onClick={() => setDeleteModalStatus(true)}
              />
              <Modal
                status={deleteModalStatus}
                content={deleteModalContent}
                setState={setDeleteModalStatus}
              />
            </>
          )}
        </td>
      </tr>
    )
  })

  return (
    <div>
      <div className={styles.title_container}>
        <h2>{props.sponsor.name}のメンバー</h2>
        <RoundBtn
          text="メンバーを招待"
          type="plus"
          color="main"
          onClick={() => setCreateModalStatus(true)}
        />
        <Modal
          status={createModalStatus}
          content={createModalContent}
          setState={setCreateModalStatus}
        />
      </div>

      <table>
        <tbody>
          <tr>
            <th>名前</th>
            <th>メールアドレス</th>
            <th></th>
          </tr>
          {memberList}
        </tbody>
      </table>
    </div>
  )
}

Member.propTypes = propTypes

export default Member
