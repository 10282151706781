import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Select from "../Select.js"
import LshapedImg from "./Occupation/l_shaped.svg"
import styles from "./Occupation.scss"

const propTypes = {
  occupations: PropTypes.array.isRequired,
  setRequired: PropTypes.any
}

const defaultProps = {
  setRequired: () => {}
}

const SelectOccupation = (props) => {
  const [childOptions, setChildOptions] = useState([])
  const [parentValue, setParentValue] = useState()
  const [childValue, setChildValue] = useState()

  const setDefaultSelectValue = () => {
    if(parentValue) return true

    props.occupations.forEach((occupation, index) => {
      if(Number(occupation.id) === Number(props.value)) {
        setParentValue(occupation.id)
      }

      occupation.children.forEach((childOccupation, index) => {
        if(Number(childOccupation.id) === Number(props.value)) {
          setParentValue(occupation.id)
          setChildOptions(occupation.children.map((occupation, index) => { return { value: occupation.id, name: occupation.name } }))
          setChildValue(childOccupation.id)
        }
      })
    })
  }

  const parentOptions =
    props.occupations.map((occupation, index) => {
      return { value: occupation.id, name: occupation.name }
    })
  // props.occupations.forEach((occupation, index) => {
  //   if(occupation.mainly_displayed) {
  //     let suboccupationList = []
  //     occupation.children.forEach ((suboccupation, subIndex) => {
  //       suboccupationList.push(
  //       )
  //     })
  //   }
  // })
  const setParentOccupation = (value) => {
    const occupation =
      props.occupations.find((o) => o.id === Number(value))

    setParentValue(value)

    if(!occupation) {
      setChildOptions([])
      props.setState(null)
      props.setRequired(false)
    } else if(occupation.children.length) {
      setChildOptions(occupation.children.map((occupation, index) => { return { value: occupation.id, name: occupation.name } }))
      props.setState(null)
      props.setRequired(true)
    } else {
      setChildOptions([])
      props.setState(value)
      props.setRequired(false)
    }
  }

  const setChildOccupation = (value) => {
    const occupation =
      childOptions.find((o) => o.value === Number(value))

    if(occupation){
      setChildValue(value)
      props.setState(value)
    } else {
      setChildValue(null)
      props.setState(null)
    }
  }

  useEffect(() => {
    setDefaultSelectValue()
  })

  return (
    <>
      <Select
        label={props.label}
        id={props.id}
        name={"_parent_occupation"}
        options={parentOptions}
        value={parentValue}
        className={props.className}
        setState={setParentOccupation}
        required={false}
        prompt={"職業を選択してください"}
      />
      { childOptions.length > 0 &&
        <div className={styles.child_container}>
          <LshapedImg />
          <Select
            label={""}
            id={`${props.id}_children`}
            name={"_child_occupation"}
            options={childOptions}
            value={childValue}
            className={props.className}
            setState={setChildOccupation}
            prompt={"職業を選択してください"}
            required={true}
          />
        </div>
      }
      <input type="hidden" name={props.name} value={props.value || ""} />
    </>
  )
}

SelectOccupation.propTypes = propTypes

SelectOccupation.defaultProps = defaultProps
export default SelectOccupation
