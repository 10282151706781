import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./Layout.scss"
import loadingImage from "../../../Shared/Icons/loading.gif"

const propTypes = {
  topLeftButtons: PropTypes.any.isRequired,
  topRightButtons: PropTypes.array.isRequired,
  table: PropTypes.array.isRequired,
}

const Layout = (props) => {
  let topRightButtonElements = []
  props.topRightButtons.forEach((topRightButton, index) => {
    topRightButtonElements.push(
      <div key={index} className={styles.menu_btn_item}>
        {topRightButton}
      </div>
    )
  })

  return (
    <div>
      <div className={styles.menu_container}>
        <div className={styles.status_container}>
          {
            props.topLeftButtons.map((topLeftButton, index) => {
              return (
                <div key={index} className={styles.status_item}>
                  {topLeftButton}
                </div>
              )
            })
          }
        </div>

        <div>

        </div>

        <div className={styles.menu_btn_container}>
          {topRightButtonElements}
        </div>
      </div>
      {
        props.table.length === 0 && props.loadingImageStatus === false ?
        <p className={styles.zero_influencers_message}>該当するインフルエンサーがいません。</p> :
        <table>
          <tbody>
            {props.table}
          </tbody>
        </table>
      }
      { props.loadingImageStatus && <p className={styles.loading_image_container}><img src={loadingImage} className={styles.loading_image} /></p>}
    </div>
  )
}

Layout.propTypes = propTypes

export default Layout
