import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Project from "../Shared/Project.js"
import styles from "./Show.scss"

const propTypes = {
  project: PropTypes.object.isRequired,
}

const Show = (props) => {
  return (
    <div>
      <h1>投稿報告が完了しました！</h1>
      <p>ご協力ありがとうございました。</p>

      <hr className={styles.hr} />

      <Project className={styles.project} project={props.project} />

      <a className={styles.topPageLink} href="/influencer">トップページへ</a>
    </div>
  )
}

Show.propTypes = propTypes

export default Show
