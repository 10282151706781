import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import Select from "../Select.js"

const SelectMonth = (props) => {
  let months = []
  for (let index = 1; index < 12; index++) {
    months.push({
      value: index,
      name: index
    })
  }

  return (
    <Select
      label={props.label}
      id={props.id}
      name={props.name}
      options={months}
      value={props.value}
      className={props.className}
      onClick={props.onClick}
      onChange={props.onChange}
      setState={props.setState}
      required={props.required}
    />
  )
}

export default SelectMonth