import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "./../../../Shared/RailsForm.js"
import TextField from "./../../FormTags/TextField.js"
import Submit from "./../../FormTags/Submit.js"
import EmailLabel from "./../EmailLabel.js"
import styles from "./../Edit.scss"

const propTypes = {
}

const defaultProps = {
  errors: [],
}

const Edit = (props) => {
  const [password, setPassword] = useState()
  const [passwordConfirmation, setPasswordConfirmation] = useState()
  const influencerPasswordPath = `/influencer/password`
  const afterSignIn = props.is_sign_in

  return (
    <div className={styles.container}>
      <RailsForm
        action={influencerPasswordPath}
        method={"patch"}
      >
        <TextField
          label="新しいパスワード"
          type="password"
          name={'influencer[password]'}
          value={password}
          setState={setPassword}
          required={true}
        />
        <TextField
          label="新しいパスワード（確認用）"
          type="password"
          name={'influencer[password_confirmation]'}
          value={passwordConfirmation}
          setState={setPasswordConfirmation}
          required={true}
        />
        <Submit
          className={styles.form_submit}
          buttonColor={"brown"}
          text={"変更を保存する"}
        />
      </RailsForm>
    </div>
  )
}

Edit.propTypes = propTypes
Edit.defaultProps = defaultProps

export default Edit
