import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import TextField from "./TextField.js"

const propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setPrefectureId: PropTypes.func.isRequired,
  setCity: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  required: PropTypes.bool,
}

const defaultProps = {
  className: "",
  value: "",
  onClick: null,
  onChange: null,
  setState: null,
  required: false,
}

const ZipCode = (props) => {
  useEffect(() => {
    const zipCodeInput = document.getElementById(props.id)
    zipCodeInput.addEventListener('change', () => { requestAddress(props.value) })
  })

  const requestAddress = (value) => {
    const data = new FormData()
    data.append("zipcode", value)
    fetch(`/zipcloud`, {
      method: "POST",
      credentials: 'include',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: data
    }).then((response) => response.json()).then((jsonData) => {
      props.setPrefectureId(jsonData.prefectureId)
      props.setCity(jsonData.city)
    }).catch(
      (error) => console.log(error)
    )
  }

  return (
    <>
      <TextField
        type="number"
        label={props.label}
        id={props.id}
        name={props.name}
        value={props.value}
        onClick={props.onClick}
        onChange={props.onChange}
        setState={props.setState}
        required={true}
      />
    </>
  )
}

ZipCode.propTypes = propTypes
ZipCode.defaultProps = defaultProps

export default ZipCode