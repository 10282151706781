import PropTypes from "prop-types"

const propTypes = {
  setMessageRooms: PropTypes.func.isRequired,
  loadStart: PropTypes.func,
  loadEnd: PropTypes.func
}

const defaultProps = {
  loadStart: () => {},
  loadEnd: () => {}
}

const FetchMessageRooms = async (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`
  let currentPageNum = 1
  let loadMore = true
  const newApiEndpoint = "/sponsor/message_rooms"

  // このの関数が非同期で複数回呼ばれたときに、複数のステータスが混ざってしまうため、
  // この関数自体がmessageRoomsを持つ
  let messageRooms = []

  const updateCurrentPage = async () => {
    currentPageNum = currentPageNum + 1
    return true
  }

  const updateLoadMore = async () => {
    loadMore = false
    return true
  }

  while(loadMore) {
    props.loadStart()
    let page = `${newApiEndpoint}?page=${currentPageNum}`
    console.log(page)
    callAnalytics(String(newApiEndpoint).replace(currentPageOrigin, ""))

    await fetch(page, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then(async (response) => {
      const jsonData = await response.json()
      return jsonData
    }).then(async (jsonData) => {
      if(currentPageNum == 1) {
        props.setMessageRooms([])
      }

      const message_rooms = jsonData.message_rooms
      messageRooms = messageRooms.concat(message_rooms)
      props.setMessageRooms(messageRooms)
      props.loadEnd()
      if(message_rooms.length === 0) {
        const result = await updateLoadMore()
        return result
      } else {
        const result = await updateCurrentPage()
        return result
      }
    }).catch((error) => {
      console.log(error)
    })
  }
  return newApiEndpoint
}
FetchMessageRooms.propTypes = propTypes
FetchMessageRooms.defaultProps = defaultProps

export default FetchMessageRooms
