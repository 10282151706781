import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Moment from "moment"
import Project from "../Shared/Project.js"
import styles from "./Index.scss"

const propTypes = {
  project: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  typeVisitId: PropTypes.number.isRequired
}

const Index = (props) => {
  return (
    <div>
      <div className={styles.container}>
        <h2>応募を受け付けました</h2>
        <p>
          ご応募いただきありがとうございます！<br />
          {
            props.project.approve_all ?
              `${Moment(props.entry.shipping_limit_at).format("YYYY年MM月DD日")}${props.project.project_type_id === props.typeVisitId ? "までに訪問してサービスを利用してください。" : "以内に商品が発送されます。お届けまでもうしばらくお待ち下さい。"}` :
              `${Moment(props.entry.selection_end_at).format("YYYY年MM月DD日")}までに選考結果をご連絡致します。`
          }
        </p>
      </div>
      <Project project={props.project} />
    </div>
  )
}

Index.propTypes = propTypes

export default Index
