import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../../Shared/RailsForm.js"
import TextField from "../../FormTags/TextField.js"
import ImageFile from "../../FormTags/ImageFile.js"
import Submit from "../../FormTags/Submit.js"

const propTypes = {
  staff: PropTypes.object.isRequired,
}

const Account = (props) => {
  const [name, setName] = useState(props.staff.name)
  const [image, setImage] = useState(props.staff.image)
  const [email, setEmail] = useState(props.staff.email)
  const [tel, setTel] = useState(props.staff.tel)
  const sponsorAccountPath = `/sponsor/account`

  const elements = (
    <>
      <TextField
        label="担当者名"
        id="account_name"
        name="account[name]"
        value={name}
        setState={setName}
      />

      <ImageFile
        label="担当者アイコン"
        id="account_image"
        name="account[image]"
        value={image}
        setState={setImage}
      />

      <TextField
        label="メールアドレス"
        id="account_email"
        name="account[email]"
        value={email}
        setState={setEmail}
      />

      <TextField
        label="電話番号"
        id="account_tel"
        name="account[tel]"
        value={tel}
        setState={setTel}
      />

      <Submit text="保存する" />
    </>
  )

  return (
    <RailsForm
      action={sponsorAccountPath}
      elements={elements}
      multipart={true}
      patch={true}
      remote={true}
      method={"patch"}
    />
  )
}

Account.propTypes = propTypes

export default Account
