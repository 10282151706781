import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from "prop-types"
import LazyLoad from "../../Shared/LazyLoad.js"
import styles from "./DateRange.scss"

const propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any.isRequired,
  setStartDate: PropTypes.any.isRequired,
  setEndDate: PropTypes.any.isRequired,
  startDateName: PropTypes.string.isRequired,
  endDateName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}

const defaultProps = {
  className: "",
  value: null,
  onClick: null,
  onChange: null,
  required: false,
}

import { DayPickerRangeController } from 'react-dates';
import moment from 'moment'
import 'moment/locale/ja'; // 日本語ローカライズ
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const DateRange = (props) => {
  const startDate = props.startDate
  const endDate = props.endDate
  const setStartDate = props.setStartDate
  const setEndDate = props.setEndDate
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [focusedInput, setFocusedInput] = useState("startDate")
  const onClickTerm = () => { setDisplayCalendar(true) }
  const [blockedDays, setBlockedDays] = useState([]);
  const [unBlockedDays, setUnBlockedDays] = useState([]);

  const closeCalendar = () => {
    setDisplayCalendar(false)
    setBlockedDays([]);
    setUnBlockedDays([]);
  }
  const isFirstRender = useRef(false)

  useEffect(() => {
    isFirstRender.current = true
  }, [])

  useEffect(() => {
    if(isFirstRender.current) {
      isFirstRender.current = false
    } else {
      props.onChange()
    }
  }, [endDate])

  return (
    <div>
      <p className={styles.label}>{props.label}</p>
      <p className={styles.termInputLabel} onClick={onClickTerm}>{startDate?.format("YYYY/MM/DD") || "年/月/日"}　〜　{endDate?.format("YYYY/MM/DD") || "年/月/日"}</p>
      <div className={`${styles.calendar_container} agency-reports-range-datepicker`} >
        { displayCalendar &&
          <DayPickerRangeController
            startDate={startDate}
            startDateId="startDateId"
            endDate={endDate}
            endDateId="endDateId"
            focusedInput={focusedInput}
            onFocusChange={(focused) => {
              setFocusedInput(!focused ? 'startDate' : focused);
            }}
            onDatesChange={(selectedDates) => {
              console.log("-1");
              if(selectedDates.startDate && selectedDates.startDate < props.minDate) {
                return false;
              }

              if(selectedDates.endDate && selectedDates.endDate < props.minDate) {
                return false;
              }

              if(selectedDates.endDate && selectedDates.endDate > props.maxDate) {
                return false;
              }

              if (focusedInput === 'startDate') {
                setStartDate(selectedDates.startDate);
                if(selectedDates.startDate > endDate) {
                  setEndDate(selectedDates.startDate);
                  setFocusedInput('endDate');
                }
              } else {
                setEndDate(selectedDates.endDate);
                setFocusedInput('startDate');

                if(selectedDates.endDate && selectedDates.endDate >= startDate) {
                  closeCalendar()
                }
              }
            }}
            numberOfMonths={2}
            onOutsideClick={() => {
              if(endDate && endDate >= startDate) {
                closeCalendar()
              }
            }}
            hideKeyboardShortcutsPanel={true}
            minDate={props.minDate}
            maxDate={props.maxDate}
            isDayBlocked={(day) => {
              // ここの処理がないと開始日を選択した時に選択状態になってしまう
              if(blockedDays.includes(day)) {
                return true
              }
              if(unBlockedDays.includes(day)) {
                return false
              }

              if(props.minDate && day < props.minDate || props.maxDate && day > props.maxDate || false){
                setBlockedDays((days) => [...days, day])
                return true
              } else {
                setUnBlockedDays((days) => [...days, day])
                return false
              }
            }}
          />
        }
      </div>

      <input
        type='hidden'
        name={props.startDateName}
        value={startDate?.format("YYYY-MM-DD")}
      />
      <input
        type='hidden'
        name={props.endDateName}
        value={endDate?.format("YYYY-MM-DD")}
      />
    </div>
  )
 }

 export default DateRange
