import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import LazyLoad from "../../../../../Shared/LazyLoad.js"
import CheckboxBool from "../../../../FormTags/CheckboxBool"
import InstagramIcon from "../../../../Shared/Icons/monochrome_instagram_icon.png"
import TwitterIcon from "../../../../Shared/Icons/monochrome_twitter_icon.png"

import styles from "./../Index.scss"

const propTypes = {
  entries: PropTypes.array.isRequired,
  checkedEntries: PropTypes.object.isRequired,
  setCheckEntries: PropTypes.func.isRequired,
}

const EntryTable = (props) => {
  const selectEntry = (e) => {
    props.setCheckEntries({
      ...props.checkedEntries,
      [e.target.id]: e.target.checked
    })
  }

  const entryList = [
    <tr key="thead">
      <th>インフルエンサー</th>
      <th>カテゴリー</th>
      <th>SNS</th>
      <th>総フォロワー</th>
      <th>エンゲージメント率</th>
      <th>応援メッセージ</th>
    </tr>
  ]

  props.entries.forEach((entry, index) => {
    const influencer = entry.influencer
    const snsList = influencer.social_network_accounts.map((v) => v.social_network_name )

    const influencerCategories = (influencerCategories) => {
      let categories = []
      influencerCategories.forEach((category, index) => {
        if(category.parent && category.parent.mainly_displayed){
          if(categories[category.parent.id]){
            categories[category.parent.id].push(category.name)
          }else{
            categories[category.parent.id] = [category.name]
            categories[category.parent.id].parent_name = category.parent.name
          }
        }else if(category.mainly_displayed){
          if(!categories[category.id]){
            categories[category.id] = []
            categories[category.id].parent_name = category.name
          }
        }
      })
      categories = categories.filter(v => v)
      let categoryList = []
      categories.forEach((cate, index) =>{
        categoryList.push(<div key={index}>
          <div className={styles.influencer_category_parent}>{cate.parent_name}</div>
          <div className={styles.influencer_category}>{cate.join("・")}</div>
        </div>)
      })
      return categoryList
    }

    entryList.push(
      <tr key={entry.id}>
        <td>
          <div className={styles.influencer_name_column}>
            <div className={styles.influencer_checkbox}>
              <CheckboxBool
                id={entry.id}
                name="entries"
                className={styles.influencer_checkbox}
                value={false}
                onChange={selectEntry}
              />
            </div>
            <div
              className={styles.influencer_name}
            >
              <div className={styles.influencer_icon}>
                <LazyLoad src={String(influencer.profile_pic)}/>
              </div>
              <p>
                {influencer.name}
              </p>
            </div>
          </div>
        </td>
        <td className={styles.category_column}>{influencerCategories(influencer.categories)}</td>
        <td className={styles.sns_column}>
          {snsList.includes("Instagram") ? <a href={`https://www.instagram.com/${influencer.instagram_user_name}/`} target="_blank"><img src={InstagramIcon} className={styles.sns_icon} /></a> : <></>}
          {snsList.includes("Twitter") ? <img src={TwitterIcon} className={styles.sns_icon} /> : <></>}
        </td>
        <td className={styles.count_column}>
          {influencer.followed_by_count ? influencer.followed_by_count.toLocaleString() : "-"}
        </td>
        <td className={styles.count_column}>
          {influencer.average_engagement_rate ?
            <p>{influencer.average_engagement_rate}<span className={styles.count_column_unit}>%</span></p> :
            "-"
          }
        </td>
        <td className={styles.influencer_message_column}>{entry.influencer_message}</td>
      </tr>
    )
  })

  return (
      <table className={styles.entry_table}>
        <tbody>
        {entryList}
        </tbody>
      </table>
  )
}

EntryTable.propTypes = propTypes

export default EntryTable
