import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import Submit from "../FormTags/Submit.js"
import SelectYear from "../FormTags/Select/Year.js"
import SelectMonth from "../FormTags/Select/Month.js"
import SelectDays from "../FormTags/Select/Days.js"
import SelectPrefecture from "../FormTags/Select/Prefecture.js"
import SelectGender from "../FormTags/Select/Gender.js"
import SelectOccupation from "../FormTags/Select/Occupation.js"
import Categories from "../FormTags/Categories.js"
import SelectDesiredIncentivePerPost from "../FormTags/Select/DesiredIncentivePerPost.js"
import styles from "./New.scss"

const propTypes = {
  influencer: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  genders: PropTypes.array.isRequired,
  prefectures: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  occupations: PropTypes.array.isRequired
}

const defaultProps = {
  errors: [],
}

const New = (props) => {
  const influencer = props.influencer
  const [categoryIds, setCategoryIds] = useState(influencer.category_ids)
  const [genderId, setGenderId] = useState(influencer.gender_id)
  const [birthYear, setBirthYear] = useState(influencer.birth_year)
  const [birthMonth, setBirthMonth] = useState(influencer.birth_month)
  const [birthDay, setBirthDay] = useState(influencer.birth_day)
  const [prefectureId, setPrefectureId] = useState(influencer.prefecture_id)
  const [desiredIncentivePerPost, setDesiredIncentivePerPost] = useState(influencer.desired_incentive_per_post)
  const [occupation, setOccupation] = useState(null)
  const [occupationRequired, setOccupationRequired] = useState(false)
  const influencerRegistrationPath = `/influencer/accounts`

  const [step, setStep] = useState(0)
  const onClickNext = () => {
    if(occupationRequired && !occupation) {
      return false
    } else {
      scrollTo(0, 0);
      setStep(step + 1)
    }
  }

  const elements = (
    <div className={styles.container}>
      <div className={step != 0 && styles.hide}>
        <div className={styles.step_header}>
          <h1>プロフィール登録</h1>
        </div>
        <div className={`${styles.step_top} ${styles.step_1}`}>
          <div className={styles.step_bar_container}>
            <div className={styles.step_bar_active}>
            </div>

            <div className={styles.step_bar}>
            </div>
          </div>
        </div>

        <h2>基本情報の登録</h2>
        <SelectGender
          label="性別（必須）"
          id="influencer_gender_id"
          name="influencer[gender_id]"
          genders={props.genders}
          value={genderId}
          setState={setGenderId}
          className={styles.gender_field}
          required={true}
        />

        <label>生年月日（必須）</label>
        <div className={styles.birthdate_container}>
          <SelectYear
            label=""
            id="influencer_birth_year"
            name="influencer[birth_year]"
            value={birthYear}
            setState={setBirthYear}
            className={styles.year_field}
            required={true}
          />
          <SelectMonth
            label=""
            id="influencer_birth_month"
            name="influencer[birth_month]"
            value={birthMonth}
            setState={setBirthMonth}
            className={styles.month_field}
            required={true}
          />
          <SelectDays
            label=""
            id="influencer_birth_day"
            name="influencer[birth_day]"
            value={birthDay}
            setState={setBirthDay}
            className={styles.day_field}
            required={true}
          />
        </div>
        <SelectPrefecture
          label="都道府県（必須）"
          id="influencer_prefecture_id"
          name="influencer[prefecture_id]"
          prefectures={props.prefectures}
          value={prefectureId}
          setState={setPrefectureId}
          required={true}
        />

        <SelectOccupation label={"職業（任意）"} name={"occupation_id"} occupations={props.occupations} value={occupation} setState={setOccupation} setRequired={setOccupationRequired}/>

        <a onClick={onClickNext} className={styles.button}>
          つぎへ
        </a>
      </div>
      <div className={step != 1 && styles.hide}>
        <div className={styles.step_header}>
          <h1>プロフィール登録</h1>
        </div>
        <div className={`${styles.step_top} ${styles.step_1}`}>
          <div className={styles.step_bar_container}>
            <div className={styles.step_bar}>
            </div>

            <div className={styles.step_bar_active}>
            </div>
          </div>
        </div>

        <h2>投稿カテゴリーの登録</h2>
        <p className={styles.category_description}>普段の投稿内容に当てはまるカテゴリーを、最大3つまでお選び下さい。</p>

        <Categories categories={props.categories} label={false}/>
        <Submit className={`${styles.submit_button}`} text="登録を完了する" />
      </div>
    </div>
  )

  return (
    <>
      {props.errors.length !== 0 && (
        <ErrorMessages messages={props.errors} />
      )}
      <RailsForm
        action={influencerRegistrationPath}
        elements={elements}
      />
    </>
  )
}

New.propTypes = propTypes
New.defaultProps = defaultProps

export default New
