import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect, useState }  from 'react'
import PropTypes from "prop-types"
import RoundBtn from "../../Shared/Buttons/Round.js"
import styles from "./PreviewModal.scss"
import Modal from "../../Shared/Modal.js"
// 共通でメンテナンスしていくために例外的にInfluencers側を使う仕様
import InfluencerShow from "../../../Influencers/Projects/Show.js"

const propTypes = {
  projectId: PropTypes.number.isRequired,
  previewModalState: PropTypes.bool.isRequired,
  setPreviewModalState: PropTypes.func.isRequired,
}

const PreviewModal = (props) => {
  const [projectId] = useState(props.projectId)
  const [project, setProject] = useState()
  const [typeUseId, setTypeUseId] = useState()
  const [typeVisitId, setTypeVisitId] = useState()
  const [typeBuyId, setTypeBuyId] = useState()
  const [typeAppUseId, setTypeAppUseId] = useState()

  useEffect(() => {
    fetchPreviewData()
  }, projectId)

  const fetchPreviewData = () => {
    if(projectId) {
      const previewPath = `/sponsor/project_previews/${props.projectId}`

      callAnalytics(previewPath)

      fetch(previewPath, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
        },
      }).then((response) => response.json()).then((jsonData) => {
        setTypeUseId(jsonData.typeUseId)
        setTypeVisitId(jsonData.typeVisitId)
        setTypeBuyId(jsonData.typeBuyId)
        setTypeAppUseId(jsonData.typeAppUseId)
        setProject(jsonData.project)
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  if(project) {
    return (
      <Modal
        className={styles.modal}
        status={props.previewModalState}
        setState={props.setPreviewModalState}
      >
        <div className={styles.preview_container}>
          <InfluencerShow
            project={project}
            entry={null}
            previewMode={true}
            typeUseId={typeUseId}
            typeVisitId={typeVisitId}
            typeBuyId={typeBuyId}
            typeAppUseId={typeAppUseId}
          />
        </div>
      </Modal>
    )
  } else {
    return <div></div>
  }
}

PreviewModal.propTypes = propTypes

export default PreviewModal
