import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Select from "../Select.js"

const propTypes = {
  genders: PropTypes.array.isRequired,
}

const SelectGender = (props) => {
  let genders = []
  props.genders.forEach((gender) => {
    genders.push({
      value: gender.id,
      name: gender.name
    })
  })


  return (
    <Select
      label={props.label}
      id={props.id}
      name={props.name}
      options={genders}
      value={props.value}
      className={props.className}
      onClick={props.onClick}
      onChange={props.onChange}
      setState={props.setState}
      required={props.required}
    />
  )
}

SelectGender.propTypes = propTypes

export default SelectGender
