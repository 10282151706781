import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./HeaderLogo.scss"
import Logo from "./logo.png"

const HeaderLogo = () => {
  return (
    <img className={styles.header_logo} src={Logo} />
  )
}

export default HeaderLogo
