import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import Submit from "../FormTags/Submit.js"
import styles from "./New.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
}

const defaultProps = {
  errors: [],
}

const New = (props) => {
  const [email, setEmail] = useState()
  const influencerPasswordPath = `/influencer/password`

  return (
    <div>
      <div className={styles.sns_link_btn_container}>
        <h2>パスワードの再設定</h2>
         <RailsForm
          action={influencerPasswordPath}
        >
          <TextField
            label="メールアドレス"
            id="influencer_email"
            name="influencer[email]"
            type="email"
            value={email}
            setState={setEmail}
            required={true}
          />
          
          <div className={styles.form_link}>
            <span>ご登録されたメールアドレスにパスワードの再設定手続きのご案内が送信されます。</span>
          </div>
          <Submit className={styles.submit_button} text={"メールを送信する"}/>
        </RailsForm>
      </div>
    </div>
  )
}

New.propTypes = propTypes
New.defaultProps = defaultProps

export default New
