import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./Thankyou.scss"
import LogoImg from "./Index/logo.png"

const Thankyou = () => {
  const sponsorLpPath = `/`

  return (

    <div className={styles.base_container}>
        <header className={styles.common_header}>
          <a href={sponsorLpPath}>
          <img src={LogoImg} className={styles.head_logo} />
          </a>
        </header>

        <main className={styles.thankyou}>
          <div className={styles.thankyou_title}>ダウンロードありがとうございます！</div>
          <div className={styles.thankyou_content}>
            <div><p>資料をダウンロードしていただき、</p><p>誠にありがとうございました。</p></div>
            <div><p>ご入力いただいたメールアドレスに、</p><p>資料ダウンロード用のリンクをお送りいたしました。</p></div>
            <div><p>当資料がご参考になりますと幸いです。</p></div>
          </div>
        </main>

        <footer className={styles.common_footer}>
          <img src={LogoImg} className={styles.logo} />
          <small className={styles.footer_copyright}>Copyright © 2020 Maro inc. All rights reserved.</small>
        </footer>


    </div>


  )
}

export default Thankyou
