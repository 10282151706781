import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./Checkbox.scss"

const propTypes = {
  elements: PropTypes.array.isRequired,
  className: PropTypes.string,
  value: PropTypes.array,
  direction: PropTypes.string,
  onChange: PropTypes.func
}

const defaultProps = {
  className: "",
  value: [],
  direction: "column",
  onChange: null
}

const CheckboxCollection = (props) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (props.onChange)
      props.onChange(values)
  }, [values, props.onChange])

  const direction = (
    props.direction === "column" ? styles.direction_column :
      props.direction === "row" ? styles.direction_row : ""
  )

  const handleParentChange = (evt) => {
    if (evt.target.checked) {
      const childrenIds = props.elements.find(e => e.id == evt.target.value).children.map((child) => child.id)
      setValues([...new Set([...values, ...childrenIds])])
    } else {
      const childrenIds = props.elements.find(e => e.id == evt.target.value).children.map((child) => child.id)
      setValues(values.filter(v => !childrenIds.includes(v)))
    }
  }
  const handleChildChange = (evt) => {
    if (evt.target.checked) {
      setValues(values.concat(parseInt(evt.target.value)))
    } else {
      setValues(values.filter(v => v != evt.target.value))
    }
  }
  const parentCheckboxes = props.elements.map((element) => {
    const childrenIds = element.children.map((child) => child.id)
    const parentChecked = childrenIds.every(v => values.includes(v))
    const childrenList = element.children.map((child) => (
      <label key={child.id}>
        <input
          type="checkbox"
          name="child_selections"
          checked={values.includes(child.id)}
          value={child.id}
          onChange={handleChildChange}
        />
        <span />
        <div>{child.name}</div>
      </label>
    ))
    return (
      <div key={element.id}>
        <div className={`${styles.checkbox_bool}`}>
          <label>
            <input
              type="checkbox"
              name="parent_selections"
              value={element.id}
              onChange={handleParentChange}
              checked={parentChecked}
            />
            <span />
            <div>{element.name}</div>
          </label>
        </div>
        <div className={`${styles.checkbox} ${direction}`}>
          {childrenList}
        </div>
      </div>
    )
  })

  return (
    <div className={styles.collection_container}>
      {parentCheckboxes}
    </div>
  )
}

CheckboxCollection.propTypes = propTypes
CheckboxCollection.defaultProps = defaultProps

export default CheckboxCollection
