import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Tile from "../Shared/Tile.js"
import styles from "./RewardSummary.scss"
import QuestionMark from "../../Shared/QuestionMark.js"

const propTypes = {
  todo: PropTypes.object.isRequired,
  reward_summary: PropTypes.object.isRequired
}

const RewardSummary = (props) => {
  const reward_summary = props.reward_summary

  return (
    <div className={styles.sales_container}>
      <div className={styles.confirmed_sales_container}>
        <div>
          <p className={styles.label}>売上件数（確定）<QuestionMark message="直近30日間で確定したCV件数の合計" /></p>
          <p className={styles.amount}>{reward_summary.approved_count.toLocaleString()}<span className={styles.unit}>/件</span></p>
        </div>
        <div>
          <p className={styles.label}>売上金額（確定）<QuestionMark message="直近30日間で確定した報酬金額の合計" /></p>
          <p className={styles.amount}>￥{reward_summary.approved_amount.toLocaleString()}</p>
        </div>
      </div>
      <div className={styles.unconfirmed_sales_container}>
        <div>
          <p className={styles.label}>売上件数（未計上）<QuestionMark message="直近30日間で発生し、未確定のCV件数の合計" /></p>
          <p className={styles.amount}>{reward_summary.pending_count.toLocaleString()}<span className={styles.unit}>/件</span></p>
        </div>
        <div>
          <p className={styles.label}>売上金額（未計上）<QuestionMark message="直近30日間で発生し、未確定の報酬金額の合計" /></p>
          <p className={styles.amount}>￥{reward_summary.pending_amount.toLocaleString()}</p>
        </div>
      </div>
    </div>
  )
}

RewardSummary.propTypes = propTypes
export default RewardSummary
