import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import styles from "./Index.scss"
import EyecatchImg from "./Index/eyecatch.png"
import InfluencerImg from "./Index/01.png"
import HumanImg from "./Index/human.png"
import FilterImg from "./Index/filter.png"
import OperatorImg from "./Index/03.png"
import SupportImg from "./Index/support.png"
import AnalyticsImg from "./Index/analytics.png"
import WalletImg from "./Index/wallet.png"
import FasionImg from "./Index/02.png"
import Howto1Img from "./Index/howto1.png"
import Howto2Img from "./Index/howto2.png"
import Howto3Img from "./Index/howto3.png"

import FuncPrImg from "./Index/func_pr.png"
import FuncCheckImg from "./Index/func_check.png"
import FuncScoutImg from "./Index/func_scout.png"
import FuncContactImg from "./Index/func_contact.png"
import FuncMessageImg from "./Index/func_message.png"
import FuncReportImg from "./Index/func_report.png"
import QImg from "./Index/Q.png"
import LogoImg from "./Index/logo.png"
import AfloatImg from "./Index/afloat.png"
import ArtistarImg from "./Index/artistar.png"
import LutyImg from "./Index/luty.png"
import MauloaImg from "./Index/mauloa.png"
import SecretmuseImg from "./Index/secretmuse.png"
import TogenkyoImg from "./Index/togenkyo.png"
import ViluxueuxImg from "./Index/viluxueux.png"
import BrandLogosImg from "./Index/brand_logos.png"
import RailsForm from "../../Shared/RailsForm.js"
import Header from "../Shared/SponsorHeader.js"
import InfluencerExample0_Icon from "./Index/InfluencerExample/0_icon.png"
import InfluencerExample0_0 from "./Index/InfluencerExample/0_0.png"
import InfluencerExample0_1 from "./Index/InfluencerExample/0_1.png"
import InfluencerExample1_Icon from "./Index/InfluencerExample/1_icon.png"
import InfluencerExample1_0 from "./Index/InfluencerExample/1_0.png"
import InfluencerExample1_1 from "./Index/InfluencerExample/1_1.png"
import InfluencerExample2_Icon from "./Index/InfluencerExample/2_icon.png"
import InfluencerExample2_0 from "./Index/InfluencerExample/2_0.png"
import InfluencerExample2_1 from "./Index/InfluencerExample/2_1.png"
import InfluencerExample3_Icon from "./Index/InfluencerExample/3_icon.png"
import InfluencerExample3_0 from "./Index/InfluencerExample/3_0.png"
import InfluencerExample3_1 from "./Index/InfluencerExample/3_1.png"
import InfluencerExample4_Icon from "./Index/InfluencerExample/4_icon.png"
import InfluencerExample4_0 from "./Index/InfluencerExample/4_0.png"
import InfluencerExample4_1 from "./Index/InfluencerExample/4_1.png"
import InfluencerExample5_Icon from "./Index/InfluencerExample/5_icon.png"
import InfluencerExample5_0 from "./Index/InfluencerExample/5_0.png"
import InfluencerExample5_1 from "./Index/InfluencerExample/5_1.png"

import DashImg from "./Index/dash_image.png"


const Index = () => {
  const [isOpen, setIsOpen] = useState(false)
  const catalogRequestPath = `/catalog_requests`
  const blogUrl = `https://blog.maro.mu/`
  const zenDeskUrl = `https://maro-support.zendesk.com/hc/ja`
  const influencerExampleList = [
    {
      iconImg: InfluencerExample5_Icon,
      name: "O様",
      flw: "28,000",
      img0: InfluencerExample5_0,
      img1: InfluencerExample5_1
    },
    {
      iconImg: InfluencerExample0_Icon,
      name: "H様",
      flw: "7,000",
      img0: InfluencerExample0_0,
      img1: InfluencerExample0_1
    },
    {
      iconImg: InfluencerExample1_Icon,
      name: "K様",
      flw: "5,300",
      img0: InfluencerExample1_0,
      img1: InfluencerExample1_1
    },
    {
      iconImg: InfluencerExample2_Icon,
      name: "R様",
      flw: "4,000",
      img0: InfluencerExample2_0,
      img1: InfluencerExample2_1
    },
    {
      iconImg: InfluencerExample3_Icon,
      name: "S様",
      flw: "19,000",
      img0: InfluencerExample3_0,
      img1: InfluencerExample3_1
    },
    {
      iconImg: InfluencerExample4_Icon,
      name: "U様",
      flw: "4,500",
      img0: InfluencerExample4_0,
      img1: InfluencerExample4_1
    },
  ]

  const formEmelents = (
    <div>
      <div className={styles.form_input_container}>
        <div className={styles.form_input_item}>
          <input type='hidden' name="catalog_request[contact_type]" value="新規資料ダウンロード" />
          <label htmlFor={styles.catalog_request_company_name}>会社名</label>
          <input placeholder="株式会社◯◯" required="required" type="text" name="catalog_request[company_name]" />
        </div>

        <div className={styles.form_input_item}>
          <label htmlFor={styles.catalog_request_last_name}>氏名</label>
          <div className={styles.form_input_name}>
            <input placeholder="山田" required="required" type="text" name="catalog_request[last_name]" />
            <input placeholder="まろ" required="required" type="text" name="catalog_request[first_name]" />
          </div>
        </div>

        <div className={styles.form_input_item}>
          <label htmlFor="catalog_request_company_email">メールアドレス</label>
          <input placeholder="maro@maro.mu" required="required" type="email" name="catalog_request[company_email]" />
        </div>

        <div className={styles.form_input_item}>
          <label htmlFor="catalog_request_phone_number">電話番号</label>
          <input placeholder="08012345678" required="required" type="tel" name="catalog_request[phone_number]" />
        </div>

        <div className={styles.form_input_item}>
          <label htmlFor="catalog_request_company_url">会社ホームページ</label>
          <input placeholder="https://maro.mu" required="required" type="url" name="catalog_request[company_url]" />
        </div>

        <div className={styles.form_input_item}>
          <label htmlFor="catalog_request_proposal_position">提案の立場</label>
          <div className={styles.form_input_proposal_position}>
            <div>
              <input required="required" type="radio" value="自社導入" name="catalog_request[proposal_position]" />
              <label htmlFor="catalog_request_proposal_position">自社導入</label>
            </div>
            <div>
              <input required="required" type="radio" value="代理販売" name="catalog_request[proposal_position]" />
              <label htmlFor="catalog_request_proposal_position">代理販売</label>
            </div>
          </div>
        </div>
      </div>

      <input type="submit" name="commit" value="資料ダウンロード" data-disable-with="資料ダウンロード" />
    </div>
  )

  return (
    <div className={styles.base_container}>
      <div className={styles.lp_container}>
        <div className={styles.lp_container_item_width}>
          <Header />
        </div>
        <div className={styles.lp_container_main}>
          <div className={styles.lp_container_item_width}>
            <div className={styles.eyecatch_container}>
              <div className={styles.eyecatch_title_container}>
                <h1 className={styles.eyecatch_title}>
                  美容・ファッションブランドのための<br />
                  インフルエンサーマーケティング<br />
                  プラットフォーム
                </h1>
                <div className={styles.eyecatch_sub_title}>
                  ブランドイメージにぴったりなインフルエンサーとのマッチングを実現し、施策効果の可視化・PDCAサイクルの実行を可能にします。
                </div>
              </div>
              <div className={styles.eyecatch_image}>
                <img src={EyecatchImg} />
              </div>
            </div>
          </div>
          <div className={styles.lp_container_item_width}>
            <div className={styles.description_container}>
              <div className={styles.maro_description_solution_container}>
                <div className={styles.maro_description_solution}>Feature 1</div>
                <h2>ブランドイメージにぴったりなインフルエンサーと出会えます</h2>
                <p>
                  「どのインフルエンサーにPRを依頼すべきか分からない」「ブランドイメージに合ったインフルエンサーが見つからない」といったお悩みを解決します。
                </p>

                <div>
                  <div className={styles.maro_description_solution_item}>
                    <div className={styles.sponsor_lp_pc_only}>
                      <img src={InfluencerImg} className={styles.maro_description_solution_item_icon} />
                    </div>
                    <div className={styles.maro_description_solution_item_inner_container}>
                      <div className={styles.left}>
                        <h3 className={styles.maro_description_solution_item_title}>厳正な登録基準をクリアした<br className={styles.sponsor_lp_sp_only} />インフルエンサーのみが登録</h3>
                        <p className={styles.maro_description_solution_item_title_description}>
                          Maroでは下記の登録条件を満たしたインフルエンサーが登録しています。
                        </p>
                        <p className={styles.maro_description_solution_item_text}>
                          【 登録基準 】※一部抜粋<br />
                          ・フォロワー数が1000名以上<br />
                          ・直近5投稿の平均エンゲージメント率が3％以上<br />
                          ・フォロワーの国内在住比率が9割以上<br />
                          ・フォロワーを購入していない
                        </p>
                      </div>
                      <div className={styles.maro_description_solution_item_image_container}>
                        <img src={HumanImg} />
                      </div>
                    </div>
                  </div>

                  <div className={styles.maro_description_solution_item}>
                    <div className={styles.sponsor_lp_pc_only}>
                      <img src={FasionImg} className={styles.maro_description_solution_item_icon} />
                    </div>
                    <div className={styles.maro_description_solution_item_inner_container}>
                      <div className={styles.left}>
                        <h3 className={styles.maro_description_solution_item_title}>美容・ファッションジャンル別の<br className={styles.sponsor_lp_sp_only} />絞り込み機能</h3>
                        <p className={styles.maro_description_solution_item_title_description}>
                          インフルエンサーのジャンルを細分化し、そのジャンルをもとに絞り込み可能な機能を提供しています。他にも、フォロワー数やエンゲージメント率等の詳細のデータを元に簡単に検索することができます。
                        </p>
                        <p className={styles.maro_description_solution_item_text}>
                          【 ジャンルの具体例 】<br />
                          ファッションの場合：カジュアル系・古着系・ストリート系・韓国系・ハイブランド系<br />
                          美容の場合：コスメ・ヘアスタイル・ネイル<br />
                          <span>※ 一部抜粋</span>
                        </p>
                      </div>
                      <div className={styles.maro_description_solution_item_image_container}>
                        <img src={FilterImg} />
                      </div>
                    </div>
                  </div>

                  <div className={styles.maro_description_solution_item}>
                    <div className={styles.sponsor_lp_pc_only}>
                      <img src={OperatorImg} className={styles.maro_description_solution_item_icon} />
                    </div>
                    <div className={styles.maro_description_solution_item_inner_container}>
                      <div className={styles.left}>
                        <h3 className={styles.maro_description_solution_item_title}>専任の担当による<br className={styles.sponsor_lp_sp_only} />オンボーディングサポート</h3>
                        <p className={styles.maro_description_solution_item_title_description}>
                          インフルエンサーマーケティングの実務経験豊富な弊社スタッフが、Maroの効果的な活用方法・マーケティングノウハウのご提供およびご不明点の解消等を通じてクライアント様をサポートさせて頂きます。
                        </p>
                        <p className={styles.maro_description_solution_item_text}>
                          【 オンボーディングの内容 】<br />
                          ・ ブランドイメージにあったインフルエンサーの選定<br />
                          ・ インフルエンサーを惹きつける案件の記載方法<br />
                          <span>※ 一部抜粋</span>
                        </p>
                      </div>
                      <div className={styles.maro_description_solution_item_image_container}>
                        <img src={SupportImg} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.maro_description_solution_container}>
                <div className={styles.maro_description_solution}>Feature 2</div>
                <h2 className={styles.maro_description_title}>
                  施策効果を可視化し、効果最大化に向けたPDCAサイクルの実行を<br />可能にします
                </h2>

                <div className={styles.maro_description_container}>
                  <div className={styles.maro_description_item}>
                    <div className={styles.maro_description_item_label}>
                      課題1
                    </div>
                    <div className={styles.maro_description_item_title}>
                      インフルエンサーの投稿により発生<br className={styles.sponsor_lp_pc_only} />した施策効果が計測できていない
                    </div>
                    <div className={styles.maro_description_item_text}>
                      <div className={styles.maro_description_item_text_title}>
                        効果分析
                      </div>
                      <div className={styles.maro_description_item_content}>
                        <div className={styles.maro_description_item_content_title}>
                          インフルエンサーごとの<br className={styles.sponsor_lp_pc_only} />施策効果を可視化
                        </div>
                        <p>アフィリエイトリンクとAPI連携を用いてインフルエンサーごとの効果を可視化します。</p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.maro_description_item}>
                    <div className={styles.maro_description_item_label}>
                      課題2
                    </div>
                    <div className={styles.maro_description_item_title}>
                      PDCAの回し方が<br className={styles.sponsor_lp_pc_only} />わからない
                    </div>
                    <div className={styles.maro_description_item_text}>
                      <div className={styles.maro_description_item_text_title}>
                        運用サポート
                      </div>
                      <div className={styles.maro_description_item_content}>
                        <div className={styles.maro_description_item_content_title}>
                          専任の担当がPDCAサイクルの実行をサポート
                      </div>
                        <p>弊社のスタッフが豊富なノウハウをもとに、施策開始から次の施策に向けた改善案の抽出・実行までサポートします。</p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.maro_description_item}>
                    <div className={styles.maro_description_item_label}>
                      課題3
                    </div>

                    <div className={styles.maro_description_item_title}>
                      沢山インフルエンサーを起用したいが費用が高い
                    </div>
                    <div className={styles.maro_description_item_text}>
                      <div className={styles.maro_description_item_text_title}>
                        プラットフォーム<br />利用料金
                      </div>
                      <div className={styles.maro_description_item_content}>
                        <div className={styles.maro_description_item_content_title}>
                          月額定額のため何人起用しても追加料金は0円
                        </div>
                        <p>定額料金のためトライアンドエラーを重ねることができ、PDCAサイクルを実行しやすい料金体系となっております。</p>
                        <small>※ インフルエンサーへの報酬はプラットフォームの利用料金とは別に必要となります。</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.lp_container_item_width}>
            <div className={styles.func_container}>
              <h2 className={styles.func_title}>主な機能</h2>
              <div className={styles.func_item_container}>
                <div className={styles.func_item}>
                  <img src={FuncPrImg} />
                  <h3 className={styles.func_title}>PR案件の作成・公開</h3>
                  <p className={styles.func_sentence}>ご希望の条件でPR案件を作成し、Maro上に公開いただけます。公開後の案件はMaroに登録済みのインフルエンサー全員が閲覧・応募できる状態となります。</p>
                </div>

                <div className={styles.func_item}>
                  <img src={FuncCheckImg} />
                  <h3 className={styles.func_title}>募集状況のチェック</h3>
                  <p className={styles.func_sentence}>案件への参加者数・応募者数といった応募条件や、商品サンプルの未発送者数・PR未投稿者数といった案件の進行状況をひと目で確認可能です。</p>
                </div>

                <div className={styles.func_item}>
                  <img src={FuncScoutImg} />
                  <h3 className={styles.func_title}>インフルエンサーの検索・スカウト</h3>
                  <p className={styles.func_sentence}>エンゲージメント率やフォロワー属性などのSNS上のデータや過去に実施したPR案件の結果をもとに、インフルエンサーの検索および案件への参加を依頼するスカウトが可能です。</p>
                </div>

                <div className={styles.func_item}>
                  <img src={FuncContactImg} />
                  <h3 className={styles.func_title}>一括承認・発送連絡</h3>
                  <p className={styles.func_sentence}>案件への応募承認や商品発送完了の連絡を一括で行うことができます。</p>
                </div>

                <div className={styles.func_item}>
                  <img src={FuncMessageImg} />
                  <h3 className={styles.func_title}>メッセージ機能</h3>
                  <p className={styles.func_sentence}>インフルエンサーとの連絡をMaro上で行うことが可能です。コミュニケーションの効率化を実現する文章のテンプレート機能も設けています。</p>
                </div>

                <div className={styles.func_item}>
                  <img src={FuncReportImg} />
                  <h3 className={styles.func_title}>分析レポート</h3>
                  <p className={styles.func_sentence}>投稿経由のクリック数・CV数・CPA・広告コストなどの重要指標がレポート化されています。</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.lp_container_item_width}>
            <div className={styles.maro_howto_container}>
              <h2 className={styles.maro_howto_title}>ご利用の流れ</h2>
              <div className={styles.maro_howto_list}>
                <div className={styles.maro_howto_list_item}>
                  <h3 className={styles.maro_howto_list_item_title}>1.募集</h3>
                  <img className={styles.maro_howto_list_item_image} src={Howto1Img} alt="" />
                  <p className={styles.maro_howto_list_item_description}>案件を掲載して<br />インフルエンサーを募集<br /><span>※10分で完了します</span></p>
                </div>
                <div className={styles.maro_howto_list_item}>
                  <h3 className={styles.maro_howto_list_item_title}>2.選考</h3>
                  <img className={styles.maro_howto_list_item_image} src={Howto2Img} alt="" />
                  <p className={styles.maro_howto_list_item_description}>応募者一覧からブランドに合った<br />インフルエンサーを選考</p>
                </div>
                <div className={styles.maro_howto_list_item}>
                  <h3 className={styles.maro_howto_list_item_title}>3.投稿</h3>
                  <img className={styles.maro_howto_list_item_image} src={Howto3Img} alt="" />
                  <p className={styles.maro_howto_list_item_description}>インフルエンサーが投稿します</p>
                </div>

              </div>
            </div>
          </div>

          <div className={styles.lp_container_item_width}>
            <div className={styles.maro_influrncer_list_container}>
              <h2 className={styles.maro_influrncer_list_title}>登録中のインフルエンサー様</h2>
              <ul className={styles.flex}>
                {
                  influencerExampleList.map((influencerExample) => {
                    return <li className={styles.flex_box}>
                      <div className={styles.up}>
                        <p className={styles.left}><img src={influencerExample.iconImg} alt="" /></p>
                        <p className={styles.right}>
                          <span className={styles.name}>{influencerExample.name}</span>
                          <span className={styles.number}>{influencerExample.flw}flw</span>
                        </p>
                      </div>
                      <div className={styles.image_container}>
                        <p className={styles.down}><img src={influencerExample.img0} alt="" /></p>
                        <p className={styles.down}><img src={influencerExample.img1} alt="" /></p>
                      </div>
                    </li>
                  })
                }
              </ul>
            </div>
          </div>

          <div className={styles.lp_container_item_width}>
            <div className={styles.maro_brand_container}>
              <h2 className={styles.maro_brand_title}>導入ブランド様の例</h2>
              <div className={`${styles.sponsor_lp_pc_only} ${styles.maro_brand_item_container}`}>
                <div className={styles.maro_brand_item}><img src={AfloatImg} className={styles.afloat} alt="afloat" /></div>
                <div className={styles.maro_brand_item}><img src={ArtistarImg} className={styles.artistar} alt="artistar" /></div>
                <div className={styles.maro_brand_item}><img src={LutyImg} className={styles.luty} alt="luty" /></div>
                <div className={styles.maro_brand_item}><img src={MauloaImg} className={styles.mauloa} alt="mauloa" /></div>
                <div className={styles.maro_brand_item}><img src={SecretmuseImg} className={styles.secretmuse} alt="secretmuse" /></div>
                <div className={styles.maro_brand_item}><img src={TogenkyoImg} className={styles.togenkyo} alt="togenkyo" /></div>
                <div className={styles.maro_brand_item}><img src={ViluxueuxImg} className={styles.viluxueux} alt="viluxueux" /></div>
              </div>
              <p className={styles.sponsor_lp_sp_only}><img src={BrandLogosImg} alt="" /></p>

              <p className={styles.maro_brand_description}>
                <small>※アルファベット順</small>
              </p>
            </div>
          </div>

          <div className={`${styles.lp_container_item_width} ${styles.price}`}>
            <div className={styles.maro_price_container}>
              <h2 className={styles.maro_price_title}>料金プラン</h2>

              <div className={styles.maro_price}>
                <div className={styles.maro_price_campaign_container}>
                </div>

                <div className={styles.maro_price_plan_list}>
                  <div className={styles.maro_price_plan_list_item}>
                    <p className={styles.maro_price_plan_list_item_name_label}>スタンダード</p>
                    <p className={styles.maro_price_plan_list_item_name}>月額定額</p>
                    <hr />
                    <p className={styles.maro_price_plan_list_item_feature}>特徴</p>
                    <p className={styles.maro_price_plan_list_item_description}>何人起用いただいても追加料金は<br />発生いたしません。</p>
                  </div>

                  <div className={styles.maro_price_plan_list_item}>
                    <p className={styles.maro_price_plan_list_item_name_label}>カスタマイズ</p>
                    <p className={styles.maro_price_plan_list_item_name}>お見積</p>
                    <hr />
                    <p className={styles.maro_price_plan_list_item_feature}>特徴</p>
                    <p className={styles.maro_price_plan_list_item_description}>貴社ビジネスに最適なプランの<br />ご提案いたします。</p>

                    <p className={styles.maro_price_plan_list_item_service_label}>提供可能なサービス</p>
                    <br />

                    <p className={styles.maro_price_plan_list_item_service}>運用フルサポート</p>
                    <br />
                    <p className={styles.maro_price_plan_list_item_service}>LP制作</p>
                    <br />
                    <p className={styles.maro_price_plan_list_item_service}>SNS運用</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.lp_container_item_width}>
            <div className={styles.contact_container}>
              <h2 className={styles.contact_title}>
                利用をご検討中のブランド様へ
              </h2>
              <p>
                ご不明な点はお気軽にお問い合わせください。<br />
                スタッフからサービスについてご説明させて頂きます。
              </p>
              <div className={styles.contact_btn_container}>
                <a href="/contacts/new" className={styles.contact_btn_center}>
                  新規登録/お問い合わせ
                </a>
              </div>
            </div>
          </div>

          <div className={styles.lp_container_item_width}>
            <div className={styles.faq_container}>
              <h2 className={styles.faq_title}>よくあるご質問</h2>
              <div className={styles.faq_item_container}>
                <div className={styles.faq_item}>
                  <img src={QImg} />
                  <div className={styles.faq_item_content}>
                    <h3>成果報酬での依頼は可能ですか？</h3>
                    <p>はい。インフルエンサーへの報酬は「固定報酬」「成果報酬」「商品提供のみ」のいずれかでご設定頂けます。</p>
                  </div>
                </div>
                <div className={styles.faq_item}>
                  <img src={QImg} />
                  <div className={styles.faq_item_content}>
                    <h3>画像の二次利用は可能ですか？</h3>
                    <p>DM機能からインフルエンサーに直接利用可否を聞いて頂き、許可が下りた場合、二次利用も可能です。</p>
                  </div>
                </div>
                <div className={styles.faq_item}>
                  <img src={QImg} />
                  <div className={styles.faq_item_content}>
                    <h3>インフルエンサーの投稿は確認できますか？</h3>
                    <p>はい。インフルエンサーの投稿は全て自動で収集されます。収集された投稿は管理画面上でご確認頂けます。</p>
                  </div>
                </div>
                <div className={styles.faq_item}>
                  <img src={QImg} />
                  <div className={styles.faq_item_content}>
                    <h3>インフルエンサーが利用しているSNSはどれですか？</h3>
                    <p>現在はInstagramを利用しているインフルエンサーにご利用頂いています。今後は他のSNSにも対応予定です。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.lp_container_item_width}>
          <footer className={styles.common_footer}>
            <img src={LogoImg} className={styles.logo} />
            <nav>
              <ul>
                <li><a href="/corporate" target="_blank">会社概要</a></li>
                <li><a href={blogUrl} target="_blank" rel="noopenner nofollow">ブログ</a></li>
                <li><a href={"/contacts/new"} >新規登録/お問い合わせ</a></li>
                <li><a href={"/policy.pdf"} target="_blank">プライバシーポリシー</a></li>
                <li><a href={"/TOSforSponsor.pdf"} target="_blank">利用規約</a></li>
              </ul>
            </nav>

            <small className={styles.footer_copyright}>Copyright © 2020 Seekle inc. All rights reserved.</small>

          </footer>
        </div>
      </div>

      <div className={styles.form_container_sp_open_btn_container} onClick={() => setIsOpen(true)}>
        <div className={styles.form_container_sp_open_btn}>資料ダウンロード（無料）</div>
      </div>

      <div className={`${styles.form_container} ${isOpen ? "" : styles.form_container_sp}`}>
        <div className={styles.form_sticky}>
          <div className={styles.form_title}>
            <div className={styles.sponsor_lp_sp_only} onClick={() => setIsOpen(false)}>◀︎</div>
            <div>サービス資料ダウンロード</div>
            <div></div>
          </div>
          <RailsForm
            action={catalogRequestPath}
            elements={formEmelents}
            remote={true}
          />
        </div>
      </div>
    </div>
  )
}

export default Index
