import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./Form.scss"
import RailsForm from "./../../Shared/RailsForm.js"
import TextField from "./../FormTags/TextField.js"
import Submit from "./../FormTags/Submit.js"

const propTypes = {
  bank_account: PropTypes.object.isRequired,
  method: PropTypes.string.isRequired
}

const Form = (props) => {
  const bank_account = props.bank_account
  const [bankName, setBankName] = useState(bank_account.bank_name)
  const [branchName, setBranchName] = useState(bank_account.branch_name)
  const [accountType, setAccountType] = useState(bank_account.account_type)
  const [number, setNumber] = useState(bank_account.number)
  const [lastName, setLastName] = useState(bank_account.last_name)
  const [firstName, setFirstName] = useState(bank_account.first_name)

  const elements = (
    <div>
      <TextField
        label="銀行名"
        id="bank_account_bank_name"
        name="bank_account[bank_name]"
        value={bankName}
        setState={setBankName}
        required={true}
      />
      { //* TODO セレクトに変える *//
      }
      <TextField
        label="口座種別"
        id="bank_account_account_type"
        name="bank_account[account_type]"
        value={accountType}
        setState={setAccountType}
        required={true}
      />

      <TextField
        label="支店"
        id="bank_account_branch_name"
        name="bank_account[branch_name]"
        value={branchName}
        setState={setBranchName}
        required={true}
      />

      <TextField
        label="口座番号"
        id="bank_account_number"
        name="bank_account[number]"
        value={number}
        setState={setNumber}
        required={true}
      />

      <div className={styles.nameFieldContainer}>
        <TextField
          label="姓"
          id="bank_account_last_name"
          name="bank_account[last_name]"
          value={lastName}
          setState={setLastName}
          required={true}
          className={styles.nameField}
        />
        <TextField
          label="名"
          id="bank_account_first_name"
          name="bank_account[first_name]"
          value={firstName}
          setState={setFirstName}
          required={true}
          className={styles.nameField}
        />
      </div>

      <Submit
        className={styles.largeRoundnessButton}
        text={"つぎへ"}
      />
    </div>
  )

  return (
    <>
      <RailsForm
        action={`/influencer/bank_account`}
        elements={elements}
        patch={props.method === "patch"}
        remote={true}
      />
    </>
  )
}

Form.propTypes = propTypes

export default Form
