import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import Submit from "../FormTags/Submit.js"
import styles from "./New.scss"
import Popup from "../../Application/PopupRules/Popup.js"

const propTypes = {
}

const New = (props) => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [popup, setPopup] = useState('default')
  const [agreePolicy, setAgreePolicy] = useState(false)
  const influencerSignInPath = "/influencer/sign_in"

  const validationForm = () =>{
    if(email && password){
      return true
    }else{
      return false
    }
  }

  const signUpPath =
    props.agency_influencer_registration_link ?
      `/influencer/sign_up?agency_influencer_registration_link_code=${props.agency_influencer_registration_link.code}` :
      `/influencer/sign_up`

  return (
    <div>
      <div className={styles.sns_link_btn_container}>
        <h2>新規アカウント登録</h2>
        <RailsForm
          action={"/influencer"}
          formId="influencer_register_form"
        >
          <TextField
            label="メールアドレス"
            id="influencer_email"
            type="text"
            value={email}
            setState={setEmail}
            name="influencer[email]"
            required={true}
          />
          { props.agency_influencer_registration_link && props.agency_registration_form &&
            <input type="hidden" value={props.agency_influencer_registration_link.code} name="agency_influencer_registration_link_code"/>
          }
          <TextField
            label="パスワード"
            id="influencer_password"
            name="influencer[password]"
            type="password"
            value={password}
            setState={setPassword}
            required={true}
          />

          {agreePolicy === false && <input type="hidden" name="agree_policy" value="0" />}
          {agreePolicy === true && <input type="hidden" name="agree_policy" value="1" />}

          <Submit className={styles.submit_button} text={"登録する"} type={"check_form"} setPopup={setPopup} validationForm={validationForm()} />
        </RailsForm>
        <Popup method={popup} setPopup={setPopup} setAgreePolicy={setAgreePolicy} />
        { props.agency_influencer_registration_link && props.agency_registration_form &&
          <section className={styles.agency_attention_container}>
            <div className={styles.agency_attention}>
              <h2 className={styles.agency_attention_title}>注意事項（登録前にご確認ください）</h2>
              <p className={styles.agency_attention_description}>
                ※ こちらはARTISTAR株式会社（以下「ARTISTAR」）が所属インフルエンサーを募集することを目的としたアカウント登録ページです<br /><br />
                ※ ARTISTARは、所属インフルエンサーの管理およびPR案件の受注増加を目的に、株式会社Maroが提供する「Maro」を利用しています<br /><br />
                ※ インフルエンサーがこちらのフォームを経由して「Maro」に登録した場合、自動的にARTISTAR所属となり、今後Maroに掲載されている案件を受注することにより獲得する金銭報酬の一部（最大20％）がARTISTARに配分されます。ARTISTAR所属となるメリットとしては、ARTISTARと取引のある美容系企業からのお仕事依頼が多く届く可能性が高い点が挙げられます<br /><br />
                ※ ただし、 こちら（ <a href="/for-influencers">https://maro.mu/for-influencers</a> ）のフォームから登録いただけた場合、ARTISTAR所属ではなく一般のインフルエンサーとして登録されます。この場合、金銭報酬を全額受け取ることが可能です
              </p>
            </div>
          </section>
        }
        <a className={styles.sign_in_link} href={influencerSignInPath}>すでにアカウトをお持ちの方はこちら</a>
        { props.agency_influencer_registration_link && !props.agency_registration_form &&
          <a className={styles.sign_in_link} href={signUpPath}>事務所所属登録はこちら</a>
        }
      </div>
    </div>
  )
}

New.propTypes = propTypes

export default New
