import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import styles from "./HashTag.scss"

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  placeholder: PropTypes.string,
}

const defaultProps = {
  value: [],
  onChange: null,
  setState: null,
  placeholder: "",
}

// このコンポーネントはもっと拡張できる
// 詳しくはreact-tag-inputのドキュメントを読めばわかる
const HashTag = (props) => {
  const [tags, setTags] = useState(props.value)

  const inputs =
    tags.map((tag, i) => {
      return (
        <input
          key={i}
          type="hidden"
          id={`${props.id}${i}`}
          name={props.name}
          defaultValue={tag}
        />
      )
    })

  return (
    <div>
      <div className={styles.react_tag_input}>
        <ReactTagInput
          placeholder={props.placeholder}
          tags={tags}
          onChange={(newTags) => (
            setTags(newTags),
            props.setState ? props.setState(newTags) : null
          )}
        />
      </div>
      {inputs}
    </div>
  )
}

HashTag.propTypes = propTypes
HashTag.defaultProps = defaultProps

export default HashTag
