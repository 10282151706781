import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom';

const INTERCOM_APP_ID = 'axv39iqc';
const Content= (props) => {
  const { boot, shutdown, hide, show, update } = useIntercom();

  useEffect(() => {
    boot({
      name: props.sponsor.name,
      email: props.sponsor.email,
      languageOverride: 'ja'
    })

  }, [])

  return (
    <div></div>
  )
}

const Intercom = (props) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Content {...props} />
    </IntercomProvider>
  )
}

export default Intercom
