import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../../Shared/RailsForm.js"
import ImageFile from "../../FormTags/ImageFile.js"
import TextField from "../../FormTags/TextField.js"
import TextArea from "../../FormTags/TextArea.js"
import Submit from "../../FormTags/Submit.js"

const propTypes = {
  sponsor: PropTypes.object.isRequired,
}

const Sponsor = (props) => {
  const [name, setName] = useState(props.sponsor.name)
  const [image, setImage] = useState(props.sponsor.image)
  const [headerImage, setHeaderImage] = useState(props.sponsor.header_image)
  const [introduction, setIntroduction] = useState(props.sponsor.introduction)
  const [homepageAddress, setHomepageAddress] = useState(props.sponsor.homepage_address)
  const sponsorSponsorPath = `/sponsor/settings/${props.sponsor.id}`

  const elements = (
    <>
      <TextField
        label="企業名"
        id="sponsor_name"
        name="sponsor[name]"
        value={name}
        setState={setName}
        required={true}
      />

      <ImageFile
        label="企業アイコン"
        id="sponsor_image"
        name="sponsor[image]"
        value={image}
        setState={setImage}
      />

      <ImageFile
        label="ヘッダー画像"
        id="sponsor_header_image"
        name="sponsor[header_image]"
        value={headerImage}
        setState={setHeaderImage}
      />

      <TextArea
        label="企業紹介"
        id="sponsor_introduction"
        name="sponsor[introduction]"
        value={introduction}
        setState={setIntroduction}
        required={true}
      />

      <TextField
        label="HPのURL"
        id="sponsor_homepage_address"
        name="sponsor[homepage_address]"
        value={homepageAddress}
        setState={setHomepageAddress}
        required={true}
      />

      <Submit text="完了"/>
    </>
  )

  return (
    <RailsForm
      action={sponsorSponsorPath}
      elements={elements}
      multipart={true}
      patch={true}
      remote={true}
    />
  )
}

Sponsor.propTypes = propTypes

export default Sponsor
