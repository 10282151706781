import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./Show.scss"
import Header from "../Shared/SponsorHeader.js"

const Show = () => {
  const sponsorLpPath = `/`

  return (
  <div className={styles.base_container}>
      <div className={styles.lp_container}>
        <div className={styles.lp_container_item_width}>
          <Header logoOnly={true} />

          <div className={styles.container}>
            <h2>Maroにご関心をお寄せいただきありがとうございます。</h2>
            <p>営業担当から1営業日以内にご連絡いたします。</p>
            <a href={"/"} className={styles.btn}>
               トップへ戻る
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Show
