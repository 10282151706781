import "core-js/stable"
import "regenerator-runtime/runtime"
import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import CsrfToken from "./RailsForm/CsrfToken.js"
import ErrorMessages from "./RailsForm/ErrorMessages.js"

const propTypes = {
  action: PropTypes.string.isRequired,
  elements: PropTypes.object,
  className: PropTypes.string,
  get: PropTypes.bool,
  patch: PropTypes.bool,
  put: PropTypes.bool,
  delete: PropTypes.bool,
  multipart: PropTypes.bool,
  remote: PropTypes.bool,
  method: PropTypes.string,
  setCustomErrorMessage: PropTypes.func,
  onSuccess: PropTypes.func,
  idPrefix: PropTypes.string,
  onChange: PropTypes.func
}

const defaultProps = {
  className: "",
  get: false,
  patch: false,
  put: false,
  delete: false,
  multipart: false,
  remote: false,
  method: "post",
  onSuccess: () => {},
  idPrefix: ""
}

const RailsForm = (props) => {
  const [errorMessage, setErrorMessage] = useState("")
  const setCustomErrorMessage = props.setCustomErrorMessage
  const reactFormContainerName = props.formId || `reactFormContainer_${props.action}${props.idPrefix}`
  const [currentOnSuccess, setCurrentOnSuccess] = useState()

  useEffect(() => {
    if (!props.remote) { return }

    const onSuccess = (e) => {
      if(!e) { return }

      if (!isExist(e.detail[2].response)) { return }

      if(props.onSuccess) {
        props.onSuccess(e)
      }

      try {
        const messages = JSON.parse(e.detail[2].response).errors
        if(setCustomErrorMessage) {
          setCustomErrorMessage(messages)
        } else {
          const messageList = <ErrorMessages messages={messages} />
          setErrorMessage(<ul>{messageList}</ul>)
        }
        scrollToTop()
        return false
      } catch (e) {
        // 動作しなくても問題ない箇所なのでエラーを握り潰す
        console.log(e)
      }
    }

    setCurrentOnSuccess(onSuccess)

    // Railsのajax:successイベントを使うため
    document.getElementById(
      reactFormContainerName
    ).addEventListener("ajax:success", onSuccess, true)
  }, [])

  // useEffect(() => {
  //   if (!props.remote) { return }
  //   console.log("updateEventListner")
  //   // Railsのajax:successイベントを使うため
  //   document.getElementById(
  //     reactFormContainerName
  //   ).removeEventListener("ajax:success", currentOnSuccess, true)
  //
  //   const onSuccess = (e) => {
  //     debugger
  //     if(!e) { return }
  //
  //     if (!isExist(e.detail[2].response)) { return }
  //
  //     if(props.onSuccess) {
  //       props.onSuccess(e)
  //     }
  //
  //     try {
  //       const messages = JSON.parse(e.detail[2].response).errors
  //       if(setCustomErrorMessage) {
  //         setCustomErrorMessage(messages)
  //       } else {
  //         const messageList = <ErrorMessages messages={messages} />
  //         setErrorMessage(<ul>{messageList}</ul>)
  //       }
  //       scrollToTop()
  //       return false
  //     } catch (e) {
  //       // 動作しなくても問題ない箇所なのでエラーを握り潰す
  //       console.log(e)
  //     }
  //   }
  //
  //   setCurrentOnSuccess(onSuccess)
  //
  //   document.getElementById(
  //     reactFormContainerName
  //   ).addEventListener("ajax:success", onSuccess)
  // }, [props.onSuccess])

  const scrollToTop = () => {
    const top = 0
    window.scrollTo({
      top,
      behavior: "smooth"
    })
  }

  return (
    <form
      id={reactFormContainerName}
      className={props.className}
      action={props.action}
      encType={props.multipart ? "multipart/form-data" : ""}
      data-remote={props.remote}
      method={props.get || props.method === "get" ? "get" : "post"}
      onChange={props.onChange || (() => {})}
      ref={props.formRef || null}
    >
      {(props.patch || props.method === "patch") && (
        <input type="hidden" name="_method" value="patch" />
      )}

      {(props.put || props.method === "put") && (
        <input type="hidden" name="_method" value="put" />
      )}

      {(props.delete || props.method === "delete") && (
        <input type="hidden" name="_method" value="delete" />
      )}
      
      <CsrfToken />

      {!setCustomErrorMessage && errorMessage && (
        <>
          {errorMessage}
        </>
      )}

      {props.children || props.elements}
    </form>
  )
}

RailsForm.propTypes = propTypes
RailsForm.defaultProps = defaultProps

export default RailsForm
