import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Radio.scss"

const propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  elements: PropTypes.array.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  required: PropTypes.bool,
  styleType: PropTypes.string
}

const defaultProps = {
  className: "",
  setState: null,
  onClick: null,
  onChange: null,
  setState: null,
  required: false,
  styleType: "button"
}

const Radio = (props) => {
  let list = []
  props.elements.forEach((element, index) => {
    const elementId = `${props.id}_${element.value}`
    list.push(
      <div key={index}>
        <input
          type="radio"
          id={elementId}
          name={props.name}
          value={element.value}
          defaultChecked={element.value === props.value}
          onClick={element.onClick}
          onChange={element.onChange}
          onInput={(e) => props.setState ? props.setState(e.target.value) : null}
          required={element.required}
        />
        <label htmlFor={elementId}>{element.label}</label>
      </div>
    )
  })

  // 初期値の表示は調整中(まだ正常に動作するかの検証ができてない)

  const styleType = {
    button: styles.radio_style_button,
    radio: styles.radio_style_radio
  }[props.styleType]
  return (
    <div className={props.className}>
      <label>{props.label}{props.required ? <span className={styles.required}>*</span> : <></>}</label>
      <div className={styleType}>
        {list}
      </div>
    </div>
  )
}

Radio.propTypes = propTypes
Radio.defaultProps = defaultProps

export default Radio
