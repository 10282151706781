import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import MessageList from "./Shared/MessageList.js"

const propTypes = {
  messageRooms: PropTypes.array.isRequired,
}

const Index = (props) => {
  return (
    <MessageList
      messageRooms={props.messageRooms}
    />
  )
}

Index.propTypes = propTypes

export default Index
