import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Square from "../../Shared/Buttons/Square.js"
import Modal from "../../Shared/Modal.js"
import styles from "./AffiliateLinkButton.scss"

const propTypes = {
  project: PropTypes.object.isRequired,
  entry: PropTypes.object
}

const AffiliateLinkButton = (props) => {
  const project = props.project
  const entry = props.entry
  const [affiliateUrl, setAffiliateUrl] =  useState(entry && entry.affiliate_link_url)
  const [modalStatus, setModalStatus] = useState(false)

  const copyToClipboard = (affiliateUrl) => {
    return navigator.clipboard.writeText(affiliateUrl).then(e => {
      setModalStatus(true)
    }).catch((error) => alert("URLのコピーに失敗しました"))
  }

  const copyUrl = () => {
    copyToClipboard(affiliateUrl)
  }

  return (
    <div className={styles.generate_url_container}>
      <p className={styles.message}>こちらのリンクを必ず発行し、投稿に記載してください</p>
      <div onClick={ ()=> copyUrl() }>
        <Square className={styles.generate_url_button} text={'あなた専用のURLをコピー'} />
      </div>
      <p className={styles.url}>専用URL：{affiliateUrl}</p>

      <Modal
        status={modalStatus}
        setState={setModalStatus}
        className={styles.modal}
      >
        <p className={styles.copy_successed_message}>リンクをコピーしました</p>
        <p className={styles.close_button} onClick={() => { setModalStatus(false) }}>閉じる</p>
      </Modal>
    </div>
  )
}

AffiliateLinkButton.propTypes = propTypes

export default AffiliateLinkButton
