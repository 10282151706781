import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Moment from "moment"
import Sticky from '../Shared/Sticky.js'
import Project from "../Shared/Project.js"
import LazyLoad from "../../Shared/LazyLoad.js"
import Square from "../Shared/Buttons/Square.js"
import Slick from "../../Shared/Slick.js"
import styles from "./Show.scss"
import AffiliateLinkButton from "./Show/AffiliateLinkButton.js"
import ScheduleCircle from "./Show/schedule_circle.svg"
import ScheduleLine from "./Show/schedule_line.svg"
import ScheduleLineLong from "./Show/schedule_l_line.svg"

const propTypes = {
  project: PropTypes.object.isRequired,
  entry: PropTypes.object,
  previewMode: PropTypes.bool.isRequired,
  typeVisitId: PropTypes.number.isRequired
}

const Show = (props) => {
  const influencerSponsorsPath = `/influencer/sponsors/${props.project.sponsor_id}`
  const newInfluencerProjectEntryPath = `/influencer/projects/${props.project.id}/entries/new`
  const newInfluencerProjectPostReportPath = `/influencer/projects/${props.project.id}/post_report/new`

  let productImageList = []
  props.project.product.images.forEach((image, index) => {
    productImageList.push(
      <div key={index}><LazyLoad src={String(image)} /></div>
    )
  })

  const copyHashTagsToClipboard = (affiliateUrl) => {
    const hashTagText =
      props.project.project_hashtags.map((tag) => `#${tag.hashtag.name}` ).join(" ")
    return navigator.clipboard.writeText(`${hashTagText}`)
  }

  const UseProjectDetail = () => {
    const ProductOption = () => {
      let optionList = []
      props.project.product.options.forEach((option, index) => {
        optionList.push(
          <p key={index}>{option.name} <span>{option.product_option_variations.map((v) => v.name).join("・")}</span></p>
        )
      })

      if(optionList.length > 0) {
        return (
          <div>
            <p>サイズや色のオプション</p>
            {optionList}
          </div>
        )
      } else {
        return <></>
      }
    }

    return (
      <div>
        <Slick images={productImageList} />
        <div className={styles.product_name}>
          {props.project.product.name}
        </div>
        <p>{props.project.product.description}</p>
        <ProductOption />
      </div>
    )
  }

  const VisitProjectDetail = () => {
    const address = props.project.project_type_visit.address

    return (
      <div>
        <Slick images={productImageList} />
        <div className={styles.product_name}>
          {props.project.product.name}
        </div>
        <p>{props.project.product.description}</p>
        { props.project.product.list_price > 0 &&
          <div className={styles.product_list_price}>
            <p>料金</p>
            <p>¥{props.project.product.list_price.toLocaleString()}</p>
          </div>
        }
        <div>
          <p>交通費支給額</p>
          <p>
            {props.project.project_type_visit.transportation_full_payment && "全額負担" || `${props.project.project_type_visit.transportation_expenses}円まで`}
          </p>
        </div>
        <div>
          <p>拘束時間</p>
          <p>
            {{ 1: "1時間", 2: "2時間", 3: "3時間" }[props.project.project_type_visit.restraint_time]}
          </p>
        </div>
        { address &&
          <div>
            <p>場所</p>
            <p>{`〒${address.postal_code} ${address.prefecture.name}${address.city} ${address.block}${address.building}`}</p>
          </div>
        }
        <div>
          <p>持ち物</p>
          <p>{props.project.project_type_visit.belongings}</p>
        </div>
        <div>
          <p>受付方法</p>
          <p>{props.project.project_type_visit.how_to_accept}</p>
        </div>
        <div>
          <p>お子様の同伴可否</p>
          <p>{props.project.project_type_visit.accompany ? "可" : "不可"}</p>
        </div>
      </div>
    )
  }

  const BuyProjectDetail = () => {
    return (
      <div>
        <div className={styles.product_name}>
          {props.project.product.name}
        </div>
        <Slick images={productImageList} />
        <p>{props.project.product.description}</p>
        <div>
          <p>URL</p>
          <p>
            <a href={props.project.product.url} target="_blank">{props.project.product.url}</a>
          </p>
        </div>
        <div className={styles.product_list_price}>
          <p>定価</p>
          <p>¥{props.project.product.list_price.toLocaleString()}</p>
        </div>
        <div>
          <p>購入できる場所</p>
          <p>{props.project.project_type_buy.ec_site ? "ECサイト" : "店舗"}</p>
        </div>
      </div>
    )
  }

  const AppUseProjectDetail = () => {
    return (
      <div>
        <div className={styles.product_name}>
          {props.project.product.name}
        </div>
        <Slick images={productImageList} />
        <p>{props.project.product.description}</p>
        <div>
          <p>URL</p>
          <p>
            <a href={props.project.product.url} target="_blank">{props.project.product.url}</a>
          </p>
        </div>
        <div className={styles.product_list_price}>
          <p>定価</p>
          <p>¥{props.project.product.list_price.toLocaleString()}</p>
        </div>
      </div>
    )
  }

  const ProjectDetail = () => {
    switch(props.project.project_type_id) {
      case props.typeUseId:
        return <UseProjectDetail />
      case props.typeVisitId:
        return <VisitProjectDetail />
      case props.typeBuyId:
        return <BuyProjectDetail />
      case props.typeAppUseId:
        return <AppUseProjectDetail />
    }
  }

  const ensureInfluencerMode = (e) => {
    if(props.previewMode) {
      e.preventDefault()
      return false
    } else {
      return true
    }
  }

  const calcInfluencerShare = (reward) => {
    const rate =
      props.entry?.reward_distribution_rate ||
      props.influencer?.current_reward_distribution_rate ||
      100

    return Math.round((reward * rate) / 100)
  }

  return (
    <div className={styles.container}>
      <div className={styles.eyecatch_container}>
        <Project project={props.project} previewMode={props.previewMode} detail={true} sponsorLink={true} />
      </div>

      {
        (() => {
          if(!props.entry) {
            return (
              <div className={styles.entry_btn}>
                <Sticky className={styles.sticky}>
                  <a href={newInfluencerProjectEntryPath} onClick={ensureInfluencerMode}>
                    <Square text="応募する" className={styles.sticky_button}/>
                  </a>
                 </Sticky>
              </div>
            )
          } else {
            switch(props.entry.status){
              case "pending":
                return <p className={styles.entry_pending_bar}>参加申請済みです</p>
              case "rejected":
                return <p className={styles.entry_rejected_bar}>参加申請が非承認になりました</p>
              case "shipped":
              case "approve":
                return (
                  <>
                    <AffiliateLinkButton project={props.project} entry={props.entry} />
                    <div>
                      <Sticky className={styles.sticky}>
                        <a href={newInfluencerProjectPostReportPath}>
                          <Square text="投稿報告をする" className={styles.sticky_button}/>
                        </a>
                      </Sticky>
                    </div>
                  </>
                )
              default:
                return (
                  <>
                    <AffiliateLinkButton project={props.project} entry={props.entry} />
                  </>
                )
            }
          }
        })()
      }

      <div className={styles.content_container}>
        <h2>どんな商品・サービス？</h2>
        <ProjectDetail />
      </div>
      <div className={styles.content_container}>
        <h2>こんな方にご協力いただきたいです</h2>
        <p>{props.project.appointment_influencer_statue}</p>
      </div>

      {
        props.project.message &&
        <div className={styles.content_container}>
          <h2>ブランドからのメッセージ</h2>
          <div className={styles.message_balloon}>
            {props.project.message}
          </div>
          <LazyLoad src={String(props.project.message_image)} />
        </div>
      }

      { !props.project.schedule_hidden &&
        <div className={styles.content_container}>
          <h2>スケジュール</h2>
          <div className={styles.schedule_item}>
            <div className={styles.schedule_image_container}>
              <ScheduleCircle className={styles.schedule_image_circle}/>
              <ScheduleLine className={styles.schedule_image_line}/>
            </div>
            <div className={styles.schedule_cotent}>
              <p className={styles.schedule_title}>プロジェクトに応募</p>
            </div>
          </div>

          <div className={styles.schedule_item}>
            <div className={styles.schedule_image_container}>
              <ScheduleCircle className={styles.schedule_image_circle}/>
              <ScheduleLine className={styles.schedule_image_line}/>
            </div>
            <div className={styles.schedule_cotent}>
              <p className={styles.schedule_title}>選考結果の確認</p>
              <p className={styles.schedule_detail}>
                { props.project.approve_all ?
                    "こちらのプロジェクトは即時的に応募が承認されます" :
                    `承認の場合、応募後${props.project.days_to_selection_result}日以内に承認通知が届きます`
                }
              </p>
            </div>
          </div>

          <div className={styles.schedule_item}>
            <div className={styles.schedule_image_container}>
              <ScheduleCircle className={styles.schedule_image_circle}/>
              {
                props.project["type_use?"] || props.project.project_type_id == props.typeVisitId ? <ScheduleLineLong className={styles.schedule_image_line_long}/> : <ScheduleLine className={styles.schedule_image_line}/>
              }
            </div>
            <div className={styles.schedule_cotent}>
              <p className={styles.schedule_title}>投稿</p>
              <p className={styles.schedule_detail}>
                  
                {
                  props.project.fixed_posting_limit_at ?
                  `${props.project.project_type_id != props.typeVisitId ? "商品発送完了" : "サービス利用"}後、${Moment(props.project.fixed_posting_limit_at).format("YYYY年MM月DD日")}までに投稿をお願いします` :
                  `${props.project.project_type_id != props.typeVisitId ? "商品発送完了" : "サービス利用"}後、${props.project.days_to_post}日以内に投稿をお願いします`
                }

              </p>
              {
                props.project["type_use?"] ?
                  <p className={styles.type_use_shipping_message}>※ 応募の承認〜発送までは平均1週間程度です<br />※ 商品到着が遅延している状況においても投稿リマインドが届く可能性がございますが、この場合はリマインドをご放念ください。ご不便をおかけし申し訳ございません。<br />また、商品到着の遅延によるケースを含め期限内での投稿が難しい場合は、大変お手数でございますが、Maro内のメッセージからその旨をスポンサー様にお伝えいただきますようお願い申し上げます。</p> :
                  <></>
              }
              {
                props.project.project_type_id == props.typeVisitId ?
                  <p className={styles.type_use_shipping_message}>※入れ違いにより、まだご訪問されていない状況においても投稿リマインドが届く可能性がございますが、この場合はリマインドをご放念ください。ご不便をおかけし申し訳ございません。<br />また、訪問日が変更になったケースを含め、期限内での投稿が難しい場合は、大変お手数でございますが、Maro内のメッセージからその旨をスポンサー様にお伝えいただきますようお願い申し上げます。</p> :
                  <></>
              }
            </div>
          </div>

          <div className={styles.schedule_item}>
            <div className={styles.schedule_image_container}>
              <ScheduleCircle className={styles.schedule_image_circle}/>
              <ScheduleLine className={styles.schedule_image_line}/>
            </div>
            <div className={styles.schedule_cotent}>
              <p className={styles.schedule_title}>投稿完了報告</p>
              <p className={styles.schedule_detail}>
                投稿完了後、{props.project.days_to_report}日以内に投稿完了の報告をお願いします
              </p>
            </div>
          </div>
        </div>
      }
      { !props.project.project_social_networks_hidden &&
        <div className={styles.content_container}>
          <h2>あなたに投稿してほしいこと</h2>
          {
            props.project.project_social_networks.map((project_social_network, psnIndex) => {
              return project_social_network.project_social_network_posts.map((project_social_network_post, psnpIndex) => {
                return (
                  <div key={`${psnIndex}${psnpIndex}`} className={styles.post_detail_container}>
                    <div className={styles.post_detail_item}>
                      <div className={styles.project_detail_title}>投稿方法</div>
                      <div>{project_social_network_post.social_network_post_types.map((snpt)=>(snpt.name)).join("&")}</div>
                      <div className={styles.project_detail_want}>
                        <div className={styles.project_detail_want_mark}>!</div>
                        <div className={styles.project_detail_want_title}>フォロワーが1万人以上のみなさまへ</div>
                        <div className={styles.project_detail_want_message}>ストーリーズ投稿へのリンク貼付にご協力いただけますと幸いです（必須ではございません）</div>
                      </div>
                    </div>

                    <div className={styles.post_detail_item}>
                      <div className={styles.project_detail_title}>投稿する画像</div>
                      <div>{project_social_network_post.image_description}</div>
                    </div>

                    <div className={styles.post_detail_item}>
                      <div className={styles.project_detail_title}>投稿するテキスト</div>
                      <div>{project_social_network_post.text_description}</div>
                    </div>

                    <div className={styles.post_detail_item}>
                      <div className={styles.project_detail_title}>投稿に含めるハッシュタグ</div>
                      <div className={styles.hashtag_container}>
                        {
                          props.project.project_hashtags.sort((a,b) => {
                            return a.id - b.id
                          }).map((project_hashtag, index) => {
                            return (
                              <div key={index} className={styles.hashtag_item}>
                                #{project_hashtag.hashtag.name}
                              </div>
                            )
                          })
                        }
                      </div>
                      <p className={styles.copy_link} onClick={copyHashTagsToClipboard}>コピーする</p>
                    </div>

                    <div className={styles.post_detail_item}>
                      <div className={styles.project_detail_title}>投稿に含めるアカウント（タグ付け・投稿本文内のメンション）</div>
                      {
                        props.project.account_tags.map((tag) => {
                          return <div>@{tag.name}</div>
                        })
                      }
                    </div>
                  </div>
                )
              })
            })
          }
        </div>
      }
      <div className={styles.content_container}>
        <h2>募集期間</h2>

        <p>
          {Moment(props.project.recruitment_start_at).format("YYYY年MM月DD日")}
          〜
          {Moment(props.project.recruitment_end_at).format("YYYY年MM月DD日")}
        </p>
      </div>

      { !props.project.rewards_hidden &&
        <div className={styles.content_container}>
          <h2>報酬について</h2>
          <ul className={styles.reward_list}>
            {props.project.product.list_price > 0 &&
            <li>
              {props.project.project_type_id == props.typeVisitId ? "サービス" : "商品"}の無料提供（¥{props.project.product.list_price.toLocaleString()}相当）
            </li>
            }
            {props.project.project_achievement_reward &&
            <li>
              成果報酬（1件購入発生につき¥{calcInfluencerShare(props.project.project_achievement_reward.amount).toLocaleString()}円）
            </li>
            }
            {
              props.project.project_social_network_rewards.map((reward) => (
                <li>
                  固定報酬（投稿完了で¥{calcInfluencerShare(reward.amount).toLocaleString()}円）
                </li>
              ))
            }
          </ul>
        </div>
      }

      <div className={styles.content_container}>
        <h2>会社情報</h2>
        <a href={influencerSponsorsPath} className={styles.sponsor_container} onClick={ensureInfluencerMode}>
          <div>
            <div><LazyLoad src={String(props.project.sponsor.image)} /></div>
            <div>{props.project.sponsor.name}</div>
          </div>
          <div><span /></div>
        </a>
      </div>

    </div>
  )
}

Show.propTypes = propTypes

export default Show
