import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState , useEffect} from 'react'
import PropTypes from "prop-types"
import closeIcon from "./icon-close.png"
import styles from "./Template.scss"

const propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

let varlist = [
  { var_name: "i-name", display_name: "インフルエンサー名", variable: document.createElement("span") },
  { var_name: "p-title", display_name: "プロジェクトタイトル", variable: document.createElement("span") },
  { var_name: "sponsor-name", display_name: "企業名", variable: document.createElement("span") },
  { var_name: "staff-name", display_name: "担当者名", variable: document.createElement("span") }
];

let templatelist = { scout: "スカウトテンプレート", approve_request_item: "プロジェクト申請承認テンプレート", item_send_contact: "商品発送テンプレート" }

varlist.forEach(v =>{
  v.variable.classList.add(styles.variable, `var-${v.var_name}`);
  v.variable.setAttribute("contentEditable", "false");
  v.variable.innerHTML = v.display_name;
  let closeimg = document.createElement("img");
  closeimg.classList.add(styles.close_img);
  closeimg.src = closeIcon;
  closeimg.alt = 'close-img';
  v.variable.insertAdjacentHTML('beforeend',closeimg.outerHTML);
});

const Template = (props) => {
  let htmlContent = props.content
  const [rawContent, setRawContent] = useState(htmlContent)

  const toRaw = () => {
    let editor = document.getElementById(props.name).cloneNode(true)
    varlist.forEach(function (v) {
      let editor_elements = editor.querySelectorAll(`.var-${v.var_name}`)
      editor_elements.forEach((ele)=>{
        ele.onclick = () => {ele.parentElement.remove()};
        ele.parentElement.replaceChild(document.createTextNode(`$[${v.var_name}]`), ele);
      })
    });
    setRawContent(editor.innerText)
  }

  const addDeleteEvent = ()=> {
    let close_elements = document.querySelectorAll(`.${styles.close_img}`)
    close_elements.forEach((ele)=>{
      ele.onclick = () => {ele.parentElement.remove()};
    })
  }
  useEffect(()=>{
    addDeleteEvent()
    toRaw()
  })

  const handleInput = (e) => {
    let r = new RegExp(`\\$\\[(${varlist.map(v=>v.var_name).join("|")})\\]`);
    let v;
    if((v = window.getSelection().focusNode.textContent.match(r)) !== null){
      let b = window.getSelection().focusNode;
      console.log(b)
      let range = document.createRange();
      range.setStart(b, v.index);
      range.setEnd(b, v.index+v[0].length);
      range.deleteContents();
      const selection = window.getSelection();
      let variable = varlist.find(e => e.var_name === v[1]).variable.cloneNode(true)
      variable.lastChild.onclick =() =>{
        variable.remove();
      }
      selection.getRangeAt(0).insertNode(variable);
      selection.collapseToEnd();
    }
    toRaw()
  }

  varlist.forEach(v =>{
    let r = new RegExp(`\\$\\[${v.var_name}\\]`, "g");
    let variable = v.variable.cloneNode(true)

    htmlContent = htmlContent.replace(r, variable.outerHTML)
  });

  return (
    <>
      <label>{templatelist[props.name]}</label>
      <div
        contentEditable={true}
        className={styles.template_input}
        id={props.name}
        onInput={handleInput}
        dangerouslySetInnerHTML={{ __html: htmlContent}}>
      </div>
      <input type="hidden" value={rawContent} name={`message_template[${props.name}]`}/>
    </>
  )
}

Template.propTypes = propTypes

export default Template
