import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./StepPage.scss"
import CheckIconImage from "./StepPage/check_icon.svg"
import ArrowImage from "./StepPage/chevron-down.svg"

const CompleteStep = () => {
  return (
    <div className={`${styles.step_container} ${styles.complete_step}`}>
      <div className={styles.step_header}>
        <h1>SNS連携完了</h1>
      </div>

      <div className={styles.complete_step_main}>
        <CheckIconImage className={styles.checkicon}/>
        <h1>連携が完了しました！<br /><br /><span>最後にプロフィールをご登録頂くことで<br />本登録が完了いたします。</span></h1>

        <a href={"/influencer/accounts/new"} className={styles.button}>
          <span>プロフィールを登録</span>
          <ArrowImage className={styles.arrow} />
        </a>
      </div>
    </div>
  )
}

export default CompleteStep
