import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Input from "./SocialNetworks/Input.js"
import styles from "./SocialNetworks.scss"

const propTypes = {
  socialNetwork: PropTypes.object.isRequired,
  sociaNetworkPostTypes: PropTypes.array.isRequired,
  socialNetworkSetCount: PropTypes.func.isRequired,
  isSocialNetworks: PropTypes.bool.isRequired,
  setIsSocialNetworks: PropTypes.func.isRequired,
}

const SocialNetworks = (props) => {
  const [count, setCount] = useState(props.project && props.project.project_social_networks?.length && props.project.project_social_networks[0].project_social_network_posts?.length || 1)
  const [formList, setFormList] = useState([])
  const [form, setForm] = useState(<></>)

  useEffect(() => {
    let tmpFormList = []
    if(props.project && props.project.project_social_networks?.length && props.project.project_social_networks[0].project_social_network_posts?.length) {
      props.project.project_social_networks[0].project_social_network_posts.forEach((v, i) => {
        tmpFormList.push(
          <Input
            key={i}
            no={i}
            socialNetwork={props.socialNetwork}
            sociaNetworkPostTypes={props.sociaNetworkPostTypes}
            setIsSocialNetworks={props.setIsSocialNetworks}
            onChange={props.onChange}
            projectSocialNetworkPost={v}
          />
        )
      })
    } else {
      for (let i = 0; i < count; i++) {
        tmpFormList.push(
          <Input
            key={i}
            no={i}
            socialNetwork={props.socialNetwork}
            sociaNetworkPostTypes={props.sociaNetworkPostTypes}
            setIsSocialNetworks={props.setIsSocialNetworks}
            onChange={props.onChange}
          />
        )
      }
    }

    setFormList(tmpFormList)

    setForm(<>{tmpFormList}</>)
  }, [props.projectId])

  return (
    <div>
      <h3>{props.socialNetwork.name}での投稿内容</h3>

      {formList}
      <div className={styles.add_form_btn_container}>
        {count < 3 && (
          <div
            className={styles.add_form_btn}
            onClick={() => {
              props.setIsSocialNetworks(false)
              setCount(count + 1)
              let input =
                <Input
                  key={formList.length + 1}
                  no={formList.length + 1}
                  socialNetwork={props.socialNetwork}
                  sociaNetworkPostTypes={props.sociaNetworkPostTypes}
                  setIsSocialNetworks={props.setIsSocialNetworks}
                  onChange={props.onChange}
                />

              const newFormList = [...formList, input]
              setFormList(newFormList)
              console.log(newFormList.length)
              //props.socialNetworkSetCount(count + 1)
            }}
          >+ 投稿数を追加する</div>
        )}

        {count > 1 && (
          <div
            className={styles.add_form_btn}
            onClick={() => {
              props.setIsSocialNetworks(true)
              setCount(count - 1)
              const newFormList = formList.slice(0, -1)
              setFormList(newFormList)
              //props.socialNetworkSetCount(count - 1)
            }}
          >+ 最後の投稿を削除する</div>
        )}
      </div>
    </div>
  )
}

SocialNetworks.propTypes = propTypes

export default SocialNetworks
