import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./StepPage.scss"
import QuestionImage from "./StepPage/question_button.svg"
import MaroIconImage from "./StepPage/maro.svg"
import StepNumber from "./StepPage/3.svg"
import CautionImage from "./StepPage/caution.svg"
import Step4_1_Img from "./StepPage/step4_1.png"
import Step4_2_Img from "./StepPage/step4_2.png"
import Step4_3_Img from "./StepPage/step4_3.png"
import Step4_4_Img from "./StepPage/step4_4.png"
import ArrowUnderImage from "./StepPage/chevron-down-under.svg"
import BackButtonImage from "./StepPage/back_button.svg"
import FacebookIconImg from "./StepPage/facebook_icon.svg"
import { Link, animateScroll as scroll } from "react-scroll";

const propTypes = {
}

const ThirdStep = (props) => {
  return (
    <div className={styles.step_container}>
      <div className={styles.step_header}>
        <a onClick={props.onClickBack} className={styles.back_button}>
          <BackButtonImage />
        </a>
        <h1>Facebook連携</h1>
      </div>
      <div className={`${styles.step_top} ${styles.step_3}`}>
        <div className={styles.step_bar_container}>
          <div className={styles.step_bar_4}>
          </div>

          <div className={styles.step_bar_4}>
          </div>

          <div className={styles.step_bar_4}>
          </div>

          <div className={`${styles.step_bar_4} ${styles.active}`}>
          </div>
        </div>

        <div className={styles.question_container}>
          <QuestionImage onClick={props.onClickQuestion} />
        </div>

        <div className={styles.step_main}>
          <div className={styles.step_title}>
            <StepNumber className={styles.step_number} />
            <MaroIconImage  className={styles.maro_icon_image}/>
            <h1>MaroとSNSを連携しましょう！</h1>
          </div>

          <div className={styles.button_container}>
            <a
              href={props.authGraphApiPath}
              className={`${styles.sns_link_btn_item} ${styles.facebook}`}
              >
              <FacebookIconImg className={styles.snsIconImg} />
              Facebook認証をする
            </a>
          </div>
          <div className={styles.button_container}>
            <Link
              to="detail"
              spy={true}
              smooth={true}
              offset={-50}
              duration={600}
              className={`${styles.no_button} ${styles.center}`}>
              <span>Facebook認証の方法を確認</span>
              <ArrowUnderImage className={styles.under_arrow} />
            </Link>
          </div>

          <div className={styles.checkpoint_container}>
            <h2 className={styles.checkpoint_title}><CautionImage className={styles.caution_image}/><span>確認事項</span></h2>
            <p className={styles.checkpoint}>
              ※ インフルエンサー様の許可なくInstagramの情報・投稿がFacebookに公開されることはありません。また、Facebookの情報・投稿がInstagramに公開されることもありません。<br /><br />
              ※ インフルエンサー様の許可なくMaroからInstagaram/Facebookに投稿されることはありません。<br /><br />
              ※ インフルエンサー様のアカウントのインサイト情報がMaroを利用する企業以外の第三者に公開されることはありません。 
            </p>
          </div>
        </div>
      </div>

      <div className={styles.step_what}>
        <h2 id='detail' className={styles.step_what_title}>Facebook認証とは</h2>
        <p className={styles.step_what_content}>
          Facebook認証とは、インフルエンサー様の「Instagram・Facebookアカウント」をMaroに連携するアクションのことです。<br /><br />
          Facebook上で、「Maroと連携するInstagramのビジネス/クリエイターアカウント」と「該当のInstagramアカウントと連携しているFacebookページ」を選択し、認証をおこなうことでMaroとの連携が完了します。
        </p>
      </div>

      <div className={styles.step_detail}>
        <h2>Facebook認証の方法</h2>

        <div className={styles.step_detail_body}>
          <div className={styles.block}>
            <p>1. Facebookとの連携ページに遷移後、「設定を編集」を選択</p>
            <img className={styles.step_image} src={Step4_1_Img} />
          </div>
          <div className={styles.block}>
            <p>2. Maroと連携するInstagramアカウントを選択（1つのみ）</p>
            <p className={styles.caption}>※ ビジネス/クリエイターアカウントで、Facebookページとの連携が完了している必要があります。</p>
            <img className={styles.step_image} src={Step4_2_Img} />
          </div>

          <div className={styles.block}>
            <p>3. 「2」で選択したInstagramアカウントと連携したFacebookページを選択（1つのみ）</p>
            <img className={styles.step_image} src={Step4_3_Img} />
          </div>

          <div className={styles.block}>
            <p>4. 全ての連携設定をオンに「完了」を選択で連携が完了します</p>
            <img className={styles.step_image} src={Step4_4_Img} />
          </div>
        </div>
      </div>
    </div>
  )
}

ThirdStep.propTypes = propTypes

export default ThirdStep
