import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./NextBtn.scss"

const propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const NextBtn = (props) => {
  return (
    <>
      <div className={styles.next_btn}>
        <span onClick={props.onClick}>
          {props.text}
        </span>
      </div>
    </>
  )
}

NextBtn.propTypes = propTypes

export default NextBtn
