import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./New.scss"
import Project from "../Shared/Project.js"
import RailsForm from "../../Shared/RailsForm.js"
import TextArea from "../FormTags/TextArea.js"
import TextField from "../FormTags/TextField.js"
import ImageFile from "../FormTags/ImageFile.js"
import Submit from "../FormTags/Submit.js"

const propTypes = {
  project: PropTypes.object.isRequired,
}

const New = (props) => {
  const [reportMessage, setReportMessage] = useState()
  const [reportUrl, setReportUrl] = useState()
  const [image, setImage] = useState()
  const [images, setImages] = useState()
  const [showImageValidationMessage, setShowImageValidationMessage] = useState()

  const influencerProjectPostReportPath = `/influencer/projects/${props.project.id}/post_report`

  const formElements = (
    <>

    </>
  )

  let urlRequired = false
  let imageRequired = false
  props.project.project_social_networks.forEach((project_social_network) => {
    project_social_network.project_social_network_posts.forEach((project_social_network_post) => {
      project_social_network_post.social_network_post_types.forEach((social_network_post_type) => {
        console.log(social_network_post_type)
        if(social_network_post_type.url_required) {
          urlRequired = true
        }

        if(social_network_post_type.image_required) {
          imageRequired = true
        }
      })
    })
  })

  const onClickSubmit = () => {
    if(!imageRequired || image) {
      return true
    } else {
      setShowImageValidationMessage(true)
      return false
    }
  }

  return (
    <div>
      <h1>投稿報告をする</h1>

      <Project project={props.project} />

      <RailsForm
        action={influencerProjectPostReportPath}
        elements={formElements}
        remote={true}
        className={styles.form}
        multipart={true}
      >
        <TextArea
          label="スポンサーに投稿完了のメッセージを送りましょう（任意）"
          id="post_report_message"
          name="post_report[message]"
          value={reportMessage}
          setState={setReportMessage}
          placeholder="ありがとうございました！投稿完了しました！"
          required={false}
        />

        <TextField
          label={`フィード投稿のURLリンクを貼りましょう${urlRequired ? "（必須）" : "（任意）"}`}
          id="post_report_url"
          name="post_report[post_report_posts_attributes][][url]"
          value={reportUrl}
          setState={setReportUrl}
          required={urlRequired}
        />

        { !props.fetch_instagram_post_available && urlRequired &&
          <ImageFile
            label="投稿画像のスクリーンショットを添付して下さい（必須）"
            id="post_report_images"
            name="post_report[images][]"
            value={images}
            setState={setImages}
            required={true}
            multiple={true}
          />
        }

        <ImageFile
          label={`ストーリーズのスクリーンショットを添付して下さい${imageRequired ? "（必須）" : "（任意）"}`}
          id="post_report_image"
          name="post_report[image]"
          value={image}
          setState={setImage}
          required={imageRequired}
        />
        { showImageValidationMessage && !image && <p className={styles.image_validation_message}>画像を選択してください</p> }

        <Submit text="投稿報告する" onClick={onClickSubmit} />
      </RailsForm>
    </div>
  )
}

New.propTypes = propTypes

export default New
