import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Project from "../Shared/Project.js"
import styles from "./Index.scss"
import LineBannerImg from "../Shared/Images/line_banner.png"

const propTypes = {
  recommendedProjects: PropTypes.array.isRequired,
  popularProjects: PropTypes.array.isRequired,
  add_line_url: PropTypes.string.isRequired
}

const Index = (props) => {
  let recommendedProjectList = []
  props.recommendedProjects.forEach((project, index) => {
    recommendedProjectList.push(
      <div key={index}>
        <Project project={project} smallStyle={true} />
      </div>
    )
  })

  let popularProjectList = []
  props.popularProjects.forEach((project, index) => {
    popularProjectList.push(
      <div key={index}>
        <Project project={project} />
      </div>
    )
  })

  return (
    <div>
      <a href={props.add_line_url} >
        <img src={LineBannerImg} />
      </a>
      <div className={styles.projects_container}>
        <h2>すべてのプロジェクト</h2>
        <div className={styles.recommended_projects}>
          {recommendedProjectList}
        </div>
        <a href={`/influencer/projects?order=recommend`}>
          すべて見る
        </a>
      </div>

      <div className={styles.projects_container}>
        <div className={styles.projects_container_header}>
          <h2>人気のプロジェクト</h2>
        </div>
        {popularProjectList}
        <a href={`/influencer/projects?order=popular`}>
          すべて見る
        </a>
      </div>
    </div>
  )
}

Index.propTypes = propTypes

export default Index
