import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import ErrorMessages from "../../Shared/RailsForm/ErrorMessages.js"
import AccountForm from "../Shared/AccountForm.js"
import styles from "./New.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
}

const defaultProps = {
  errors: [],
}

const New = (props) => {
  const staffRegistrationPath = `/staff`

  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <RailsForm
          action={staffRegistrationPath}
        >
          <h2 className={styles.form_title}>アカウント作成</h2>

          <AccountForm errors={props.errors} staff={props.staff} />
        </RailsForm>
      </div>
    </div>
  )
}

New.propTypes = propTypes
New.defaultProps = defaultProps

export default New
