import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import RoundBtn from "../../Shared/Buttons/Round.js"
import SwitchBtn from "../../Shared/Buttons/Switch.js"
import Layout from "./Shared/Layout.js"
import LazyLoad from "../../../Shared/LazyLoad.js"
import CheckboxBool from "../../FormTags/CheckboxBool"
import Modal from "../../Shared/Modal"
import fetchProjectEntry from "./fetchEntry.js"
import InstagramIcon from "../../Shared/Icons/monochrome_instagram_icon.png"
import TwitterIcon from "../../Shared/Icons/monochrome_twitter_icon.png"
import Message from "./Shared/Message.js"
import InfluencerDetailModal from "./../../Shared/InfluencerDetailModal.js"

import styles from "./Request.scss"

const propTypes = {
  entries: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired
}

const Request = (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`
  const [checkedEntries, setCheckEntries] = useState({})
  const [modalStatus, setModalStatus] = useState(false);
  const [postEntryStatus, setPostEntryStatus] = useState("");
  const [loadingImageStatus, setLoadingImageStatus] = useState(false);
  const statusPendings = "pendings"
  const statusApproves = "approves"
  const statusRejects = "rejects"
  const [entries, setEntries] = useState({ [statusPendings]: [], [statusApproves]: [], [statusRejects]: [] })
  const [currentStatus, setCurrentStatus] = useState(statusPendings)
  const [influencerId, setInfluencerId] = useState(null)
  const [influencerDetailModalStatus, setInfluencerDetailModalStatus] = useState(false)

  useEffect(() => {
    fetchEntry(statusPendings)
  }, [])

  const fetchEntry = (status) => {
    fetchProjectEntry({
      status: status,
      setEntries: setEntries,
      loadStart: () => { setLoadingImageStatus(true) },
      loadEnd: () => {
        setLoadingImageStatus(false)
      }
    })
  }

  const selectEntry = (e) => {
    setCheckEntries({
      ...checkedEntries,
      [e.target.id]: e.target.checked
    })
  }

  const filteredEntries = () => {
    var result = []
    Object.keys(checkedEntries).map((key) => {
      if (checkedEntries[key] == true) {
        result.push(key)
      }
    })
    return result
  }

  const confirmEntriesStatus = (status) => {
    setPostEntryStatus(status)
    setModalStatus(true)
  }

  const postEntriesStatus = (status) => {
    let postUrl = ""
    if (status == "approve") {
      postUrl = `${currentPageUrl}/entries/pendings/approve`
    } else if (status == "reject") {
      postUrl = `${currentPageUrl}/entries/pendings/reject`
    } else {
      return
    }
    fetch(postUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: JSON.stringify({
        "entries": filteredEntries()
      }),
    }).then(() => {
      props.updatePanelCount()
      fetchEntry(currentStatus)
    }).catch((error) => {
      console.log(error)
    })
    setCheckEntries({})
    setModalStatus(false)
  }

  const topLeftButtons = ([
    <SwitchBtn
      elements={[
        {
          text: `未承認${props.pendingCount > 0 ? `(${props.pendingCount})` : ""}`,
          selected: true,
          onClick: () => {
            fetchEntry(statusPendings)
            setCurrentStatus(statusPendings)
          }
        },
        {
          text: `承認${props.approvedCount > 0 ? `(${props.approvedCount})` : ""}`,
          onClick: () => {
            fetchEntry(statusApproves)
            setCurrentStatus(statusApproves)
          }
        },
        {
          text: `非承認${props.rejectedCount > 0 ? `(${props.rejectedCount})` : ""}`,
          onClick: () => {
            fetchEntry(statusRejects)
            setCurrentStatus(statusRejects)
          }
        }
      ]}
    />
  ])

  const topRightButtons = []

  if (!props.project.approve_all) {
    Array.prototype.push.apply(topRightButtons, [
      <RoundBtn
        text="非承認にする"
        type="check"
        color="main-border"
        onClick={() => confirmEntriesStatus("reject")}
      />,
      <RoundBtn
        text="承認する"
        type="check"
        color="main"
        onClick={() => confirmEntriesStatus("approve")}
      />
    ])
  }

  const entryList = entries[currentStatus].length ? [
    <tr key="thead">
      <th>インフルエンサー</th>
      <th>カテゴリー</th>
      <th>SNS</th>
      <th>総フォロワー</th>
      <th>エンゲージメント率</th>
      <th>応援メッセージ</th>
      <th>メッセージ</th>
    </tr>
  ] : []

  entries[currentStatus].forEach((entry, index) => {
    const influencer = entry.influencer
    const snsList = influencer.social_network_accounts.map((v) => v.social_network_name )

    const influencerCategories = (influencerCategories) => {
      let categories = []
      influencerCategories.forEach((category, index) => {
        if(category.parent && category.parent.mainly_displayed){
          if(categories[category.parent.id]){
            categories[category.parent.id].sub_categories.push(category.name)
          }else{
            categories[category.parent.id] = {
              sub_categories: [category.name],
              parent_name: category.parent.name
            }
          }
        }else if(category.mainly_displayed){
          if(!categories[category.id]){
            categories[category.id] = {
              sub_categories: [],
              parent_name: category.name
            }
          }
        }
      })
      categories = categories.filter(v => v)
      let categoryList = []
      categories.forEach((cate, index) =>{
        categoryList.push(<div key={index}>
          <div className={styles.influencer_category_parent}>{cate.parent_name}</div>
          { cate.sub_categories.length && <div className={styles.influencer_category}>{cate.sub_categories.join("・")}</div> }
        </div>)
      })
      return categoryList
    }

    entryList.push(
      <tr key={entry.id}>
        <td>
          <div className={styles.influencer_name_column}>
            <div className={styles.influencer_checkbox}>
              {entry.status == "pending" && (
                <CheckboxBool
                  id={entry.id}
                  name="entries"
                  className={styles.influencer_checkbox}
                  value={false}
                  onChange={selectEntry}
                />
              )}
            </div>
            <div
              className={styles.influencer_name}
              onClick={(e) => (
                setInfluencerId(influencer.id),
                setInfluencerDetailModalStatus(true)
              )}
            >
              <div className={styles.influencer_icon}>
                <LazyLoad src={String(influencer.profile_pic)}/>
              </div>
              <p>
                {influencer.name}
              </p>
            </div>
          </div>
        </td>
        <td className={styles.category_column}>{influencerCategories(influencer.categories)}</td>
        <td className={styles.sns_column}>
          {snsList.includes("Instagram") ? <a href={`https://www.instagram.com/${influencer.instagram_user_name}/`} target="_blank"><img src={InstagramIcon} className={styles.sns_icon} /></a> : <></>}
          {snsList.includes("Twitter") ? <img src={TwitterIcon} className={styles.sns_icon} /> : <></>}
        </td>
        <td className={`${styles.count_column} ${styles.center} ${styles.bold}`}>
          {influencer.followed_by_count ? influencer.followed_by_count.toLocaleString() : "-"}
        </td>
        <td className={`${styles.count_column} ${styles.center} ${styles.bold}`}>
          {influencer.average_engagement_rate ?
            <p>{influencer.average_engagement_rate}<span className={styles.count_column_unit}>%</span></p> :
            "-"
          }
        </td>
        <td className={styles.influencer_message_column}>{entry.influencer_message}</td>
        <td><Message influencerId={influencer.id} /></td>
      </tr>
    )
  })

  return (
    <div>
      <Layout
        topLeftButtons={topLeftButtons}
        topRightButtons={topRightButtons}
        table={entryList}
        loadingImageStatus={loadingImageStatus}
      />
      <Modal
        status={modalStatus}
        setState={setModalStatus}
      >
        <div className={`${styles.modal_btn_container}`}>
          <div>{filteredEntries().length} 件のステータスを変更しますか？</div>
          <div className={styles.modal_btn_area}>
            {postEntryStatus == "approve" && (
              <RoundBtn
                text="承認する"
                type="check"
                color="main"
                onClick={() => postEntriesStatus("approve")}
              />
            )}
            {postEntryStatus == "reject" && (
              <RoundBtn
                text="非承認にする"
                type="check"
                color="main-border"
                onClick={() => postEntriesStatus("reject")}
              />
            )}
          </div>
        </div>
      </Modal>

      <InfluencerDetailModal
        influencerId={influencerId}
        influencerDetailModalStatus={influencerDetailModalStatus}
        setInfluencerDetailModalStatus={setInfluencerDetailModalStatus}
      />
    </div>
  )
}

Request.propTypes = propTypes

export default Request
