import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import styles from "./Navigation.scss"
import HomeIconImg from "./Navigation/homeIcon.svg"
import ProjectIconImg from "./Navigation/projectIcon.svg"
import MessageIconImg from "./Navigation/messageIcon.svg"
import RewardIconImg from "./Navigation/rewardIcon.svg"
import MypageIconImg from "./Navigation/mypageIcon.svg"

const Navigation = () => {
  const influencerRootPath = `/influencer`
  const influencerMessagesPath = `/influencer/messages`
  const influencerMypagePath = `/influencer/mypage`
  const influencerReportsPath = `/influencer/reports`
  const influencerEntriesPath = `/influencer/entries`
  const current_url = location.pathname
  const activeClass = (path) => ( current_url === path ? styles.currentMenu : "" )

  return (
    <div>
      <div className={styles.container}>
        <a href={influencerRootPath} className={activeClass(influencerRootPath)}>
          <HomeIconImg />
          <span>ホーム</span>
        </a>
        <a href={influencerEntriesPath} className={activeClass(influencerEntriesPath)}>
          <ProjectIconImg />
          <span>プロジェクト</span>
        </a>
        <a href={influencerMessagesPath} className={activeClass(influencerMessagesPath)}>
          <MessageIconImg className={styles.messageIcon} />
          <span>メッセージ</span>
        </a>
        <a href={influencerReportsPath} className={activeClass(influencerReportsPath)}>
          <RewardIconImg />
          <span>報酬管理</span>
        </a>
        <a href={influencerMypagePath} className={activeClass(influencerMypagePath)}>
          <MypageIconImg />
          <span>マイページ</span>
        </a>
      </div>
    </div>
  )
}

export default Navigation
