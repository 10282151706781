import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import styles from "./New.scss"
import LogoImg from "./New/logo.png"
import RailsForm from "../../Shared/RailsForm.js"
import Header from "../Shared/SponsorHeader.js"
import TextField from "../FormTags/TextField.js"
import Radio from "../FormTags/Radio.js"
import TextArea from "../FormTags/TextArea.js"
import Submit from "../FormTags/Submit.js"

const New = () => {
  const [isOpen, setIsOpen] = useState(false)
  const catalogRequestPath = `/contacts`
  const influencerLpPath = `/for-influencers`
  const blogUrl = `https://blog.maro.mu/`
  const loginPath = `/staff/sign_in`
  const mailAddress = `mailto:info2@maro.mu`
  const zenDeskUrl = `https://maro-support.zendesk.com/hc/ja`

  const [companyName, setCompanyName] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [companyUrl, setCompanyUrl] = useState();
  const [proposalPosition, setProposalPosition] = useState("自社導入");
  const [examinationStatus, setExaminationStatus] = useState("登録したい");
  const [consultationMessage, setConsultationMessage] = useState();

  return (
    <div className={styles.base_container}>
      <div className={styles.lp_container}>
        <div className={styles.lp_container_item_width}>
          <Header />

          <div className={styles.form_container}>
            <div className={styles.form_header}>
              <h2>新規登録/お問い合わせ</h2>
              <p>営業担当から1営業日以内にご連絡いたします。</p>
            </div>
            <RailsForm
              action={catalogRequestPath}
              remote={true}
            >
              <div className={styles.form}>
                <input type='hidden' name="catalog_request[contact_type]" value="新規登録/問い合わせ" />
                <TextField
                  label="会社名"
                  placeholder="株式会社◯◯"
                  id="company-name"
                  name="catalog_request[company_name]"
                  value={companyName}
                  setState={setCompanyName}
                  required={true}
                />

                <TextField
                  label="氏名"
                  placeholder="山田 まろ"
                  id="name"
                  name="catalog_request[last_name]"
                  value={name}
                  setState={setName}
                  required={true}
                />

                <TextField
                  label="メールアドレス"
                  placeholder="maro@maro.mu"
                  id="email"
                  name="catalog_request[company_email]"
                  value={email}
                  setState={setEmail}
                  required={true}
                />

                <TextField
                  label="電話番号"
                  placeholder="08012345678"
                  id="phone_number"
                  name="catalog_request[phone_number]"
                  value={phoneNumber}
                  setState={setPhoneNumber}
                  required={true}
                />

                <TextField
                  label="会社ホームページ"
                  placeholder="https://maro.mu"
                  id="company_url"
                  name="catalog_request[company_url]"
                  value={companyUrl}
                  setState={setCompanyUrl}
                  required={true}
                />

                <Radio
                  label="ご利用目的"
                  id="proposal_position"
                  name="catalog_request[proposal_position]"
                  elements={[
                    {
                      label: "自社導入",
                      value: "自社導入"
                    },
                    {
                      label: "代理販売",
                      value: "代理販売"
                    }
                  ]}
                  value={proposalPosition}
                  setState={setProposalPosition}
                  direction="row"
                  styleType="radio"
                  required={true}
                />
                <Radio
                  label="ご検討状況"
                  id="examination_status"
                  name="catalog_request[examination_status]"
                  elements={[
                    {
                      label: "登録したい",
                      value: "登録したい"
                    },
                    {
                      label: "まずは話を聞きたい",
                      value: "まずは話を聞きたい"
                    },
                    {
                      label: "その他",
                      value: "その他"
                    }
                  ]}
                  value={examinationStatus}
                  setState={setExaminationStatus}
                  direction="row"
                  styleType="radio"
                  required={true}
                />
                <TextArea
                  label="ご相談・ご質問"
                  id="consultation_message"
                  name="catalog_request[consultation_message]"
                  placeholder="ご自由にご記入ください"
                  value={consultationMessage}
                  setState={setConsultationMessage}
                />

                <Submit text="送信する" className={styles.submit} />
              </div>
            </RailsForm>
          </div>
        </div>
      </div>
    </div>
  )
}

export default New
