import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./MessageList.scss"
import LazyLoad from "../../../Shared/LazyLoad";
import FetchMessageRooms from "./FetchMessageRooms.js"

const propTypes = {
}

const MessageList = (props) => {
  const messageRooms = props.messageRooms
  const setMessageRooms = props.setMessageRooms
  const sponsorMessageRoomPath = `/sponsor/messages`

  useEffect(() => {
    FetchMessageRooms({ setMessageRooms: setMessageRooms, loadStart: ()=>{}, loadEnd: ()=>{}  })
  }, [])

  const fetchMessageRoom = (messageRoomId) => {
    props.setLoadingImageStatus(true)
    fetch(`/sponsor/message_rooms/${messageRoomId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => {
      const jsonData = response.json()
      return jsonData
    }).then((jsonData) => {
      props.setMessageRoom(jsonData.message_room)
      props.setEntries(jsonData.entries)
      props.setLoadingImageStatus(false)
    }).catch((error) => {
      console.log(error)
    })
  }

  const onClickMessageRoom = (messageRoomId) => {
    fetchMessageRoom(messageRoomId)
  }

  let messageList = []
  messageRooms.forEach((room, index) => {
    messageList.push(
      <a
        key={index}
        className={styles.message_open_link}
        onClick={() => { onClickMessageRoom(room.id) }}
      >
        <div className={`${styles.message_pair} ${room.sponsor_unread_count > 0 ? styles.unread_message : ''}`}>
          <div className={styles.message_pair_icon}>
            <LazyLoad src={String(room.influencer.profile_pic)}/>
          </div>
          <div className={styles.message_pair_info}>
            <div className={styles.message_pair_name}>{room.influencer.name}</div>
            <div className={styles.truncate_message}>{room.truncate_latest_message}</div>
          </div>
          {room.sponsor_unread_count > 0 ? <span className={styles.unread_message_mark} /> : ''}
        </div>
      </a>
    )
  })

  return (
    <div className={styles.message_list}>
      <div className={styles.message_list_header}><span>メッセージ一覧</span></div>
      <div className={styles.message_pairs}>
        {messageList}
      </div>
    </div>
  )
}

MessageList.propTypes = propTypes


export default MessageList
