import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import styles from "./Tooltip.scss"
import PropTypes from "prop-types"
import QuestionPng from "./Tooltip/question.png"
import ReactTooltip from 'react-tooltip'

const propTypes = {
  tip: PropTypes.any.isRequired
}
const Tooltip = (props) => {
  return <span data-tip={props.tip}>
    <img src={QuestionPng} className={styles.question_mark} />
    <ReactTooltip effect="solid" place="right" backgroundColor="white" textColor="#4F4F4F" />
  </span>
}

Tooltip.propTypes = propTypes

export default Tooltip
