import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Select.scss"

const propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  required: PropTypes.bool,
  prompt: PropTypes.any
}

const defaultProps = {
  className: "",
  value: "",
  onClick: null,
  onChange: null,
  setState: null,
  required: false,
  prompt: false
}

const Select = (props) => {
  let list = []
  props.options.forEach((option, index) => {
    list.push(
      <option
        key={index}
        value={option.value}
      >{option.name}</option>
    )
  })


  if(props.prompt) {
    if(props.prompt === true) {
      list.unshift(
        <option
          key={list.length}
          value={null}
        >選択してください</option>
      )
    } else {
      list.unshift(
        <option
          key={list.length}
          value={null}
        >{props.prompt}</option>
      )
    }
  }

  return (
    <div className={props.className}>
      { props.label && props.label.length &&
        <label>{props.label}</label> }
      <div className={styles.select}>
        <select 
          id={props.id}
          name={props.name}
          value={props.value}
          onClick={props.onClick}
          onChange={props.onChange}
          onInput={(e) => props.setState ? props.setState(e.target.value) : null}
          required={props.required}
          className={props.prompt && !props.value ? styles.prompt : ""}
        >{list}</select>
      </div>
    </div>
  )
}

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select
