import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Modal from "../../Shared/Modal.js"
import styles from "./ScoutModal.scss"
import RailsForm from "../../../Shared/RailsForm.js"
import Select from "../../FormTags/Select.js"
import TextArea from "../../FormTags/TextArea.js"
import Submit from "../../FormTags/Submit.js"
import RoundBtn from "../../Shared/Buttons/Round.js"

const propTypes = {
  selectedInfluencerIds: PropTypes.array.isRequired,
  scoutModalStatus: PropTypes.bool.isRequired,
  setScoutModalStatus: PropTypes.func.isRequired,
}

const ScoutModal = (props) => {
  const [projects, setProjects] = useState([])
  const [selectedProjectIds, setSelectedProjectIds] = useState([])
  const [scoutMessage, setScoutMessage] = useState("")
  const [projectId, setProjectId] = useState()
  const [completeModalStatus, setCompleteModalStatus] = useState();
  const [completeModalMessage, setCompleteModalMessage] = useState();

  useEffect(() => {
    fetchProjects()
    fetchMessageTemplates()
  }, [])

  const fetchProjects = () => {
    const sponsorProjectsPath = `${window.location.origin}/sponsor/projects/in_recruitments`

    fetch(sponsorProjectsPath, {
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      setProjects(jsonData.items)
    }).catch((error) => {
      console.log(error)
    })
  }

  const fetchMessageTemplates = () => {
    const sponsorMessageTemplatesPath = `${window.location.origin}/sponsor/message_template`

    fetch(sponsorMessageTemplatesPath , {
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      if(jsonData.message_templates.scout) {
        setScoutMessage(jsonData.message_templates.scout)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const projectElements =
    projects.map((project, key) => {
      return (
        {
          name: project.title,
          value: project.id,
        }
      )
    })

  const onClickSubmit = () => {
    fetch("/sponsor/scouts", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: JSON.stringify({
        "scouts_form": {
          influencer_ids: props.selectedInfluencerIds,
          project_id: projectId,
          message: scoutMessage
        }
      }),
    }).then((response) => {
      props.setScoutModalStatus(false)
      setCompleteModalStatus(true)

      if(response.status === 200) {
        setCompleteModalMessage("スカウトを送信しました")
        props.setSelectedInfluencerIds([])
      } else {
        setCompleteModalMessage("スカウトの送信に失敗しました")
      }

    }).catch((error) => {
      props.setScoutModalStatus(false)
      setCompleteModalMessage("スカウトの送信に失敗しました")
      setCompleteModalStatus(true)
      console.log(error)
    })
  }

  return (
    <>
      <Modal
        status={props.scoutModalStatus}
        setState={props.setScoutModalStatus}
        position="center"
      >
        <div>
          {
            props.selectedInfluencerIds.map((influencerId, key) => {
              return (
                <input
                  type="hidden"
                  id={`scouts_form_influencer_id_${influencerId}`}
                  name="scouts_form[influencer_ids][]"
                  value={influencerId}
                  key={key}
                />
              )
            })
          }
          <h1>依頼するプロジェクトを選択してください</h1>
          <Select
            label=""
            name="scouts_form[project_id]"
            className={styles.project_select}
            options={projectElements}
            value={projectId}
            setState={setProjectId}
            required={true}
            prompt={true}
          />
          <h1>インフルエンサーへのメッセージ</h1>

          <TextArea
            label=""
            id="scout_message"
            name="scouts_form[message]"
            value={scoutMessage}
            setState={setScoutMessage}
            required={true}
          />
          <p>※ 自動でメッセージが送信されます</p>

          <Submit text={"スカウトを送る"} onClick={onClickSubmit} />
        </div>
      </Modal>
      <Modal
        status={completeModalStatus}
        setState={setCompleteModalStatus}
      >
        <div className={`${styles.modal_btn_container}`}>
          <div>{completeModalMessage}</div>
          <div className={styles.modal_btn_area}>
            <RoundBtn
              text="閉じる"
              type="check"
              color="main"
              onClick={() => setCompleteModalStatus(false) }
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

ScoutModal.propTypes = propTypes

export default ScoutModal
