import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Moment from "moment"
import PrevArrowImg from "./prev_arrow.png"
import NextArrowImg from "./next_arrow.png"
import styles from "./Index.scss"

const propTypes = {
  remain: PropTypes.number.isRequired,
  monthlyReport: PropTypes.object.isRequired
}

const Index = (props) => {
  const currentMonth = Moment(new Date(props.monthlyReport.year, props.monthlyReport.month))

  return (
    <div className={styles.container}>
      <h1>報酬管理</h1>
      <div className={styles.remainContainer}>
        <p className={styles.remainTitle}>報酬残高</p>
        <div className={styles.remainTotalContainer}>
          <p className={styles.remain}>{props.remain.toLocaleString()}</p>
          <a className={styles.withdrawButton} href="/influencer/bank_account/new">
            <span className={styles.withdrawCheckMark}>✔︎</span>
            <span className={styles.withdrawTitle}>引き出す</span>
          </a>
        </div>
      </div>

      <div className={styles.pager}>
        <a href={`/influencer/reports?year=${currentMonth.add('months', -1).format("YYYY")}&month=${currentMonth.add('months', -1).format("MM")}`}>
          <img src={PrevArrowImg} className={styles.pagerArrow} />
        </a>
        <p className={styles.pagerTitle}>{`${props.monthlyReport.month}月の報酬`}</p>
        <a href={`/influencer/reports?year=${currentMonth.add('months', 1).format("YYYY")}&month=${currentMonth.add('months', 1).format("MM")}`}>
          <img src={NextArrowImg} className={styles.pagerArrow} />
        </a>
      </div>

      <div className={styles.reportTable}>
        <div className={styles.reportTableHeader}>
          <div className={styles.reportTableRow}>
            <p className={styles.reportProjectTitleColumn}>案件名</p>
            <p className={styles.reportCountColumn}>購入件数</p>
            <p className={styles.reportStatusColumn}>ステータス</p>
            <p className={styles.reportAmountColumn}>報酬額</p>
          </div>
        </div>
        <div className={styles.reportTableBody}>
          {
            props.monthlyReport.reports.map((report, index) => {
              return (
                <div className={styles.reportTableRow}>
                  <p className={styles.reportProjectTitleColumn}>{report.name}</p>
                  <p className={styles.reportCountColumn}>{report.reward_type == "achievement" ? `${report.num}件` : "-"}</p>
                  <p className={styles.reportStatusColumn}>{report.reward_type == "achievement" ? report.status : "-"}</p>
                  <p className={styles.reportAmountColumn}>{report.total}円</p>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className={styles.totalContainer}>
        <p>合計</p>
        <p className={styles.total}>{props.monthlyReport.total.toLocaleString()}円</p>
      </div>
    </div>
  )
}

Index.propTypes = propTypes

export default Index
