import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./MessagePairProject.scss"

const propTypes = {
  entries: PropTypes.array.isRequired,
}

const MessagePairProject = (props) => {
  const entryies = props.entries

  const entryStatus = (status) => {
    switch (status) {
      case "reported":
        return "プロジェクト完了"
      case "shipped":
        return "投稿完了報告待ち"
      case "approve":
        return "発送待ち"
      case "rejected":
        return "非承認"
      case "pending":
        return "承認待ち"
    }
  }
  let entryList = []
  entryies.forEach((entry, index) => {
    const SponsorProjectPath = `/sponsor/projects/${entry.project.id}`
    entryList.push(
      <React.Fragment key={index}>
        <span className={styles.project_status}>
          {entryStatus(entry.status)}
        </span>
        <a href={SponsorProjectPath} className={styles.project_title} target="_blank">{entry.project.title}</a>
      </React.Fragment>
    )
  })

  const scrollTop = () => {
    let target = document.getElementById("entry-list")
    target.scroll({
      top: 0
    })
  };

  useEffect(() =>
    scrollTop()
  , [props.entries])

  return (
    <div className={styles.message_pair_project}>
      <div className={styles.message_pair_project_header}>参加中のプロジェクト</div>
      <div className={styles.message_pair_project_content} id="entry-list">
        {entryList}
      </div>
    </div>
  )
}

MessagePairProject.propTypes = propTypes


export default MessagePairProject
