import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import ReactTooltip from 'react-tooltip';
import RoundBrn from "../Shared/Buttons/Round.js"
import Logo from "./SideMenu/logo.png"
import styles from "./SideMenu.scss"
import HomeIconImg from "./SideMenu/homeIcon.svg"
import ProjectIconImg from "./SideMenu/projectIcon.svg"
import MessageIconImg from "./SideMenu/messageIcon.svg"
import AnalyticsIconImg from "./SideMenu/analyticsIcon.svg"
import ScoutIconImg from "./SideMenu/scoutIcon.svg"

const propTypes = {
  open: PropTypes.bool.isRequired,
}

const defaultProps = {
  open: false,
}

const SideMenu = (props) => {
  const sponsorRootPath = `/sponsor`
  const sponsorProjectsPath = `/sponsor/projects`
  const sponsorScoutsPath = `/sponsor/scouts`
  const newSponsorProjectPath = `/sponsor/projects/new`
  const sponsorMessagesPath = `/sponsor/messages`
  const [unreadMessageCount, setUnreadMessageCount] = useState(null);
  const [sponsor, setSponsor] = useState(null)

  useEffect(() => {
    fetchSponsor()
    fetchUnreadMessageCount()
  }, [])

  const fetchSponsor = () => {
    fetch(`/sponsor/sponsor`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => {
      const jsonData = response.json()
      return jsonData
    }).then((jsonData) => {
      setSponsor(jsonData.sponsor)
    }).catch((error) => {
      console.log(error)
    })
  }

  const fetchUnreadMessageCount = () => {
    fetch(`/sponsor/unread_messages_count`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => {
      const jsonData = response.json()
      return jsonData
    }).then((jsonData) => {
      setUnreadMessageCount(jsonData.count)
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logo_container}>
          <a href={sponsorRootPath}>
            <div className={styles.item}>
              <img
                src={Logo}
                className={styles.logo}
              />
            </div>
          </a>
        </div>

        { sponsor &&
          <>
            <a href={sponsorRootPath}>
              <div className={`${styles.item} ${window.location.pathname == sponsorRootPath ? styles.active : ""}`} data-tip="ホーム">
                <HomeIconImg />
                {props.open && (
                  <div className={styles.text}>ホーム</div>
                )}
              </div>
            </a>

            <a href={sponsorProjectsPath}>
              <div className={`${styles.item} ${window.location.pathname.startsWith(sponsorProjectsPath) ? styles.active : ""}`} data-tip="プロジェクト管理">
                <ProjectIconImg />
                {props.open && (
                  <div className={styles.text}>プロジェクト管理</div>
                )}
              </div>
            </a>
            { !sponsor.scout_disabled &&
              <a href={sponsorScoutsPath} data-tip="スカウト">
                <div className={`${styles.item} ${window.location.pathname.startsWith(sponsorScoutsPath) ? styles.active : ""}`}>
                  <ScoutIconImg />
                  {props.open && (
                    <div className={styles.text}>スカウト</div>
                  )}
                </div>
              </a>
            }

            <a href={sponsorMessagesPath} data-tip="メッセージ">
              <div className={`${styles.item} ${window.location.pathname.startsWith(sponsorMessagesPath) ? styles.active : ""} ${unreadMessageCount != null && unreadMessageCount > 0 && !props.open ? styles.unread_message : ""}`}>
                <MessageIconImg />
                {props.open && (
                  <>
                    <div className={styles.text}>メッセージ</div>
                    {
                      unreadMessageCount != null && unreadMessageCount > 0 &&
                      <span className={styles.unreadMessagesCount}>{unreadMessageCount}</span>
                    }
                  </>
                )}
              </div>
            </a>
          </>
        }
      </div>

      {!props.open && (
        <ReactTooltip />
      )}

      {props.open && (
        <a
          href={newSponsorProjectPath}
          className={styles.create_project_btn}
        ><RoundBrn
          text="プロジェクト作成"
          type="plus"
        /></a>
      )}
      {!props.open && (
        <a
          href={newSponsorProjectPath}
          className={styles.create_project_btn_small}
        >＋</a>
      )}
    </div>
  )
}

SideMenu.propTypes = propTypes
SideMenu.defaultProps = defaultProps

export default SideMenu
