import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Round.scss"
import PenSvg from "./Round/pen.svg"
import EyeSvg from "./Round/eye.svg"

const propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
}

const defaultProps = {
  type: "check",
  color: "main",
  onClick: null,
}

const Round = (props) => {
  const css = (
    props.color === "main" ? styles.color_main : (
      props.color === "gray" ? styles.color_gray : (
        props.color === "gray-fill" ? styles.color_gray_fill : (
          props.color === "main-border" ? styles.main_border : (
            props.color === "gray-border" ? styles.color_gray_border : ""
          )
        )
      )
    )
  )

  return (
    <div className={`${styles.round} ${css}`} onClick={props.onClick}>
      {props.type === "plus" && (
        <span className={styles.type_plus} />
      )}
      {props.type === "check" && (
        <span className={styles.type_check} />
      )}
      {props.type === "pen" && (
        <PenSvg className={styles.type_svg} />
      )}
      {props.type === "eye" && (
        <EyeSvg className={styles.type_svg} />
      )}

      { props.text}
    </div>
  )
}

Round.propTypes = propTypes
Round.defaultProps = defaultProps

export default Round
