import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroller"

const propTypes = {
  items: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
  page: PropTypes.string,
  baseUrl: PropTypes.string,
  itemsKey: PropTypes.string,
  threshold: PropTypes.number,
  loadStart: PropTypes.func,
  loadEnd: PropTypes.func
}

const defaultProps = {
  page: "1",
  baseUrl: location.href,
  threshold: 250,
  loadStart: () => {},
  loadEnd: () => {}
}

const InfiniteScroller = (props) => {
  const [page, setPage] = useState(props.page)
  const [hasMore, setHasMore] = useState(true)
  const currentPageOrigin = window.location.origin
  const loader = <div className="loader" key={0}>Loading ...</div>
  const [beforeUrl, setBeforeUrl] = useState(props.baseUrl)
  const itemsKey = props.itemsKey

  useEffect(() => {
    if (beforeUrl !== props.baseUrl) {
      console.log(`page: refreshed`)
      console.log(`page: ${props.baseUrl}`)
      setPage("1")
      setHasMore(true)
      setBeforeUrl(props.baseUrl)
    }
  }, [props.baseUrl])

  const loadMore = () => {
    props.loadStart()
    setHasMore(false)
    console.log(`InfiniteScroller load ${props.baseUrl}`)

    const url = new URL(props.baseUrl)
    url.searchParams.set("page", Number(page) + 1)
    callAnalytics(String(url).replace(currentPageOrigin, ""))
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      console.log('infinity callback called')
      props.loadEnd()
      if(itemsKey) {
        if (jsonData[itemsKey].length < 1) { return setHasMore(false) }
        props.setState(props.items.concat(jsonData[itemsKey]))
      } else {
        if (jsonData.items.length < 1) { return setHasMore(false) }
        props.setState(props.items.concat(jsonData.items))
      }
      setPage(Number(page) + 1)
      setTimeout(() => { setHasMore(true) }, 1000);
    }).catch(
      (error) => console.log(error)
    )
  }

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      loader={loader}
      threshold={props.threshold}
    >{<></>}</InfiniteScroll>
  )
}

InfiniteScroller.propTypes = propTypes
InfiniteScroller.defaultProps = defaultProps

export default InfiniteScroller
