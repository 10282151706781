import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'

const CsrfToken = () => {
  // RailsのCSRFトークンを取得している
  // <%= csrf_meta_tags %> ←これ
  const csrfToken = document.getElementsByName('csrf-token').item(0).content

  return (
    <>
      <input type="hidden" name="authenticity_token" value={csrfToken} />
    </>
  )
}

export default CsrfToken