// このファイルをcomponentsに移動してimportする形式にするか？を悩んでる

const isExist = (value) => {
  let result = false
  if (
    value !== "" &&
    value !== null &&
    value !== undefined &&
    value !== "undefined"
  ) {
    result = true
  }
  return result
}
window.isExist = isExist

const isGreaterThanToZero = (value) => {
  let result = false
  if (Number(value) > 0) {
    result = true
  }
  return result
}
window.isGreaterThanToZero = isGreaterThanToZero

const isLessThanOrEqualTo = (startValue, endValue) => {
  let result = false
  if (startValue <= endValue) {
    result = true
  }
  return result
}
window.isLessThanOrEqualTo = isLessThanOrEqualTo
