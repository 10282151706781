import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'

const SaveTimer = (props) => {
  const [count, setCount] = useState(0)
  const [currentInterval, setCurrentInterval] = useState()

  useEffect(() => {
    if(currentInterval) {
      clearInterval(currentInterval)
    }

    const interval = setInterval(() => {
      setCount((_count) => _count + 10)
    } , 10000);

    setCurrentInterval(interval)

    setCount(0)
  }, [props.originalKey])

  return <div>
    { count != null  && <p>内容を保存しました：{count < 10 ? "今" : (count >= 60 ? "1分以上前" : `${count}秒前`)}</p>}
  </div>
}

export default SaveTimer
