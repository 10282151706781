import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Select from "../../../../FormTags/Select.js"

const propTypes = {
  setIsBuyInput: PropTypes.func.isRequired,
}

const BuyInput = (props) => {
  const project = props.project
  const project_type_buy = project && project.project_type_buy
  const [ecSite, setEcSite] = useState(project_type_buy && project_type_buy.ec_site)

  const checkValues = (
    isExist(ecSite)
  )

  useEffect(() => {
    props.setIsBuyInput(checkValues)
  })


  return (
    <Select
      label="購入できる場所"
      id="project_type_buy_ec_site"
      name="project_type_buy[ec_site]"
      options={[
        { value: null, name: "場所を選択してください" },
        { value: true, name: "ECサイト" },
        { value: false, name: "店舗" }
      ]}
      value={ecSite}
      setState={setEcSite}
      onChange={props.onChange}
      required={true}
    />
  )
}

BuyInput.propTypes = propTypes

export default BuyInput
