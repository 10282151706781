import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Modal.scss"

const propTypes = {
  status: PropTypes.bool.isRequired,
  content: PropTypes.object,
  setState: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  children: PropTypes.node
}

const defaultProps = {
  status: false,
}

const Modal = (props) => {
  const display = props.status ? "" : styles.hide
  const fullScreen = props.fullScreen ? styles.fullScreen : ""

  return (
    <>
      <div className={`${styles.container} ${display} ${fullScreen}`}>
        {props.children || props.content}
      </div>
      <div
        className={`${styles.background} ${display}`}
        onClick={() => props.setState(false)}
      />
    </>
  )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
