import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import Submit from "../FormTags/Submit.js"
import styles from "./Done.scss"

const propTypes = {
}

const defaultProps = {
}

const Done = (props) => {
  const influencerSessionsPath = "/influencer/sign_in"

  return (
    <div className={styles.container}>
      <div className={styles.icon1}>
        <svg className={styles.no_border_svg} width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.0272 0.648095C24.527 0.14706 23.7155 0.146196 23.2153 0.64597L7.84274 15.978L2.29636 9.95407C1.81711 9.43384 1.00682 9.40011 0.485727 9.8793C-0.0349643 10.3585 -0.0683002 11.1692 0.410954 11.6899L6.86073 18.6945C7.09693 18.9513 7.4275 19.1003 7.77607 19.1076C7.78543 19.108 7.79447 19.108 7.80343 19.108C8.14217 19.108 8.46802 18.9734 8.70808 18.7342L25.0247 2.46039C25.5261 1.96068 25.527 1.14913 25.0272 0.648095Z" fill="black" fillOpacity="0.87"/>
        </svg>
      </div>
      <div className={styles.icon2}>
        <svg className={styles.no_border_svg} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.7186 15.7186C32.0108 15.7186 31.4372 16.2922 31.4372 17C31.4372 24.961 24.961 31.4372 17 31.4372C9.03948 31.4372 2.56282 24.961 2.56282 17C2.56282 9.03948 9.03948 2.56282 17 2.56282C17.7078 2.56282 18.2814 1.9892 18.2814 1.28144C18.2814 0.573617 17.7078 0 17 0C7.62609 0 0 7.62609 0 17C0 26.3735 7.62609 34 17 34C26.3735 34 34 26.3735 34 17C34 16.2922 33.4264 15.7186 32.7186 15.7186Z" fill="black" fillOpacity="0.87"/>
        </svg>
      </div>
      
      <div className={styles.reset_complete}>
        <h2>パスワードの再設定が完了しました！</h2>
      </div>

      <div className={styles.proceed_login}>
        <p>下記のボタンから新しいパスワードでログインをおこなってください。</p>    
      </div>
      
      <a href={influencerSessionsPath}>
        <Submit className={styles.submit_button} text={"ログインする"}/>
      </a>
    </div>
  )
}

Done.propTypes = propTypes
Done.defaultProps = defaultProps

export default Done
