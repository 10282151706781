import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./New.scss"

import BasicDisplayStep from "./New/BasicDisplayStep.js"
import PreCompleteStep from "./New/PreCompleteStep.js";
import PassingCompleteStep from "./New/PassingCompleteStep.js"
import StartPage from "./New/StartPage.js"
import FirstStep from "./New/FirstStep.js"
import SecondStep from "./New/SecondStep.js"
import ThirdStep from "./New/ThirdStep.js"
import CompleteStep from "./New/CompleteStep.js"
import Modal from "../Shared/Modal.js"
import { IntercomProvider, useIntercom } from 'react-use-intercom';

const propTypes = {
  authGraphApiPath: PropTypes.string.isRequired,
  basicDisplayAuthUrl: PropTypes.string.isRequired,
  connected_api_types: PropTypes.array.isRequired,
  examination: PropTypes.string.isRequired,
}

const INTERCOM_APP_ID = 'axv39iqc';

const Content  = (props) => {
  const { boot, shutdown, hide, show, update } = useIntercom();

  const [step, setStep] = useState(props.connected_api_types.includes("instagram_basic") ? 0  : 1)
  const onClickYes = () => {
    scrollTo(0, 0);
    setStep(step + 1)
  }

  const onClickBack = () => {
    scrollTo(0, 0);
    setStep(step - 1)
  }

  const onClickQuestion = () => {
    show()
  }

  useEffect(() => {
    boot({
      name: props.influencer.name,
      email: props.influencer.email,
      hideDefaultLauncher: true,
      languageOverride: 'ja'
    })
  }, [])

  return (
    <>
      {
        !props.connected_api_types.includes("instagram_basic") && props.examination === "pending" &&
        <div className={styles.container}>
          <BasicDisplayStep basicDisplayAuthUrl={props.basicDisplayAuthUrl}
                            connected={props.connected_api_types.includes("basic")}
                            onClickQuestion={onClickQuestion}/>
        </div>
      }
      {
        props.connected_api_types.includes("instagram_basic") && props.examination === "pending" &&
        <div className={`${styles.container} ${styles.complete_step}`}><PreCompleteStep /></div>
      }
      {
        !props.connected_api_types.includes("instagram_graph") && props.examination === "approve" &&
        <div className={styles.container}>
          { step === 0 && <PassingCompleteStep onClickYes={onClickYes} /> }
          { step === 1 && <StartPage onClickYes={onClickYes} /> }
          { step === 2 && <FirstStep onClickYes={onClickYes} onClickBack={onClickBack} onClickQuestion={onClickQuestion} /> }
          { step === 3 && <SecondStep onClickYes={onClickYes} onClickBack={onClickBack} onClickQuestion={onClickQuestion} /> }
          { step === 4 && <ThirdStep onClickYes={onClickYes} onClickBack={onClickBack} onClickQuestion={onClickQuestion} authGraphApiPath={props.authGraphApiPath}/> }
        </div>
      }
      {
        props.connected_api_types.includes("instagram_graph") && props.examination === "approve" &&
        <div className={`${styles.container} ${styles.complete_step}`}><CompleteStep /></div>
      }
    </>
  )
}


const New = (props) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Content {...props} />
    </IntercomProvider>
  )
}

New.propTypes = propTypes

export default New
