import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import PropTypes from "prop-types"
import styles from "./Tab.scss"

const propTypes = {
  panels: PropTypes.array.isRequired,
  rightSide: PropTypes.bool,
}

const defaultProps = {
  rightSide: false
}

const TabCustom = (props) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const changeActiveIndex = (index) => {
    setActiveIndex(index)
  }

  let titleList = []
  props.panels.forEach((panel, index) => {
    const active = activeIndex === index ? styles.active : ""
    const rightSide = (props.panels.length - 1) === index ? styles.right_side : ""
    titleList.push(
      <Tab
        key={index}
        className={`${active} ${props.rightSide ? rightSide : ""}`}
        onClick={() => changeActiveIndex(index)}
      >{panel.title}</Tab>
    )
  })

  let panelList = []
  props.panels.forEach((panel, index) => {
    panelList.push(
      <TabPanel key={index}>
        <div className={styles.content}>
          {panel.content}
        </div>
      </TabPanel>
    )
  })

  return (
    <div className={styles.container}>
      <Tabs>
        <TabList className={styles.list}>
          {titleList}
        </TabList>

        {panelList}
      </Tabs>
    </div>
  )
}

TabCustom.propTypes = propTypes
TabCustom.defaultProps = defaultProps

export default TabCustom
