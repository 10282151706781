import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./StartPage.scss"
import ConnectImage from "./StartPage/maro_instagram_connect.svg"
import CautionImage from "./StartPage/caution.svg"
import ArrowImage from "./StepPage/chevron-down.svg"
import RailsForm from "../../../Shared/RailsForm.js"
import Submit from "../../FormTags/Submit.js"

const propTypes = {
}

const StartPage = (props) => {
  return (
    <div className={styles.start_page_container}>
      <div className={styles.step_header}>
        <h1>Facebook連携</h1>
      </div>

      <div className={styles.step_bar_container}>
        <div className={`${styles.step_bar_4} ${styles.active}`}>
        </div>

        <div className={styles.step_bar_4}>
        </div>

        <div className={styles.step_bar_4}>
        </div>

        <div className={styles.step_bar_4}>
        </div>
      </div>

      <RailsForm
        action={'/influencer/fb_connect_skip'}
        method="post"
      >
        <h2 className={styles.action_title}>Facebook連携をスキップして登録</h2>
        <Submit>
          <div className={styles.skip_fb_connect_link}>Facebook連携をスキップする</div>
        </Submit>
      </RailsForm>

      <hr />

      <h2 className={styles.action_title}>Facebook連携をする（1分で完了）</h2>
      <p className={styles.action_message}>Maroでは、インフルエンサー様にFacebook連携の<br />ご協力をお願いしています。</p>
      <ConnectImage className={styles.connect_image}/>
      <p>お持ちのアカウントと連携していただくことで、<br/>Maroを利用している企業は、インフルエンサー様の下記のデータを閲覧することができるようになります。</p>

      <p className={styles.data_list}>
        ・ID名<br />
        ・プロフィール画像<br />
        ・フォロワー数<br />
        ・投稿へのエンゲージメント（いいね数・リーチ数）<br />
        ・フォロワーの男女比と年齢層<br />
        ・最新5件の投稿画像<br />
      </p>

      <p className={styles.reason}>
        企業は、上記の情報を元に、インフルエンサー様にお仕事の依頼（応募の承認やスカウト）を行ないます。<br /><br />
        連携していただくことで、<br />
        <span className={styles.strong}>あなたのアカウントとマッチする企業からの採用率・<br />
        スカウト率の向上</span>にも繋がります。<br /><br />
        お手数をおかけいたしますが、<br />
        ご協力の程よろしくお願い致します。
      </p>

      <div className={styles.checklist_container}>
        <p className={styles.checklist_title}><CautionImage className={styles.caution_image}/><span>確認事項</span></p>
        <p className={styles.checklist}>
          ※ インフルエンサー様のデータが上記以外の目的で利用されることはございません<br />
        ※ 当サービスおよび株式会社Maroがご連携頂いたアカウントにログインすることはできませんのでご安心ください<br />
        ※ ご連携により連携されたことが通知されたり、勝手に投稿されるといった事象が発生することはございません<br />
        </p>
      </div>

      <a onClick={props.onClickYes} className={styles.start_button}>
        Facebook連携を開始する
        <ArrowImage className={styles.arrow} />
      </a>
    </div>
  )
}

StartPage.propTypes = propTypes

export default StartPage
