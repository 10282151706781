import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import PropTypes from "prop-types"
import Checkbox from "../../FormTags/SearchPanelCheckbox.js"
import TextField from "../../FormTags/TextField.js"
import Submit from "../../FormTags/Submit.js"
import styles from "./SearchPanel.scss"
import CheckboxCollection from "../../FormTags/CheckboxCollection";

const propTypes = {
  categories: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  setSearchFormData: PropTypes.func.isRequired,
  searchInfluencer: PropTypes.func.isRequired,
  current_sponsor_id: PropTypes.number.isRequired
}

const SearchPanel = (props) => {
  const [categoryIds, setCategoryIds] = useState([])
  const [followedByCountMin, setFollowedByCountMin] = useState(null)
  const [followedByCountMax, setFollowedByCountMax] = useState(null)
  const [averageEngagementRateMin, setAverageEngagementRateMin] = useState(null)
  const [averageEngagementRateMax, setAverageEngagementRateMax] = useState(100)
  const [hasScout, setHasScout] = useState([])
  const [hasProject, setHasProject] = useState([])
  const [projectId, setProjectId] = useState(null)
  const [genderIds, setGenderIds] = useState([])

  useEffect(() => {
    const special_params = {
      "search[scouted_projects_id_in]": null,
      "search[scouted_projects_id_not_in]": null,
      "search[scouted_sponsors_id_in]": null,
      "search[scouted_sponsors_id_not_in]": null,
      "search[entried_projects_id_in]": null,
      "search[entried_projects_id_not_in]": null,
      "search[entried_sponsors_id_in]": null,
      "search[entried_sponsors_id_not_in]": null,
    }
    if (hasScout.length == 1) {
      if (projectId) {
        if (hasScout[0] == 1)
          special_params["search[scouted_projects_id_in]"] = projectId
        else
          special_params["search[scouted_projects_id_not_in]"] = projectId
      } else {
        if (hasScout[0] == 1)
          special_params["search[scouted_sponsors_id_in]"] = props.current_sponsor_id
        else
          special_params["search[scouted_sponsors_id_not_in]"] = props.current_sponsor_id
      }
    }
    if (hasProject.length == 1) {
      if (projectId) {
        if (hasProject[0] == 1)
          special_params["search[entried_projects_id_in]"] = projectId
        else
          special_params["search[entried_projects_id_not_in]"] = projectId
      } else {
        if (hasProject[0] == 1)
          special_params["search[entried_sponsors_id_in]"] = props.current_sponsor_id
        else
          special_params["search[entried_sponsors_id_not_in]"] = props.current_sponsor_id
      }
    }

    props.setSearchFormData(
      state => ({...state,
        "search[categories_id_eq_any][]": categoryIds,
        "search[average_engagement_rate_gteq]": averageEngagementRateMin,
        "search[average_engagement_rate_lteq]": averageEngagementRateMax,
        "search[followed_by_count_gteq]": followedByCountMin,
        "search[followed_by_count_lteq]": followedByCountMax,
        "search[gender_id_eq_any][]": genderIds,
        ...special_params
      })
    )
  }, [categoryIds, averageEngagementRateMin, averageEngagementRateMax, followedByCountMin, followedByCountMax, hasScout, hasProject, projectId, genderIds])

  const projectSelectable = hasScout.length > 0 || hasProject.length > 0;
  let categoryList = []
  props.categories.forEach((category) => {
    categoryList.push(
      {
        label: category.name,
        id: `search_ids_${category.name}`,
        value: category.id,
      }
    )
  })

  return (
    <div className={styles.search_panel}>
      <div className={styles.search_container}>
        <div className={styles.search_fieldcol}>
          <div className={styles.search_fieldset}>
            <h4>カテゴリで探す</h4>
            <CheckboxCollection elements={props.categories} onChange={setCategoryIds} />
          </div>

          <div className={styles.search_fieldset}>
            <h4>性別</h4>
            <div className={styles.range_container}>
              <Checkbox
                label=""
                name="search[gender_id][]"
                elements={[{ id: 'chk_gender_id_2', label: '男性', value: 2 }, { id: 'chk_gender_id_3', label: '女性', value: 3 }, { id: 'chk_gender_id_1', label: 'その他', value: 1 }]}
                value={genderIds}
                setState={setGenderIds}
                className={styles.checkbox_container}
                checkableItem={4} //チェックすると一度に2個の配列が入ってしまう　バグを治すのに時間がかかるため後回し中　
                direction={"row"} 
              />
            </div>
            <h4>フォロワー数</h4>
            <div className={styles.range_container}>
              <TextField
                label=""
                id="search_followed_by_count_min"
                name="search[followed_by_count_gteq]"
                value={followedByCountMin}
                setState={setFollowedByCountMin}
                className={styles.followed_by_count}
                onlyNumbers={true}
              />
              <p className={styles.range_dash}>〜</p>
              <TextField
                label=""
                id="search_followed_by_count_max"
                name="search[followed_by_count_lteq]"
                value={followedByCountMax}
                setState={setFollowedByCountMax}
                className={styles.followed_by_count}
                onlyNumbers={true}
              />
            </div>
            <hr/>
            <h4>エンゲージメント率</h4>
            <div className={styles.range_container}>
              <TextField
                  label=""
                  id="search_average_engagement_rate_min"
                  name="search[average_engagement_rate_gteq]"
                  value={averageEngagementRateMin}
                  setState={setAverageEngagementRateMin}
                  className={styles.average_engagement_rate}
                  type="number"
                  min={0}
                  max={100}
              />
              <p className={styles.range_unit}>％</p>
              <p className={styles.range_dash}>〜</p>
              <TextField
                  label=""
                  id="search_average_engagement_rate_max"
                  name="search[average_engagement_rate_lteq]"
                  value={averageEngagementRateMax}
                  setState={setAverageEngagementRateMax}
                  className={styles.average_engagement_rate}
                  type="number"
                  min={0}
                  max={100}
              />
              <p className={styles.range_unit}>％</p>
            </div>
          </div>

          <div className={styles.search_fieldset}>
            <h4 className={styles.mb_0}>スカウト送信有無</h4>
            <Checkbox
              label=""
              name="search[has_scout][]"
              elements={[{ id: 'chk_has_scout_1', label: '送信済み', value: 1 }, { id: 'chk_has_scout_2', label: '未送信', value: 2 }]}
              value={hasScout}
              setState={setHasScout}
              className={styles.checkbox_container}
              checkableItem={1}
            />
            <hr/>
            <h4 className={styles.mb_0}>プロジェクトへの参加有無</h4>
            <Checkbox
              label=""
              name="search[has_project][]"
              elements={[{ id: 'chk_has_project_1', label: '参加あり', value: 1 }, { id: 'chk_has_project_2', label: '参加なし', value: 2 }]}
              value={hasProject}
              setState={setHasProject}
              className={styles.checkbox_container}
              checkableItem={1}
            />
            <hr/>
            <h4 className={projectSelectable ? "" : styles.disabled_label}>プロジェクト</h4>
            <Select
                id="search_project"
                name="search[project_id]"
                placeholder={'プロジェクトを選択'}
                isDisabled={!projectSelectable}
                options={props.projects.map((project) => ({ value: project.id, label: <div><img src={project.image} style={{ height: 38, width: 38, verticalAlign: 'middle' }}/> {project.title}</div> }))}
                isClearable={true}
                onChange={(e) => setProjectId(e?.value)}
            />
          </div>
        </div>
      </div>

      <Submit
        text="検索する"
        onClick={() => props.searchInfluencer()}
        className={styles.submit_button}
      />
    </div>
  )
}

SearchPanel.propTypes = propTypes

export default SearchPanel
