import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "./../../../Shared/RailsForm.js"
import TextField from "./../../FormTags/TextField.js"
import Submit from "./../../FormTags/Submit.js"
import EmailLabel from "./../EmailLabel.js"
import styles from "./../Edit.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
  reset_password_token:PropTypes.string.isRequired,
  email:PropTypes.string.isRequired
}

const defaultProps = {
  errors: [],
}

const Edit = (props) => {
  const [password, setPassword] = useState()  
  const influencerPasswordPath = `/influencer/password`

  return (
    <div>
      <div className={styles.sns_link_btn_container}>
        <h2>パスワードの再設定</h2>
        <RailsForm
          action={influencerPasswordPath}
          method={"patch"}
        >
        
        <EmailLabel
          email ={props.email}
        />
        
        <TextField
              label="新しいパスワード"
              id="influencer_password"
              name="influencer[password]"
              type="password"
              value={password}
              setState={setPassword}
              required={true}
              placeholder="password"
        />
        <TextField
          label=""
          id="reset_password_token"
          name="influencer[reset_password_token]"
          value={props.reset_password_token}
          type="hidden"
        />

        <Submit className={styles.submit_button} text={"保存"}/>
        </RailsForm>
      </div>
    </div>
  )
}

Edit.propTypes = propTypes
Edit.defaultProps = defaultProps

export default Edit
