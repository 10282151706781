import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Index.scss";
import MessageList from "./Shared/MessageList.js";
import MessageTable from "./Shared/MessageTable.js";
import MessagePairProject from "./Shared/MessagePairProject";

const propTypes = {
  messageRooms: PropTypes.array.isRequired,
  messageRoom: PropTypes.object.isRequired,
  entries: PropTypes.array.isRequired,
}

const Show = (props) => {
  return (
    <div className={styles.message_body}>
      <MessageList
        messageRooms={props.messageRooms}
      />

      <MessageTable
        messageRoom={props.messageRoom}
      />

      <MessagePairProject
        entries={props.entries}
      />
    </div>
  )
}

Show.propTypes = propTypes

export default Show
