import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./ImportCsvModal.scss"
import Modal from "../../Shared/Modal.js"
import Submit from "../../FormTags/Submit.js"
import CheckboxBool from "../../FormTags/CheckboxBool.js"
import RailsForm from "../../../Shared/RailsForm.js"

const propTypes = {
  errors: PropTypes.array.isRequired,
}

const defaultProps = {
  errors: [],
}

const ImportCsvModal = (props) => {
  const [csvFileName, setCsvFileName] = useState();
  const onChangeFile = (e) => {
    const fileName = e.target.files.item(0).name
    if(fileName.match(/\.csv$/)){
      setCsvFileName(fileName)
    } else {
      setCsvFileName(<span className={styles.file_name_invalid}>ファイルの種類は.csvでなければなりません</span>)
      e.target.value = null
    }
  }
  const [override, setOverride] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])

  return (
    <Modal
      status={props.modalStatus}
      setState={props.setModalStatus}
      className={styles.import_csv_modal}
    >
      <div className={styles.modal_header}>
        <h2>インフルエンサーをCSVで一括登録</h2>
        <a className={styles.sample_csv_download_link} href="/agency/influencer_sample_csv.csv">サンプルCSVをダウンロード</a>して、記入フォーマットをご確認ください。
      </div>

      <div className={styles.modal_main}>
        {
          errorMessage.length > 0 &&
          <p className={styles.error_message}>
            {errorMessage}
          </p>
        }

        <RailsForm
          action={"/agency/influencer_list"}
          method="post"
          remote={true}
          multipart={true}
          setCustomErrorMessage={setErrorMessage}
        >
          <div className={styles.file_input_button_container}>
             <label className={styles.file_input_button_label}>
               <input type="file" name="influencer_csv[file]" className={styles.file_input_button} onChange={onChangeFile} required/>ファイルを選択
             </label>
             <span className={styles.file_name}>{csvFileName}</span>
          </div>
          <CheckboxBool
            label={<p className={styles.override_label}>メールアドレスが一致する既存のインフルエンサー情報を上書きする</p>}
            name="influencer_csv[overwrite]"
            value={override}
            setState={setOverride}
          />
          <p className={styles.description}>
            ※ メールアドレスが一致しない場合は新規登録されます。<br />
            ※ 事務所側で一度登録したメールアドレスの上書きをすることはできません。（インフルエンサー自身が変更することは可能）<br />
            ※報酬還元率を上書きする場合、CSV登録完了以降に発生した報酬から適用されます。
          </p>
          <Submit className={styles.import_button} text="インポートする" />
        </RailsForm>
      </div>
    </Modal>
  )
}

ImportCsvModal.propTypes = propTypes
ImportCsvModal.defaultProps = defaultProps

export default ImportCsvModal
