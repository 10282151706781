import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Account from "./Edit/Account.js"
import Password from "./Edit/Password.js"
import Tab from "../Shared/Tab.js"
import styles from "./Edit.scss"

const propTypes = {
  staff: PropTypes.object.isRequired,
}

const Edit = (props) => {
  const panels = ([
    {
      title: "アカウント情報",
      content: (
        <Account staff={props.staff} />
      )
    },
    {
      title: "パスワード変更",
      content: (
        <Password />
      )
    }
  ])

  return (
    <div className={styles.container}>
      <h1>アカウント設定</h1>
      <Tab panels={panels} />
    </div>
  )
}

Edit.propTypes = propTypes

export default Edit
