import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect, useState }  from 'react'
import Moment from "moment"
import RoundBtn from "../../Shared/Buttons/Round.js"
import SwitchBtn from "../../Shared/Buttons/Switch.js"
import CheckboxBool from "../../FormTags/CheckboxBool"
import Modal from "../../Shared/Modal.js"
import Layout from "./Shared/Layout.js"
import LazyLoad from "../../../Shared/LazyLoad.js"
import styles from "./PostReport.scss"
import fetchEntry from "./fetchEntry.js"
import CautionIconImg from "./PostReport/caution.png"
import Message from "./Shared/Message.js"
import InfluencerDetailModal from "./../../Shared/InfluencerDetailModal.js"
import Tooltip from "../../Shared/Tooltip.js"

// fetchしてインフルエンサーの情報を取得してステートに格納
// ステートの値を元にテーブルをレンダー
// 左上のボタンで対象のインフルエンサーをfetchする
const PostReport = (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`
  const [selectedEntryIds, setSelectedEntryIds] = useState([])
  const [modalStatus, setModalStatus] = useState(false);
  const [completeModalStatus, setCompleteModalStatus] = useState();
  const [completeModalMessage, setCompleteModalMessage] = useState();
  const [loadingImageStatus, setLoadingImageStatus] = useState(false);
  const currentStatus = "unreported"
  const [entries, setEntries] = useState({ unreported: [] })
  const [influencerId, setInfluencerId] = useState(null)
  const [influencerDetailModalStatus, setInfluencerDetailModalStatus] = useState(false)

  useEffect(() => {
    fetchEntry({
      status: currentStatus,
      setEntries: setEntries,
      loadStart: () => setLoadingImageStatus(true),
      loadEnd: () => setLoadingImageStatus(false)
    })
  }, [])

  const updateSelectedEntryIds = (e, entryId) => {
    if(e.target.checked) {
      setSelectedEntryIds(selectedEntryIds.concat(entryId))
    } else {
      const newSelectedEntryIds = selectedEntryIds.filter(id => id !== entryId);
      setSelectedEntryIds(newSelectedEntryIds)
    }
  }

  const sendRemindMail = () => {
    let postUrl = `${currentPageUrl}/entries/post_report_reminders`

    fetch(postUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: JSON.stringify({
        "entry_ids": selectedEntryIds
      }),
    }).then((response) => {
      if(response.status === 200) {
        setCompleteModalMessage("送信しました")
      } else {
        setCompleteModalMessage("送信に失敗しました")
      }
      setCompleteModalStatus(true)
      fetchEntry({
        status: currentStatus,
        setEntries: setEntries,
        loadStart: () => setLoadingImageStatus(true),
        loadEnd: () => setLoadingImageStatus(false)
      })
    }).catch((error) => {
      setCompleteModalMessage("送信に失敗しました")
      setCompleteModalStatus(true)
      console.log(error)
    })
    setModalStatus(false)
    //setSelectedEntryIds([])
  }

  const topRightButtons = ([
    <RoundBtn
      text="投稿報告をリマインドする"
      type="check"
      color="main"
      onClick={() => setModalStatus(true)}
    />
  ])

  const influencerList = entries[currentStatus].length ? [
    <tr>
      <th>インフルエンサー</th>
      {
        entries[currentStatus][0].reporting_limit_at &&
        <>
          <th>投稿完了報告期限<Tooltip tip={props.project.project_type_id == props.typeVisitId ? `インフルエンサーは「投稿完了報告期限」に記載の日程までにスポンサーに対して「投稿完了報告」を行う必要があります。今回のプロジェクトで、投稿完了報告期限は「応募承認日から${props.project.days_to_ship + props.project.days_to_post + props.project.days_to_report}日」と設定されています。` : `インフルエンサーは「投稿完了報告期限」に記載の日程までにスポンサーに対して「投稿完了報告」を行う必要があります。今回のプロジェクトで、投稿完了報告期限は「商品発送完了日から${props.project.days_to_post}日＋投稿完了日から${props.project.days_to_report}日」と設定されています。`} /></th>
          <th>ステータス</th>
        </>
      }
      <th>メッセージ</th>
    </tr>
  ] : []

  entries[currentStatus].forEach((entry, index) => {
    const Status = () => {
      if(entry.isReported || !entry.reporting_limit_at) {
        return <></>
      } else if(entry.isReportingLimitPast) {
        return (
          <>
            <span className={styles.statusLabel}>未投稿報告</span>
            <span>期限超過</span>
            <img src={CautionIconImg} className={styles.cautionImage} />
          </>
        )
      } else {
        return (
          <>
            <span className={styles.statusLabel}>未投稿報告</span>
            <span>{`期限まで残り${entry.days_to_reporting_limit}日`}</span>
          </>
        )
      }
    }


    influencerList.push(
      <tr>
        <td>
          <div className={styles.influencer_name_column}>
            <div>
              {!entry.isReported && (
                <CheckboxBool
                  id={entry.id}
                  name="entries"
                  value={false}
                  onChange={(e) => updateSelectedEntryIds(e, entry.id)}
                />
              )}
            </div>
            <div
              className={styles.influencer_name}
              onClick={(e) => (
                setInfluencerId(entry.influencer.id),
                setInfluencerDetailModalStatus(true)
              )}
            >
              <div className={styles.influencer_icon}>
                <LazyLoad src={String(entry.influencer.profile_pic)}/>
              </div>
              <p>
                {entry.influencer.name}
              </p>
            </div>
          </div>
        </td>
        {
          entry.reporting_limit_at &&
          <>
            <td>
              {Moment(entry.reporting_limit_at).format("YYYY/")}
              <span className={styles.posting_end_md}>
                {Moment(entry.reporting_limit_at).format("MM/DD")}
              </span>
            </td>
            <td>
              <Status />
            </td>
          </>
        }

        <td><Message influencerId={entry.influencer.id} /></td>
      </tr>
    )
  })

  return (
    <div>
      <Layout
        topLeftButtons={[]}
        topRightButtons={topRightButtons}
        table={influencerList}
        loadingImageStatus={loadingImageStatus}
      />
      <Modal
        status={modalStatus}
        setState={setModalStatus}
      >
        <div className={`${styles.modal_btn_container}`}>
          <div>{selectedEntryIds.length} 件の投稿報告リマインドを送信しますか？</div>
          <div className={styles.modal_btn_area}>
            <RoundBtn
              text="送信する"
              type="check"
              color="main"
              onClick={sendRemindMail}
            />
          </div>
        </div>
      </Modal>

      <Modal
        status={completeModalStatus}
        setState={setCompleteModalStatus}
      >
        <div className={`${styles.modal_btn_container}`}>
          <div>{completeModalMessage}</div>
          <div className={styles.modal_btn_area}>
            <RoundBtn
              text="閉じる"
              type="check"
              color="main"
              onClick={() => setCompleteModalStatus(false) }
            />
          </div>
        </div>
      </Modal>

      <InfluencerDetailModal
        influencerId={influencerId}
        influencerDetailModalStatus={influencerDetailModalStatus}
        setInfluencerDetailModalStatus={setInfluencerDetailModalStatus}
      />

    </div>
  )
}

export default PostReport
