import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import Submit from "../../FormTags/Submit.js"
import Template from "./Template.js"
import PropTypes from "prop-types";
import RailsForm from "../../../Shared/RailsForm";

const propTypes = {
  messageTemplate: PropTypes.object.isRequired,
}

const MessageTemplate = (props) => {
  const sponsorMessageTemplatePath = `/sponsor/message_template`
  let templates = []
  Object.entries(props.messageTemplate).forEach(([k, v]) => {
    if(k !== "scout") return
    templates.push(<Template name={k} content={v}/>)
  })


  let elements = (
    <>
      {templates}
      <Submit text="保存する" />
    </>
  )
  return (
    <>
      <p>変数タグをご利用いただけます ▼</p>
      <p>インフルエンサー名：<span>$[i-name]（敬称をお忘れなく！）</span></p>
      <p>プロジェクトタイトル：<span>$[p-title]</span></p>
      <p>企業名：<span>$[sponsor-name]</span></p>
      <p>担当者名：<span>$[staff-name]</span></p>

      <RailsForm
        action={sponsorMessageTemplatePath}
        remote={true}
        put={true}
        elements={elements}
      />
    </>
  )
}

MessageTemplate.propTypes = propTypes

export default MessageTemplate
