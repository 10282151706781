import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Logo from "./SideMenu/logo.png"
import Dummy from "./SideMenu/dummy.png"
import styles from "./SideMenu.scss"
import HomeIconImg from "./SideMenu/homeIcon.svg"
import InfluencersIconImg from "./SideMenu/influencersIcon.svg"
import ReportIconImg from "./SideMenu/reportIcon.svg"

const propTypes = {
  open: PropTypes.bool.isRequired,
}

const defaultProps = {
  open: false,
}

const SideMenu = (props) => {
  const agencyRootPath = `/agency`
  const agencyInfluencersPath = `/agency/influencers`
  const agencyReportsPath = `/agency/reports`

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logo_container}>
          <a href={agencyRootPath}>
            <div className={styles.item}>
              <img
                src={Logo}
                className={styles.logo}
              />
            </div>
          </a>
        </div>

        <a href={agencyRootPath}>
          <div className={styles.item}>
            <HomeIconImg />
            {props.open && (
              <div className={styles.text}>ホーム</div>
            )}
          </div>
        </a>
        <a href={agencyInfluencersPath}>
          <div className={styles.item}>
            <InfluencersIconImg />
            {props.open && (
              <div className={styles.text}>インフルエンサー管理</div>
            )}
          </div>
        </a>
        <a href={agencyReportsPath}>
          <div className={styles.item}>
            <ReportIconImg />
            {props.open && (
              <div className={styles.text}>売上レポート</div>
            )}
          </div>
        </a>
      </div>
    </div>
  )
}

SideMenu.propTypes = propTypes
SideMenu.defaultProps = defaultProps

export default SideMenu
