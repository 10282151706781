import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./ArrowBack.scss"

const propTypes = {
  text: PropTypes.string.isRequired,
}

const defaultProps = {
  onClick: null,
}

const ArrowBack = (props) => {
  return (
    <div onClick={props.onClick}>
      <div className={styles.arrow_back}>
        {props.text}
      </div>
    </div>
  )
}

ArrowBack.propTypes = propTypes
ArrowBack.defaultProps = defaultProps

export default ArrowBack