import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Tile from "../Shared/Tile.js"
import styles from "./Index.scss"

const propTypes = {
  todo: PropTypes.object.isRequired,
}

const Index = (props) => {
  const sponsorTasksPendingsPath = '/sponsor/tasks/pendings'
  const sponsorTasksShipmentsPath = '/sponsor/tasks/shipments'
  const request = (
    <a href={sponsorTasksPendingsPath}>
      <strong>{props.todo.num_new_request}件</strong>の新着応募があります
    </a>
  )

  const unshipped = (
    <a href={sponsorTasksShipmentsPath}>
      <strong>{props.todo.num_unshipped}件</strong>の未発送があります
    </a>
  )

  return (
    <div className={styles.container}>
      <h1>やることリスト</h1>
      <div className={styles.todo_container}>
        { props.todo.num_new_request != 0 && (
          <Tile
            content={request}
            className={styles.todo_item}
          />
        )}
        { props.todo.num_unshipped != 0 && (
          <Tile
            content={unshipped}
            className={styles.todo_item}
          />
        )}
      </div>
    </div>
  )
}

Index.propTypes = propTypes
export default Index
