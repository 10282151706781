import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Checkbox.scss"

const propTypes = {
  label: PropTypes.string.isRequired,
  elements: PropTypes.array.isRequired,
  className: PropTypes.string,
  value: PropTypes.array,
  direction: PropTypes.string,
  onChange: PropTypes.func,
  setState: PropTypes.func,
}

const defaultProps = {
  className: "",
  value: [],
  direction: "column",
  onChange: null,
  setState: null,
}

const Checkbox = (props) => {
  const name = `name_${Math.random().toString(36).slice(-8)}`
  const direction = (
    props.direction === "column" ? styles.direction_column :
      props.direction === "row" ? styles.direction_row : ""
  )

  const setValues = (e) => {
    const elements = document.querySelectorAll(`.${name}`)
    let array = []

    if(e.target.checked) {
      array.push(e.target.value)
    }

    elements.forEach((element) => {
      if (!element.checked) { return }

      if(props.checkableItem && array.length < props.checkableItem) {
        array.push(element.value)
      }
    })
    props.setState(array)
  }

  let list = []
  props.elements.forEach((element, index) => {
    list.push(
      <label key={index}>
        <input
          type="checkbox"
          id={element.id}
          className={name}
          name={props.name}
          value={element.value}
          onClick={element.onClick}
          onChange={(e) => props.setState ? setValues(e) : null}
          onInput={(e) => props.setState ? setValues(e) : null}
          defaultChecked={props.value.some(num => num === element.value)}
          checked={props.value.some(num => num == element.value)}
        />
        <span />
        <div>{element.label}</div>
      </label>
    )
  })

  return (
    <div className={`${styles.container} ${props.className}`}>
      <label>{props.label}</label>
      <div className={`${styles.checkbox} ${direction}`}>
        {list}
      </div>
    </div>
  )
}

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
