// 画像を読み込めるようにする
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// トランスパイルのあれ
import "core-js/stable"
import "regenerator-runtime/runtime"

// 元からあったやつ
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// 全てのページでReactを使っているからここで読み込んでおく
import "../javascripts/server_rendering"

// 追加で読み込むアセット
import "../javascripts/turbolinks-customized"
import "../javascripts/validation"
