import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import styles from "./Popup.scss"
import Modal from "../../Agencies/Shared/Modal"
import CheckboxBool from "../../Sponsors/FormTags/CheckboxBool"
import RailsForm from "../../Shared/RailsForm"

const propTypes = {
  path: PropTypes.string.isRequired,
  status: PropTypes.any.isRequired,
  current_influencer: PropTypes.bool.isRequired,
  method: PropTypes.string.isRequired
}


const Popup = (props) => {
  const influencers_rules = '/influencers/influencers_rules'
  const [modalStatus, setModalStatus] = useState(props.current_influencer === false ? props.status == 1 ? false : true : false)
  const [checkStatus, setCheckStatus] = useState(false)
  const [method, setMethod] = useState("post")

  useEffect(() => {
    props.method == 'popupAuthent' || props.method == 'popupSessions' ? setModalStatus(true) : false;
  }, [props.method])

  const closeModal = () => {
    setModalStatus(false)
    setMethod('delete')
    props.method == 'popupAuthent' || props.method == 'popupSessions' ? props.setPopup('default') : false
  }

  useEffect(() => {
    if(props.method === 'popupAuthent') {
        props.setAgreePolicy(checkStatus)
    }

  }, [checkStatus])

  const onSubmit = () => {
    setModalStatus(false)
    if (props.method == 'popupAuthent') {
      document.getElementById("influencer_register_form").submit()
    } else if (props.method == 'popupSessions') {
      const data = new FormData()
      data.append("checkbox", checkStatus)
      data.append("id", props.userId ? props.userId : null)
      fetch(influencers_rules, {
        method: "POST",
        credentials: 'include',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
        },
        body: data
      })
      document.getElementById("influencer_check_sessions_form").submit()
    } else {
      const data = new FormData()
      data.append("checkbox", checkStatus)
      data.append("id", props.userId ? props.userId : null)
      fetch(influencers_rules, {
        method: "POST",
        credentials: 'include',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
        },
        body: data
      })
    }
  }

  const modalContent = (
    <RailsForm
      action={method == "post" ? influencers_rules : props.path}
      method={method}
    >
      <div className={styles.popup_rules_container}>
        <h3>Maroサービス利用規約</h3>
        <div className={styles.pr_content}>
          <div>

            <p style={{textAlign: "justify"}}>
                    本利用規約（以下「本規約」といいます。）には、株式会社Maro（以下「当社」といいます。）の提供する本サービス（第2条に定義）のご利用にあたり、インフルエンサーの皆様に遵守していただかなければならない事項及び当社とインフルエンサーの皆様との間の権利義務関係が定められております。本サービスをご利用になり、業務を受託する方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
                </p>
                <p style={{textAlign: "justify"}}>
                    &nbsp;
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__1__条____適　用</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 本規約は、本サービスの利用に関する当社と登録インフルエンサー（第2条に定義）との間の権利義務関係を定めることを目的とし、登録インフルエンサーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__2__条____定　義</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    (1) 「個別業務」とは、個別契約により当社から登録インフルエンサーに対して委託される業務を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    (2) 「個別契約」とは、第7条第1項に定義される個別契約を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    (3) 「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    (4) 「当社ウェブサイト」とは、そのドメインが「maro.mu」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    (5) 「登録希望者」とは、第3条において定義された「登録希望者」を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    (6) 「登録情報」とは、第3条において定義された「登録情報」を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    (7) 「登録インフルエンサー」とは、第3条に基づき本サービスの利用者としての登録がなされた個人を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    (8) 「本サービス」とは、当社が提供するMaroという名称のインフルエンサーマーケティングプラットフォームのサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    (9) 「利用契約」とは、第3条第4項に定義される「利用契約」を意味します。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__3__条____登　録</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 登録の申請は必ず本サービスを利用する個人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
                </p>
                <p style={{textAlign: "justify"}}>
                    (1) 本規約に違反するおそれがあると当社が判断した場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (2) 当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (3) 過去に本サービスの利用契約を解除された者である場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (4) 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (5) その他、当社が登録を適当でないと合理的に判断した場合
                </p>
                <p style={{textAlign: "justify"}}>
                    4. 当社は、前項その他当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知します。かかる通知により登録希望者の登録インフルエンサーとしての登録は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）が登録インフルエンサーと当社の間に成立します。
                </p>
                <p style={{textAlign: "justify"}}>
                    5. 登録インフルエンサーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__4__条____本サービスの利用</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    登録インフルエンサーは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__5__条____料金</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    登録インフルエンサーは、本サービスを無償で利用することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__6__条____業務の委託</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 当社は登録インフルエンサーに対し、本規約に定めるところにより、業務を委託することができるものとし、登録インフルエンサーはこれを本サービスを利用して受託することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 本規約に基づき委託される業務の具体的な内容及び諸条件は、登録インフルエンサーと当社の間で締結される個別契約に定めるところによるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 個別契約又は法令に別段の定めがある場合を除き、個別契約に基づく業務の委託は、請負契約とします。
                </p>
                <p style={{textAlign: "justify"}}>
                    4. 登録インフルエンサーは、善良なる管理者の注意義務をもって個別業務を履行するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    5. 個別業務の内容がPR案件である場合、登録インフルエンサーは、当社の指示に従って、PR表記その他ステルスマーケティングとならないために必要な表示を行うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__7__条____個別契約</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 当社及び登録インフルエンサーは、具体的な業務の委託にあたり、本サービスを利用して、委託業務の内容、成果物、納入期限、納入方法、業務委託料、支払期日その他必要な事項を定めた個別の業務委託契約（以下「個別契約」といいます。）を締結するものとします。なお、当事者間の合意により、上記と異なる方法で個別契約を締結することを妨げないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 本規約は基本契約として、全ての個別契約に共通に適用されるものとします。本規約の内容と個別契約の内容が異なる場合には、個別契約の内容が優先して効力を有するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 個別契約に下請代金支払遅延等防止法（以下「下請法」といいます。）が適用される場合、登録インフルエンサーは、下請法第3条第1項の規定による書面の交付に代えて、本サービス上にアップロードされたPDFをダウンロードする方法により、電磁的記録の提供を受けることを承諾するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__8__条____再委託</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    登録インフルエンサーは、個別業務を自ら遂行するものとし、当社が事前に承諾しない限り、個別業務の全部又は一部を他の第三者に再委託することはできないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__9__条____報告</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    登録インフルエンサーは、当社からの指示がある場合には、受託した個別業務の進捗状況その他当社が指定する事項について、当社が求める時期、内容及び様式により報告するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__10__条____商品の提供等</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 個別業務の実施に当たりPRに必要な当社又は当社が指定する第三者の商品又は役務は、当社が登録インフルエンサーに対し無償で提供するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 個別業務の実施のために必要があると当社が認めるときは、当社は、登録インフルエンサーに対し、設備、システム、資料その他のもの（以下総称して「貸与物件」といいます。）を、有償又は無償で登録インフルエンサーに貸与又は使用許諾することがあります。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 登録インフルエンサーは、次の各号に該当する場合、当社の指示に従い、速やかに貸与物件を当社に返却するか又は貸与物件の使用を中止するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    (1) 個別業務が終了したとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (2) 貸与期間が経過したとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (3) 当社が返却又は使用中止を要求した場合
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__11__条____成果物の納入</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    個別契約に成果物が定められた場合、登録インフルエンサーは、個別契約記載の納入期限までに、個別業務を完了し、個別契約記載の納入方法にて、成果物を自己の費用をもって当社に納入するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__12__条____検　収</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 登録インフルエンサーが成果物を納入したときは、当社は当社所定の検査方法に基づき、速やかに当該成果物の受入検査を行い、納入後7日以内にその結果を登録インフルエンサーに対して通知するものとし、当社から登録インフルエンサーに対する受入検査合格を証する通知をもって当該成果物の検収は完了されたものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当社は、前項の受入検査において成果物に個別契約の内容への不適合（以下「不適合」といいます。）を発見したときは、登録インフルエンサーに対して修正による履行の追完（以下「履行追完」といいます。）を求めることができるものとします。この場合、登録インフルエンサーは自己の責任と負担において、当社の指定する期日までに履行追完を行うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 前項に定める不適合が発見された成果物について、登録インフルエンサーが履行追完を行った場合、当社は速やかに再検査を行い、履行追完が行われた後7日以内にその結果を登録インフルエンサーに対して通知するものとし、当社から登録インフルエンサーに対する当該再検査の合格を証する通知をもって当該成果物の検収は完了されたものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    4. 再検査において成果物に不適合が発見された場合には、第2項及び第3項の定めが準用されるものとし、その後の検査についても同様とします。
                </p>
                <p style={{textAlign: "justify"}}>
                    5. 本条の定めは、登録インフルエンサーの遅延の責任を減免するものではなく、当社による解除権その他の権利行使を妨げるものではありません。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__13__条____業務委託料の支払</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    当社は、登録インフルエンサーに対し個別業務の対価として、個別契約に記載された業務委託料を、個別契約記載の支払期日その他の条件に従って支払うものとします。銀行振込手数料その他支払に要する費用は当社の負担とします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__14__条____不適合の追完責任</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 成果物の検収完了後1年以内に成果物に不適合が発見された場合、登録インフルエンサーは自己の責任と費用により、速やかに履行追完を行うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2． 本条の定めは、当社による代金減額請求、損害賠償請求及び解除の権利の行使を妨げないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__15__条____知的財産権</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 個別業務の過程で生じた知的財産権及び成果物に含まれる知的財産権は、発生と同時に当社に移転するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当事者間の合意その他何らかの理由により、前項の知的財産権が登録インフルエンサーに留保された場合でも、当社は、当該知的財産権に関する使用等の対価を支払うことなく、個別業務の過程で生じた知的財産権及び成果物を自由に利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 登録インフルエンサーは、成果物その他個別業務の過程で作成された著作物について、著作者人格権を行使しないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__16__条____第三者の権利侵害等</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 登録インフルエンサーは個別業務の実施に当たり第三者の権利を侵害しないように留意すると共に、個別業務の遂行又は成果物が、第三者のいかなる権利も侵害していないことを当社に保証するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 前項の定めに拘らず、個別業務又は成果物について、第三者から当社に対して何らかの訴え、異議、請求等がなされた場合において、当社から処理の要請がなされたときは、登録インフルエンサーは自己の責任と費用負担において、当社に代わって当該第三者との紛争を処理するとともに、当社がかかる訴え、異議、請求等により被った一切の損害（弁護士費用を含みます。）を賠償するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 登録インフルエンサーは、個別業務の実施に当たり、不当景品類及び不当表示防止法（優良誤認表示、有利誤認表示、及び内閣総理大臣が指定する告示に関する規定を含みますが、これらに限られません。）、医療機器等の品質、有効性及び安全性の確保等に関する法律その他の法令並びに当該法令に関するガイドラインを遵守するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__17__条____アカウント情報の管理</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 登録インフルエンサーは、自己の責任において、本サービスにかかるID及びパスワード（以下「アカウント情報」といいます。）を管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録インフルエンサーが負うものとし、当社は一切の責任を負いません。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 登録インフルエンサーは、アカウント情報が盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__18__条____禁止行為</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 登録インフルエンサーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
                </p>
                <p style={{textAlign: "justify"}}>
                    (1) 当社、又は他の登録インフルエンサーその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
                </p>
                <p style={{textAlign: "justify"}}>
                    (2) 当社の事前の書面による承諾なしに、登録インフルエンサーが遂行した個別業務にかかる広告主（当社の顧客である広告主を意味します。）と取引又は取引を誘引する行為（但し、本サービスにより募集されていないPR案件を他社が運営するサービスを介して受託することを除きます。）
                </p>
                <p style={{textAlign: "justify"}}>
                    (3) 犯罪行為に関連する行為又は公序良俗に反する行為
                </p>
                <p style={{textAlign: "justify"}}>
                    (4) コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
                </p>
                <p style={{textAlign: "justify"}}>
                    (5) 当社による本サービスの運営を妨害するおそれのあると合理的に認められる行為
                </p>
                <p style={{textAlign: "justify"}}>
                    (6) その他、当社が不適切と合理的に判断する行為
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当社は、本サービスにおける登録インフルエンサーによる情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が合理的に判断した場合には、登録インフルエンサーに事前に通知することなく、当該情報の全部又は一部について削除、送信停止その他の措置をとることができるものとします。また、当社は、登録インフルエンサーが個別業務の実施として行った投稿その他の情報の発信について、当該情報の削除、修正その他の措置を指示することができるものとし、登録インフルエンサーは、当該指示に従うものとします。当社は、本項に基づき当社が行った措置に基づき登録インフルエンサーに生じた損害について、一切の責任を負いません。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__19__条____本サービスの停止等</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 当社は、以下のいずれかに該当する場合には、登録インフルエンサーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    (1) 本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (2) コンピューター、通信回線等が事故により停止した場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (3) 火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (4) その他、当社が停止又は中断を合理的に必要と判断した場合
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当社は、当社の合理的な判断により、本サービスの提供を終了することができます。この場合、当社は登録インフルエンサーに事前に通知するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 当社は、本条に基づき当社が行った措置に基づき登録インフルエンサーに生じた損害について、一切の責任を負いません。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__20__条____設備の負担等</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 本サービスの提供を受けるために必要な、コンピューター、スマートフォン、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、登録インフルエンサーの費用と責任において行うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 登録インフルエンサーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 当社は、登録インフルエンサーが送受信したメッセージその他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__21__条____権利帰属</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    当社ウェブサイト及び本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本規約において明示されているものを除き、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__22__条____損害賠償等</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 登録インフルエンサーが、本規約又は個別契約に関連して当社又は第三者に損害を及ぼした場合には、登録インフルエンサーはその損害（直接損害及び通常損害のみならず、逸失利益、事業機会の喪失、データの喪失、事業の中断、その他の間接損害、特別損害、派生的損害及び付随的損害を含む全ての損害を意味します。）を賠償する責任を負うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 登録インフルエンサーが、個別契約に従って個別業務（PR案件に関する投稿を含みますが、これに限られません。）を実施しなかった場合、登録インフルエンサーは、当該個別業務に関して提供を受けた第三者の商品、役務その他のものに関する費用及びこれらの送料を当社に支払うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__23__条____不可抗力</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    いずれの当事者も、自らの合理的な支配の及ばない状況（火事、停電、ハッキング、コンピューターウィルスの侵入、地震、洪水、戦争、疫病、通商停止、ストライキ、暴動、物資及び輸送施設の確保不能、政府当局若しくは地方自治体による介入、指示若しくは要請、又は内外法令の制定若しくは改廃を含みますがこれらに限られません。）により本規約上の義務を履行できない場合、その状態が継続する期間中相手方に対し債務不履行責任を負わないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__24__条____解　除</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 当社及び登録インフルエンサーは、相手方に次の各号に掲げる事由の一つが生じたとき（但し、第2号から第7号までの規定は、登録インフルエンサーに当該各号に掲げる事由が生じたときに限ります。）には、催告を要せず相手方に書面で通知することにより直ちに利用契約及び個別契約の全部又は一部を将来に向かって解除することができるものとします。また、当社は、登録インフルエンサーに次の各号に掲げる事由の一つが生じたときには、事前に通知又は催告することなく、当該登録インフルエンサーについて本サービスの利用を一時的に停止することができます。
                </p>
                <p style={{textAlign: "justify"}}>
                    (1) 本規約又は個別契約に違反し、その是正を求める通知を受領後15日以内に当該違反の是正をしないとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (2) 登録情報に虚偽の事実があることが判明したとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (3) 当社、他の登録インフルエンサーその他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとしたとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (4) 手段の如何を問わず、本サービスの運営を妨害したとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (5) 死亡したとき又は後見開始、保佐開始若しくは補助開始の審判を受けたとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (6) 第3条第3項各号に該当する場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (7) その他、当社が登録インフルエンサーとしての登録の継続を適当でないと合理的に判断した場合
                </p>
                <p style={{textAlign: "justify"}}>
                    (8) 第12条第3項の再検査の結果再度成果物が不合格となったとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (9) 支払停止若しくは支払不能となり、又は、破産手続開始、民事再生手続開始若しくは会社更生手続開始その他これらに類する手続開始の申立てがあったとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (10) 振り出し若しくは引き受けた手形若しくは小切手が不渡りとなったとき、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (11) 仮差押え若しくは仮処分の命令を受け、その効力が15日以上継続した場合、又は差押え若しくは競売の申立てを受けたとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (12) 公租公課の滞納処分を受けたとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (13) 解散したとき（合併による場合を除きます。）、清算開始となったとき、又は事業の全部（実質的に全部の場合を含みます。）を第三者に譲渡したとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (14) 監督官庁から営業停止又は営業免許若しくは営業登録の取消等の処分を受けたとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (15) 資産、信用状態が悪化し、又はそのおそれがあると認められる相当の理由があるとき
                </p>
                <p style={{textAlign: "justify"}}>
                    (16) 天災等の不可抗力により個別業務の遂行が不可能となったとき
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 個別契約が解除された場合、当該個別契約について、当社は自己の裁量に基づき、完成済み又は未完成の成果物の納入を登録インフルエンサーに請求することができるものとします。この場合、当社は納入を受けた成果物の出来高に応じた対価を算定して登録インフルエンサーに通知するものとし、過払いがある場合は登録インフルエンサーは直ちに超過額を当社に返還し、未払いがある場合は当社は当該納入後30日以内に未払額を登録インフルエンサーに支払うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 本条の定めは、民法第641条その他法令上当社に認められた解除権の行使を妨げるものではありません。
                </p>
                <p style={{textAlign: "justify"}}>
                    4. 登録インフルエンサーは、個別契約の有効期間中である場合を除き、当社所定の方法で当社に通知することにより、利用契約を解除することができます。
                </p>
                <p style={{textAlign: "justify"}}>
                    5. 登録インフルエンサーは本規約に明記される場合のほか、利用契約又は個別契約を解除することはできないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    6. 当社は、本条に基づき当社が行った行為により登録インフルエンサーに生じた損害について、一切の責任を負いません。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__25__条____保証の否認及び免責</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 当社は、当社の登録インフルエンサーに対する個別業務の委託の有無及び数につき如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 登録インフルエンサーが当社から直接又は間接に、本サービス、当社ウェブサイト、本サービスの他の登録インフルエンサーその他の事項に関する何らかの情報を得た場合であっても、当社は登録インフルエンサーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 本サービス又は当社ウェブサイトに関連して登録インフルエンサーと他の登録インフルエンサー、その他の第三者との間において生じた取引、連絡、紛争等については、登録インフルエンサーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
                </p>
                <p style={{textAlign: "justify"}}>
                    4. 当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、登録インフルエンサーのメッセージ又は情報の削除又は消失､本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録インフルエンサーが被った損害につき、賠償する責任を一切負わないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    5. 当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して、一切の責任を負わないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    6. 強行法規の適用その他何らかの理由により、当社が登録インフルエンサーに対して損害賠償責任を負う場合においても、当社の賠償責任は、直接かつ通常の損害に限り、逸失利益、間接損害等は含まないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__26__条____反社会的勢力の排除</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 当社及び登録インフルエンサーは、相手方又は利用契約若しくは個別契約締結に関する相手方の代理人若しくは利用契約締結を媒介した者が反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団員又は暴力団準構成員でなくなった日から5年を経過しない者、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人を意味します。以下同じ。）であることが判明したときには、催告を要せず相手方に書面で通知することにより直ちに利用契約及び個別契約の全部又は一部を将来に向かって解除することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当社及び登録インフルエンサーは、相手方が自ら又は第三者を利用して以下の各号の一にでも該当する行為をした場合には、催告を要せず相手方に書面で通知することにより直ちに利用契約及び個別契約の全部又は一部を将来に向かって解除することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    (1) 暴力的な要求行為
                </p>
                <p style={{textAlign: "justify"}}>
                    (2) 法的な責任を超えた不当な要求行為
                </p>
                <p style={{textAlign: "justify"}}>
                    (3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為
                </p>
                <p style={{textAlign: "justify"}}>
                    (4) 風説を流布し、偽計若しくは威力を用いて信用を毀損し、又は業務を妨害する行為
                </p>
                <p style={{textAlign: "justify"}}>
                    (5) その他前各号に準ずる行為
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 当社及び登録インフルエンサーは、相手方が利用契約若しくは個別契約に関連して締結した契約（以下「関連契約」といいます。）の当事者又は関連契約の締結に関する関連契約の当事者の代理人若しくは関連契約の締結を媒介した者が反社会的勢力であることが判明した場合には、相手方に対し、当該関連契約の解除その他の必要な措置を講ずるよう求めることができるものとします。相手方が正当な理由なくかかる請求を拒否した場合、請求した当事者は催告を要せず相手方に書面で通知することにより直ちに利用契約及び個別契約の全部又は一部を将来に向かって解除することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    4. 当社及び登録インフルエンサーは、自己又は自己の関連契約の当事者が、本規約に関連して、反社会的勢力から不当要求、業務妨害その他の不当な介入（以下「不当介入」といいます。）を受けた場合は、これを拒否し、又は関連契約の当事者をしてこれを拒否させるとともに、不当介入があった時点で、速やかに不当介入の事実を相手方に報告し、相手方の捜査機関への通報及び報告に必要な協力を行うものとします。相手方が本項の規定に違反した場合には、当社及び登録インフルエンサーは催告を要せず相手方に書面で通知することにより直ちに利用契約及び個別契約の全部又は一部を将来に向かって解除することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    5. 前各項に定める場合のほか、当社及び登録インフルエンサーは、相手方の取締役、監査役、従業員その他の構成員、株主、取引先、若しくは顧問その他のアドバイザーが反社会的勢力であること、又は相手方が資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力との何らかの交流若しくは関与を行っていることが判明した場合は、相手方に書面で通知することにより直ちに利用契約及び個別契約の全部又は一部を将来に向かって解除することができるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    6. 本条に基づき利用契約又は個別契約を解除した当事者は、当該解除により相手方に生じた損害の賠償責任を負わないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__27__条____契約終了時の取扱い</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    利用契約が期間満了又は解除により終了した場合でも、個別契約（解除された場合を除きます。）は個別契約所定の有効期間中有効に存続するものとし、本規約の定めは当該個別契約に関する限りにおいて、当該個別契約の有効期間中効力を有するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__28__条____秘密保持</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 本規約において「秘密情報」とは、利用契約、個別契約又は本サービスに関連して、登録インフルエンサーが、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、当社の顧客の情報、個別契約の内容、個別業務の内容、その他の事項に関する全ての情報を意味します。但し、(1)当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)当社から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)当社から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 登録インフルエンサーは、秘密情報を本規約又は個別契約の履行目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    3. 第2項の定めに拘わらず、登録インフルエンサーは、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。
                </p>
                <p style={{textAlign: "justify"}}>
                    4. 登録インフルエンサーは、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には、事前に当社の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    5. 登録インフルエンサーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__29__条____個人情報等の取扱い</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 当社による登録インフルエンサーの個人情報（個人情報の保護に関する法律第2条第1項に定める「個人情報」を意味します。）の取扱いについては、別途定める当社のプライバシーポリシーの定めによるものとし、登録インフルエンサーはこのプライバシーポリシーに従って当社が登録インフルエンサーの個人情報を取扱うことについて同意するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当社は、登録インフルエンサーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録インフルエンサーはこれに異議を述べないものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__30__条____有効期間</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    利用契約は、登録インフルエンサーについて第3条に基づく登録が完了した日に効力を生じ、利用契約が解除された日又は本サービスの提供が終了した日のいずれか早い日まで、当社と登録インフルエンサーとの間で有効に存続するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__31__条____本規約等の変更</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 当社は、本サービスの内容を自由に変更できるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更する場合には、変更の内容及び変更の効力発生時期を、当該効力発生時期までに当社所定の方法で告知するものとします。告知された効力発生時期以降に登録ユーザーが本サービスを利用した場合には、登録ユーザーは、本規約の変更に同意したものとみなします。但し、効力発生時期より前に締結した個別契約については、変更前の本規約が適用されるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__32__条____連絡/通知</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    本サービスに関する問い合わせその他登録インフルエンサーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から登録インフルエンサーに対する連絡又は通知は、当社の定める方法で行うものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__33__条____本規約の譲渡等</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    1. 登録インフルエンサーは、当社の書面による事前の承諾なく、利用契約若しくは個別契約上の地位又は利用契約若しくは個別契約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                </p>
                <p style={{textAlign: "justify"}}>
                    2. 当社は本サービス及び個別契約にかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約及び個別契約上の地位、利用契約及び個別契約に基づく権利及び義務並びに登録インフルエンサーの登録情報その他の顧客情報を当該譲渡の譲受人に譲渡することができるものとし、登録インフルエンサーは、かかる譲渡につき本項において予め同意したものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__34__条____完全合意</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    本規約は、本規約に含まれる事項に関する当社と登録インフルエンサーとの完全な合意を構成し、本規約に含まれる事項に関する、書面、口頭その他いかなる方法による当社と登録インフルエンサーとの事前の合意、表明及び了解にも優先します。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__35__条____分離可能性</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    本規約又は個別契約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約又は個別契約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び登録インフルエンサーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__36__条____存続規定</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    第10条第3項、第13条（未払がある場合に限ります。）、第14条から第16条まで、第17条第2項、第18条第2項、第19条第3項、第20条から第23条まで、第24条第2項及び第6項、第25条、第26条第6項、第27条から第29条まで、並びに第33条から第37条までの規定は利用契約及び個別契約の終了後も有効に存続するものとします。但し、第28条については、利用契約終了後5年間に限り存続するものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__37__条____準拠法及び管轄裁判所</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
                </p>
                <p style={{textAlign: "justify"}}>
                    <u>第__38__条____協議解決</u>
                </p>
                <p style={{textAlign: "justify"}}>
                    当社及び登録インフルエンサーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
                </p>
                <p style={{textAlign: "justify"}}>
                    【2023年5月29日 制定】
                </p>

            </div>
        </div>
        <CheckboxBool
          label="利用規約に同意する"
          value={checkStatus}
          setState={setCheckStatus}
          className={styles.pr_check_config}
          name="checkbox"
        />
        {
          props.method == 'popupAuthent' || props.method == 'popupSessions' ?
            <>
            {
              checkStatus ? 
              <p className={styles.pr_btn} onClick={() => onSubmit()}>次へ進む</p> 
              : 
              <p className={styles.pr_btn_dislable} >次へ進む</p>
            }
              
              <p className={styles.pr_link} onClick={() => closeModal()}>キャンセル</p>
            </>
            :
            <>
            {
              checkStatus ? 
              <button className={styles.pr_btn} onClick={() => onSubmit()}>次へ進む</button>
              : 
              <button className={styles.pr_btn_dislable}>次へ進む</button>
            }
              
              <button className={styles.pr_link} onClick={() => closeModal()}>キャンセル</button>
            </>
        }
        {
          props.current_influencer === true ? '' : <p className={styles.pr_mesager_warning}>※キャンセルを選択された場合、アカウントは自動的にログアウトされます</p>
        }
      </div>
    </RailsForm>
  )
  return (
    <Modal
      status={modalStatus}
      content={modalContent}
      setState={setModalStatus}
      className={styles.popup_rules__config}
      notAutoClose={true}
    />
  )
}

Popup.propTypes = propTypes
export default Popup