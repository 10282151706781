import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import Loading from "./LazyLoad/loading.svg"
import NoImage from "./LazyLoad/noimage.png"

const propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
}

const defaultProps = {
  src: "",
  className: "",
}

const Lazyload = (props) => {
  const [loaded, setLoaded] = useState(false)
  const [src, setSrc] = useState()

  useEffect(() => {
    if(props.src === "null" || !props.src){
      setLoaded(true)
      setSrc(NoImage)
      return
    }
    const img = new Image()

    img.src = props.src
    img.onload = () => {
      setLoaded(true)
      setSrc(props.src)
    }
    img.onerror = () => {
      setLoaded(true)
      setSrc(NoImage)
    }
  }, [props.src])

  return (
    <img src={loaded ? src : Loading} className={props.className} />
  )
}

Lazyload.propTypes = propTypes
Lazyload.defaultProps = defaultProps

export default Lazyload
