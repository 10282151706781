import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import Select from "../Select.js"

const SelectYear = (props) => {
  const now = new Date()
  const currentYear = now.getFullYear()
  
  let years = []
  for (let index = currentYear; index > 1960; index--) {
    years.push({
      value: index,
      name: index
    })
  }

  return (
    <Select
      label={props.label}
      id={props.id}
      name={props.name}
      options={years}
      value={props.value}
      className={props.className}
      onClick={props.onClick}
      onChange={props.onChange}
      setState={props.setState}
      required={props.required}
    />
  )
}

export default SelectYear