import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RoundBtn from "../../../Shared/Buttons/Round.js"
import Modal from "../../../Shared/Modal"
import EntryTable from "./index/EntryTable"
import styles from "./Index.scss"

const propTypes = {
  projects: PropTypes.array.isRequired
}

const Index = (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`

  const [projects, setProjects] = useState(props.projects)
  const [checkedEntries, setCheckEntries] = useState({})
  const [modalStatus, setModalStatus] = useState(false);
  const [postEntryStatus, setPostEntryStatus] = useState("");

  const filteredEntries = () => {
    let result = []
    Object.keys(checkedEntries).map((key) => {
      if (checkedEntries[key] === true) {
        result.push(key)
      }
    })
    return result
  }

  const confirmEntriesStatus = (status) => {
    setPostEntryStatus(status)
    setModalStatus(true)
    console.log(filteredEntries())
  }

  const fetchEntry = () => {
    callAnalytics(String(currentPageUrl).replace(currentPageOrigin, ""))
    fetch(currentPageUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      setProjects(jsonData.items)
    }).catch((error) => {
      console.log(error)
    })
  }

  const postEntriesStatus = (status) => {
    let postUrl = ""
    if (status === "approve") {
      postUrl = `${currentPageUrl}/approve`
    } else if (status === "reject") {
      postUrl = `${currentPageUrl}/reject`
    } else {
      return
    }
    fetch(postUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: JSON.stringify({
        "entries": filteredEntries()
      }),
    }).then(() => {
      fetchEntry()
    }).catch((error) => {
      console.log(error)
    })
    setCheckEntries({})
    setModalStatus(false)
  }

  const ProjectList = []

  projects.forEach((project) => {
    ProjectList.push(
      <>
        <h4>
          {project.project.title}
        </h4>
        <EntryTable
          entries={project.entries}
          checkedEntries={checkedEntries}
          setCheckEntries={setCheckEntries}
        />
      </>
    )
  })

  return (
    <div>
      <div className={styles.title_container}>
        <h1>応募</h1>
        <div className={styles.button_container}>
          <RoundBtn
            text="非承認にする"
            type="check"
            color="main-border"
            onClick={() => confirmEntriesStatus("reject")}
          />,
          <RoundBtn
            text="承認する"
            type="check"
            color="main"
            onClick={() => confirmEntriesStatus("approve")}
          />
        </div>
      </div>
      <div>
        {ProjectList}
      </div>
      <Modal
        status={modalStatus}
        setState={setModalStatus}
      >
        <div className={`${styles.modal_btn_container}`}>
          <div>{filteredEntries().length} 件のステータスを変更しますか？</div>
          <div className={styles.modal_btn_area}>
            {postEntryStatus == "approve" && (
              <RoundBtn
                text="承認する"
                type="check"
                color="main"
                onClick={() => postEntriesStatus("approve")}
              />
            )}
            {postEntryStatus == "reject" && (
              <RoundBtn
                text="非承認にする"
                type="check"
                color="main-border"
                onClick={() => postEntriesStatus("reject")}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

Index.propTypes = propTypes

export default Index
