import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./Edit.scss"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import Submit from "../FormTags/Submit.js"

const INTERCOM_APP_ID = 'axv39iqc';

const propTypes = {
  email: PropTypes.string.isRequired,
}

const Edit = (props) => {
  const [email, setEmail] = useState();

  return (
    <div className={styles.container}>
      <RailsForm
        action={"/influencer/email"}
        method={"patch"}
      >
        <TextField
          label="登録済みのメールアドレス"
          value={props.email}
          required={true}
          readonly={true}
          diabled={true}
        />
        <TextField
          label="新しいメールアドレス"
          name={"influencer[email]"}
          value={email}
          setState={setEmail}
          required={true}
        />
        <Submit
          className={styles.form_submit}
          buttonColor={"brown"}
          text={"変更を保存する"}
        />
      </RailsForm>
    </div>
  )
}

Edit.propTypes = propTypes

export default Edit
