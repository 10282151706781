import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Index.scss"
import RewardSummary from "../Shared/RewardSummary.js"

const propTypes = {
  todo: PropTypes.object.isRequired,
  reward_summary: PropTypes.object.isRequired
}

const Index = (props) => {
  return (
    <div>
      <h1>ホーム</h1>
      <div className={styles.summary_container}>
        <RewardSummary reward_summary={props.reward_summary}/>
        <a href="/agency/reports">売上レポート ▷</a>
      </div>
    </div>
  )
}

Index.propTypes = propTypes
export default Index
