import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import LazyLoad from "../../Shared/LazyLoad.js"
import ArrowLink from "../Shared/ArrowLink.js"
import RoundBtn from "../Shared/Buttons/Round.js"
import Tab from "../Shared/Tab.js"
import Panel from './Show/Panel.js'
import styles from "./Show.scss"
import PreviewModal from "./Show/PreviewModal.js"

const propTypes = {
  project: PropTypes.object.isRequired,
  entries: PropTypes.array.isRequired,
}

const Show = (props) => {
  const [previewModalState, setPreviewModalState] = useState(false)
  const sponsorProjectsPath = `/sponsor/projects`

  const panels = Panel(props)

  const showPreview = () => {
    setPreviewModalState(true)
  }

  return (
    <div>
      <h1>プロジェクト管理</h1>
      <ArrowLink
        text="プロジェクト一覧に戻る"
        url={sponsorProjectsPath}
      />

      <div className={styles.project_title_container}>
        <div><LazyLoad src={String(props.project.image)} /></div>
        <div>
          <span className={styles.project_category}>
            {props.project.project_type.name}
          </span>
          <div className={styles.project_title}>
            {props.project.title}
          </div>
        </div>
      </div>

      <div className={styles.menu_container}>
        <div className={styles.menu_btn_container}>
          <div className={styles.menu_btn_item}>
            <a href={`/sponsor/projects/${props.project.id}/edit`}>
              <RoundBtn
                text="プロジェクトを編集"
                type="pen"
                color="gray-border"
              />
            </a>
          </div>

          <div className={styles.menu_btn_item}>
            <RoundBtn
              text="インフルエンサーへの見え方"
              type="eye"
              color="gray-border"
              onClick={showPreview}
            />
          </div>
        </div>
      </div>

      <Tab
        panels={panels}
        rightSide={props.project.project_achievement_reward}
      />
      <PreviewModal projectId={props.project.id} previewModalState={previewModalState} setPreviewModalState={setPreviewModalState}/>
    </div>
  )
}

Show.propTypes = propTypes

export default Show
