import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import TextField from "../TextField.js"
import Select from "../Select.js"
import styles from "./Category.scss"

const SelectCategory = (props) => {
  const categories = props.categories
  const [categoryId, setCategoryId] = useState(props.categoryId)

  const [mainlyOptions, setMainlyOptions] = useState([])
  const [mainlyCategory, setMainlyCategory] = useState()
  const [notMainlyCategoryOptions, setNotMainlyCategoryOptions] = useState([])
  const [notMainlyCategoryId, setNotMainlyCategoryId] = useState()
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [subCategoryId, setSubCategoryId] = useState(props.subCategoryId)

  useEffect(() => {
    const newMainlyOptions = []
    const newNotMainlyCategoryOptions = []

    categories.forEach((category) => {
      if(category.mainly_displayed) {
        newMainlyOptions.push({
          value: category.id,
          name: category.name,
        })
      } else {
        newNotMainlyCategoryOptions.push({
          value: category.id,
          name: category.name,
        })
      }
    })
    newMainlyOptions.push({
      value: "",
      name: "その他"
    })

    setMainlyOptions(newMainlyOptions)
    setNotMainlyCategoryOptions(newNotMainlyCategoryOptions)
    setCategoryId(newMainlyOptions[0].value)
    setMainlyCategory(newMainlyOptions[0].value)
    updateSubcategory(newMainlyOptions[0].value)
    setNotMainlyCategoryId(newNotMainlyCategoryOptions[0].value)
  }, [])

  useEffect(() => {
    console.log(`categoryId ${categoryId}`)
    console.log(`subCategoryId ${subCategoryId}`)
  })

  const updateSubcategory = (_main) => {
    const category =
      categories.find((category) => {
        return category.id == (_main || mainlyCategory)
      })
    if(category) {
      setSubCategoryOptions(
        category.children.map((c) => {
          return { value: c.id, name: c.name }
        })
      )

      setCategoryId(category.children[0].id)
      setSubCategoryId(category.children[0].id)
    } else {
      setSubCategoryOptions([])
      setCategoryId(notMainlyCategoryId)
    }
  }

  const onChangeMainSelect = () => {
    updateSubcategory()
  }

  const onChangeNotMainlyCategorySelect = () => {
    setCategoryId(notMainlyCategoryId)
  }

  const onChangeSubCategorySelect = () => {
    setCategoryId(subCategoryId)
  }

  return (
    <div>
      <div>
        {
          /*
           * TODO: 非表示にする
           */
        }

        <TextField
          label="投稿のカテゴリー"
          id="influencer_category_ids"
          name="influencer[category_ids][]"
          value={categoryId}
          setState={setCategoryId}
          required={true}
          className={styles.hiddenField}
        />
      </div>

      <Select
        label="投稿のカテゴリー"
        id=""
        name=""
        options={mainlyOptions}
        value={mainlyCategory}
        setState={setMainlyCategory}
        onChange={onChangeMainSelect}
        className={styles.mainCategory}
        required={true}
      />
      { subCategoryOptions.length === 0 &&
        <Select
          label=""
          id=""
          name=""
          options={notMainlyCategoryOptions}
          value={notMainlyCategoryId}
          onChange={onChangeNotMainlyCategorySelect}
          setState={setNotMainlyCategoryId}
          className={styles.nestedCategory}
          required={true}
        />
      }
      { subCategoryOptions.length > 0 &&
        <Select
          label=""
          id=""
          name=""
          options={subCategoryOptions}
          value={subCategoryId}
          setState={setSubCategoryId}
          onChange={onChangeSubCategorySelect}
          className={styles.nestedCategory}
          required={true}
        />
      }
    </div>
  )
}

export default SelectCategory
