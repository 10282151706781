import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import RailsForm from "../../../Shared/RailsForm.js"
import Submit from "../../FormTags/Submit.js"
import TextField from "../../FormTags/TextField.js"

const Password = () => {
  const staffPath = `/staff`

  const elements = (
    <>
      <TextField
        type="password"
        label="現在のパスワード"
        id="staff_current_password"
        name="staff[current_password]"
      />

      <TextField
        type="password"
        label="新しいパスワード"
        id="staff_password"
        name="staff[password]"
      />

      <TextField
        type="password"
        label="パスワード確認"
        id="staff_password_confirmation"
        name="staff[password_confirmation]"
      />

      <Submit text="保存する" />
    </>
  )

  return (
    <RailsForm
      action={staffPath}
      elements={elements}
      patch={true}
    />
  )
}

export default Password
