import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./Index.scss";
import MessageList from "./Shared/MessageList.js";
import MessageTable from "./Shared/MessageTable.js";
import MessagePairProject from "./Shared/MessagePairProject";
import loadingImage from "../Shared/Icons/loading.gif"

const propTypes = {
}

const Index = (props) => {
  const [messageRoom, setMessageRoom] = useState(props.message_room)
  const [entries, setEntries] = useState(props.entries)
  const [messageRooms, setMessageRooms] = useState(props.message_rooms)
  const [loadingImageStatus, setLoadingImageStatus] = useState(false)

  return (
    <div className={styles.message_body}>
      <MessageList setMessageRoom={setMessageRoom} setEntries={setEntries} messageRooms={messageRooms} setMessageRooms={setMessageRooms} setLoadingImageStatus={setLoadingImageStatus} />

      { loadingImageStatus && <p className={styles.loading_image_container}><img src={loadingImage} className={styles.loading_image} /></p>}
      {
        !loadingImageStatus && messageRoom && entries &&
        <>
          <MessageTable
            messageRoom={messageRoom}
          />

          <MessagePairProject
            entries={entries}
          />
        </>
      }
      {
        !loadingImageStatus && !messageRoom && !entries &&
        <div>メッセージは選択されていません</div>
      }
    </div>
  )
}

Index.propTypes = propTypes

export default Index
