import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import ReactTooltip from 'react-tooltip'
import QuestionMarkImage from "./QuestionMark/question_mark.svg"
    
const QuestionMark = (props) => {
    return (
        <span data-tip={props.message}>
            <QuestionMarkImage />
            <ReactTooltip effect="solid" place="bottom" />
        </span>
    )
}

export default QuestionMark
