import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import styles from "./Create.scss"
import RailsForm from "./../../../Shared/RailsForm.js"
import TextField from "./../../FormTags/TextField.js"
import Submit from "./../../FormTags/Submit.js"

const propTypes = {
  withdrawal_request: PropTypes.object.isRequired
}

const Show = (props) => {
  const withdrawalAmount = props.withdrawal_request.withdrawal_amount
  const fee = props.withdrawal_request.fee
  const transfer_amount = props.withdrawal_request.transfer_amount

  return (
    <div>
      <h1>引き出し額の確認</h1>
      <div className={styles.confirmBoxContainer}>
        <div className={styles.confirmBox}>
          <p className={styles.confirmBoxLabel}>申請額</p>
          <p className={styles.confirmBoxValue}>{withdrawalAmount.toLocaleString()}<span className={styles.confirmBoxUnit}>円</span></p>
        </div>
        <div className={styles.confirmBox}>
          <p className={styles.confirmBoxFeeLabel}>手数料</p>
          <p className={styles.confirmBoxFeeValue}>-{fee.toLocaleString()}<span className={styles.confirmBoxUnit}>円</span></p>
        </div>
        <hr className={styles.hr}/>
        <div className={styles.confirmBox}>
          <p className={styles.confirmBoxLabel}>申請額</p>
          <p className={styles.confirmBoxValue}>{transfer_amount.toLocaleString()}<span className={styles.confirmBoxUnit}>円</span></p>
        </div>
      </div>
      <RailsForm
        action={"/influencer/withdrawal_requests"}
      >
        <TextField
          label="引き出し額"
          id="withdrawal_request_withdrawable_amount"
          name="withdrawal_request[withdrawal_amount]"
          type="number"
          value={withdrawalAmount}
          required={true}
          className={styles.withdrawalAmountField}
        />

        <Submit
          className={styles.largeRoundnessButton}
          text={"申請する"}
          buttonType="circle"
        />
      </RailsForm>
    </div>
  )
}

Show.propTypes = propTypes

export default Show
