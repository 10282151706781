import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'

import RoundBtn from "../../Shared/Buttons/Round.js"
import SwitchBtn from "../../Shared/Buttons/Switch.js"
import Layout from "./Shared/Layout.js"
import LazyLoad from "../../../Shared/LazyLoad.js"
import CheckboxBool from "../../FormTags/CheckboxBool"
import Select from "../../FormTags/Select"
import TextField from "../../FormTags/TextField"
import fetchProjectEntry from "./fetchEntry.js"

import Modal from "../../Shared/Modal"
import Message from "./Shared/Message.js"

import styles from "./Unshipped.scss"
import InfluencerDetailModal from "./../../Shared/InfluencerDetailModal.js"

const Unshipped = (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`

  const [shipmentCarriers, setShipmentCarriers] = useState([])
  const [shipmentCarrier, setShipmentCarrier] = useState(1)
  const [trackingCode, setTrackingCode] = useState("")
  const [checkedEntries, setCheckEntries] = useState({})
  const [modalStatus, setModalStatus] = useState(false)
  const [loadingImageStatus, setLoadingImageStatus] = useState(false);
  const statusUnshipment = "unshipment"
  const statusShipment = "shipment"
  const [entries, setEntries] = useState({ [statusUnshipment]: [], [statusShipment]: [] })
  const [currentStatus, setCurrentStatus] = useState(statusUnshipment)
  const [influencerId, setInfluencerId] = useState(null)
  const [influencerDetailModalStatus, setInfluencerDetailModalStatus] = useState(false)

  useEffect(() => {
    fetchEntry(statusUnshipment)
    fetchShipmentCarriers()
  }, [])

  const selectEntry = (e) => {
    setCheckEntries({
      ...checkedEntries,
      [e.target.id]: e.target.checked
    })
  }

  const selectShipmentCarriers = () => {
    const shipmentCarriersOptions = []
    shipmentCarriers.forEach(carrier => {
      var opt = { value: carrier.id, name: carrier.name }
      shipmentCarriersOptions.push(opt)
    })
    return shipmentCarriersOptions
  }

  const filteredEntries = () => {
    var result = []
    Object.keys(checkedEntries).map((key) => {
      if (checkedEntries[key] == true) {
        result.push(key)
      }
    })
    return result
  }

  const confirmEntriesShipment = () => {
    setModalStatus(true)
  }

  const postShipmentEntries = () => {
    const postUrl = `${currentPageUrl}/entries/shipments`
    fetch(postUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: JSON.stringify({
        "entries": filteredEntries(),
        "shipment": {
          "shipment_carrier_id": shipmentCarrier,
          "tracking_code": trackingCode
        }
      }),
    }).then(() => {
      props.updatePanelCount()
      fetchEntry(statusUnshipment)
    }).catch((error) => {
      console.log(error)
    })
    setCheckEntries({})
    setModalStatus(false)
  }

  const createApiEndpoint = (status) => {
    switch (status) {
      case "unshipment":
        return `${currentPageUrl}/entries/unshipments`
      case "shipment":
        return `${currentPageUrl}/entries/shipments`
      default:
        console.log("The specified type does not exist")
        return null
    }
  }
  
  const fetchShipmentCarriers = () => {
    const apiEndPoint = `${currentPageOrigin}/sponsor/shipment_carriers`
    callAnalytics(String(apiEndPoint).replace(currentPageOrigin, ""))
    fetch(apiEndPoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      setShipmentCarriers(jsonData.items)
    }).catch((error) => {
      console.log(error)
    })
  }

  const fetchEntry = (status) => {
    setCurrentStatus(status)
    fetchProjectEntry({
      status: status,
      setEntries: setEntries,
      loadStart: () => { setLoadingImageStatus(true) },
      loadEnd: () => {
        setLoadingImageStatus(false)
      }
    })
  }

  const topRightButtons = ( entries[statusUnshipment].length ? [
    <a href={`/sponsor/projects/${props.project.id}/delivery_informations.csv`}>
      <RoundBtn
        text="CSVで住所をエクスポート"
        type="check"
        color="main-border"
      />
    </a>,
    <RoundBtn
      text="発送連絡する"
      type="check"
      color="main"
      onClick={() => confirmEntriesShipment()}
    />
  ] : [])

  const entryList = entries[currentStatus].length ? [
    <tr key="head">
      <th>インフルエンサー</th>
      <th>発送商品</th>
      <th>発送先住所</th>
      <th>メッセージ</th>
    </tr>
  ] : []

  entries[currentStatus].forEach((entry, index) => {
    entryList.push(
      <tr key={entry.id} className="unshipped">
        <td>
          <div className={styles.influencer_name_column}>
            <div className={styles.influencer_checkbox}>
               {!entry.shipment && (
                  <CheckboxBool
                    id={entry.id}
                    name="entries"
                    className={styles.influencer_checkbox}
                    value={false}
                    onChange={selectEntry}
                  />
               )}
            </div>

            <div className={styles.influencer_name}
              onClick={(e) => (
                setInfluencerId(entry.influencer.id),
                setInfluencerDetailModalStatus(true)
              )}
            >
              <div className={styles.influencer_icon}>
                <LazyLoad src={String(entry.influencer.profile_pic)}/>
              </div>
              <p>
                {entry.influencer.name}
              </p>
            </div>
          </div>
        </td>
        <td>
          <div className={styles.postal_code}>
            {entry.project.product.name}
            {entry.product_option_variations.length ? <br /> : <></>}
            {entry.product_option_variations.map((v)=> `${v.option_name}: ${v.name}`).join("、 ")}
          </div>
        </td>
        <td>
          { entry.delivery_information &&
            <>
              <div className={styles.entry_address_postal_code}>
                〒{entry.delivery_information.address.postal_code}
              </div>
              <div className={styles.entry_address}>
                <span>{entry.delivery_information.address.prefecture.name}</span>
                <span>{entry.delivery_information.address.city}</span>
                <span>{entry.delivery_information.address.block}</span>
                <span>{entry.delivery_information.address.building}</span>
              </div>
              <div className={styles.entry_fullname}>
                <span>{entry.delivery_information.first_name} {entry.delivery_information.last_name}</span>
                {
                  entry.delivery_information.last_name_kana && entry.delivery_information.last_name_kana ?
                  <span>{`(${entry.delivery_information.last_name_kana} ${entry.delivery_information.first_name_kana})`}</span> :
                  <></>
                }
              </div>
              <div className={styles.entry_tel}>
                {entry.delivery_information.tel}
              </div>
            </>
          }
          { !entry.delivery_information &&
              <p>発送先情報がありません。メッセージで確認してください</p>
          }
        </td>
        <td><Message influencerId={entry.influencer.id} /></td>
      </tr>
    ) 
  })

  return (
    <div>
      <Layout
        topLeftButtons={[
          <SwitchBtn
            elements={[
              {
                text: `未発送${props.unshipmentCount > 0 ? `(${props.unshipmentCount})` : ""}`,
                selected: true,
                onClick: () => fetchEntry(statusUnshipment)
              },
              {
                text: `発送済${props.shippedCount > 0 ? `(${props.shippedCount})` : ""}`,
                onClick: () => fetchEntry(statusShipment)
              }
            ]}
          />,
        ]}
        topRightButtons={topRightButtons}
        table={entryList}
        loadingImageStatus={loadingImageStatus}
      />
      <Modal
      status={modalStatus}
      setState={setModalStatus}
      >
        <div className={`${styles.modal_btn_container}`}>
          <Select
            id="shipment_carrier"
            name="shipment_carrier"
            label="配送業者を選択"
            options={selectShipmentCarriers()}
            value={shipmentCarrier}
            setState={setShipmentCarrier}
          />
          <TextField
            id="tracking_code"
            name="tracking_code"
            type="text"
            label="追跡番号を入力"
            value={trackingCode}
            setState={setTrackingCode}
          />
          <div className={styles.modal_btn_area}>
            <RoundBtn
              text="発送連絡する"
              type="check"
              color="main"
              onClick={() => postShipmentEntries()}
            />
          </div>
        </div>
      </Modal>

      <InfluencerDetailModal
        influencerId={influencerId}
        influencerDetailModalStatus={influencerDetailModalStatus}
        setInfluencerDetailModalStatus={setInfluencerDetailModalStatus}
      />
    </div>
  )
}

export default Unshipped
