import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Form from "./Form.js"

const propTypes = {
  bank_account: PropTypes.object.isRequired
}

const Edit = (props) => {
  return (
    <div>
      <h1>振込先口座の指定</h1>
      <Form bank_account={props.bank_account} method={"patch"} />
    </div>
  )
}

Edit.propTypes = propTypes

export default Edit
