import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./MessageList.scss"
import LazyLoad from "../../../Shared/LazyLoad";

const propTypes = {
  messageRooms: PropTypes.array.isRequired,
}

const MessageList = (props) => {
  const messageRooms = props.messageRooms
  const influencerMessageRoomPath = `/influencer/messages`

  let messageList = []
  messageRooms.forEach((room, index) => {
    messageList.push(
      <a
        key={index}
        href={`${influencerMessageRoomPath}/${room.id}`}
        className={styles.message_open_link}
      >
        <div className={styles.message_pair}>
          <div className={styles.message_pair_icon}>
            <LazyLoad src={String(room.sponsor.image_url)}/>
          </div>
          <div className={styles.message_pair_info}>
            <div className={styles.message_pair_name}>{room.sponsor.name}</div>
            <div className={styles.truncate_message}>{room.truncate_latest_message}</div>
          </div>
          {room.influencer_unread_count > 0 ? <span className={styles.unread_message_mark} /> : ''}
        </div>
      </a>
    )
  })

  return (
    <div className={styles.message_list}>
      <div className={styles.message_list_header}><span>メッセージ一覧</span></div>
      <div className={styles.message_pairs}>
        {messageList}
      </div>
    </div>
  )
}

MessageList.propTypes = propTypes

export default MessageList