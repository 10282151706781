import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect, useState } from 'react'
import RoundBtn from "../../Shared/Buttons/Round.js"
import SwitchBtn from "../../Shared/Buttons/Switch.js"
import Layout from "./Shared/Layout.js"

import Modal from "../../Shared/Modal"

import styles from "./Management.scss"
import Moment from "moment"
import InfluencerDetailModal from "./../../Shared/InfluencerDetailModal.js"

const Management = (props) => {
  const currentPageOrigin = window.location.origin
  const currentPagePath = location.pathname
  const currentPageUrl = `${currentPageOrigin}${currentPagePath}`
  
  const [cvHistories, setCvHistories] = useState([])
  const [cvHistory, setCvHistory] = useState(null)
  const [modalStatus, setModalStatus] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(null)
  const [influencerId, setInfluencerId] = useState(null)
  const [influencerDetailModalStatus, setInfluencerDetailModalStatus] = useState(false)

  useEffect(() => {
    fetchCvHistory("all")
  }, [])

  const createApiEndpoint = (status) => {
    switch (status) {
      case "all":
        return `${currentPageUrl}/cv_histories`
      case "confirm":
        return `${currentPageUrl}/cv_histories/confirms`
      case "unconfirm":
        return `${currentPageUrl}/cv_histories/unconfirms`
      default:
        console.log("The specified type does not exist")
        return null
    }z
  }

  const fetchCvHistory = (status) => {
    const apiEndPoint = createApiEndpoint(status)
    callAnalytics(String(apiEndPoint).replace(currentPageOrigin, ""))
    fetch(apiEndPoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
    }).then((response) => response.json()).then((jsonData) => {
      setCvHistories(jsonData.items)
    }).catch((error) => {
      console.log(error)
    })
  }

  const unConfirmCvHistory = () => {
    const postUrl = `${currentPageUrl}/cv_histories/unconfirms`
    fetch(postUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
      },
      body: JSON.stringify({
        "history_id": cvHistory.id
      }),
    }).then(() => {
      props.updatePanelCount()
      if(currentStatus) {
        fetchCvHistory(currentStatus)
      } else {
        fetchCvHistory("all")
      }
    }).catch((error) => {
      console.log(error)
    })
    setModalStatus(false)
  }

  const topLeftButtons = ([
    <SwitchBtn
      elements={[
        {
          text: `未管理${props.unconfirmCvCount > 0 ? `(${props.unconfirmCvCount})` : ""}`,
          onClick: () => {
            setCurrentStatus("unconfirm")
            fetchCvHistory("unconfirm")
          }
        },
        {
          text: `管理済${props.confirmedCvCount > 0 ? `(${props.confirmedCvCount})` : ""}`,
          onClick: () => {
            setCurrentStatus("confirm")
            fetchCvHistory("confirm")
          }
        }
      ]}
    />
  ])

  const topRightButtons = ([])


  const historyList = cvHistories.length ? [
    <tr>
      <th>インフルエンサー</th>
      <th>購入発生日時</th>
      <th>成果報酬額</th>
      <th>自動確定日</th>
      <th>確定/取消</th>
    </tr>
  ] : []

  cvHistories.forEach((history, index) => {
    historyList.push(
      <tr>
        <td>
          <div className={styles.influencer_id_contaienr}>
            <div className={styles.influencer_icon}>
              <div />
            </div>
            <div className={styles.influencer_id}
              onClick={(e) => (
                setInfluencerId(history.affiliate_link.entry.influencer.id),
                setInfluencerDetailModalStatus(true)
              )}
            >
              ID: {history.affiliate_link.entry.influencer.id} {history.affiliate_link.entry.influencer.name}
            </div>
          </div>
        </td>
        <td>
          {Moment(history.created_at).format("YYYY/")}
          <span className={styles.history_create_md}>
            {Moment(history.created_at).format("MM/DD　HH:mm")}
          </span>
        </td>
        <td>
          <div className={styles.histroy_center}>
            { history.reward }円
          </div>
        </td>
        <td>
          <div className={styles.histroy_center}>
            残り {history.until_due_date} 日
          </div>
        </td>
        <td>
          <div className={styles.histroy_center}>
            {(!history.approved_at && !history.rejected_at)  && (
              <RoundBtn
                text="取り消し"
                type="check"
                color="gray-border"
                onClick={() => {
                  setCvHistory(history)
                  setModalStatus(true)
                  }
                }
              />
            )}
            {history.approved_at && "確定済" }
            {history.rejected_at && "取り消し済" }
          </div>
        </td>
      </tr>
    )
  })

  return (
    <div>
      <Layout
        topLeftButtons={topLeftButtons}
        topRightButtons={topRightButtons}
        table={historyList}
      />
      <Modal
        status={modalStatus}
        setState={setModalStatus}
        >
        <div className={`${styles.modal_btn_container}`}>
          {cvHistory && (
            <div>
              <div>{cvHistory.affiliate_link.entry.influencer.name} さんの発生を取り消しますか？</div>
              <div className={styles.modal_btn_area}>
                <RoundBtn
                  text="取り消し"
                  type="check"
                  color="gray-border"
                  onClick={() => unConfirmCvHistory()}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
      <InfluencerDetailModal
        influencerId={influencerId}
        influencerDetailModalStatus={influencerDetailModalStatus}
        setInfluencerDetailModalStatus={setInfluencerDetailModalStatus}
      />
    </div>
  )
}

export default Management
