
import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styles from "./Slick.scss"
import PrevArrow from "./Slick/prevArrow.svg"
import NextArrow from "./Slick/nextArrow.svg"

const propTypes = {
  images: PropTypes.array.isRequired,
  arrows: PropTypes.boolean
}

const Slick = (props) => {
  const settings = {
    accessibility: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 3,
    arrows: props.arrows || false,
    dots: false,
    swipe: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  }

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        {props.images}
      </Slider>
    </div>
  )
}

Slick.propTypes = propTypes

export default Slick
