import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import Select from "../../../FormTags/Select.js"
import HashTag from "../../../FormTags/HashTag.js"
import styles from "./Step04.scss"
import ReactTooltip from 'react-tooltip'
import QuestionPng from "./question.png"

const propTypes = {
  stepBackBtn: PropTypes.object,
  projectAdTypes: PropTypes.array.isRequired,
  productName: PropTypes.string.isRequired,
  nextBtn: PropTypes.object.isRequired,
}

const Step04 = (props) => {
  const [hashtags, setHashtags] = useState(props.hashtags || [])
  const [projectAdTypeId, setProjectAdTypeId] = useState(props.projectAdTypeId || 0)

  const checkValues = (
    isGreaterThanToZero(projectAdTypeId) &&
    isGreaterThanToZero(hashtags.length)
  )

  let projectAdTypeList = []
  props.projectAdTypes.forEach((projectAdType) => {
    projectAdTypeList.push(
      {
        value: projectAdType.id,
        name: `#${projectAdType.name}`,
      }
    )
  })

  return (
    <div>
      <div className={styles.header}>
        {props.stepBackBtn}
        {props.saveBtn}
      </div>

      <h2>4/6 ハッシュタグを決めましょう</h2>

      <Select
        label="広告表示"
        id="project_project_ad_type_id"
        name="project[project_ad_type_id]"
        options={projectAdTypeList}
        value={projectAdTypeId}
        setState={setProjectAdTypeId}
        prompt={"広告表示を選択してください"}
        required={true}
        onChange={props.onChange}
      />

      <div>
        <label>
          投稿に含めるハッシュタグ (#)
          <span data-tip={"インフルエンサーにご希望のハッシュタグを投稿文章内に含めてもらうことが可能です。"}>
            <img src={QuestionPng} className={styles.question_mark} />
            <ReactTooltip effect="solid" place="right" backgroundColor="white" textColor="#4F4F4F" />
          </span>
        </label>
      </div>
      <p className={styles.tag_description}>追加したいアカウントIDを入力し、スペースキーまたはエンターキーを押して下さい。<br />※ ハッシュタグ入力時に「#」は不要です</p>

      <HashTag
        id="hashtag_name"
        name="hashtags[][name]"
        value={hashtags}
        setState={setHashtags}
        tagPosition='outside'
        placeholder="maro_hashtag"
        onChange={props.onChange}
      />

      {checkValues && (
        props.nextBtn
      )}
    </div>
  )
}

Step04.propTypes = propTypes

export default Step04
