import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import Submit from "../FormTags/Submit.js"
import ErrorMessages from "../../Shared/RailsForm/ErrorMessages.js"
import styles from "./Edit.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
  influencer: PropTypes.object.isRequired
}

const defaultProps = {
  errors: [],
}

const Edit = (props) => {
  const influencerInvitationUpdatePath = `/influencer/invitation`
  const [password, setPassword] = useState();

  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <RailsForm
          action={influencerInvitationUpdatePath}
          patch={true}
        >
          <h2 className={styles.form_title}>アカウント登録</h2>
          {props.errors.length !== 0 && (
            <ErrorMessages messages={props.errors} />
          )}

          <div>
            <label>メールアドレス</label>
            <p>{props.influencer.email}</p>
          </div>

          <TextField
            label="パスワード"
            id="influencer_password"
            name="influencer[password]"
            type="password"
            value={password}
            setState={setPassword}
            required={true}
          />

          <input type='hidden' name="influencer[invitation_token]" value={props.influencer.invitation_token} />

          <Submit className={styles.submit_button} text={"登録する"} />
        </RailsForm>
      </div>
    </div>
  )
}

Edit.propTypes = propTypes
Edit.defaultProps = defaultProps

export default Edit
