import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import Checkbox from "../../../../FormTags/Checkbox.js"

const propTypes = {
  operationSystems: PropTypes.array.isRequired,
  setAppInput: PropTypes.func.isRequired,
}

const AppInput = (props) => {
  const project = props.project
  const product = project && project.product
  const product_operation_systems = product && product.product_operation_systems
  const operation_system_ids = product_operation_systems && product_operation_systems.map(po => po.operation_system_id) || []
  const operationsystemIds = operation_system_ids

  useEffect(() => {
    props.setAppInput(isGreaterThanToZero(operationsystemIds.length))
  }, [])

  const changeOsArray = (value) => {
    let array = operationsystemIds
    operationsystemIds.includes(value) ? array.pop(value) : array.push(value)
    props.setAppInput(isGreaterThanToZero(array.length))
  }

  let operationSystemList = []
  props.operationSystems.forEach((operationSystem) => {
    operationSystemList.push(
      {
        label: operationSystem.name,
        id: `product_operation_system_operation_system_id_${operationSystem.id}`,
        value: operationSystem.id,
        onChange: (e) => {
          props.onChange(e)
          changeOsArray(e.target.value)
        }
      }
    )
  })

  return (
    <Checkbox
      label="対応OS"
      name="product_operation_system[][operation_system_id]"
      elements={operationSystemList}
      value={operation_system_ids}
    />
  )
}

AppInput.propTypes = propTypes

export default AppInput
