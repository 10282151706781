import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./Tile.scss"

const propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
}

const defaultProps = {
  content: "",
  className: "",
}

const Tile = (props) => {
  return (
    <div className={`${styles.container} ${props.className}`}>
      {props.content}
    </div>
  )
}

Tile.propTypes = propTypes
Tile.defaultProps = defaultProps

export default Tile