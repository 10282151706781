import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import Logo from "./Header/logo.png"
import styles from "./Header.scss"

const Header = () => {
  const rootPath = `/`
  const influencersPath = `/for-influencers`
  const currentPath = location.pathname

  return (
    <header className={styles.header}>
      <a href={rootPath}><img src={Logo} /></a>
    </header>
  )
}

export default Header
