import "core-js/stable"
import "regenerator-runtime/runtime"
import React, {useState} from 'react'
import PropTypes from "prop-types"
import styles from "./Categories.scss";
import CheckImg from "./Categories/check.png"
import Category19 from "./Categories/19.png"
import Category20 from "./Categories/20.png"
import Category21 from "./Categories/21.png"
import Category22 from "./Categories/22.png"
import Category23 from "./Categories/23.png"
import Category24 from "./Categories/24.png"
import Category25 from "./Categories/25.png"
import Category26 from "./Categories/26.png"
import Category27 from "./Categories/27.png"
import Category28 from "./Categories/28.png"
import Category29 from "./Categories/29.png"
import Category30 from "./Categories/30.png"
import Category31 from "./Categories/31.png"
import Category32 from "./Categories/32.png"
import Category33 from "./Categories/33.png"
import Category34 from "./Categories/34.png"
const categoryImage = {19: Category19, 20: Category20, 21: Category21, 22: Category22, 23: Category23, 24: Category24, 25: Category25, 26: Category26, 27: Category27, 28: Category28, 29: Category29, 30: Category30, 31: Category31, 32: Category32, 33: Category33, 34: Category34}

const propTypes = {
  categories: PropTypes.array.isRequired,
  categoryIds: PropTypes.array,
  label: PropTypes.bool
}

const defaultProps = {
  categoryIds: [],
  label: true
}

const Categories = (props) => {
  const [categoryIds, setCategoryIds] = useState(props.categoryIds)
  let categoryList = []
  props.categories.forEach((category, index) => {
    if(category.mainly_displayed) {
      let subCategoryList = []
      category.children.forEach ((subCategory, subIndex) => {
        subCategoryList.push(
          <div key={`${index}-${subIndex}`}>
            <label>
              <div className={styles.category_box}>
                {categoryIds.includes(subCategory.id) ? <img className={styles.check_image} src={CheckImg}/>: ""}
              {categoryImage[subCategory.id] ?
                  <img className={`${styles.category_image} ${categoryIds.includes(subCategory.id) ? styles.checked : ""}`} src={categoryImage[subCategory.id]} /> :
                  <div className={styles.empty_category_image}><div>{subCategory.name}</div></div>
              }
              </div>
              <input
                type="checkbox"
                name="influencer[category_ids][]"
                value={subCategory.id}
                disabled={categoryIds.length === 3 && !categoryIds.includes(subCategory.id)}
                defaultChecked={categoryIds.includes(subCategory.id)}
                onChange={() => {
                  let array = []
                  const elements = document.querySelectorAll("input[name='influencer[category_ids][]']")
                  elements.forEach((element) => {
                    if (element.checked) { array.push(Number(element.value)) }
                  })
                  setCategoryIds(array)
                }}
                required={categoryIds.length === 0}
              />
              <div className={styles.label}>{subCategory.name}</div>
            </label>
          </div>
        )
      })
      categoryList.push(
        <React.Fragment key={index}>
          <label>{category.name}</label>
          <div className={styles.sub_categories}>
            {subCategoryList}
          </div>
        </React.Fragment>
      )
    }
  })
  return (
    <>
      { props.label &&
        <>
          <label>投稿のカテゴリー</label>
          <div>普段の投稿に近いカテゴリーをお選びください。</div>
        </>
      }
      {categoryList}
    </>
  )
}

Categories.propTypes = propTypes
Categories.defaultProps = defaultProps

export default Categories
