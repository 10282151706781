import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import Checkbox from "../Checkbox.js"

const propTypes = {
  categories: PropTypes.array.isRequired,
}

const CheckboxCategories = (props) => {
  let categories = []
  props.categories.forEach((category) => {
    categories.push({
      label: category.name,
      id: category.id,
      value: category.id
    })
  })

  return (
    <Checkbox
      label={props.label}
      id={props.id}
      name={props.name}
      elements={categories}
      value={props.value}
      setState={props.setState}
      direction={props.direction}
    />
  )
}

CheckboxCategories.propTypes = propTypes

export default CheckboxCategories