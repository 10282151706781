import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import SocialNetworks from "./Step03/SocialNetworks.js"
import ReactTooltip from 'react-tooltip'
import HashTag from "../../../FormTags/HashTag.js"
import QuestionPng from "./question.png"
import styles from "./Step03.scss"

const propTypes = {
  stepBackBtn: PropTypes.object,
  isSnsList: PropTypes.array.isRequired,
  socialNetworks: PropTypes.array.isRequired,
  sociaNetworkPostTypes: PropTypes.array.isRequired,
  socialNetworkSetCountList: PropTypes.array.isRequired,
  nextBtn: PropTypes.object.isRequired,
}

const Step03 = (props) => {
  const [isSocialNetworks, setIsSocialNetworks] = useState(false)
  const [accountTags, setAccountTags] = useState(props.accountTagNames || [])

  return (
    <div>
      <div className={styles.header}>
        {props.stepBackBtn}
        {props.saveBtn}
      </div>

      <h2>3/6 どんな投稿をしてもらいたいですか？</h2>

      <SocialNetworks
        socialNetwork={props.socialNetworks[0]}
        sociaNetworkPostTypes={props.sociaNetworkPostTypes}
        socialNetworkSetCount={props.socialNetworkSetCountList[0]}
        isSocialNetworks={isSocialNetworks}
        setIsSocialNetworks={setIsSocialNetworks}
        project={props.project}
        onChange={props.onChange}
        projectId={props.project?.id || props.projectId}
      />
      <div>
        <div>
          <label>
            タグ付・メンション先のインスタグラムアカウント (@)
            <span data-tip={"インフルエンサーにご希望のアカウントのタグ付・メンションをしてもらうことが可能です。"}>
              <img src={QuestionPng} className={styles.question_mark} />
              <ReactTooltip effect="solid" place="right" backgroundColor="white" textColor="#4F4F4F" />
            </span>
          </label>
          <p className={styles.tag_description}>追加したいアカウントIDを入力し、スペースキーまたはエンターキーを押して下さい。<br />※ アカウントID入力時に「@」は不要です</p>
        </div>

        <HashTag
          id="project_account_tag"
          name="account_tags[][name]"
          placeholder="maro_instagram"
          value={accountTags}
          setState={setAccountTags}
          tagPosition='outside'
          prefix="@"
          onChange={props.onChange}
        />
      </div>

      {isGreaterThanToZero(accountTags.length) && isSocialNetworks && (
        props.nextBtn
      )}
    </div>
  )
}

Step03.propTypes = propTypes

export default Step03
