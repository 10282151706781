import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import styles from "./SponsorHeader.scss"
import LogoImg from "./SponsorHeader/logo.png"
import RailsForm from "../../Shared/RailsForm.js"

const SposorHeader = (props) => {
  const influencerLpPath = `/for-influencers`
  const blogUrl = `https://blog.maro.mu/`
  const loginPath = `/staff/sign_in`

  return (
    <header className={styles.common_header}>
      <a href="/" >
        <img src={LogoImg} className={styles.head_logo} />
      </a>
      { !props.logoOnly &&
        <nav>
          <ul>
            <li>
              <a href={influencerLpPath} className={styles.sponsor_lp_pc_only}>
                インフルエンサー様はこちら
              </a>
            </li>
            <li>
              <a href={blogUrl} target="_blank" rel="noopenner nofollow">
                BLOG
              </a>
            </li>
            <li>
              <a href={loginPath}>
                LOGIN
              </a>
            </li>
            <li>
              <a href={"/contacts/new"} className={styles.contact_btn}>
                 新規登録/お問い合わせ
              </a>
            </li>
          </ul>
        </nav>
      }
    </header>
  )
}

export default SposorHeader
