import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../Shared/RailsForm.js"
import TextField from "../FormTags/TextField.js"
import ErrorMessages from "../../Shared/RailsForm/ErrorMessages.js"
import styles from "./New.scss"

const propTypes = {
  errors: PropTypes.array.isRequired,
}

const defaultProps = {
  errors: [],
}

const New = (props) => {
  const [email, setEmail] = useState("")
  const staffPasswordPath = `/staff/password`
  const newStaffSessionPath = `/staff/sign_in`

  const elements = (
    <>
      <h2 className={styles.form_title}>パスワード再設定</h2>
      <TextField
        label="メールアドレス"
        id="staff_email"
        name="staff[email]"
        value={email}
        setState={setEmail}
      />
      <div className={styles.form_link}>
        <a href={newStaffSessionPath}>
          キャンセル
        </a>
      </div>
      <button
        type="submit"
        className={styles.form_submit}
      >送信</button>
    </>
  )
  return (
    <div className={styles.container}>
      {props.errors.length !== 0 && (
          <ErrorMessages messages={props.errors} />
      )}
      <div className={styles.form_container}>
        <RailsForm
          action={staffPasswordPath}
          elements={elements}
        />
      </div>
    </div>
  )
}

New.propTypes = propTypes
New.defaultProps = defaultProps

export default New
