import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./Submit.scss"

const propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  position: PropTypes.string,
  onClick: PropTypes.func,
  confirm: PropTypes.bool,
  sending: PropTypes.bool,
  disableWith: PropTypes.string
}

const defaultProps = {
  className: "",
  position: "right",
  onClick: null,
  confirm: false,
  sending: false,
  disableWith: null
}

const Submit = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if(props.sending != undefined) {
      setSending(props.sending)
      setDisabled(props.sending)
    }
  }, [props.sending])

  const position = () => {
    if(props.position === "right") {
      return styles.right
    } else if(props.position === "left") {
      return styles.left
    } else if(props.position === "center") {
      return styles.center
    } else {
      ""
    }
  }

  const handleSubmit = (e) => {
    // 多重送信防止
    if (disabled) { return e.preventDefault() }

    setDisabled(true)

    setTimeout(() => {
      setSending(false)
      setDisabled(false)
    }, 30000)

    // 確認オプションが有効な場合は確認する
    if (!props.confirm) {
      if(props.disableWith) {
        setSending(true)
      }

      return
    }

    if (window.confirm('本当に実行しますか？')) {
      if(props.disableWith) {
        setSending(true)
      }

      return true
    }
    else {
      window.alert('キャンセルしました')
      setDisabled(false)
      e.preventDefault()
      return false
    }
  }

  const buttonStyle = props.buttonStyle === "circle" ? styles.circle : ""

  return (
    <div className={props.className}>
      <div className={`${styles.submit} ${position()}`}>
        <span onClick={props.onClick} className={`${sending ? styles.sending : ""} ${buttonStyle}`}>
          {sending ? (props.disableWith || "送信中...") : props.text}
          <button
            type="submit"
            onClick={(e) => handleSubmit(e)}
          />
        </span>
      </div>
    </div>
  )
}

Submit.propTypes = propTypes
Submit.defaultProps = defaultProps

export default Submit
