import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect, useRef } from 'react'
import styles from "./Sticky.scss"

const Sticky = (props) => {
  const stickyRef = useRef(null)
  const boxTop = useRef(0)
  const [top, setTop] = useState()

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current && boxTop.current) {
        if (window.pageYOffset > boxTop.current) {
          stickyRef.current.classList.add(styles.sticky)
          stickyRef.current.classList.add(props.className)
        } else {
          stickyRef.current.classList.remove(styles.sticky)
          stickyRef.current.classList.remove(props.className)
        }
      }
    };

    if (stickyRef.current) boxTop.current = stickyRef.current.offsetTop;

    window.addEventListener('scroll', handleScroll);
  }, [])

  return (
    <div ref={stickyRef}>
      {props.children}
    </div>
  )
}

export default Sticky
