import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import styles from "./Form.scss"
import TextField from "../../FormTags/TextField.js"
import DateSelect from "../../FormTags/Date.js"
import Moment from "moment"

const propTypes = {
  influencer: PropTypes.object,
  agency_influencer: PropTypes.object,
}

const defaultProps = {
}

const Form = (props) => {
  const [email, setEmail] = useState(props.influencer?.email)
  const [rewardDistributionRate, setRewardDistributionRate] = useState()
  const minimunmDistributionStartAt = Moment().add(2, 'days').toISOString().substr(0, 10)
  const [rewardDistributionStartAt, setRewardDistributionStartAt] = useState("")

  return (
    <>
      <div className={styles.form_block}>
        <div className={styles.form_block_title_container}>
          <h3>インフルエンサーの基本情報</h3>
        </div>

        <div className={styles.form_block_field_container}>
          <div className={styles.form_block_field_contents}>
            { props.new &&
              <TextField
                label="メールアドレス"
                id="influencer_email"
                name="influencer[email]"
                email={true}
                value={email}
                setState={setEmail}
                required={true}
              />
             }
             { props.edit &&
               <>
                 <label>メールアドレス</label>
                 <p>{email}</p>
               </>
             }
          </div>
        </div>
      </div>
      <div className={styles.form_block}>
        <div className={styles.form_block_title_container}>
          <h3>インフルエンサーへの報酬分配</h3>
          <p className={styles.description}>インフルエンサーに報酬還元率が反映されます。（例：15%と設定した場合インフルエンサーからの確定売上の15%がインフルエンサーのデポジットに入ります）</p>
        </div>

        <div className={styles.form_block_field_container}>
          { props.new &&
            <>
              <label htmlFor="reward_distribution_rate">インフルエンサーへの報酬分配率</label>
              <div className={styles.rate_container}>
                <TextField
                  id="reward_distribution_rate"
                  name="reward_distribution[rate]"
                  value={rewardDistributionRate}
                  setState={setRewardDistributionRate}
                  required={true}
                  className={styles.rate_field}
                />
                <span>%</span>
              </div>
            </>
          }
          { props.edit &&
            <>
              <div>
                <p>現在の報酬分配率：{props.influencer.current_reward_distribution_rate}％</p>
              </div>
              <div className={styles.rate_container}>
                <DateSelect
                  id="reward_distribution_start_at"
                  name="reward_distribution[start_at]"
                  value={rewardDistributionStartAt}
                  setState={setRewardDistributionStartAt}
                  required={rewardDistributionRate}
                  className={styles.start_at_field}
                  min={minimunmDistributionStartAt}
                />
                <span>より</span>

                <TextField
                  id="reward_distribution_rate"
                  name="reward_distribution[rate]"
                  value={rewardDistributionRate}
                  setState={setRewardDistributionRate}
                  required={!!rewardDistributionStartAt}
                  className={styles.rate_field}
                />
                <span>%に変更</span>
              </div>
            </>
          }

        </div>
      </div>
    </>
  )
}

Form.propTypes = propTypes
Form.defaultProps = defaultProps

export default Form
