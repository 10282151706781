import "core-js/stable"
import "regenerator-runtime/runtime"
import React, { useState } from 'react'
import PropTypes from "prop-types"
import RailsForm from "../../../Shared/RailsForm.js"
import Submit from "../../FormTags/Submit.js"
import SelectYear from "../../FormTags/Select/Year.js"
import SelectMonth from "../../FormTags/Select/Month.js"
import SelectDays from "../../FormTags/Select/Days.js"
import SelectPrefecture from "../../FormTags/Select/Prefecture.js"
import SelectDesiredIncentivePerPost from "../../FormTags/Select/DesiredIncentivePerPost.js"
import Categories from "../../FormTags/Categories.js"
import SelectOccupation from "../../FormTags/Select/Occupation.js"

const propTypes = {
  influencer: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  prefectures: PropTypes.array.isRequired,
  visibleAttributes: PropTypes.array.isRequired,
}

const Form = (props) => {
  const influencer = props.influencer
  const [categoryIds, setCategoryIds] = useState(influencer.category_ids)
  const [birthYear, setBirthYear] = useState(influencer.birth_year)
  const [birthMonth, setBirthMonth] = useState(influencer.birth_month)
  const [birthDay, setBirthDay] = useState(influencer.birth_day)
  const [prefectureId, setPrefectureId] = useState(influencer.prefecture_id)
  const [desiredIncentivePerPost, setDesiredIncentivePerPost] = useState(influencer.desired_incentive_per_post)
  const [occupation, setOccupation] = useState(influencer.occupation?.id)
  const [occupationRequired, setOccupationRequired] = useState(false)
  const visibleAttributes = props.visibleAttributes
  const influencerAccountsPath = `/influencer/accounts`

  const onClickSubmit = (e) => {
    if(occupationRequired && !occupation) {
      e.preventDefault()

      return false
    } else {
      return true
    }
  }

  const elements = (
    <div>
      { visibleAttributes.includes("category_ids") &&
        <Categories categories={props.categories} categoryIds={categoryIds}/>
      }

      { visibleAttributes.includes("birth_year") &&
        <SelectYear
          label="年"
          id="influencer_birth_year"
          name="influencer[birth_year]"
          value={birthYear}
          setState={setBirthYear}
          required={true}
        />
      }

      { visibleAttributes.includes("birth_month") &&
        <SelectMonth
          label="月"
          id="influencer_birth_month"
          name="influencer[birth_month]"
          value={birthMonth}
          setState={setBirthMonth}
          required={true}
        />
      }
      { visibleAttributes.includes("birth_day") &&
        <SelectDays
          label="日"
          id="influencer_birth_day"
          name="influencer[birth_day]"
          value={birthDay}
          setState={setBirthDay}
          required={true}
        />
      }
      { visibleAttributes.includes("prefecture_id") &&
        <SelectPrefecture
          label="都道府県"
          id="influencer_prefecture_id"
          name="influencer[prefecture_id]"
          prefectures={props.prefectures}
          value={prefectureId}
          setState={setPrefectureId}
          required={true}
        />
      }
      { visibleAttributes.includes("occupation_id") &&
        <SelectOccupation label={"職業（任意）"} name={"occupation_id"} occupations={props.occupations} value={occupation} setState={setOccupation} setRequired={setOccupationRequired} />
      }
      { visibleAttributes.includes("desired_incentive_per_post") &&
        <SelectDesiredIncentivePerPost
          label="1投稿あたりの希望報酬"
          id="influencer_desired_incentive_per_post"
          name="influencer[desired_incentive_per_post]"
          value={desiredIncentivePerPost}
          setState={setDesiredIncentivePerPost}
          required={true}
        />
      }
      <Submit text={"保存する"} onClick={onClickSubmit} buttonColor={"brown"} />
    </div>
  )

  return (
    <>
      <RailsForm
        action={influencerAccountsPath}
        elements={elements}
        remote={true}
        patch={true}
      />
    </>
  )
}

Form.propTypes = propTypes

export default Form

