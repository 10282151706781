import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"

const propTypes = {
  messages: PropTypes.array.isRequired,
}

const defaultProps = {
  messages: [],
}

const ErrorMessages = (props) => {
  let errorMessageList = []
  props.messages.forEach((message, index) => {
    errorMessageList.push(
      <li key={index}>{message}</li>
    )
  })

  return (
    <>
      {props.messages && (
        <>{errorMessageList}</>
      )}
    </>
  )
}

ErrorMessages.propTypes = propTypes
ErrorMessages.defaultProps = defaultProps

export default ErrorMessages