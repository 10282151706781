import "core-js/stable"
import "regenerator-runtime/runtime"
import React from 'react'
import PropTypes from "prop-types"
import styles from "./TextField.scss"

const propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  setState: PropTypes.func,
  required: PropTypes.bool,
}

const defaultProps = {
  type: "text",
  className: "",
  value: "",
  placeholder: "",
  onClick: null,
  onChange: null,
  setState: null,
  required: false,
}

const TextField = (props) => {
  const value = props.type === "number" ? Number(props.value) : (
    props.type === "password" ? undefined : props.value
  )

  return (
    <div className={props.className}>
      <label>{props.label}</label>
      <input
        className={styles.textfield}
        type={props.type}
        id={props.id}
        name={props.name}
        value={value}
        placeholder={props.placeholder}
        onClick={props.onClick}
        onChange={props.onChange}
        onInput={(e) => props.setState ? props.setState(e.target.value) : null}
        required={props.required}
      />
    </div>
  )
}

TextField.propTypes = propTypes
TextField.defaultProps = defaultProps

export default TextField